import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

// context
import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'

// selectors
import { getRequiredTickerDataSelector } from '~/src/Redux/StockTicker/Selectors'

// types
import {
  DsStack,
  DsRemixIcon,
  DsTypography,
  DsStackProps,
  DsTypographyProps,
  DsRemixIconProps
} from '@am92/react-design-system'
import { getCurrencyFormat } from '~/src/Utils/global'

interface IStockTickerWithNetPercentChangeProps {
  scriptId: string
  closeprice: number
  segment: string
  showNetChange: boolean
  showPercentChange: boolean
  showChangeIcon: boolean
  NetChangeWrapperProps?: DsStackProps
  NetChangeTypoProps?: DsTypographyProps
  PercentChangeTypoProps?: DsTypographyProps
  NetChangeIconProps?: Omit<DsRemixIconProps, 'ref'>
  netChangeNumberFormatOptions?: Intl.NumberFormatOptions
  percentChangeNumberFormatOptions?: Intl.NumberFormatOptions
}

class StockTickerWithNetPercentChange extends PureComponent<
  IStockTickerWithNetPercentChangeProps & PropsFromRedux
> {
  getTypoColor = () => {
    const { LTP = 0, NET_CHANGE = 0 } = this.props
    return LTP
      ? NET_CHANGE > 0
        ? 'var(--ds-colour-iconPositive)'
        : 'var(--ds-colour-iconNegative)'
      : 'var(--ds-colour-typoPrimary)'
  }

  getIconAttributes = (): {
    className: DsRemixIconProps['className']
    color: DsRemixIconProps['color']
  } => {
    const { NET_CHANGE = 0 } = this.props
    return {
      className: `ri-arrow-${NET_CHANGE > 0 ? 'up' : 'down'}-fill`,
      color: NET_CHANGE > 0 ? 'success' : 'error'
    }
  }

  getNetChangeValue = () => {
    const { NET_CHANGE, segment, netChangeNumberFormatOptions } = this.props
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: segment === 'CURR' ? 4 : 2,
      signDisplay: 'exceptZero',
      style: 'decimal'
    }
    return `${getCurrencyFormat(NET_CHANGE, {
      ...options,
      ...netChangeNumberFormatOptions
    })} `
  }

  getPercentageChangeValue = () => {
    const { PERCENT_CHANGE, percentChangeNumberFormatOptions } = this.props
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: 2, //NOTE - in case of percent change no need to show 4 decimals for currencies
      signDisplay: 'never',
      style: 'percent'
    }
    return getCurrencyFormat(PERCENT_CHANGE / 100, {
      ...options,
      ...percentChangeNumberFormatOptions
    })
  }

  renderNetPercentChange = () => {
    const {
      LTP = 0,
      NetChangeTypoProps,
      PercentChangeTypoProps,
      NetChangeIconProps,
      showPercentChange,
      showNetChange,
      showChangeIcon
    } = this.props
    //NOTE -  here we do not use closeprice instead of LTP since need to know whether ltp is cominh

    const jsx = []

    if (showNetChange) {
      const typoColor = this.getTypoColor()
      jsx.push(
        <DsTypography
          color={typoColor}
          variant='supportRegularInfo'
          {...NetChangeTypoProps}
        >
          {this.getNetChangeValue()}
        </DsTypography>
      )
    }

    if (showPercentChange) {
      const typoColor = this.getTypoColor()
      jsx.push(
        <DsTypography
          color={typoColor}
          variant='supportRegularInfo'
          {...NetChangeTypoProps}
          {...PercentChangeTypoProps}
        >
          {this.getPercentageChangeValue()}
        </DsTypography>
      )
    }

    if (showChangeIcon && LTP) {
      jsx.push(
        <DsRemixIcon
          fontSize='bitterCold'
          {...this.getIconAttributes()}
          {...NetChangeIconProps}
          sx={{
            display: 'flex',
            ...NetChangeIconProps?.sx
          }}
        />
      )
    }
    return jsx
  }

  render() {
    const { NetChangeWrapperProps } = this.props
    return (
      <DsStack
        flexDirection='row'
        alignItems='center'
        {...NetChangeWrapperProps}
      >
        {this.renderNetPercentChange()}
      </DsStack>
    )
  }
}

const mapStateToProps = (
  state: TTickerStore,
  ownProps: IStockTickerWithNetPercentChangeProps
) => {
  const { scriptId, closeprice, segment } = ownProps
  const { LTP, NET_CHANGE, PERCENT_CHANGE } = getRequiredTickerDataSelector(
    state,
    scriptId,
    closeprice,
    segment
  )
  return {
    LTP,
    NET_CHANGE,
    PERCENT_CHANGE
  }
}

const connector = connect(mapStateToProps, null, null, {
  context: tickerContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(StockTickerWithNetPercentChange)
