import { createSelector } from '@reduxjs/toolkit'
import { TAppStore } from '~/src/Configurations/AppStore'
export const SLICE_NAME = 'alerts'

const select = (state: TAppStore) => state[SLICE_NAME]
const selectToggle = (state: TAppStore) =>
  state[SLICE_NAME].toggleAlertsForm.toggle

export const getAlertsData = createSelector(select, alerts => alerts.data)
export const getAlertsDataLength = createSelector(
  select,
  alerts => alerts.data?.length
)
export const getAlertsFormDetails = createSelector(
  select,
  alerts => alerts.toggleAlertsForm
)

export const getAlertsFormToggleSelector = createSelector(
  selectToggle,
  toggle => toggle
)
