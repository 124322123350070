import React, { PureComponent } from 'react'
import { DsBox } from '@am92/react-design-system'
import SidebarTabs from '../Sidebar/Components/SidebarTabs'

export interface IWatchlistHeaderProps {}
class WatchlistHeader extends PureComponent<IWatchlistHeaderProps> {
  render() {
    return (
      <DsBox
        sx={{
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          mt: {
            md: 'var(--ds-spacing-bitterCold)',
            xs: 'var(--ds-spacing-zero)'
          },
          borderRadius:
            'var(--ds-radius-glacial) var(--ds-radius-glacial) var(--ds-radius-zero) var(--ds-radius-zero)',
          position: { md: 'unset', xs: 'sticky' },
          zIndex: { md: 'unset', xs: '1' },
          top: {
            md: 'unset',
            xs: 'calc(var(--ds-spacing-molten) + var(--ds-spacing-glacial) + var(--ds-spacing-deepFreeze))'
          }
        }}
      >
        <SidebarTabs />
      </DsBox>
    )
  }
}

export default WatchlistHeader
