import React from 'react'
import {
  DsTableCell,
  DsTableRow,
  DsTypography
} from '@am92/react-design-system'

import useResponsiveBreakpoints from '~/src/hooks/useResponsiveBreakpoints'

interface IMarketDepthSkeletonProps {
  type: string
}

const MarketDepthSkeleton = (props: IMarketDepthSkeletonProps) => {
  const { isDesktop } = useResponsiveBreakpoints()

  const renderSkeleton = () => {
    const { type } = props
    if (type === 'buy') {
      return (
        <>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
        </>
      )
    }
    if (type === 'sell') {
      return (
        <>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
          <DsTableRow
            sx={{ backgroundColor: 'var(--ds-colour-surfaceSecondary)' }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                0.00
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                0
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
        </>
      )
    }
  }

  return renderSkeleton()
}

export default MarketDepthSkeleton
