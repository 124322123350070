// TODO - CLEANUP: @vrushabh
// 1. define types
// 2. use single selected if required
import React, { Component } from 'react'
import {
  DsBox,
  DsDrawer,
  DsPopup,
  enqueueNotistack,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import { format, parse } from 'date-fns'

import SearchStocks from '../../Components/Sidebar/Components/Search2'
import ReviewSip from './Components/ReviewSip'
import SipCreationPage from './Components/SipCreationPage'
import SipScriptsPage from './Components/SipScriptsPage'

import GratificationDialog from '../GratificationDialog/GratificationDialog'
import PostAPIResponseDialog from '../PostAPIResponseDialog/PostAPIResponseDialog'

import {
  updateModifySipOrderIndicator,
  updateModifySipPopup,
  updateOrderSipPageModalIndicator
} from '~/src/Redux/Indicators/Reducer'
import {
  getOrderPageSipModalIndicator,
  isEditingSipSelector,
  isModifingSelector,
  isReactivatingSelector,
  openModifySip
} from '~/src/Redux/Indicators/Selectors'
import {
  changeOrderbookTab,
  clearSipInfo,
  updateReactivatSip,
  updateSipInfo,
  updateSipSelectedScript
} from '~/src/Redux/Orders/reducer'
import { getSelectedScripts, getSipInfo } from '~/src/Redux/Orders/selectors'
import createSipOrderAction, {
  T_GET_CREATE_SIP_PAYLOAD
} from '~/src/Redux/Orders/Services/createSip.Service'
import modifySipOrderAction, {
  T_GET_MODIFY_SIP_PAYLOAD
} from '~/src/Redux/Orders/Services/modifySip.Service'
import { clearSearchAction } from '~/src/Redux/Search/Actions'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { PLEDGE_FAILED } from '~/src/Constants/ASSET_MAP'
import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import { capitalizeFirstLetter } from '~/src/Utils/global'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { ORDERBOOK_TAB } from '~/src/Pages/Orderbook/Orderbook.constants'
import { SIPClevertap } from '~/src/Pages/Orderbook/SIP.ClevertapEvents'

type ActionTypes = {
  updateSipOpenState: (data: boolean) => any
  updateSipNameIndicator: (data: boolean) => any
  updateModiFySipIndicator: (data: boolean) => any
  updateSipInfo: (data: any) => any
  updateOpenModify: (data: any) => any
  createSip: (data: any) => any
  modifySip: (data: any) => any
  clearSipInfo: (data: any) => any
  changeOrderbookTab: (orderbookTab: string) => Promise<any>
  updateSelectedScripts: (data: any) => Promise<any>
  clearSearch: () => Promise<void>
  updateReactivatSipAction: (item: boolean) => any
}

export interface ISipModalProps extends IWithRouterProps, IwithBreakpoints {
  actions: ActionTypes
  isModifing: boolean
  orderPageSipModalIndicator: boolean
  openSipNamePopup: boolean
  sipInfo: any
  selectedSipScripts: any
  openModify: boolean
  isEditingSip: boolean
  isReactivating: boolean
  handleError: (res: any) => any
}

class SipModal extends Component<ISipModalProps> {
  state = {
    // sipName: '',
    sipNameError: false,
    sipNameHelperText: '',
    showSelecetStock: false,
    showGratification: false,
    showError: false,
    errrorMessage: '',
    showSearch: false,
    searchString: ''
  }
  componentWillUnmount(): void {
    const { actions } = this.props
    actions.updateModiFySipIndicator(false)
  }
  setShowSelecetStock = (value: boolean) => {
    this.setState({
      showSelecetStock: value
    })
  }
  setShowSearch = (value: boolean) => {
    this.setState({
      showSearch: value
    })
    if (!value) {
      const { actions } = this.props
      this.setString('')
      actions.clearSearch()
    }
  }

  setString = (searchString: string) => {
    this.setState({
      searchString
    })
  }

  handleOnCloseNamePopup = async () => {
    const { actions } = this.props
    await actions.updateSipNameIndicator(false)
  }

  handleCreateSip = async () => {
    const { actions, sipInfo } = this.props
    const { sipName } = sipInfo
    if (!this.validateSipName(sipName.trim())) {
      await actions.updateSipNameIndicator(false)
      await actions.updateSipOpenState(true)
      return
    }
    await actions.updateSipNameIndicator(false)
    await actions.updateSipOpenState(false)
  }

  handleSipNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const sipName = e.target.value
    const { actions } = this.props
    const info = { name: 'sipName', value: sipName }
    await actions.updateSipInfo(info)
    this.validateSipName(sipName.trim())
  }

  validateSipName = (name: string) => {
    const response: any = { _isCustomError: false }
    this.setState({
      sipNameError: false,
      sipNameHelperText: ''
    })

    if (name === '') {
      this.setState({
        sipNameError: true,
        sipNameHelperText: 'Name can’t be empty'
      })
      return true
    } else if (name.length >= 18) {
      const errorMessage = 'Sip name should be 1-18 characters long'
      this.setState({
        sipNameError: true,
        sipNameHelperText: errorMessage
      })
      return true
    } else if (response?._isCustomError) {
      const errorMessage =
        'This name is already taken. Try a different SIP name.'
      this.setState({
        sipNameError: true,
        sipNameHelperText: errorMessage
      })
      return true
    } else {
      this.setState({
        sipNameError: false,
        sipNameHelperText: ''
      })
      return false
    }
  }
  formattedSipOrderDetailsSelector = (data: any[], sipType: string) => {
    const { isModifing } = this.props
    return data.map(item => {
      const amountOrQuantityValue =
        sipType === 'quantity' || sipType === 'Quantity'
          ? item.quantity
          : item.amount
      if (!isModifing) {
        return {
          scriptId: item.scriptId,
          exchange: item.exchange,
          amountOrQuantityValue: Number(amountOrQuantityValue)
        }
      } else {
        return {
          scriptId: item.scriptId,
          exchange: item.exchange,
          amountOrQuantityValue: Number(amountOrQuantityValue),
          action: item.action
        }
      }
    })
  }
  formatDateString = (dateString: string) => {
    const formattedDate = format(
      parse(dateString, 'dd/MM/yy', new Date()),
      'ddMMyyyy'
    )
    return formattedDate
  }

  formatDurationString = (durationString: string | number) => {
    if (typeof durationString === 'number') {
      return durationString
    }

    const parts = durationString?.split(' ')
    let formattedDuration = 12
    if (parts[1] === 'Year') {
      formattedDuration = Number(parts[0]) * 12
    } else formattedDuration = Number(parts[0])
    return formattedDuration
  }

  handleConfirmSip = async () => {
    const { actions, navigateTo, sipInfo, isModifing, handleError } = this.props
    const {
      sipName,
      frequency,
      duration,
      basedOn,
      startDate,
      orgSelectedScripts,
      sipReferenceNumber
    } = sipInfo

    new SIPClevertap().onClickConfirm(sipInfo, 'Confirm')

    const capitalizedString = capitalizeFirstLetter(basedOn)

    const formattedStartDate = this.formatDateString(startDate)

    const formattedScriptsDetails = this.formattedSipOrderDetailsSelector(
      orgSelectedScripts,
      basedOn
    )
    const formattedDuration = this.formatDurationString(duration)
    const requestData = !isModifing
      ? {
          segment: 'EQ',
          basketName: sipName,
          sipFrequency: frequency,
          sipPeriod: formattedDuration,
          sipType: capitalizedString,
          startDate: formattedStartDate,
          securities: formattedScriptsDetails
        }
      : {
          segment: 'EQ',
          basketName: sipName,
          sipAction: 'Modify',
          sipFrequency: frequency,
          sipPeriod: formattedDuration,
          sipType: capitalizedString,
          sipReferenceNumber: sipReferenceNumber,
          startDate: formattedStartDate,
          securities: formattedScriptsDetails
        }

    if (!isModifing) {
      const result = await actions.createSip(requestData)
      if (result?._isCustomError) {
        const { error } = result
        if (error.error) {
          this.setState({ showError: true, errrorMessage: error.error })
          console.log('failed API - createSIP')
        } else {
          handleError(result)
        }

        return
      }
    } else {
      const result = await actions.modifySip(requestData)
      if (result?._isCustomError) {
        const { error } = result
        console.log('failed API - createSIP')
        this.setState({ showError: true, errrorMessage: error.error })
        return
      }
    }
    this.setState({ showGratification: true })
    setTimeout(() => {
      actions.updateReactivatSipAction(true)
      actions.updateSipOpenState(false)
      actions.updateOpenModify(false)
      this.setState({ showGratification: false })
      actions.clearSipInfo({})
      actions.changeOrderbookTab(ORDERBOOK_TAB.SIP.value)
      navigateTo(APP_ROUTES.ORDERBOOK.pathname)
    }, 3000)
  }

  handleOnCloseReviewSIP = () => {
    const { actions } = this.props
    actions.updateOpenModify(false)
  }

  handleStockSelect = (scriptId: string) => {
    const { selectedSipScripts, isModifing, actions } = this.props

    new SIPClevertap().onClickAddStock('Added_Stock', {
      Scrip_Name: scriptId
    })

    let formattedData = {}

    if (selectedSipScripts.length >= 10) {
      enqueueNotistack({
        message: 'You can add up to 10 Scrips',
        autoHideDuration: 2000
      })
      this.setShowSearch(false)
      this.setString('')
      return
    }

    const { exchange, exchangeSymbol, coname, closeprice, segment } =
      (window as any).secMaster.getByScriptId(scriptId) || {}

    if (isModifing) {
      formattedData = {
        scriptId,
        exchangeSymbol,
        coname,
        exchange,
        action: 'Add',
        closeprice,
        segment
      }
    } else {
      formattedData = {
        scriptId,
        exchangeSymbol,
        coname,
        exchange,
        closeprice,
        segment
      }
    }
    actions.updateSelectedScripts([...selectedSipScripts, formattedData])
    this.setShowSearch(false)
    this.setString('')
    return
  }

  render() {
    const {
      orderPageSipModalIndicator,
      breakpoints,
      sipInfo,
      selectedSipScripts,
      openModify,
      actions,
      isModifing,
      isEditingSip,
      isReactivating
    } = this.props

    const { sipName } = sipInfo

    const {
      showSelecetStock,
      showGratification,
      showError,
      errrorMessage,
      showSearch,
      searchString
    } = this.state

    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const selectedScrips = selectedSipScripts?.length > 0
    return (
      <>
        {orderPageSipModalIndicator && (
          <DsDrawer
            PaperProps={{
              sx: {
                top: {
                  md: '4.8rem',
                  lg: '5.4rem',
                  xs: '0%'
                },
                height: {
                  md: 'calc(100% - 4.2rem)',
                  lg: 'calc(100% - 5.4rem)',
                  xs: '100%'
                }
              }
            }}
            sx={theme => ({
              position: 'absolute',
              zIndex: theme.zIndex.drawer
            })}
            anchor='right'
            open={orderPageSipModalIndicator}
            variant='persistent'
            hideBackdrop
          >
            {openModify && !isDesktop ? (
              <DsBox
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  width: { md: '25rem', xs: '100vw' },
                  animation: 'reverse',
                  overflow: 'hidden',
                  overscrollBehavior: 'contain'
                }}
              >
                <ReviewSip
                  handleConfirmSip={this.handleConfirmSip}
                  handleClose={() => this.handleOnCloseReviewSIP()}
                />
              </DsBox>
            ) : !isEditingSip &&
              selectedScrips &&
              !showSelecetStock &&
              !isReactivating ? (
              <SipScriptsPage
                sipInfo={sipInfo}
                selectedSipScripts={selectedSipScripts}
                showSelecetStock={showSelecetStock}
                setShowSelecetStock={this.setShowSelecetStock}
                setShowSearch={this.setShowSearch}
                showSearch={showSearch}
                isDesktop={isDesktop}
                searchString={searchString}
                setString={this.setString}
                handleStockSelect={this.handleStockSelect}
              />
            ) : (
              <SipCreationPage
                showSelecetStock={showSelecetStock}
                setShowSelecetStock={this.setShowSelecetStock}
                orderPageSipModalIndicator={orderPageSipModalIndicator}
                isDesktop={isDesktop}
                sipName={sipName}
                isEditingSip={isEditingSip}
                setShowSearch={this.setShowSearch}
                showSearch={showSearch}
                searchString={searchString}
                setString={this.setString}
                handleStockSelect={this.handleStockSelect}
              />
            )}
          </DsDrawer>
        )}

        {isDesktop && !isEditingSip && showSearch && (
          <DsPopup
            DsDialogProps={{
              open: showSearch,
              onClose: () => this.setShowSearch(false),
              keepMounted: false,
              sx: {
                '.MuiDialog-paper': {
                  top: {
                    md: 'var(--ds-spacing-warm)',
                    xs: '0%'
                  },
                  height: {
                    md: '500px',
                    xs: '100%'
                  }
                }
              }
            }}
            showClose={true}
            title={`Add Scrips to ${sipName}`}
          >
            <SearchStocks
              type={'sip'}
              openSearch={showSearch}
              searchString={searchString}
              setString={this.setString}
              handleStockSelect={this.handleStockSelect}
            />
          </DsPopup>
        )}

        {isDesktop && (
          <DsPopup
            DsDialogProps={{
              open: openModify,
              onClose: () => actions.updateOpenModify(false)
            }}
            showClose={true}
            title={'Review SIP'}
          >
            <ReviewSip
              handleConfirmSip={this.handleConfirmSip}
              handleClose={() => this.handleOnCloseReviewSIP()}
            />
          </DsPopup>
        )}
        <PostAPIResponseDialog
          type='dualAction'
          primaryText='Try Again'
          secondaryText='Close'
          primaryAction={() => {
            this.setState({ showError: false })
          }}
          secondaryAction={() => {
            this.setState({ showError: false })
          }}
          heading='SIP Order Failed !'
          description={errrorMessage}
          onClose={() => {
            this.setState({ showError: false })
          }}
          showClose={true}
          renderPostAPIDialog={showError}
          image={PLEDGE_FAILED}
        />
        <GratificationDialog
          isHRTAllowedSelect={false}
          showGratification={showGratification}
          heading={`Your SIP has been ${isModifing ? 'modified' : 'created'}!`}
          subHeading={
            'Please do not close this window while we redirect you to the order book'
          }
        />
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const orderPageSipModalIndicator = getOrderPageSipModalIndicator(state)

  const sipInfo = getSipInfo(state)
  const selectedSipScripts = getSelectedScripts(state)
  const openModify = openModifySip(state)
  const isModifing = isModifingSelector(state)
  const isEditingSip = isEditingSipSelector(state)
  const isReactivating = isReactivatingSelector(state)
  return {
    orderPageSipModalIndicator,
    sipInfo,
    selectedSipScripts,
    openModify,
    isModifing,
    isEditingSip,
    isReactivating
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    updateSipOpenState: (requestData: any) =>
      dispatch(updateOrderSipPageModalIndicator(requestData)),
    updateSipInfo: (requestData: any) => dispatch(updateSipInfo(requestData)),
    updateOpenModify: (requestData: any) =>
      dispatch(updateModifySipPopup(requestData)),
    createSip: (requestData: T_GET_CREATE_SIP_PAYLOAD) =>
      dispatch(createSipOrderAction(requestData)),
    modifySip: (requestData: T_GET_MODIFY_SIP_PAYLOAD) =>
      dispatch(modifySipOrderAction(requestData)),
    clearSipInfo: (requestData: any) => dispatch(clearSipInfo(requestData)),
    updateModiFySipIndicator: (requestData: any) =>
      dispatch(updateModifySipOrderIndicator(requestData)),
    changeOrderbookTab: (orderbookTab: string) =>
      dispatch(changeOrderbookTab(orderbookTab)),
    updateSelectedScripts: (val: any) => dispatch(updateSipSelectedScript(val)),
    clearSearch: () => dispatch(clearSearchAction()),
    updateReactivatSipAction: (requestData: boolean) =>
      dispatch(updateReactivatSip(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SipModal)
)
