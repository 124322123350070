import { createSelector } from '@reduxjs/toolkit'
import { _groupBy, _filter } from '~/src/Utils/lodash'
import { TAppStore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'dashboard'

const select = (state: TAppStore) => state[SLICE_NAME]
const selectHoldingFromPrimaryPortfolioSelector = (
  state: TAppStore,
  isinCode: string
) => state[SLICE_NAME]?.holdingQuantitiesForPrimaryPortfolio?.[isinCode]
const selectHoldingFromHoldingSummary = (state: TAppStore, scriptId: number) =>
  state[SLICE_NAME]?.HOLDING_SUMMARY_INDEX?.[scriptId]

export const getHoldingsFilter = createSelector(
  select,
  dashboard => dashboard?.holdingsFilter
)

export const getPledgeFilter = createSelector(
  select,
  dashboard => dashboard?.pledgeFilter
)

export const getHoldingsDetailsSelector = createSelector(
  select,
  dashboard => dashboard.holdingDetails
)
export const getPledgeDetails = createSelector(
  select,
  dashboard => dashboard.pledgeDetails
)
export const getPledgeDetailsNotAllowed = createSelector(
  select,
  dashboard => dashboard.pledgeDataNotAllowed
)

export const getPledgeSumData = createSelector(
  select,
  dashboard => dashboard?.pledgeData
)

export const getTradingHistorySelector = createSelector(select, dashboard =>
  _filter(
    dashboard.tradingHistory,
    item => item.transactionType == 'BUY' || item.transactionType == 'SELL'
  )
)

export const isDefaultDashboardFiltersEnabled = createSelector(
  select,
  dashboard => dashboard.isDefaultFiltersEnabled
)

export const getRecommendationCalls = createSelector(
  select,
  dashboard => dashboard.recommendationDataByScripId
)

export const isPledgeDefaultFiltersEnabled = createSelector(
  select,
  dashboard => dashboard.isPledgeDefaultFiltersEnabled
)

export const getHoldingAndAveragePrice = (
  scriptId: string | number,
  isinCode?: string
) =>
  createSelector(select, dashboard => {
    const { HOLDING_SUMMARY_SCRIPTS_IN_HOLDING, HOLDING_SUMMARY_INDEX } =
      dashboard
    const holdingScript = HOLDING_SUMMARY_SCRIPTS_IN_HOLDING?.find(
      item => item?.isinCode == isinCode
    )
    const script = holdingScript ? holdingScript?.scriptId : scriptId
    return HOLDING_SUMMARY_INDEX?.[script]
  })

export const getHoldingFromHoldingSummary = createSelector(
  selectHoldingFromHoldingSummary,
  holdingObject => {
    const { holdings = 0 } = holdingObject || {}
    return holdings
  }
)

export const getEntireHoldingObjectData = createSelector(
  select,
  dashboard => dashboard.HOLDING_SUMMARY_INDEX
)

export const checkIfHoldingsAvailable = createSelector(select, dashboard => {
  const { HOLDING_SUMMARY_SCRIPTS_IN_HOLDING } = dashboard
  return Boolean(HOLDING_SUMMARY_SCRIPTS_IN_HOLDING?.length)
})

export const getAllScripsInHoldingAndTotalInvestedValue = createSelector(
  select,
  dashboard => {
    const { SCRIPTS_IN_HOLDING, SUMMED_INVESTED_VALUE, holdingDetails } =
      dashboard
    return {
      scripsInHolding: SCRIPTS_IN_HOLDING,
      totalInvestedAmount: SUMMED_INVESTED_VALUE,
      holdingDetails: holdingDetails
    }
  }
)

export const getScriptsInHoldingWithoutZeroDmatQty = createSelector(
  select,
  dashboard => {
    const { SCRIPTS_IN_HOLDING } = dashboard
    const scriptsInHoldingsWithoutZeroDmatQty =
      SCRIPTS_IN_HOLDING?.filter(holding => {
        const { dmatfreeQty = null } = holding
        return dmatfreeQty !== 0 || dmatfreeQty !== null
      }) || []

    return scriptsInHoldingsWithoutZeroDmatQty
  }
)

export const getSelectedFilterTabInHolding = createSelector(
  select,
  dashboard => dashboard.selectedFilterTabInHolding
)

export const getScriptIdArray = createSelector(
  select,
  dashboard => dashboard.scriptsArray
)

export const getTotalInvestedValueForHoldings = createSelector(
  select,
  dashboard => {
    const { SUMMED_INVESTED_VALUE } = dashboard
    return SUMMED_INVESTED_VALUE
  }
)

export const holdingDetailsScriptIndexSelector = (scriptId: string) =>
  createSelector(select, dashboard => {
    return dashboard?.holdingDetailsScriptIndex?.[scriptId] || {}
  })

export const holdingQuantitiesFromPrimaryPortfolioSelector = createSelector(
  selectHoldingFromPrimaryPortfolioSelector,
  holdingObject => {
    const {
      dmatfreeQty = 0,
      collateralQty = 0,
      emarginNetQuantity = 0,
      holdingQty = 0,
      investedValue = 0,
      avgPrice = 0
    } = holdingObject || {}
    return {
      avgPrice,
      holdingQty,
      dmatfreeQty,
      investedValue,
      collateralQty,
      emarginNetQuantity
    }
  }
)
