import { combineReducers, ReducersMapObject } from 'redux'

import StockTickerReducer from './StockTicker/Reducer'
import { SLICE_NAME as StockTickerSliceName } from './StockTicker/Selectors'

import HoldingTickerReducer from './HoldingTicker/Reducer'
import { SLICE_NAME as HoldingTickerSliceName } from './HoldingTicker/Selectors'

import PositionTickerReducer from './PositionTicker/Reducer'
import { SLICE_NAME as PositionTickerSliceName } from './PositionTicker/Selectors'

import DpHoldTickerReducer from './DPHoldTicker/Reducer'
import { SLICE_NAME as DpHoldTickerSliceName } from './DPHoldTicker/Selectors'

import ErrorReducer from './Error/Reducer'
import { SLICE_NAME as ErrorSliceName } from './Error/Selectors'
import { T_HOLDING_TICKER_REDUCER } from './HoldingTicker/TYPES'
import { T_POSITION_TICKER_REDUCER } from './PositionTicker/TYPES'

export type TTickerReducers = {
  [StockTickerSliceName]: any // TODO - PRIORITY: define valid type
  [HoldingTickerSliceName]: T_HOLDING_TICKER_REDUCER
  [PositionTickerSliceName]: T_POSITION_TICKER_REDUCER
  [DpHoldTickerSliceName]: any // TODO - PRIORITY: define valid type
  [ErrorSliceName]: any // TODO - PRIORITY: define valid type
}

const reducers: ReducersMapObject<TTickerReducers> = {
  [StockTickerSliceName]: StockTickerReducer,
  [HoldingTickerSliceName]: HoldingTickerReducer,
  [PositionTickerSliceName]: PositionTickerReducer,
  [DpHoldTickerSliceName]: DpHoldTickerReducer,
  [ErrorSliceName]: ErrorReducer
}

export default combineReducers<ReducersMapObject<TTickerReducers>>(reducers)
export const persistedReducers: (keyof TTickerReducers)[] = []
