import AppStore from '~/src/Configurations/AppStore'
import { setSortedAndFilteredMutualFundData } from '~/src/Redux/Portfolio/reducer'
import { ISingleMutualFundPortfolioData } from '~/src/Redux/Portfolio/TYPES'

export type TMutualFundPortfolioTableIndividualData =
  | [
      string,
      string,
      string,
      string,
      number,
      number,
      [number, number],
      [number, number],
      ISingleMutualFundPortfolioData
    ]
  | []

export type TMutualFundFilterOption = {
  label: string
}

type TMutualFundFilterMapper = {
  SORT_BY: TMutualFundFilterOption
  FOLIOS: TMutualFundFilterOption
}

export const MUTUAL_FUND_FILTER_BY_MAPPER: TMutualFundFilterMapper = {
  SORT_BY: { label: 'Sort By' },
  FOLIOS: { label: 'Folios' }
}

export const MUTUAL_FUND_FILTER_BY_ARRAY_MOBILE: TMutualFundFilterOption[] =
  Object.values(MUTUAL_FUND_FILTER_BY_MAPPER)

export type TMUTUAL_FUND_SORT_BY_FILTER = {
  label: string
  value: string
}

export const MUTUAL_FUND_SORT_BY_FILTER: TMUTUAL_FUND_SORT_BY_FILTER[] = [
  { label: 'Invested: High to low', value: 'INVESTED_HIGH_TO_LOW' },
  { label: 'Invested: Low to High', value: 'INVESTED_LOW_TO_HIGH' },
  { label: 'Current: High to Low', value: 'CURRENT_HIGH_TO_LOW' },
  { label: 'Current: Low to High', value: 'CURRENT_LOW_TO_HIGH' },
  { label: 'P&L: High to Low', value: 'P_L_HIGH_TO_LOW' },
  { label: 'P&L: Low to High', value: 'P_L_LOW_TO_HIGH' },
  { label: 'Alphabetical: A to Z', value: 'ALPHA_A_TO_Z' },
  { label: 'Alphabetical: Z to A', value: 'ALPHA_Z_TO_A' }
]

type TSortDirection = {
  asc: string
  desc: string
}

type TSortMap = {
  [key: string]: TSortDirection
}

export const SORT_MAP: TSortMap = {
  investment: {
    asc: 'INVESTED_LOW_TO_HIGH',
    desc: 'INVESTED_HIGH_TO_LOW'
  },
  marketValue: {
    asc: 'CURRENT_LOW_TO_HIGH',
    desc: 'CURRENT_HIGH_TO_LOW'
  },
  unrealizedGainPerc: {
    asc: 'P_L_LOW_TO_HIGH',
    desc: 'P_L_HIGH_TO_LOW'
  },
  mutualFundName: {
    asc: 'ALPHA_A_TO_Z',
    desc: 'ALPHA_Z_TO_A'
  }
}

export const sortAndFilterMutualFund = (
  mutualFundData: ISingleMutualFundPortfolioData[],
  sortBy: string,
  selectedMutualFunds: ISingleMutualFundPortfolioData[],
  showOverallTodayPnl: boolean
) => {
  // get whether today or overall selected from the state
  const isTodayPnLSelected = showOverallTodayPnl
  let newArray = selectedMutualFunds.length
    ? [...selectedMutualFunds]
    : [...mutualFundData]

  if (sortBy === 'INVESTED_HIGH_TO_LOW') {
    newArray = newArray.sort(
      (
        ITEM_A: ISingleMutualFundPortfolioData,
        ITEM_B: ISingleMutualFundPortfolioData
      ) => {
        const { investment: investedValueA } = ITEM_A
        const { investment: investedValueB } = ITEM_B
        return investedValueB - investedValueA
      }
    )
  }
  if (sortBy === 'INVESTED_LOW_TO_HIGH') {
    newArray = newArray.sort(
      (
        ITEM_A: ISingleMutualFundPortfolioData,
        ITEM_B: ISingleMutualFundPortfolioData
      ) => {
        const { investment: investedValueA } = ITEM_A
        const { investment: investedValueB } = ITEM_B
        return investedValueA - investedValueB
      }
    )
  }
  if (sortBy === 'CURRENT_HIGH_TO_LOW') {
    newArray = newArray.sort(
      (
        ITEM_A: ISingleMutualFundPortfolioData,
        ITEM_B: ISingleMutualFundPortfolioData
      ) => {
        const { marketValue: MarketValueA } = ITEM_A
        const { marketValue: MarketValueB } = ITEM_B
        return MarketValueB - MarketValueA
      }
    )
  }
  if (sortBy === 'CURRENT_LOW_TO_HIGH') {
    newArray = newArray.sort(
      (
        ITEM_A: ISingleMutualFundPortfolioData,
        ITEM_B: ISingleMutualFundPortfolioData
      ) => {
        const { marketValue: MarketValueA } = ITEM_A
        const { marketValue: MarketValueB } = ITEM_B
        return MarketValueA - MarketValueB
      }
    )
  }
  if (sortBy === 'P_L_HIGH_TO_LOW') {
    newArray = newArray.sort(
      (
        ITEM_A: ISingleMutualFundPortfolioData,
        ITEM_B: ISingleMutualFundPortfolioData
      ) => {
        const { unrealizedGain: overallPlA, dayGain: todayPlA } = ITEM_A
        const { unrealizedGain: overallPlB, dayGain: todayPlB } = ITEM_B
        if (isTodayPnLSelected) return todayPlB - todayPlA
        return overallPlB - overallPlA
      }
    )
  }
  if (sortBy === 'P_L_LOW_TO_HIGH') {
    newArray = newArray.sort(
      (
        ITEM_A: ISingleMutualFundPortfolioData,
        ITEM_B: ISingleMutualFundPortfolioData
      ) => {
        const { unrealizedGain: overallPlA, dayGain: todayPlA } = ITEM_A
        const { unrealizedGain: overallPlB, dayGain: todayPlB } = ITEM_B
        if (isTodayPnLSelected) return todayPlA - todayPlB
        return overallPlA - overallPlB
      }
    )
  }
  if (sortBy === 'ALPHA_A_TO_Z') {
    newArray = newArray.sort(
      (
        ITEM_A: ISingleMutualFundPortfolioData,
        ITEM_B: ISingleMutualFundPortfolioData
      ) => {
        const { mutualFundName: symbolA } = ITEM_A
        const { mutualFundName: symbolB } = ITEM_B
        return symbolA.localeCompare(symbolB)
      }
    )
  }
  if (sortBy === 'ALPHA_Z_TO_A') {
    newArray = newArray.sort(
      (
        ITEM_A: ISingleMutualFundPortfolioData,
        ITEM_B: ISingleMutualFundPortfolioData
      ) => {
        const { mutualFundName: symbolA } = ITEM_A
        const { mutualFundName: symbolB } = ITEM_B
        return symbolB.localeCompare(symbolA)
      }
    )
  }
  AppStore.dispatch(setSortedAndFilteredMutualFundData(newArray))
}
