import { DsBox, DsStack, DsTypography } from '@am92/react-design-system'
import React, { Component } from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { getHoldingAndAveragePrice } from '~/src/Redux/Dashboard/Selectors'
import { To } from 'react-router-dom'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import StockTickerNew from '../../TickerBasedComponents/StockTickerNew'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'

interface IWatchlistSecurityRowProps extends IWithRouterProps {
  scriptId: string
  stockDetails: T_SCRIPTDATA
  holdingsAndAveragePrice: {
    holdings: string
    avgPrice: string
  }
  showHoldings?: boolean
  showBothLTPAndNetChange?: boolean
  type?: string
}

class WatchlistSecurityRow extends Component<IWatchlistSecurityRowProps> {
  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }
  navigateToQuotes = (scriptId: any) => {
    this.navigateTo(`/quotes/${scriptId}`)
  }
  render() {
    const {
      showHoldings = true,
      stockDetails,
      scriptId,
      holdingsAndAveragePrice
    }: any = this.props
    const { coname, exchange, closeprice, label, segment } = stockDetails || {}
    const { holdings = '', avgPrice } = holdingsAndAveragePrice || {}
    return !coname ? (
      <DsTypography variant='bodyRegularMedium'>Loading ...</DsTypography>
    ) : (
      <DsBox
        key={scriptId}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <DsBox
          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          onClick={() => this.navigateToQuotes(scriptId)}
        >
          <DsBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <DsTypography variant='bodyBoldSmall'>{label}</DsTypography>
            <DsStack
              sx={{
                flexDirection: 'row',
                gap: 'var(--ds-spacing-glacial)'
              }}
            >
              <DsTypography
                sx={{ mt: 'var(--ds-spacing-deepFreeze)' }}
                color={'var(--ds-colour-typoTertiary)'}
                variant='supportRegularInfo'
              >
                {exchange}
              </DsTypography>
              {showHoldings && holdings && (
                <DsTypography
                  sx={{ mt: 'var(--ds-spacing-deepFreeze)' }}
                  color={'var(--ds-colour-typoTertiary)'}
                  variant='supportRegularInfo'
                >
                  Qty {holdings} @ {avgPrice}
                </DsTypography>
              )}
            </DsStack>
          </DsBox>
          <DsBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: 1,
              flexGrow: 1
            }}
          >
            <StockTickerNew
              scriptId={scriptId}
              closeprice={closeprice}
              segment={segment}
              WrapperProps={{
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gap: 'var(--ds-spacing-quickFreeze)'
                }
              }}
              NetChangeWrapperProps={{
                sx: {
                  gap: 'var(--ds-spacing-deepFreeze)'
                }
              }}
              PercentChangeTypoProps={{
                sx: {
                  '&::before': {
                    content: '"("'
                  },
                  '&::after': {
                    content: '")"'
                  }
                }
              }}
            />
          </DsBox>
        </DsBox>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const stockDetails = getStockMasterData(scriptId)(state)
  const holdingsAndAveragePrice = getHoldingAndAveragePrice(scriptId)(state)

  return {
    stockDetails,
    holdingsAndAveragePrice
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(WatchlistSecurityRow)
