import { DsStack, DsBox } from '@am92/react-design-system'
import React from 'react'
import { Outlet } from 'react-router-dom'
import SidebarNew from './Sidebar/SidebarNew'
import useResponsiveBreakpoints from '../hooks/useResponsiveBreakpoints'

interface IAppOutletProps {}

const AppOutlet: React.FunctionComponent<IAppOutletProps> = (
  props: IAppOutletProps
) => {
  const { isDesktop } = useResponsiveBreakpoints()

  return (
    <DsStack
      direction='row'
      spacing={isDesktop ? 'var(--ds-spacing-mild)' : 0}
      sx={{
        justifyContent: 'flex-end',
        mt: {
          md: 'calc(var(--ds-spacing-superheated) + var(--ds-spacing-warm))'
        },
        mr: { md: 'var(--ds-spacing-mild)' },
        mb: { md: 'var(--ds-spacing-mild)' },
        ml: {
          md: 'var(--ds-spacing-mild)'
        }
      }}
    >
      {isDesktop && (
        <DsBox
          sx={{
            width: { lg: '448px', md: '38%' },
            height: isDesktop ? '100vh' : 'calc(100vh - 72px)',
            display: { md: 'flex', xs: 'none' },
            flexDirection: 'column',
            position: 'fixed',
            left: 'var(--ds-spacing-mild)'
          }}
        >
          <SidebarNew />
        </DsBox>
      )}
      <DsBox
        sx={{
          width: {
            xs: '100%',
            md: 'calc(62% - 48px)',
            lg: 'calc(100% - 470px)'
          }
        }}
      >
        <Outlet />
      </DsBox>
    </DsStack>
  )
}

export default AppOutlet
