import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsBottomSheet,
  DsDrawer,
  DsRemixIcon,
  DsStack
} from '@am92/react-design-system'
import getTaxAndChargesAction, {
  T_GET_TAX_CHARGES_PAYLOAD
} from '~/src/Redux/Orders/Services/getTaxAndCharges.Service'
import Marketdepth from '~/src/Pages/Quotes/Components/MarketDepth/Marketdepth'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Redux/Orders/TYPES'
import {
  modifyOrderFormData,
  updateOrderPageModalIndicator
} from '~/src/Redux/Orders/reducer'

type ActionTypes = {
  updateOrderPageModalIndicator: any
  modifyOrderFormData: (requestData: any) => Promise<any>
}

export interface IMarketDepthModalProps extends IWithRouterProps {
  actions: ActionTypes
  handleCloseMarketDepth: () => void
  openMarketDepth: boolean
  orderScriptId: string
}

type IMarketDepthModalState = {}

class MarketDepthModal extends Component<
  IMarketDepthModalProps,
  IMarketDepthModalState
> {
  constructor(props: IMarketDepthModalProps) {
    super(props)
    this.state = {}
  }

  handleRowClick = (bidOrAsk: string, limitPrice: string | number) => {
    const { orderScriptId, actions, handleCloseMarketDepth } = this.props
    if (!Number(limitPrice)) {
      handleCloseMarketDepth()
      return false
    }
    const { exchange } =
      (window as any).secMaster.getByScriptId(orderScriptId) || {}
    const mappedValue = bidOrAsk === 'Bid' ? 'BUY' : 'SELL'
    actions.modifyOrderFormData({
      price: Number(limitPrice),
      marketDepth: 'limit'
    })
    actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: true,
      orderScriptId,
      // orderBuyOrSell: mappedValue, Don't need this currently
      selectedExchange: exchange,
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
    handleCloseMarketDepth()
  }

  render() {
    const { handleCloseMarketDepth, openMarketDepth, orderScriptId } =
      this.props

    return (
      <>
        <DsDrawer
          PaperProps={{
            sx: {
              top: '250px',
              zIndex: '10',
              boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)',
              width: '460px',
              left: '40%',
              borderTop: 'unset',
              borderRadius:
                'var(--ds-spacing-bitterCold) var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-zero)'
            }
          }}
          anchor='bottom'
          open={openMarketDepth}
          variant='persistent'
          hideBackdrop
          sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
          ModalProps={{
            keepMounted: false
          }}
        >
          <DsStack
            sx={{
              padding: 'var(--ds-spacing-mild)'
            }}
            gap={3}
          >
            {/* <DsStack
              sx={{
                flexDirection: 'row'
              }}
            > */}
            <DsRemixIcon
              onClick={handleCloseMarketDepth}
              className='ri-close-fill'
              fontSize='mild'
              sx={{
                cursor: 'pointer'
              }}
            />
            {/* </DsStack> */}
            {(openMarketDepth && (
              <Marketdepth
                scriptId={orderScriptId}
                handleRowClick={this.handleRowClick}
              />
            )) ||
              null}
          </DsStack>
        </DsDrawer>

        <DsBottomSheet
          open={openMarketDepth}
          sx={{
            display: {
              xs: 'block',
              md: 'none'
            }
          }}
          onClose={handleCloseMarketDepth}
        >
          <DsStack gap={3}>
            {(openMarketDepth && (
              <Marketdepth
                scriptId={orderScriptId}
                handleRowClick={this.handleRowClick}
              />
            )) ||
              null}
          </DsStack>
        </DsBottomSheet>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getTaxAndChargesOnOrder: (reqData: T_GET_TAX_CHARGES_PAYLOAD) =>
      dispatch(getTaxAndChargesAction(reqData)),
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    modifyOrderFormData: (requestData: any) =>
      dispatch(modifyOrderFormData(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(MarketDepthModal)
