import React from 'react'
import {
  DsBox,
  DsDivider,
  DsStack,
  DsTab,
  DsTabs
} from '@am92/react-design-system'
import WhatisDematHoldings from './Demat/WhatisDematHoldings'

export interface IPortfolioTabsProps {
  value: string
  onChange: (event: any, value: string) => any
}

export const PORTFOLIO_TAB_OPTIONS = {
  Demat: {
    label: 'Demat',
    value: 'Demat',
    isActive: true
  },
  Portfolio: { label: 'Portfolio', value: 'Portfolio', isActive: true }
}
export const PORTFOLIO_TAB_OPTIONS_LIST = Object.values(PORTFOLIO_TAB_OPTIONS)

export default class PortfolioTabs extends React.PureComponent<IPortfolioTabsProps> {
  render() {
    const { value, onChange } = this.props
    return (
      <DsStack
        sx={{
          p: 'var(--ds-spacing-bitterCold)'
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <DsTabs
            sx={{ width: { xs: '100%', md: '70%' } }}
            textColor='secondary'
            indicatorColor='secondary'
            value={value}
            onChange={onChange}
          >
            {PORTFOLIO_TAB_OPTIONS_LIST.map(tab => {
              const { label, value, isActive } = tab
              return (
                <DsTab
                  disabled={!isActive}
                  sx={{
                    textTransform: 'none',
                    width: { xs: '50%', md: '25%' }
                  }}
                  label={label}
                  value={value}
                />
              )
            })}
          </DsTabs>
          <DsBox sx={{ display: { xs: 'none', md: 'flex' } }}>
            <WhatisDematHoldings />
          </DsBox>
        </DsBox>
        <DsDivider sx={{ width: '100%' }} />
      </DsStack>
    )
  }
}
