import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
// selectors
import { DsBox, DsClickAwayListener } from '@am92/react-design-system'

// components
import SearchInput from './SearchScrips/SearchInput'
import SearchResult from './SearchScrips/SearchResult'

import {
  globalSearchCallerClickAction,
  openSearchResultContainerAction
} from '~/src/Redux/Search/Actions'
import getRecentSearchConfigAction from '~/src/Redux/Search/Services/getRecentSearch.Service'
import { setAddScriptToParticularWatchlist } from '~/src/Redux/WatchList/Reducer'
import { shouldAddScriptToParticularWatchlist } from '~/src/Redux/WatchList/Selectors'

import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'
interface ISearchStocksProps extends PropsFromRedux {
  openSearch: boolean
  isGlobalSearchCallerClicked?: boolean
  searchString: string
  setString: (searchString: string) => void
  type?: string
  handleStockSelect?: (scriptId: string) => void
}

class SearchStocks extends React.PureComponent<ISearchStocksProps> {
  async componentDidMount() {
    const { actions } = this.props
    await actions.getRecentSearch()
  }

  handleSearchResultClickAway = () => {
    const {
      actions,
      isGlobalSearchCallerClicked,
      setString,
      openSearch,
      searchString,
      addScriptToParticularWatchlist
    } = this.props
    if (openSearch) {
      if (!isGlobalSearchCallerClicked) {
        if (openSearch) actions.openSearchResultContainer(false)
        if (addScriptToParticularWatchlist)
          actions.setAddScriptToParticularWatchlist(false)
      }
      if (isGlobalSearchCallerClicked)
        actions.globalSearchCallerClickAction(false)
      if (searchString.length) setString('')
    }
  }

  render() {
    const { openSearch, searchString, setString, type, handleStockSelect } =
      this.props
    return (
      <DsClickAwayListener onClickAway={this.handleSearchResultClickAway}>
        <DsBox
          id='searchWatchList'
          sx={{ width: '100%', position: 'relative', zIndex: 4 }}
        >
          {/* SEARCH INPUT */}
          <SearchInput
            open={openSearch}
            searchString={searchString}
            setString={setString}
          />
          {/* SEARCH RESULT */}
          <SearchResult
            open={openSearch}
            searchString={searchString}
            type={type}
            handleStockSelect={handleStockSelect}
          />
        </DsBox>
      </DsClickAwayListener>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const addScriptToParticularWatchlist =
    shouldAddScriptToParticularWatchlist(state)
  return {
    addScriptToParticularWatchlist
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    openSearchResultContainer: (value: boolean) =>
      dispatch(openSearchResultContainerAction(value)),
    getRecentSearch: () => dispatch(getRecentSearchConfigAction()),
    globalSearchCallerClickAction: (value: boolean) =>
      dispatch(globalSearchCallerClickAction(value)),
    setAddScriptToParticularWatchlist: (requestData: boolean) =>
      dispatch(setAddScriptToParticularWatchlist(requestData))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SearchStocks)
