import { getOrderBookActions } from '../actions'
import {
  T_ORDER_BOOK_OBJECT,
  TOrderBookWithAdditionalKeysObject
} from '../TYPES'

import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'

import { asHttp } from '~/src/Configurations/WebHttp'
import { getSubAccountIdFromRedux, UUIDGenerator_V4 } from '~/src/Utils/global'

import { isNonTradableStock } from '~/src/Lib/SecMaster/helpers'
import { STATUS_MAPPER } from '~/src/Pages/Orderbook/Orderbook.constants'

export type T_GET_ORDER_BOOK_PAYLOAD = {
  segment: string
  orderStatus: string
  omsOrderId: string
}

async function getOrderBook(payload: T_GET_ORDER_BOOK_PAYLOAD) {
  const options = {
    url: `/trading/orders/get-order-book`,
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body

  // loop and generate required object
  const result: TOrderBookWithAdditionalKeysObject[] = []
  if (data) {
    data.forEach((item: T_ORDER_BOOK_OBJECT) => {
      const { orderStatus, scriptId, exchange } = item
      const [formattedExchange, segment] = exchange.split('_')
      const {
        aslAllowed = '',
        closeprice = 0,
        CMOTS_COCODE = ''
      } = (window as any).secMaster.getByScriptId(scriptId) || {}
      const id = UUIDGenerator_V4()
      const status = STATUS_MAPPER[orderStatus]
      const isNonTradable = isNonTradableStock(aslAllowed)

      result.push({
        ...item,
        formattedExchange,
        id,
        status,
        isNonTradable,
        closeprice,
        segment,
        CMOTS_COCODE
      })
    })
  }

  return result
}

const getOrderBookAction = serviceActionCreator(
  getOrderBookActions,
  getOrderBook
)

export default getOrderBookAction
