import { createAction, SHOULD_AUTOBATCH } from '@reduxjs/toolkit'

export const updateStockTickerAction = createAction(
  'stockMaster/updateStockTickerAction',
  (payload: any) => ({ payload, meta: { [SHOULD_AUTOBATCH]: true } })
)

export const updateOldStockTickerAction = createAction<any, string>(
  'stockMaster/updateOldStockTickerAction'
)
