import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
  DsBox,
  DsDivider,
  DsGrid,
  DsStack,
  DsTable,
  DsTableCell,
  DsTableHead,
  DsTableRow,
  DsTypography
} from '@am92/react-design-system'

import MarketDepthSkeleton from '../MarketDepthSkeleton'
import TotalB5Qty from './TotalB5Qty'

import { QUOTES_LABEL } from '~/src/Constants/LABEL'
import { tickerContext } from '~/src/Configurations/TickerStore'

import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'
import B5Buy from '~/src/Pages/Quotes/Components/MarketDepth/B5Buy'
import B5Sell from '~/src/Pages/Quotes/Components/MarketDepth/B5Sell'
export interface IMarketdepthProps extends PropsFromRedux {
  scriptId: string
  handleRowClick: (bidOrAsk: string, limitPrice: string | number) => void
  subscribeMarketDepthData: (scriptArray: any[]) => void
  subscribeTotalBidTotalAskData: (scriptArray: any[]) => void
  unSubscribeMarketDepthData: (scriptArray: any[]) => void
  unSubscribeTotalBidTotalAskData: (scriptArray: any[]) => void
}

class Marketdepth extends Component<IMarketdepthProps> {
  componentDidMount(): void {
    const {
      scriptId,
      subscribeMarketDepthData,
      subscribeTotalBidTotalAskData
    } = this.props
    subscribeMarketDepthData([{ scriptId }])
    subscribeTotalBidTotalAskData([{ scriptId }])
  }

  componentWillUnmount(): void {
    const {
      scriptId,
      unSubscribeMarketDepthData,
      unSubscribeTotalBidTotalAskData
    } = this.props
    unSubscribeMarketDepthData([{ scriptId }])
    unSubscribeTotalBidTotalAskData([{ scriptId }])
  }

  componentDidUpdate(prevProps: Readonly<IMarketdepthProps>): void {
    const {
      scriptId,
      subscribeMarketDepthData,
      subscribeTotalBidTotalAskData
    } = this.props
    const { scriptId: prevScriptId } = prevProps
    if (prevScriptId !== scriptId) {
      subscribeMarketDepthData([{ scriptId }])
      subscribeTotalBidTotalAskData([{ scriptId }])
    }
  }

  renderMdSkeleton = (type: string) => {
    return <MarketDepthSkeleton type={type} />
  }

  render() {
    const { handleRowClick, scriptId } = this.props

    return (
      <DsStack>
        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DsTypography variant='headingBoldExtraSmall'>
            {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.HEADING}
          </DsTypography>
          <DsBox sx={{ display: 'flex' }}>
            <DsBox
              sx={{
                width: 'var(--ds-spacing-bitterCold)',
                height: 'var(--ds-spacing-bitterCold)',
                backgroundColor: 'var(--ds-colour-neutral3)',
                borderRadius: 'var(--ds-radius-deepFreeze)',
                mr: 'var(--ds-spacing-quickFreeze)'
              }}
            />
            <DsTypography variant='bodyRegularSmall'>
              {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.MAXIMUM}
            </DsTypography>
          </DsBox>
        </DsBox>
        <DsTypography
          variant='bodyRegularSmall'
          color={'var(--ds-colour-typoSecondary)'}
        >
          {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.PRICE_PICK}
        </DsTypography>
        <DsGrid container columnSpacing={{ xs: 3, md: 4 }}>
          <DsGrid item md={6} xs={6}>
            <DsTable
              sx={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing:
                  'var(--ds-spacing-zero) var(--ds-spacing-glacial)',
                tableLayout: 'fixed',
                display: 'table'
              }}
            >
              <DsTableHead>
                <DsTableRow>
                  <DsTableCell
                    sx={{
                      pb: '12px',
                      px: '8px',
                      width: { xs: 'auto', lg: '33%' }
                    }}
                    align='left'
                  >
                    {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.QUANTITY}
                  </DsTableCell>
                  <DsTableCell
                    sx={{
                      pb: '12px',
                      px: '8px',
                      width: { xs: 'auto', lg: '23%' },
                      whiteSpace: 'nowrap'
                    }}
                    align='center'
                  >
                    {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.ORDERS}
                  </DsTableCell>
                  <DsTableCell
                    align='right'
                    sx={{
                      width: '44%',
                      color: 'var(--ds-colour-iconPositive)',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      pb: '12px',
                      px: '8px'
                    }}
                  >
                    {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.BID}
                  </DsTableCell>
                </DsTableRow>
              </DsTableHead>
              <B5Buy
                renderMdSkeleton={this.renderMdSkeleton}
                handleRowClick={handleRowClick}
                scriptId={scriptId}
              />
            </DsTable>
          </DsGrid>
          <DsGrid item md={6} xs={6}>
            <DsBox>
              {' '}
              <DsTable
                sx={{
                  borderCollapse: 'separate',
                  borderSpacing:
                    'var(--ds-spacing-zero) var(--ds-spacing-glacial)',
                  tableLayout: 'fixed'
                }}
              >
                <DsTableHead>
                  <DsTableRow>
                    <DsTableCell
                      sx={{
                        width: '44%',
                        color: 'var(--ds-colour-iconNegative)',
                        pb: '12px',
                        px: '8px'
                      }}
                      align='left'
                    >
                      {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.ASK}
                    </DsTableCell>
                    <DsTableCell
                      sx={{
                        pb: '12px',
                        px: '8px',
                        width: { xs: 'auto', lg: '23%' },
                        whiteSpace: 'nowrap'
                      }}
                      align='center'
                    >
                      {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.ORDERS}
                    </DsTableCell>
                    <DsTableCell
                      sx={{
                        pb: '12px',
                        px: '8px',
                        width: { xs: 'auto', lg: '33%' }
                      }}
                      align='right'
                    >
                      {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.QUANTITY}
                    </DsTableCell>
                  </DsTableRow>
                </DsTableHead>
                <B5Sell
                  renderMdSkeleton={this.renderMdSkeleton}
                  handleRowClick={handleRowClick}
                  scriptId={scriptId}
                />
              </DsTable>
            </DsBox>
          </DsGrid>
        </DsGrid>
        <DsDivider
          sx={{
            mt: 'var(--ds-spacing-bitterCold)',
            mb: 'var(--ds-spacing-bitterCold)'
          }}
        />
        <DsGrid container spacing={4}>
          <DsGrid item md={3} xs={3}>
            <TotalB5Qty scriptId={scriptId} type='B5Buy' />
          </DsGrid>
          <DsGrid item md={3} xs={3} textAlign={'right'}>
            <DsTypography
              variant='bodyRegularSmall'
              color={'var(--ds-colour-typoSecondary)'}
            >
              Total Bid
            </DsTypography>
          </DsGrid>
          <DsGrid item md={3} xs={3}>
            <DsTypography
              variant='bodyRegularSmall'
              color={'var(--ds-colour-typoSecondary)'}
            >
              Total Ask
            </DsTypography>
          </DsGrid>
          <DsGrid item md={3} xs={3} textAlign={'right'}>
            <TotalB5Qty scriptId={scriptId} type='B5Sell' />
          </DsGrid>
        </DsGrid>
      </DsStack>
    )
  }
}

const connector = connect(null, null, null, {
  context: tickerContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withStockSubUnSub(Marketdepth))
