import React, { PureComponent } from 'react'
import {
  DsBox,
  DsTable,
  DsTableCell,
  DsTableHead,
  DsTableRow,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'

// components
import OptionChainHeader from './OptionChainHeader'
import OptionChainListWrapper from './OptionChainListWrapper'

// reducers
import {
  generateGroupedOptions,
  generateGroupedFutures
} from '~/src/Redux/Derivatives/Reducer'

// selectors
import {
  getFuturesByScriptIdSelector,
  getOptionsByScriptIdSelector
} from '~/src/Redux/Derivatives/Selectors'

// utils
import { _isEqual } from '~/src/Utils/lodash'
import withColorScheme, {
  IWithColorSchemeProps
} from '~/src/Lib/withColorScheme'
import { QUOTES_LABEL } from '~/src/Constants/LABEL'

export interface ActionTypes {
  generateGroupedOptions: (options: any) => void
  generateGroupedFutures: (scriptId: string) => void
}

export interface IOptionChainProps
  extends IWithRouterProps,
    IWithColorSchemeProps {
  handleDialog: any
  options: any
  futures: any
  midIndexOfOptions: number
  breakpoints: any
  dialogOpen: boolean
  actions: ActionTypes
  scriptId: string
}

class OptionChain extends PureComponent<IOptionChainProps> {
  componentDidMount() {
    const { actions, options, scriptId, futures } = this.props
    actions.generateGroupedOptions({ options, futures })
    actions.generateGroupedFutures(scriptId)
  }

  componentDidUpdate(
    prevProps: Readonly<IOptionChainProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    const { actions, options, breakpoints, dialogOpen, scriptId, futures } =
      this.props
    const { scriptId: prevScriptId } = prevProps
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false

    if (!isDesktop && prevProps.dialogOpen !== dialogOpen) {
      document.body.style.overflowY = 'hidden'
    }
    if (!dialogOpen) {
      document.body.style.overflowY = 'unset'
    }
    if (prevScriptId != scriptId) {
      actions.generateGroupedOptions({ options, futures })
    }
  }
  generateTableHead = () => {
    return (
      <DsTableHead
        sx={{
          borderTop: '1px solid var(--ds-colour-strokeDisabled)',
          borderBottom: '1px solid var(--ds-colour-strokeDisabled)'
        }}
      >
        <DsTableRow>
          {/* CALL CELL */}
          <DsTableCell sx={{ p: 'var(--ds-spacing-glacial)' }} align='center'>
            <DsTypography
              variant='bodyBoldMedium'
              color={'var(--ds-colour-typoSecondary)'}
            >
              {QUOTES_LABEL.OPTION_CHAIN.OI}
            </DsTypography>
            <br />
            <DsTypography color={'var(--ds-colour-typoSecondary)'}>
              {QUOTES_LABEL.OPTION_CHAIN.CHANGE}
            </DsTypography>
          </DsTableCell>
          <DsTableCell sx={{ p: 'var(--ds-spacing-glacial)' }} align='center'>
            {' '}
            <DsTypography
              variant='bodyBoldMedium'
              color={'var(--ds-colour-typoSecondary)'}
            >
              {QUOTES_LABEL.OPTION_CHAIN.LTP}
            </DsTypography>
            <br />
            <DsTypography color={'var(--ds-colour-typoSecondary)'}>
              {QUOTES_LABEL.OPTION_CHAIN.CHANGE}
            </DsTypography>
          </DsTableCell>

          {/* PRICE */}
          <DsTableCell sx={{ p: 'var(--ds-spacing-glacial)' }} align='center'>
            <DsTypography
              variant='bodyBoldMedium'
              color={'var(--ds-colour-typoSecondary)'}
            >
              Price
            </DsTypography>
          </DsTableCell>

          {/* PUT CELL */}
          <DsTableCell sx={{ p: 'var(--ds-spacing-glacial)' }} align='center'>
            <DsTypography
              variant='bodyBoldMedium'
              color={'var(--ds-colour-typoSecondary)'}
            >
              {QUOTES_LABEL.OPTION_CHAIN.OI}
            </DsTypography>
            <br />
            <DsTypography color={'var(--ds-colour-typoSecondary)'}>
              {QUOTES_LABEL.OPTION_CHAIN.CHANGE}
            </DsTypography>
          </DsTableCell>
          <DsTableCell sx={{ p: 'var(--ds-spacing-glacial)' }} align='center'>
            {' '}
            <DsTypography
              variant='bodyBoldMedium'
              color={'var(--ds-colour-typoSecondary)'}
            >
              {QUOTES_LABEL.OPTION_CHAIN.LTP}
            </DsTypography>
            <br />
            <DsTypography color={'var(--ds-colour-typoSecondary)'}>
              {QUOTES_LABEL.OPTION_CHAIN.CHANGE}
            </DsTypography>
          </DsTableCell>
        </DsTableRow>
      </DsTableHead>
    )
  }

  render() {
    const { handleDialog, scriptId } = this.props
    return (
      <DsBox
        sx={{
          mb: { md: 0, xs: 'var(--ds-spacing-superheated)' },
          zIndex: 1
        }}
      >
        <DsBox
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            background: 'var(--ds-colour-surfaceBackground)'
          }}
        >
          <OptionChainHeader handleDialog={handleDialog} scriptId={scriptId} />
          <DsBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 'var(--ds-spacing-quickFreeze)'
            }}
          >
            <DsTable>{this.generateTableHead()}</DsTable>
          </DsBox>
        </DsBox>
        <OptionChainListWrapper
          scriptId={scriptId}
          handleDialog={handleDialog}
        />
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const options = getOptionsByScriptIdSelector(scriptId)(state) || {}
  const futures = getFuturesByScriptIdSelector(scriptId)(state) || {}
  return {
    options,
    futures
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    generateGroupedOptions: (options: any) =>
      dispatch(generateGroupedOptions(options)),
    generateGroupedFutures: (scriptId: string[]) =>
      dispatch(generateGroupedFutures(scriptId))
  }
})

export default withBreakpoints(
  withErrorConnect(
    mapStateToProps,
    mapDispatchToProps
  )(withColorScheme(OptionChain))
)
