// DONE - CLEANUP: @vasi
// 1. define types

import React, { Component } from 'react'
import { DsBox, DsStack, DsTypography } from '@am92/react-design-system'

import StockTickerNew from '../../TickerBasedComponents/StockTickerNew'
import SipScriptDetailsAmtValue from '../SipScriptDetailsAmtValue'

import { updateAmtQtySelectedScriptsData } from '~/src/Redux/Orders/reducer'
import { T_SIP_INFO, TSelectedStock } from '~/src/Redux/Orders/TYPES'

import { TAppDispatch } from '~/src/Configurations/AppStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'

export interface ActionType {
  updateSelectedScriptsData: (item: T_SIP_INFO) => void
}

export interface IItem {
  scriptId: string
  exchangeSymbol: string
  coname: string
  exchange: string
  amount?: number | string
  quantity?: string | number
}

export interface ISipScriptsDetailsLeftProps {
  actions: ActionType
  item: TSelectedStock
  sipInfo: T_SIP_INFO
  value: number
  isModifing: boolean
}

export class SipScriptsDetailsLeft extends Component<ISipScriptsDetailsLeftProps> {
  render() {
    const { item, sipInfo, value, isModifing, actions } = this.props
    const { closeprice, exchange, scriptId, segment } = item
    const { basedOn } = sipInfo
    const normalizedBasedOn = basedOn.toLowerCase()
    const finalValueLabel =
      normalizedBasedOn === 'amount' ? 'Quantity' : 'Amount'
    const { exchangeSymbol, coname } = item
    const scriptLabel = exchangeSymbol || coname
    return (
      <DsStack gap={'8px'} alignItems={'flex-start'}>
        <DsBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <DsTypography variant='bodyRegularMedium'>{scriptLabel}</DsTypography>
          <DsTypography
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
            variant='bodyRegularSmall'
          >
            {finalValueLabel}:
            <SipScriptDetailsAmtValue
              item={item}
              sipInfo={sipInfo}
              value={value}
              isModifing={isModifing}
              updateSelectedScriptsData={actions.updateSelectedScriptsData}
              closeprice={closeprice}
            />
          </DsTypography>
        </DsBox>
        <DsBox sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <DsTypography variant='supportRegularInfo'>{exchange}</DsTypography>
          <StockTickerNew
            scriptId={scriptId}
            closeprice={closeprice}
            segment={segment}
            WrapperProps={{
              sx: {
                display: 'flex',
                flexDirection: 'row',
                gap: 'var(--ds-spacing-quickFreeze)'
              }
            }}
            NetChangeWrapperProps={{
              sx: {
                gap: 'var(--ds-spacing-deepFreeze)'
              }
            }}
            PercentChangeTypoProps={{
              sx: {
                '&::before': {
                  content: '"("'
                },
                '&::after': {
                  content: '")"'
                }
              }
            }}
          />
        </DsBox>
      </DsStack>
    )
  }
}

const mapStateToProps = () => {}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    updateSelectedScriptsData: (requestData: T_SIP_INFO) =>
      dispatch(updateAmtQtySelectedScriptsData(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(SipScriptsDetailsLeft)
