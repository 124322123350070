export type TReverseFeedResponseMapper = {
  [key: number]: string
}

export const reverseFeedResponseMapper: TReverseFeedResponseMapper = {
  1: 'NOTIFICATION_TYPE',
  2: 'ORD_ORDER_NO',
  3: 'ORD_CLIENT_ID',
  4: 'ORD_STATUS',
  5: 'ORD_ERROR_CODE',
  6: 'ORD_REASON_DESCRIPTION',
  7: 'ORD_ENTRY_DATE',
  8: 'ORD_TRD_LAST_MODIFY_TIME',
  9: 'ORD_BUY_SELL_IND',
  10: 'ORD_EXCH_ID',
  11: 'ORD_BTM_BOOK_TYPE',
  12: 'ORD_MKT_FLG',
  13: 'ORD_PRODUCT_ID',
  14: 'ORD_SERIAL_NO',
  15: 'ORD_QTY_ORIGINAL',
  16: 'ORD_QTY_REMAINING',
  17: 'ORD_QTY_DISC',
  18: 'ORD_TRD_TRADE_QTY',
  19: 'ORD_ORDER_PRICE',
  20: 'ORD_TRIGGER_PRICE',
  21: 'ORD_TRD_TRADE_PRICE',
  22: 'ORD_SOURCE_FLG',
  23: 'ORD_SEM_SYMBOL',
  24: 'ORD_SEM_EXPIRY_DATE',
  25: 'ORD_SEM_STRIKE_PRICE',
  26: 'ORD_SEM_OPTION_TYPE',
  27: 'ORD_DAY_FLG',
  28: 'ORD_IOC_FLG',
  29: 'ORD_GOOD_TILL_DAYS',
  30: 'ORD_EXT_REF_NO',
  31: 'ORD_SYMBOL_SERIES',
  32: 'ALERT_THRESHOLD',
  33: 'ALERT_SCRIPTID',
  34: 'ALERT_CONDITION',
  35: 'ALERT_DESCRIPTION',
  36: 'ALERT_TIMESTAMP',
  37: 'ALERT_ID'
}

export type TReverseFeedResponse = {
  [key: string]: string | number
}

export const reverseFeedResponse: TReverseFeedResponse = {
  NOTIFICATION_TYPE: '',
  ORD_ORDER_NO: '',
  ORD_CLIENT_ID: '',
  ORD_STATUS: '',
  ORD_ERROR_CODE: '',
  ORD_REASON_DESCRIPTION: '',
  ORD_ENTRY_DATE: '',
  ORD_TRD_LAST_MODIFY_TIME: '',
  ORD_BUY_SELL_IND: '',
  ORD_EXCH_ID: '',
  ORD_BTM_BOOK_TYPE: '',
  ORD_MKT_FLG: '',
  ORD_PRODUCT_ID: '',
  ORD_SERIAL_NO: '',
  ORD_QTY_ORIGINAL: '',
  ORD_QTY_REMAINING: '',
  ORD_QTY_DISC: '',
  ORD_TRD_TRADE_QTY: '',
  ORD_ORDER_PRICE: '',
  ORD_TRIGGER_PRICE: '',
  ORD_TRD_TRADE_PRICE: '',
  ORD_SOURCE_FLG: '',
  ORD_SEM_SYMBOL: '',
  ORD_SEM_EXPIRY_DATE: '',
  ORD_SEM_STRIKE_PRICE: '',
  ORD_SEM_OPTION_TYPE: '',
  ORD_DAY_FLG: '',
  ORD_IOC_FLG: '',
  ORD_GOOD_TILL_DAYS: '',
  ORD_EXT_REF_NO: '',
  ORD_SYMBOL_SERIES: '',
  ALERT_THRESHOLD: '',
  ALERT_SCRIPTID: '',
  ALERT_CONDITION: '',
  ALERT_DESCRIPTION: '',
  ALERT_TIMESTAMP: '',
  ALERT_ID: ''
}

export type TAlertColorMapper = {
  [key: string]: string
}

export const alertColorMapper: TAlertColorMapper = {
  C: 'var(--ds-colour-supportPositive)',
  R: 'var(--ds-colour-supportNegative)',
  T: 'var(--ds-colour-supportPositive)',
  D: 'var(--ds-colour-supportNegative)',
  W: 'var(--ds-colour-supportNegative)',
  E: 'var(--ds-colour-supportNegative)'
}

export type TAlertIconColorMapper = {
  [key: string]: string
}

export const alertIconColorMapper: TAlertIconColorMapper = {
  C: 'success',
  R: 'error',
  T: 'success',
  D: 'error',
  W: 'error',
  E: 'error'
}

export type TAlertIconMapper = {
  [key: string]: string
}

export const alertIconMapper: TAlertIconMapper = {
  C: 'checkbox-circle-fill',
  R: 'alert-line',
  T: 'checkbox-circle-fill',
  D: 'close-circle-line',
  W: 'alert-line',
  E: 'alert-line',
  SCRIPT_ALERT: 'alarm-warning-line'
}

export type TAlertHeadingMapper = {
  [key: string]: string
}

export const alertHeadingMapper: TAlertHeadingMapper = {
  C: 'Order Pending',
  R: 'Order Rejected',
  T: 'Order Executed',
  D: 'Order Cancelled',
  W: 'Order Expired',
  E: 'Order in Transit',
  SCRIPT_ALERT: 'Alert Triggered'
}

export type TAlertDescriptionEndPartMapper = {
  [key: string]: string
}

export const alertDescriptionEndPartMapper: TAlertDescriptionEndPartMapper = {
  C: 'Qty is placed!',
  R: 'Qty is rejected!',
  T: 'Qty is traded at',
  D: 'Qty is cancelled!',
  W: 'Qty is expired!',
  E: 'Qty is in transit!'
}

export type TAlertTransactionTypeMapper = {
  [key: string]: string
}

export const alertTransactionTypeMapper: TAlertTransactionTypeMapper = {
  B: 'BUY',
  S: 'SELL'
}

export type TScriptAlertDescriptionMapper = {
  [key: string]: string
}

export const scriptAlertDescriptionMapper: TScriptAlertDescriptionMapper = {
  GT: 'Greater Than',
  LT: 'Less Than',
  GTE: 'Greater than or equals to',
  LTE: 'Less than or equals to'
}
