import React, { Component } from 'react'
import { DsTypography } from '@am92/react-design-system'

import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getLtpTickSelector } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'
import { getCurrencyFormat } from '~/src/Utils/global'

import withErrorConnect from '~/src/Lib/withErrorConnect'

interface IBnplValue {
  scriptId: string
  closeprice: number
  LTP: string
  eMarginPercentage: number
  forceRender: boolean
  options: any
  isEmarginActiveFlag: boolean
}

class BnplValue extends Component<IBnplValue, any> {
  constructor(props: IBnplValue) {
    super(props)
    this.state = {
      showLTP: false,
      bnplValue: 0
    }
  }

  componentDidUpdate(prevProps: any): void {
    const { LTP = {}, forceRender }: any = this.props
    const { forceRender: PREV_FORCE_RENDER } = prevProps
    const { showLTP } = this.state

    if (LTP?.toString() && !showLTP) {
      this.setState({ bnplValue: LTP, showLTP: true })
    }

    if (forceRender !== PREV_FORCE_RENDER) {
      this.setState({ showLTP: false })
    }
  }

  render() {
    const { bnplValue }: any = this.state
    const { eMarginPercentage = 0, options, isEmarginActiveFlag } = this.props
    const bnplValueOnEmarginPercentage =
      eMarginPercentage && bnplValue * eMarginPercentage * 0.01
    return (
      <>
        {(bnplValueOnEmarginPercentage && isEmarginActiveFlag && (
          <>
            <DsTypography
              variant='bodyRegularSmall'
              sx={{ textDecoration: 'line-through' }}
              color='var(--ds-colour-typoTertiary)'
            >
              {getCurrencyFormat(bnplValue, options)}
            </DsTypography>{' '}
            <DsTypography variant='bodyRegularSmall'>
              {getCurrencyFormat(bnplValueOnEmarginPercentage, options)}
            </DsTypography>
          </>
        )) || (
          <DsTypography variant='bodyRegularSmall'>
            {getCurrencyFormat(bnplValueOnEmarginPercentage, options)}
          </DsTypography>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId } = ownProps
  const LTP = getLtpTickSelector(state, scriptId)
  return {
    LTP
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(BnplValue)
