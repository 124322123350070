import { ThunkDispatch } from '@reduxjs/toolkit'
import { WEB_HTTP_CONTEXT, WebHttpError } from '@am92/web-http'

import loginWithRefreshTokenService from './Auth/Services/loginWithRefreshToken.Service'

import { TraceActions } from './serviceActionCreator'

import AppStore, { TAppDispatch } from '../Configurations/AppStore'
import { asHttp } from '../Configurations/WebHttp'

export default function serviceActionCreator<
  RequestData = void,
  Response = unknown
>(
  traceActions: TraceActions<Response>,
  service: (data: RequestData) => Promise<Response>
) {
  return (data: RequestData) => {
    return async (
      dispatch: TAppDispatch,
      getState: () => unknown
    ): Promise<Response | WebHttpError> => {
      if (traceActions.loading && typeof traceActions.loading === 'function') {
        dispatch(traceActions.loading())
      }

      try {
        const response = await service(data)

        if (
          traceActions.success &&
          typeof traceActions.success === 'function'
        ) {
          dispatch(traceActions.success(response))
        }

        return response
      } catch (error: unknown) {
        const err = error as WebHttpError
        const { code } = err.error || {}
        if (code === '1082') {
          return await retryWithTokenRotation<RequestData, Response>(
            traceActions,
            service,
            dispatch,
            getState,
            data
          )
        } else if (code === '1200') {
          return await retryWithPublicKeyRotation<RequestData, Response>(
            traceActions,
            service,
            dispatch,
            data,
            err
          )
        } else {
          if (traceActions.error && typeof traceActions.error === 'function') {
            dispatch(traceActions.error({ ...err }))
          }

          return err
        }
      }
    }
  }
}

async function retryWithTokenRotation<RequestData = void, Response = unknown>(
  traceActions: TraceActions<Response>,
  service: (data: RequestData) => Promise<Response>,
  dispatch: TAppDispatch,
  getState: () => unknown,
  data: RequestData
): Promise<Response | WebHttpError> {
  // TODO: get the subaccountId from the request data
  const { subAccountId } = AppStore.getState().sso
  const loginWithRefreshTokenRequestData = { subAccountId }

  const loginWithRefreshTokenServiceDispatcher = loginWithRefreshTokenService(
    loginWithRefreshTokenRequestData
  )

  const tokenRotationResponse = await loginWithRefreshTokenServiceDispatcher(
    dispatch,
    getState
  )

  if (
    tokenRotationResponse instanceof WebHttpError ||
    tokenRotationResponse._isCustomError
  ) {
    return tokenRotationResponse
  }

  try {
    const response = await service(data)
    if (traceActions.success && typeof traceActions.success === 'function') {
      dispatch(traceActions.success(response))
    }

    return response
  } catch (error: unknown) {
    const err = error as WebHttpError
    if (traceActions.error && typeof traceActions.error === 'function') {
      dispatch(traceActions.error({ ...err }))
    }
    return err
  }
}

async function retryWithPublicKeyRotation<
  RequestData = void,
  Response = unknown
>(
  traceActions: TraceActions<Response>,
  service: (data: RequestData) => Promise<any>,
  dispatch: ThunkDispatch<any, any, any>,
  data: RequestData,
  error: WebHttpError
): Promise<Response | WebHttpError> {
  const { data: DATA } = error.error
  const { publicKey } = DATA
  asHttp.context.set(WEB_HTTP_CONTEXT.PUBLIC_KEY, publicKey)

  try {
    const response = await service(data)
    if (traceActions.success && typeof traceActions.success === 'function') {
      dispatch(traceActions.success(response))
    }

    return response
  } catch (error: unknown) {
    const err = error as WebHttpError
    if (traceActions.error && typeof traceActions.error === 'function') {
      dispatch(traceActions.error({ ...err }))
    }
    return err
  }
}
