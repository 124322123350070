export type T_OVERALL_PNL = {
  value: number
  percentChange: number
}

export interface T_TODAY_PNL extends T_OVERALL_PNL {}

export type T_SINGLE_HOLDING = {
  scriptId: string
  holdingQty: number
  closeprice: number
  closePriceFromTicker: number
  overallPnl: T_OVERALL_PNL
  todayPnl: T_TODAY_PNL
  currentValue: number
  investedValue: number
  LTP?: number
  PERCENT_CHANGE?: number
}

export type T_HOLDING_SCRIPTS = {
  [key: number | string]: T_SINGLE_HOLDING
}

export interface T_HOLDING_TICKER_REDUCER {
  overallPnl: T_OVERALL_PNL
  todayPnl: T_TODAY_PNL
  currentValue: number
  totalInvestedValue: number
  scriptsInHolding: T_HOLDING_SCRIPTS
}

export const INITIAL_STATE: T_HOLDING_TICKER_REDUCER = {
  currentValue: 0,
  overallPnl: {
    value: 0,
    percentChange: 0
  },
  todayPnl: {
    value: 0,
    percentChange: 0
  },
  totalInvestedValue: 0,
  scriptsInHolding: {}
}
