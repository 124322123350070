import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from '@am92/web-http'
import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import {
  loginWithRefreshTokenTraceActions,
  loginWithRefreshTokenServiceName
} from '../Actions'

export type loginWithRefreshTokenData = {
  subAccountId: string
}

async function loginWithRefreshToken(reqData: loginWithRefreshTokenData) {
  const currentRefreshToken = asHttp.context.get(WEB_HTTP_CONTEXT.REFRESH_TOKEN)
  const reqBody = { refreshToken: currentRefreshToken }
  const { subAccountId } = reqData

  const options: WebHttpRequestOptions = {
    url: `/v1/handshake/get-auth-token`,
    method: 'POST',
    data: reqBody,
    headers: {
      'X-SubAccountID': subAccountId
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  return body
}

const loginWithRefreshTokenService = serviceActionCreator<
  loginWithRefreshTokenData,
  any
>(loginWithRefreshTokenTraceActions, loginWithRefreshToken)

export default loginWithRefreshTokenService
export { loginWithRefreshTokenServiceName }
