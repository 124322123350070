import React from 'react'
import {
  DsTableBody,
  DsTableCell,
  DsTableRow,
  DsTypography
} from '@am92/react-design-system'

import { IB5 } from '~/src/Redux/StockTicker/Reducer'
import { getB5Buy } from '~/src/Redux/StockTicker/Selectors'

import { QUOTES_LABEL } from '~/src/Constants/LABEL'
import { TTickerStore } from '~/src/Configurations/TickerStore'
import { getCurrencyFormat } from '~/src/Utils/global'

import { useTickerSelector } from '~/src/hooks/useRedux'
import useResponsiveBreakpoints from '~/src/hooks/useResponsiveBreakpoints'

interface IB5BuyProps {
  renderMdSkeleton: (type: string) => React.ReactElement
  handleRowClick: (bidOrAsk: string, limitPrice: string | number) => void
  scriptId: string
}

const B5Buy = (props: IB5BuyProps) => {
  const { renderMdSkeleton, handleRowClick, scriptId } = props
  const B5Buy = useTickerSelector((state: TTickerStore) =>
    getB5Buy(state, scriptId)
  )
  const { isDesktop } = useResponsiveBreakpoints()

  return (
    <DsTableBody>
      {(B5Buy &&
        B5Buy.map((item, index) => (
          <DsTableRow
            onClick={() =>
              handleRowClick(QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.BID, item.price)
            }
            key={index}
            sx={{
              backgroundColor: `${
                Number(item.quantity) ===
                Math.max(...B5Buy.map((item: IB5) => Number(item.quantity)))
                  ? 'var(--ds-colour-neutral3)'
                  : index % 2 === 0 && 'var(--ds-colour-surfaceSecondary)'
              }`,
              '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'var(--ds-colour-neutral3)'
              }
            }}
          >
            <DsTableCell
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                pr: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              align='left'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                {item.quantity}
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              align='center'
              sx={{
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0,
                pl: 0
              }}
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
                color='var(--ds-colour-typoSecondary)'
              >
                {item.order}
              </DsTypography>
            </DsTableCell>
            <DsTableCell
              sx={{
                borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-frostbite)',
                pb: 'var(--ds-spacing-frostbite)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pl: 0
              }}
              align='right'
            >
              <DsTypography
                variant={isDesktop ? 'bodyRegularSmall' : 'supportRegularInfo'}
              >
                {getCurrencyFormat(item.price, {
                  signDisplay: 'never',
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </DsTypography>
            </DsTableCell>
          </DsTableRow>
        ))) ||
        renderMdSkeleton('buy')}
    </DsTableBody>
  )
}
export default B5Buy
