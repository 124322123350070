// TODO - CLEANUP: @vasi
// 1. define types

import React, { PureComponent } from 'react'
import { To } from 'react-router-dom'
import { DsTableCell, DsTypography } from '@am92/react-design-system'
import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'
import StockTickerNew from '~/src/Components/TickerBasedComponents/StockTickerNew'
import OptionChainCellOI from './OptionChainCellOI'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'

export interface IOptionChainCellProps extends IWithRouterProps {
  color: 'default' | 'highlight'
  data: T_SCRIPTDATA
  handleDialog: () => void
}

class OptionChainCell extends PureComponent<IOptionChainCellProps> {
  handleNavigateToQuotes = (scriptId: any) => {
    const { handleDialog } = this.props
    this.navigateTo(`/quotes/${scriptId}`)
    handleDialog()
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  render() {
    const { color = 'default', data } = this.props
    const bgColor =
      color === 'default'
        ? 'var(--ds-colour-surfaceSecondary)'
        : 'var(--ds-colour-neutral3)'
    const { scriptId, closeprice, segment } = data || {}
    return (
      <>
        <OptionChainCellOI
          handleNavigateToQuotes={this.handleNavigateToQuotes}
          data={data}
          color={color}
        />
        <DsTableCell
          onClick={() => this.handleNavigateToQuotes(scriptId)}
          align='center'
          sx={{
            display: 'flex',
            borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-quickFreeze)',
            pb: 'var(--ds-spacing-quickFreeze)',
            backgroundColor: bgColor,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <DsTypography
            variant='bodyRegularMedium'
            color={'var(--ds-colour-typoSecondary)'}
          >
            <StockTickerNew
              scriptId={scriptId}
              closeprice={closeprice}
              segment={segment}
              LtpTypoProps={{
                variant: 'bodyRegularMedium'
              }}
              PercentChangeTypoProps={{
                variant: 'bodyRegularMedium',
                color: 'var(--ds-colour-secondary)'
              }}
              showNetChange={false}
              showChangeIcon={false}
              percentChangeNumberFormatOptions={{
                signDisplay: 'exceptZero',
                style: 'decimal'
              }}
            />
          </DsTypography>
          <br />
        </DsTableCell>
      </>
    )
  }
}
export default withRouter(OptionChainCell)
