import React, { FunctionComponent, ReactNode } from 'react'
import {
  DsCollapse,
  DsDivider,
  DsList,
  DsListItemButton,
  DsListItemIcon,
  DsListItemText,
  DsRemixIcon,
  DsStack,
  DsToggle
} from '@am92/react-design-system'

import useResponsiveBreakpoints from '../hooks/useResponsiveBreakpoints'

import { PROFILE_MENU_OPTIONS } from '../Constants/APP_CONSTANTS'

interface GlobalMenuListProps {
  selectedMenu: string
  handleOpenMenu: (menu: string) => void
  handleNavigate: (type: string, link: string) => void
  handleRenderHeader: (item: string) => ReactNode
  handleModeChange: (name: string, value: boolean) => void
  theme: string
}

const GlobalMenuList: FunctionComponent<GlobalMenuListProps> = props => {
  const {
    selectedMenu,
    handleOpenMenu,
    handleNavigate,
    handleRenderHeader,
    handleModeChange,
    theme
  } = props
  const { isDesktop } = useResponsiveBreakpoints()
  return (
    <DsList
      sx={{ width: '100%' }}
      component='nav'
      aria-labelledby='nested-list-subheader'
    >
      {PROFILE_MENU_OPTIONS?.map((item: any, index: any) => {
        const hasChildren = item?.subMenu?.length !== 0
        const isOpen = item.id === selectedMenu
        const profile = item.id === 'PROFILE'
        const noProfileMenu = !isDesktop && profile

        return (
          <>
            {!noProfileMenu && (
              <DsListItemButton
                key={index}
                onClick={
                  hasChildren
                    ? () => handleOpenMenu(item.id)
                    : () => {
                        handleNavigate(item.type, item.link)
                      }
                }
                sx={{
                  py: {
                    xs: 'var(--ds-spacing-bitterCold)',
                    md: 'var(--ds-spacing-glacial)'
                  }
                }}
              >
                <DsListItemIcon>
                  <DsStack
                    sx={{
                      borderRadius: 'var(--ds-radius-quickFreeze)',
                      border: '1px solid var(--ds-colour-strokeDefault)',
                      p: 'var(--ds-spacing-glacial)',
                      mr: 'var(--ds-spacing-frostbite)'
                    }}
                  >
                    <DsRemixIcon className={item.icon} />
                  </DsStack>
                </DsListItemIcon>
                <DsListItemText
                  primaryTypographyProps={{
                    variant: isDesktop ? 'bodyRegularMedium' : 'bodyBoldMedium'
                  }}
                  secondaryTypographyProps={{ variant: 'bodyRegularSmall' }}
                  primary={handleRenderHeader(item.header)}
                  secondary={!isDesktop && item.subHeader}
                />
                {hasChildren ? (
                  isOpen ? (
                    <DsRemixIcon className='ri-arrow-up-s-line' />
                  ) : (
                    <DsRemixIcon className='ri-arrow-down-s-line' />
                  )
                ) : (
                  <DsRemixIcon className='ri-arrow-right-s-line' />
                )}
              </DsListItemButton>
            )}
            {hasChildren && (
              <DsCollapse in={isOpen} timeout='auto' unmountOnExit>
                <DsList
                  component='div'
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    ml: '2.3rem',
                    mb: 'var(--ds-spacing-bitterCold)',
                    mr: 'var(--ds-spacing-bitterCold)'
                  }}
                  disablePadding
                >
                  <DsStack sx={{ display: 'flex', flexDirection: 'row' }}>
                    <DsDivider
                      orientation='vertical'
                      sx={{
                        height: 'calc(100% - var(--ds-spacing-frostbite))'
                      }}
                    />
                    <DsStack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2.6rem',
                        mt: '.8rem'
                      }}
                    >
                      {item.subMenu.map((item: any, index: any) => {
                        return (
                          <DsDivider key={index} sx={{ width: '1.7rem' }} />
                        )
                      })}
                    </DsStack>
                  </DsStack>
                  <DsStack
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '100%',
                      gap: 'var(--ds-spacing-bitterCold)',
                      ml: 'var(--ds-spacing-glacial)'
                    }}
                  >
                    {item.subMenu.map((item: any, index: any) => {
                      return (
                        <>
                          <DsListItemButton
                            key={index}
                            onClick={() => {
                              handleNavigate(item.type, item.link)
                            }}
                            sx={{ p: 'var(--ds-spacing-zero)' }}
                          >
                            <DsListItemText
                              primaryTypographyProps={{
                                variant: 'bodyRegularSmall'
                              }}
                              primary={item.label}
                            />
                            {item.id === 'DARK_THEME' ? (
                              <DsToggle
                                onChange={handleModeChange}
                                name='Dark Mode'
                                value={theme === 'dark'}
                              />
                            ) : null}
                          </DsListItemButton>
                        </>
                      )
                    })}
                  </DsStack>
                </DsList>
              </DsCollapse>
            )}
            <DsDivider sx={{ display: { xs: 'flex', md: 'none' } }} />
          </>
        )
      })}
    </DsList>
  )
}

export default GlobalMenuList
