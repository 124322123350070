import React, { Component } from 'react'
import {
  DsBox,
  DsSkeleton,
  DsStack,
  DsTable,
  DsTableBody,
  DsTableCell,
  DsTableRow,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'

// components
import SpotPrice from './SpotPrice'
import OptionChainCell from './OptionChainCell'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

// selectors
import { getMidIndexValueWithCallPutOptionsSelector } from '~/src/Redux/Derivatives/Selectors'

// constants
// reducers
// utils
import { _isEqual } from '~/src/Utils/lodash'

export interface ActionTypes {}

export interface IOptionChainProps extends IWithRouterProps {
  actions: ActionTypes
  LTP: string | number
  midIndex: number
  callOptions: any[]
  putOptions: any[]
  selectedDate: string
  scriptId: string
  mappedId: string
  handleDialog: any
  subscribeLtpData: (scriptIdArray: any[]) => void
  unSubscribeLtpData: (scriptIdArray: any[]) => void
}

class OptionChainList extends Component<IOptionChainProps> {
  shouldComponentUpdate(nextProps: IOptionChainProps) {
    const { midIndex: nextMidIndex, selectedDate: nextSelectedDate } = nextProps
    const { midIndex: currentMidIndex, selectedDate } = this.props
    if (nextMidIndex !== currentMidIndex || selectedDate !== nextSelectedDate) {
      return true
    }

    return false
  }
  componentDidUpdate(prevProps: Readonly<IOptionChainProps>) {
    const {
      // callOptions: prevCallOptions,
      // putOptions: prevPutOptions,
      midIndex: prevMidIndex,
      selectedDate: prevSelectedDate
    } = prevProps
    const {
      callOptions,
      putOptions,
      midIndex,
      selectedDate,
      subscribeLtpData,
      unSubscribeLtpData
    } = this.props
    if (selectedDate !== prevSelectedDate || midIndex !== prevMidIndex) {
      const maxElements = 10

      if (selectedDate !== prevSelectedDate) {
        this.unSubscribeCurrentScrips()
      }
      // calculate lower and upper index of previous
      const prevLowIndex = prevMidIndex - maxElements
      const prevUpperIndex = prevMidIndex + maxElements

      // calculate lower and upper index of current
      const currentLowIndex = midIndex - maxElements
      const currentUpperIndex = midIndex + maxElements
      if (currentLowIndex < prevLowIndex) {
        const lowerIndexDiff = prevLowIndex - currentLowIndex
        const upperIndexDiff = prevUpperIndex - currentUpperIndex
        const toSubscribe = [
          ...callOptions.slice(
            currentLowIndex,
            currentLowIndex + lowerIndexDiff
          ),
          ...putOptions.slice(currentLowIndex, prevLowIndex)
        ]
        const toUnSubscribe = [
          ...callOptions.slice(prevUpperIndex, prevUpperIndex + upperIndexDiff),
          ...putOptions.slice(prevUpperIndex, prevUpperIndex + upperIndexDiff)
        ]
        subscribeLtpData(toSubscribe)
        unSubscribeLtpData(toUnSubscribe)
      } else {
        const currentLowMaxIndex = midIndex + maxElements + 1
        const toSubscribe: any[] = [
          ...callOptions.slice(currentLowIndex, currentLowMaxIndex),
          ...putOptions.slice(currentLowIndex, currentLowMaxIndex)
        ]
        let toUnSubscribe: any[] = []
        subscribeLtpData(toSubscribe)
      }
    }
  }

  unSubscribeCurrentScrips = () => {
    const { topLeft, topRight, bottomLeft, bottomRight } =
      this.generateOptionsForRenderingTable()
    const { unSubscribeLtpData } = this.props

    unSubscribeLtpData([...topLeft, ...topRight, ...bottomLeft, ...bottomRight])
  }

  componentWillUnmount() {
    this.unSubscribeCurrentScrips()
  }

  generateOptionsForRenderingTable = () => {
    const { putOptions, callOptions, midIndex } = this.props
    const maxElement = 10

    // top array
    const startIndexForTopArr = Math.max(0, midIndex - maxElement) + 1
    const endIndexForTopArr = midIndex + 1

    const topLeft = callOptions.slice(startIndexForTopArr, endIndexForTopArr)
    const topRight = putOptions.slice(startIndexForTopArr, endIndexForTopArr)

    // bottom array
    const startIndexForBottomArray = midIndex + 1
    const endIndexForBottomArray = Math.min(
      startIndexForBottomArray + maxElement,
      callOptions.length
    )

    const bottomLeft = callOptions.slice(
      startIndexForBottomArray,
      endIndexForBottomArray
    )
    const bottomRight = putOptions.slice(
      startIndexForBottomArray,
      endIndexForBottomArray
    )

    return { topLeft, topRight, bottomLeft, bottomRight }
  }

  generateTableCells = (
    leftTable: any[],
    rightTable: any[],
    colorVariant: 'default' | 'highlight' = 'default'
  ) => {
    const { handleDialog } = this.props
    const result = []
    if (leftTable && leftTable.length > 0) {
      const totalElement = leftTable.length
      for (let i = 0; i < totalElement; i++) {
        const item = leftTable[i]
        const rightItem = rightTable[i]
        const { strikePrice } = item
        result.push(
          <DsTableRow key={i}>
            <OptionChainCell
              handleDialog={handleDialog}
              data={item}
              color={colorVariant}
              handleNavigation={this.handleNavigation}
            />
            <DsTableCell
              align='center'
              sx={{
                borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
                pt: 'var(--ds-spacing-quickFreeze)',
                pb: 'var(--ds-spacing-quickFreeze)',
                backgroundColor: 'var(ds-colour-surfaceSecondary)'
              }}
            >
              <DsTypography variant='bodyRegularSmall'>
                {strikePrice}
              </DsTypography>
            </DsTableCell>
            <OptionChainCell
              handleDialog={handleDialog}
              data={rightItem}
              color={colorVariant === 'default' ? 'highlight' : 'default'}
              handleNavigation={this.handleNavigation}
            />
          </DsTableRow>
        )
      }

      return result
    }

    return []
  }
  handleNavigation = (scriptId: string) => {
    const { navigateTo } = this.props
    navigateTo(`/quotes/${scriptId}`)
  }

  render() {
    const { selectedDate, mappedId } = this.props

    const { topLeft, topRight, bottomLeft, bottomRight } =
      this.generateOptionsForRenderingTable()

    // TODO: merge this to above function
    const topTableCells = this.generateTableCells(
      topLeft,
      topRight,
      'highlight'
    )
    const bottomTableCells = this.generateTableCells(bottomLeft, bottomRight)

    if (!selectedDate) {
      return (
        <DsStack alignItems={'flex-start'}>
          <DsSkeleton
            sx={{
              width: '100%',
              position: 'relative',
              top: '-60px',
              height: { xs: '400px', md: '400px' }
            }}
          />
        </DsStack>
      )
    }

    return (
      <DsStack
        sx={{
          padding: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-zero)'
          }
        }}
        flexDirection='column'
      >
        {/* TOP_TABLE */}
        {(topTableCells && topTableCells.length > 0 && (
          <DsBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 'var(--ds-spacing-quickFreeze)'
            }}
          >
            <DsTable>
              <DsTableBody>{topTableCells}</DsTableBody>
            </DsTable>
          </DsBox>
        )) ||
          null}

        {/* SPOT_PRICE  */}
        <DsBox
          sx={{
            position: 'sticky',
            top: { md: '14rem', lg: '14rem', xs: '13.5rem' },
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            width: '100%'
          }}
        >
          <SpotPrice mappedId={mappedId} />
        </DsBox>

        {/* BOTTOM_TABLE  */}
        {(bottomTableCells && bottomTableCells.length > 0 && (
          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTable>
              <DsTableBody>{bottomTableCells}</DsTableBody>
            </DsTable>
          </DsBox>
        )) ||
          null}
      </DsStack>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { LTP = '' } = ownProps
  const { midIndex, callOptions, putOptions, selectedDate } =
    getMidIndexValueWithCallPutOptionsSelector(LTP)(state)
  return {
    midIndex,
    callOptions,
    putOptions,
    selectedDate
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withBreakpoints(
  withStockSubUnSub(
    withErrorConnect(mapStateToProps, mapDispatchToProps)(OptionChainList)
  )
)
