import React from 'react'
import {
  autoBatchEnhancer,
  configureStore,
  StoreEnhancer
} from '@reduxjs/toolkit'
import reducers, {
  persistedReducers,
  TTickerReducers
} from '~/src/Redux/TickerStoreReducers'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import localforage from 'localforage'

const persistConfig = {
  key: `${process.env.APP_TITLE}-Ticker` || '',
  version: 1,
  storage: localforage.createInstance({}),
  whitelist: persistedReducers
}

type AutoBatchOptions =
  | { type: 'tick' }
  | { type: 'timer'; timeout: number }
  | { type: 'raf' }
  | { type: 'callback'; queueNotification: (notify: () => void) => void }

export type autoBatchEnhancer = (options?: AutoBatchOptions) => StoreEnhancer

const TickerStore = configureStore({
  reducer: persistReducer<TTickerReducers>(persistConfig, reducers),
  enhancers: getDefaultEnhancers => {
    return getDefaultEnhancers.concat(autoBatchEnhancer({ type: 'raf' }))
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
  devTools: process.env.NODE_ENV !== 'production'
})

export type RootState = ReturnType<typeof TickerStore.getState>
export type AppDispatch = typeof TickerStore.dispatch

// context
const PersistedTickerStore = persistStore(TickerStore)
const tickerContext = React.createContext<any>({})

export default TickerStore

type TTickerStore = ReturnType<typeof TickerStore.getState>

type TTickerDispatch = typeof TickerStore.dispatch

export { TTickerStore, TTickerDispatch, PersistedTickerStore, tickerContext }
