import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import {
  ssoAuthenticateActions,
  ssoCompleteActions,
  ssoInitiateActions,
  ssoValidateActions,
  updateCustomerDetailsAction,
  updateRiskDisclosureFlagAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import localforage from 'localforage'
import { loginWithRefreshTokenTraceActions } from '../Auth/Actions'
import { resetDataAction } from '../StoreActions'
export interface validationResponse {
  sessionId: string
  refreshToken: { token: string; expireAt: string }
  authToken: { token: string; expireAt: string }
  metadata?: any
}
export interface IAuthenticationResponse {
  refreshToken: { token: string; expireAt: string }
  authToken: { token: string; expireAt: string }
  metadata?: any
}
export interface IInitiateResponse {
  redirectURL: string
}
export interface ICompleteResponse {
  redirectURL: string
}
export interface SsoStore {
  haveSeenRiskDisclosure: boolean
  loading: boolean
  isValidated: boolean
  isAuthenticated: boolean
  subAccountId: string
  settlementType: number
  isNRI: boolean
  SSOID: string
  customerName: string
  ssoValidation: validationResponse
  ssoAuthentication: IAuthenticationResponse
  ssoInitiate: IInitiateResponse
  ssoComplete: ICompleteResponse
  error: any
}
const INITIAL_STATE: SsoStore = {
  haveSeenRiskDisclosure: false,
  loading: false,
  isValidated: false,
  isAuthenticated: false,
  subAccountId: '',
  isNRI: false,
  settlementType: 0,
  SSOID: '',
  customerName: '',
  ssoValidation: {
    sessionId: '',
    refreshToken: { token: '', expireAt: '' },
    authToken: { token: '', expireAt: '' },
    metadata: null
  },
  ssoAuthentication: {
    refreshToken: { token: '', expireAt: '' },
    authToken: { token: '', expireAt: '' },
    metadata: null
  },
  ssoInitiate: {
    redirectURL: ''
  },
  ssoComplete: {
    redirectURL: ''
  },
  error: { code: 0, message: '', isDispatch: false }
}
const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    storeSsoId: (state, { payload }) => {
      state.SSOID = payload.ssoId
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    //updateRDDetails
    builder.addCase(updateRiskDisclosureFlagAction, (state, { payload }) => {
      state.haveSeenRiskDisclosure = payload
    })

    // updateAuthToken
    builder.addCase(
      loginWithRefreshTokenTraceActions.success,
      (state, { payload }) => {
        state.ssoAuthentication.authToken = payload.data
      }
    )
    //updateCustomerDetailsAction
    builder.addCase(updateCustomerDetailsAction, (state, { payload }) => {
      const { subAccountId, name, isNRI = false, settlementType = 0 } = payload
      state.subAccountId = subAccountId
      state.customerName = name
      state.isNRI = isNRI
      if (isNRI) {
        state.settlementType = settlementType
      }
    })
    // authenticate
    builder.addCase(ssoAuthenticateActions.success, (state, { payload }) => {
      state.ssoAuthentication = payload
      state.isAuthenticated = true
    })
    // validate
    builder.addCase(ssoValidateActions.success, (state, { payload }) => {
      const { metadata } = payload
      const { subAccountId } = metadata
      state.ssoValidation = payload
      state.isValidated = true
      state.subAccountId = subAccountId
    })
    // complete
    builder.addCase(ssoCompleteActions.success, (state, { payload }) => {
      state.ssoComplete = payload
    })
    // initiate
    builder.addCase(ssoInitiateActions.success, (state, { payload }) => {
      state.ssoInitiate = payload
    })
    //resetDataActions
    builder.addCase(resetDataAction, (state, { payload }) => {
      state.ssoAuthentication = INITIAL_STATE.ssoAuthentication
      state.subAccountId = INITIAL_STATE.subAccountId
      state.isAuthenticated = INITIAL_STATE.isAuthenticated
      state.customerName = INITIAL_STATE.customerName
      state.isNRI = INITIAL_STATE.isNRI
      state.settlementType = INITIAL_STATE.settlementType
      state.haveSeenRiskDisclosure = INITIAL_STATE.haveSeenRiskDisclosure
    })
  }
}
const ssoSlice = createSlice(sliceOptions)
export const { storeSsoId, updateRistDisclosureFlag } = ssoSlice.actions
export const ssoPersistConfig = {
  key: SLICE_NAME,
  version: 1,
  storage: localforage,
  whitelist: [
    'ssoAuthentication',
    'subAccountId',
    'isAuthenticated',
    'customerName',
    'isNRI',
    'settlementType',
    'haveSeenRiskDisclosure'
  ]
}
export default ssoSlice.reducer
