import {
  DsDrawer,
  DsStack,
  DsTypography,
  DsRemixIcon,
  DsBottomSheet
} from '@am92/react-design-system'
import React from 'react'
import useResponsiveBreakpoints from '~/src/hooks/useResponsiveBreakpoints'
import HoldingDetailsPopUpContent from './HoldingDetailsPopUpContent'

interface IHoldingDetailsPopUpProps {
  holdingDetailsPopUpIndicator: boolean
  handleCloseHoldingDetailsPopUp: () => void
}

const HoldingDetailsPopUp: React.FunctionComponent<
  IHoldingDetailsPopUpProps
> = (props: IHoldingDetailsPopUpProps) => {
  const { isDesktop } = useResponsiveBreakpoints()
  const { holdingDetailsPopUpIndicator, handleCloseHoldingDetailsPopUp } = props

  if (isDesktop) {
    return (
      <DsDrawer
        PaperProps={{
          sx: {
            bottom: '0px',
            boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)',
            width: '460px',
            right: '400px',
            left: 'auto',
            borderTop: 'unset',
            borderRadius:
              'var(--ds-spacing-bitterCold) var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-zero)'
          }
        }}
        anchor='bottom'
        open={holdingDetailsPopUpIndicator}
        variant='persistent'
        hideBackdrop
        ModalProps={{
          keepMounted: false
        }}
      >
        <DsStack
          sx={{
            padding: 'var(--ds-spacing-mild)'
          }}
        >
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 'var(--ds-spacing-mild)'
            }}
          >
            <DsTypography variant='headingBoldMedium'>
              Holdings Details
            </DsTypography>
            <DsRemixIcon
              onClick={handleCloseHoldingDetailsPopUp}
              className='ri-close-fill'
              fontSize='mild'
              sx={{
                cursor: 'pointer'
              }}
            />
          </DsStack>
          <HoldingDetailsPopUpContent />
        </DsStack>
      </DsDrawer>
    )
  }
  return (
    <DsBottomSheet
      open={holdingDetailsPopUpIndicator}
      onClose={handleCloseHoldingDetailsPopUp}
      title='Holdings Details'
      ModalProps={{
        keepMounted: false
      }}
    >
      <HoldingDetailsPopUpContent />
    </DsBottomSheet>
  )
}

export default HoldingDetailsPopUp
