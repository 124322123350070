import Bowser from 'bowser'

import {
  getLocalStorage,
  setLocalStorage,
  UUIDGenerator
} from '~/src/Utils/global'

export const LOCAL_STORAGE_KEY = 'data'
export const LOCAL_STORAGE_UUID = 'uuid'

export type BrowserInfoType = {
  deviceId?: string
  browser?: string
  os?: string
  type?: string
  browserVersion?: string
  osVersion?: string
  locationAccess?: boolean
  message?: string
  error?: boolean
}

export const fetchDeviceDetails = () => {
  const { navigator: { userAgent } = {} } = window
  let browserInfo = {
    message: 'Cannot find userAgent',
    error: false
  } as BrowserInfoType
  if (userAgent) {
    const browserDetails = Bowser.parse(userAgent)
    const { browser, os } = browserDetails
    const { name: browserName, version: browserVersion } = browser

    const { name: osName, version: osVersion } = os

    if (!getLocalStorage(LOCAL_STORAGE_UUID)) {
      const Uuid = UUIDGenerator('ASL_LOGIN_MS')
      setLocalStorage(LOCAL_STORAGE_UUID, Uuid)
    }

    const deviceId = getLocalStorage(LOCAL_STORAGE_UUID) as string
    browserInfo = {
      deviceId,
      browser: browserName,
      os: osName,
      browserVersion,
      osVersion
    }
  } else {
    return { error: 'Fetch Device Info failed' }
  }
  return browserInfo
}

export const getDeviceActiveScreen = (breakpoints: any) => {
  const { xs, sm, md, lg, xl } = breakpoints || {}

  return { isMobile: xs, isTablet: sm, isDesktop: md || lg || xl }
}
