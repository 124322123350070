import React from 'react'

// Ds Components
import {
  DsStack,
  withBreakpoints,
  DsBox,
  DsButton,
  DsDialog,
  DsTypography,
  SupportedColorScheme
} from '@am92/react-design-system'

// Constants
import { getDeviceActiveScreen } from '../Utils/deviceDetails'

//Lib
import withErrorConnect from '../Lib/withErrorConnect'
import withColorScheme, { IWithColorSchemeProps } from '../Lib/withColorScheme'
import { IWithRouterProps } from '../Lib/withRouter'
import { To } from 'react-router-dom'
import { openFeedbackForm, triggerCleverTapEvent } from '../Utils/global'
import ssoInitiateAction, {
  ssoInitiateServiceName
} from '../Redux/SSO/Services/loginInitiate.Service'
import { getSubAccountIdFromRedux } from '../Utils/global'
import { INITIATE_REDIRECTION_LINK } from '../Configurations/env'
import APP_ROUTES from '../Constants/APP_ROUTES'
import {
  getCustomerName,
  getSSOAuthenticateData,
  getSubAccountIdSelector,
  isNRICustomer
} from '../Redux/SSO/Selectors'
import { getServiceSelector } from '../Redux/ServiceTracker/Selectors'
import Loader from './Loader'
import {
  getCommodityValueSelector,
  getEquityValueSelector
} from '../Redux/Inquiry/Selectors'
import CustomerSupportServiceNotAvailableError from './CustomerSupportSomethingWentWrongError/CustomerSupportServiceNotAvailableError'
import { getCustomerProfileSelector } from '../Redux/Customer/Selectors'
import LinkDDPIContainer from '../Pages/PledgeUnpledge/Components/LinkDDPI/LinkDDPI.Container'
import { setThemeSchemeAction } from '../Redux/Theme/Actions'
import { getThemeReducer } from '../Redux/Theme/Selectors'
import FundsLimit from './FundsLimit'
import { resetDataAction } from '../Redux/StoreActions'
import GlobalMenuList from './GlobalMenuList'

type ActionTypes = {
  SSOInitiate: (data: any) => any
  setThemeScheme: (theme: SupportedColorScheme) => any
  resetData: () => Promise<void>
}

interface GlobalMenuProps extends IWithRouterProps, IWithColorSchemeProps {
  breakpoints: any
  setShowMenu?: any
  handleInitiateReportsMs: (item: string) => void
  setLogoutConfirmation?: any
  handleLogout?: any
  handleError: (res: any) => void
  isLoadingInitiate: any
  authenticateSSOSelector: any
  getCommodityValueSelect: any
  getEquityValueSelect: any
  actions: ActionTypes
  customerProfileDetailsSelector: any
  customerName: string
  customerSubAccountId: string
  isCustomerNRI: boolean
  theme: string
}

interface GlobalMenuState {
  logoutConfirmation: boolean
  selectedMenu: string
  showCustomerServiceModal: boolean
  showLinkDDPI: boolean
}

class GlobalMenu extends React.Component<GlobalMenuProps, GlobalMenuState> {
  state = {
    logoutConfirmation: false,
    selectedMenu: '',
    showCustomerServiceModal: false,
    showLinkDDPI: false
  }

  setLogoutConfirmation = (value: boolean) => {
    this.setState({
      logoutConfirmation: value
    })
  }

  handleModeChange = (name: string, value: boolean) => {
    const { actions } = this.props
    const newMode = value ? 'dark' : 'light'
    actions.setThemeScheme(newMode)
  }

  handleOpenMenu = (menu: string) => {
    const { selectedMenu } = this.state
    if (selectedMenu !== menu) {
      this.setState({ selectedMenu: menu })
    } else {
      this.setState({ selectedMenu: '' })
    }
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleFeedBack = () => {
    const { customerName = 'failed', customerSubAccountId = 'failed' } =
      this.props

    openFeedbackForm(customerName, customerSubAccountId)
  }

  handleNavigate = async (type: string, link: string) => {
    const {
      setShowMenu,
      breakpoints = {},
      customerProfileDetailsSelector = {},
      isCustomerNRI
    } = this.props
    const parts = link?.split('/')
    const option =
      type === 'EXTERNAL' || type === 'INTERNAL'
        ? parts[parts.length - 1].toUpperCase()
        : type
    const customAttributes = {
      Event_Status: 'Pass',
      Action: option,
      Screen_Name: 'Menu'
    }
    triggerCleverTapEvent('Menu', customAttributes)
    const { productsAllowed = {} } = customerProfileDetailsSelector
    const { pledgeMargin = {} } = productsAllowed
    const { isActive, metadata = {} } = pledgeMargin
    const { code = '' } = metadata
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    if (isDesktop && (type !== 'PLEDGE' || (type === 'PLEDGE' && isActive))) {
      await setShowMenu(false)
    }
    if (type === 'DARK_THEME') {
      const customAttributes = {
        Event_Status: 'Pass',
        Action: option,
        Screen_Name: 'Dark_Theme_Enabled'
      }
      triggerCleverTapEvent('Menu', customAttributes)
      return
    }
    if (type === 'FEEDBACK') {
      this.handleFeedBack()
    }
    if (type === 'PLEDGE') {
      if (isActive) {
        this.navigateTo(APP_ROUTES.PLEDGE.pathname)
      } else if (code != 'ERR_OMS_UNIMPLEMENTED' && !isCustomerNRI) {
        this.setState({ showLinkDDPI: true })
      } else {
        this.setState({ showCustomerServiceModal: true })
      }
    }
    if (type === 'CHANGE_PASSWORD') {
      const customAttributes = {
        Event_Status: 'Pass',
        Action: 'Change_Password',
        Screen_Name: 'Menu_Settings'
      }
      triggerCleverTapEvent('Menu', customAttributes)
      this.handleChangePassword(type)
    }
    if (type === 'INTERNAL') {
      this.navigateTo(link)
    }
    if (type === 'MARGIN') {
      this.navigateTo(APP_ROUTES.FUNDSDASHBOARD.pathname)
    }
    if (type === 'LOGOUT') {
      isDesktop ? this.handleLogout() : this.setLogoutConfirmation(true)
    }
    if (type == 'EXTERNAL' && link) {
      window.open(link)
    }
    if (type == 'PARTNERS') {
      this.navigateTo(APP_ROUTES.PARTNERS_INFO.pathname)
    }
  }

  handleChangePassword = async (actionType: string) => {
    const { handleError, actions, authenticateSSOSelector, theme } = this.props
    const subAccountId = getSubAccountIdFromRedux()

    const { refreshToken } = authenticateSSOSelector
    const { token } = refreshToken

    const requestPayload = {
      type: 'LOGIN',
      refreshToken: token,
      redirectURL: INITIATE_REDIRECTION_LINK,
      metadata: {
        requestedFlow: actionType,
        subAccountId,
        theme
      }
    }
    const response = await actions.SSOInitiate(requestPayload)
    if (response._isCustomError) {
      console.log('failed API - initiate Login MS Change password')
      return handleError(response)
    }
    const { redirectURL } = response

    // const url = new URL(redirectURL)
    // const urlParams = new URLSearchParams(url.search)
    // const newSsoId = urlParams.get('ssoId') || ''
    // window.open(`http://localhost:3002/sso/validate?ssoId=${newSsoId}`, '_self')

    window.open(redirectURL, '_self')
  }

  handleLogout = async () => {
    const { actions } = this.props
    //clear user session from indexDB & redux
    window.indexedDB.deleteDatabase('localforage')
    await actions.resetData()
    this.navigateTo(APP_ROUTES.LOGIN.pathname)
  }

  handleConfirmLogin = () => {
    this.setLogoutConfirmation(false)
  }

  setShowServiceModal = (value: boolean) => {
    this.setState({ showCustomerServiceModal: value })
  }

  onClose = (value: boolean) => {
    this.setState({ showLinkDDPI: value })
  }

  handleRenderHeader = (item: string) => {
    const { getEquityValueSelect, getCommodityValueSelect, breakpoints } =
      this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    const totalAvalFunds =
      Number(getEquityValueSelect) + Number(getCommodityValueSelect)
    if (item === 'NET_MARGIN') {
      if (isDesktop) {
        return 'Net Available Margin'
      }
      if (!isDesktop) {
        return <FundsLimit type='typography' />
      }
    }
    return item
  }

  render() {
    const { breakpoints, isLoadingInitiate, theme } = this.props
    const {
      selectedMenu,
      logoutConfirmation,
      showCustomerServiceModal,
      showLinkDDPI
    } = this.state
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    const isLoading = isLoadingInitiate
    return (
      <>
        {isLoading && <Loader />}
        {showCustomerServiceModal && (
          <CustomerSupportServiceNotAvailableError
            isDesktop={isDesktop}
            setShowServiceModal={this.setShowServiceModal}
          />
        )}
        {showLinkDDPI && (
          <LinkDDPIContainer
            onClose={this.onClose}
            openServiceModal={this.setShowServiceModal}
          />
        )}

        <GlobalMenuList
          selectedMenu={selectedMenu}
          handleOpenMenu={this.handleOpenMenu}
          handleNavigate={this.handleNavigate}
          handleRenderHeader={this.handleRenderHeader}
          handleModeChange={this.handleModeChange}
          theme={theme}
        />

        <DsDialog
          open={logoutConfirmation}
          onClose={this.handleConfirmLogin}
          showClose={false}
        >
          <DsStack
            justifyContent={'center'}
            alignItems={'center'}
            spacing={'24px'}
          >
            <DsTypography variant='bodyRegularSmall'>
              Are you sure you want to logout from this device?
            </DsTypography>
            <DsBox sx={{ display: 'flex' }}>
              <DsButton
                variant='text'
                color='secondary'
                onClick={this.handleConfirmLogin}
              >
                No
              </DsButton>
              <DsButton
                variant='text'
                color='secondary'
                onClick={this.handleLogout}
              >
                Yes
              </DsButton>
            </DsBox>
          </DsStack>
        </DsDialog>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const authenticateSSOSelector = getSSOAuthenticateData(state)
  const isLoadingInitiate =
    getServiceSelector(state, ssoInitiateServiceName) === 'LOADING'
  const getCommodityValueSelect = getCommodityValueSelector(state)
  const getEquityValueSelect = getEquityValueSelector(state)
  const customerProfileDetailsSelector = getCustomerProfileSelector(state)
  const customerName = getCustomerName(state)
  const customerSubAccountId = getSubAccountIdSelector(state)
  const isCustomerNRI = isNRICustomer(state)
  const { scheme } = getThemeReducer(state)

  return {
    authenticateSSOSelector,
    isLoadingInitiate,
    getEquityValueSelect,
    getCommodityValueSelect,
    customerProfileDetailsSelector,
    customerName,
    customerSubAccountId,
    isCustomerNRI,
    theme: scheme
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SSOInitiate: (data: any) => dispatch(ssoInitiateAction(data)),
    resetData: () => dispatch(resetDataAction()),
    setThemeScheme: (theme: SupportedColorScheme) =>
      dispatch(setThemeSchemeAction(theme))
  }
})

export default withBreakpoints(
  withErrorConnect(
    mapStateToProps,
    mapDispatchToProps
  )(withColorScheme(GlobalMenu))
)
