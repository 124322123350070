import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'

export const getOrderBookServiceName = 'orders/getOrderBook'
export const getOrderBookActions = traceActionsCreator(getOrderBookServiceName)

export const getSipOrderBookServiceName = 'orders/getSipOrderBook'
export const getSipOrderBookActions = traceActionsCreator(
  getSipOrderBookServiceName
)
export const getSipOrderDetailsServiceName = 'orders/getSipOrderDetails'
export const getSipOrderDetailsActions = traceActionsCreator(
  getSipOrderDetailsServiceName
)

export const createSipOrderServiceName = 'orders/createSipOrder'
export const createSipOrderActions = traceActionsCreator(
  createSipOrderServiceName
)

export const cancelSipOrderServiceName = 'orders/cancelSipOrder'
export const cancelSipOrderActions = traceActionsCreator(
  cancelSipOrderServiceName
)

export const modifySipOrderServiceName = 'orders/modifySipOrder'
export const modifySipOrderActions = traceActionsCreator(
  modifySipOrderServiceName
)

export const getRequiredMarginServiceName = 'orders/getRequiredMargin'
export const getRequiredMarginActions = traceActionsCreator(
  getRequiredMarginServiceName
)

export const placeOrderServiceName = 'orders/placeOrder'
export const placeOrderActions = traceActionsCreator(placeOrderServiceName)

export const modifyOrderServiceName = 'orders/modifyOrder'
export const modifyOrderActions = traceActionsCreator(modifyOrderServiceName)

export const cancelOrderServiceName = 'orders/cancelOrder'
export const cancelOrderActions = traceActionsCreator(cancelOrderServiceName)

export const getByOmsOrderIDServiceName = 'orders/getByOmsOrderID'
export const getByOmsOrderIDActions = traceActionsCreator(
  getByOmsOrderIDServiceName
)

export const updateOrderPageModalIndicatorServiceName =
  'appstate/openAppStateDialog'
export const updateOrderPageModalIndicatorActions = createAction<any>(
  updateOrderPageModalIndicatorServiceName
)

export const getTaxAndChargesServiceName = 'orders/getTaxAndCharges'
export const getTaxAndChargesActions = traceActionsCreator(
  getTaxAndChargesServiceName
)

export const updateSipSelectedScriptServiceName =
  'orders/updateSipSelectedScript'
export const updateSipSelectedScriptActions = traceActionsCreator(
  updateSipSelectedScriptServiceName
)
export const resetOrderDetailsModalStatesServiceName =
  'orders/resetOrderDetailsModalStatesAction'
export const resetOrderDetailsModalStatesActions = createAction(
  resetOrderDetailsModalStatesServiceName
)
