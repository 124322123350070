import {
  DsStack,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import React, { FunctionComponent, useEffect } from 'react'
import { IWithRouterProps } from '../Lib/withRouter'
import getResearchConfigAction from '../Redux/ResearchAndRecommendation/Services/getResearchConfig.Service'
import {
  setResearchData,
  T_RecommendationsDataArray
} from '../Redux/ResearchAndRecommendation/Reducers'
import { SESSION_EXPIRED_EVENT_FROM_SOCKET } from '../Workers/tcs/SOCKET_WORKER_CONSTANT'
import { DEEP_LINK_KEYS } from '../Constants/APP_CONSTANTS'
import { resetDeepLinkData } from '../Redux/AppState/reducer'
import APP_ROUTES from '../Constants/APP_ROUTES'
import { appContext, TAppDispatch, TAppStore } from '../Configurations/AppStore'
import { getOrderPageSipModalIndicator } from '../Redux/Indicators/Selectors'
import { getDeepLinkAttributes } from '../Redux/AppState/selectors'
import {
  setPortfolioIndicator,
  setPortfolioTabIndicator
} from '../Redux/Indicators/Reducer'
import { FULL_SCREEN } from '../Utils/global'
import { getDeviceActiveScreen } from '../Utils/deviceDetails'
import { updateOrderPageModalIndicator } from '../Redux/Orders/reducer'
import AlertsFormModal from '../Components/AlertsForm/AlertsFormModal'
import AppHeader from '../Components/AppHeader'
import AppOutlet from '../Components/AppOutlet'
import BottomNavigation from '../Components/BottomNavigation/BottomNavigation'
import OrderModal from '../Components/OrderModal/OrderModal'
import ReverseFeedNotifier from '../Components/ReverseFeedAlert/ReverseFeedNotifier'
import RiskDisclosure from '../Components/RiskDisclosure'
import ShortCutSearch from '../Components/ShortCutSearch'
import SipModal from '../Components/SipModal/SipModal'
import PostLoginErrors from '../Pages/Quotes/Components/PostLoginErrors'
import withErrorConnect from '../Lib/withErrorConnect'
import { connect, ConnectedProps } from 'react-redux'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '../Redux/Orders/TYPES'
import OptionChainDialog from '../Pages/Quotes/Components/OptionChain/OptionChainDialog'

export interface IMainLayoutProps
  extends PropsFromRedux,
    IWithRouterProps,
    IwithBreakpoints {
  handleError: (res: any) => void
}

const APP_PAGES_FOR_MOBILE_LAYOUT = [
  '/quotes',
  '/profile',
  '/partners',
  '/reports',
  '/recommendation',
  '/notifications',
  '/alerts',
  '/portfolio/mutualfunds',
  '/portfolio/smartedgeplus',
  '/portfolio/stocks',
  '/portfolio/cat3',
  '/portfolio-mutualfund',
  '/research-category',
  '/alerts',
  '/portfolio/demat/unsettled-stocks',
  '/portfolio/demat/collateral-stocks'
]

const MainLayout: FunctionComponent<IMainLayoutProps> = props => {
  useEffect(() => {
    const { actions } = props
    actions.getResearchConfigAction()
    actions.setResearchData([])
    handleDeepLink()
    addEventListener('keydown', handleKeyDown)
    addEventListener(
      SESSION_EXPIRED_EVENT_FROM_SOCKET,
      handleSessionExpiryEventFromSocket
    )

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener(
        SESSION_EXPIRED_EVENT_FROM_SOCKET,
        handleSessionExpiryEventFromSocket
      )
    }
  }, [])

  const handleDeepLink = () => {
    const { actions, deepLinkAttributes } = props
    if (deepLinkAttributes) {
      const { path, parameterList } = deepLinkAttributes
      if (path === DEEP_LINK_KEYS.ORDER_FORM) {
        const parameter1 = parameterList[0]
        const parameter2 = parameterList[1]
        if (
          parameter1 &&
          parameter2 &&
          (parameter2 === 'BUY' || parameter2 === 'SELL')
        ) {
          handleBuySell(parameter2, parameter1)
        }
        actions.resetDeepLinkData()
      }
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'F3') {
      event.preventDefault()
      handleNavigateToOrderBook()
    }
    if (event.ctrlKey && event.key === 'p') {
      event.preventDefault()
      navigateToPositions()
    }
    if (event.ctrlKey && event.shiftKey && event.key === 'F2') {
      event.preventDefault()
      navigateToFunds()
    }
    if (event.alt && event.key === 'F6') {
      event.preventDefault()
      navigateToTodaysPositions()
    }
  }

  const handleSessionExpiryEventFromSocket = () => {
    const { handleError } = props
    handleError({
      error: {
        code: 1081
      }
    })
  }

  const handleNavigateToOrderBook = () => {
    const { navigateTo } = props
    const status = 'Pending'
    const options = {
      state: { status }
    }
    navigateTo(APP_ROUTES.ORDERBOOK.pathname, options)
    window.scrollTo(0, 0)
  }

  const navigateToTodaysPositions = () => {
    const { navigateTo, actions } = props
    actions.setPortfolioTabIndicator(1)
    actions.setPortfolioIndicator(1)
    navigateTo('/portfolio/stocks')
    window.scrollTo(0, 0)
  }

  const navigateToPositions = () => {
    const { navigateTo, actions } = props
    actions.setPortfolioTabIndicator(1)
    navigateTo('/portfolio/stocks')
    window.scrollTo(0, 0)
  }

  const navigateToFunds = () => {
    const { navigateTo } = props
    navigateTo(APP_ROUTES.FUNDSDASHBOARD.pathname)
    window.scrollTo(0, 0)
  }

  const handleShowMobileLayout = () => {
    const url = window.location.href
    const pathname = new URL(url).pathname
    const regexPattern = new RegExp(APP_PAGES_FOR_MOBILE_LAYOUT.join('|'))
    if (regexPattern.test(pathname)) {
      return false
    }
    return !FULL_SCREEN.includes(pathname)
  }

  const handleBuySell = (value: string, selectedScript: string) => {
    const { navigate, breakpoints, actions } = props
    const { exchange } =
      (window as any).secMaster.getByScriptId(selectedScript) || {}
    if (!exchange) {
      const { isDesktop } = getDeviceActiveScreen(breakpoints)
      if (!isDesktop) {
        navigate(APP_ROUTES.MOBILE_WATCHLIST.pathname)
        return
      }
      return
    }
    actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: true,
      orderScriptId: selectedScript,
      orderBuyOrSell: value,
      selectedExchange: exchange,
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
  }

  const { orderPageSipModalIndicator } = props
  return (
    <>
      <DsStack sx={{ position: 'relative' }}>
        <AppHeader handleShowMobileLayout={handleShowMobileLayout} />
        <AppOutlet />
        {handleShowMobileLayout() && (
          <DsStack sx={{ position: 'sticky', bottom: 0, zIndex: 3 }}>
            <BottomNavigation />
          </DsStack>
        )}
      </DsStack>
      <RiskDisclosure />
      <PostLoginErrors />
      <ReverseFeedNotifier />
      <ShortCutSearch />
      <OrderModal />
      {orderPageSipModalIndicator && <SipModal />}
      <AlertsFormModal />
      <OptionChainDialog />
    </>
  )
}

const mapStateToProps = (state: TAppStore) => {
  const deepLinkAttributes = getDeepLinkAttributes(state)
  const orderPageSipModalIndicator = getOrderPageSipModalIndicator(state)
  return {
    deepLinkAttributes,
    orderPageSipModalIndicator
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    resetDeepLinkData: () => dispatch(resetDeepLinkData(null)),
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    setPortfolioTabIndicator: (tab: number) =>
      dispatch(setPortfolioTabIndicator(tab)),
    setPortfolioIndicator: (val: number) =>
      dispatch(setPortfolioIndicator(val)),
    getResearchConfigAction: () => dispatch(getResearchConfigAction()),
    setResearchData: (data: T_RecommendationsDataArray) =>
      dispatch(setResearchData(data))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(MainLayout)
)
