import {
  DsBox,
  DsDivider,
  DsGrid,
  DsListItemButton,
  DsMenu,
  DsMenuItem,
  DsPopup,
  DsRemixIcon,
  DsStack,
  DsTextField,
  DsTypography,
  enqueueNotistack,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'
import { removeWatchlistIdFromStocksAction } from '~/src/Redux/StockWatchlistMaster/Actions'
import {
  T_SCRIPTS_OBJ,
  selectWatchlist,
  selectWatchlistTab,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import {
  getSelectedWatchlist,
  getWatchListData,
  preDefinedWatchlistCountSelector
} from '~/src/Redux/WatchList/Selectors'
import deleteWatchlistAction, {
  DeleteWatchlistParams
} from '~/src/Redux/WatchList/Services/DeleteWatchlist.Service'
import renameWatchlistAction, {
  renameWatchlistPayload
} from '~/src/Redux/WatchList/Services/RenameWatchlist.Service'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

type ActionTypes = {
  renameWatchlist: (reqData: renameWatchlistPayload) => Promise<any>
  deleteWatchlist: (param: DeleteWatchlistParams) => Promise<any>
  removeWatchlistIdFromStocks: (requestData: {
    watchListIdToDelete: string
    scriptIdArray: T_SCRIPTS_OBJ[]
  }) => Promise<any>
  setSelectedWatchlistTab: (reqData: number | string) => Promise<any>
  setSelectedWatchlist: (selectedWatchlist: watchlistObj) => Promise<any>
}

export interface IWatchlistMenuActionsProps extends IWithRouterProps {
  actions: ActionTypes
  showMenu?: boolean
  anchorEl?: any
  setShowMenu?: (value: boolean, selectedWatchlist?: watchlistObj) => void
  singleWatchlistData: watchlistObj
  breakpoints: any
  selectedWatchlist?: watchlistObj
  watchList: watchlistObj[]
  renameSelectedWatchlistName: (
    selectedWatchlist: watchlistObj,
    watchlistName: string
  ) => void
  unSubscribeLtpData: (scriptArray: any[]) => void
  preDefinedWatchlistCount: number
}

class WatchlistMenuActions extends Component<IWatchlistMenuActionsProps> {
  state = {
    renameId: '',
    deleteId: '',
    watchlistName: '',
    watchlistNameError: false,
    watchlistNameHelperText: '',
    openDeleteWatchlistModal: false,
    openRenameWatchListNameModal: false
  }

  handleOpenRenameWatchlistModal = () => {
    const { selectedWatchlist, setShowMenu } = this.props
    const { watchlistId, watchlistName } = selectedWatchlist as watchlistObj

    this.setState({
      openRenameWatchListNameModal: true,
      renameId: watchlistId,
      watchlistName
    })

    setShowMenu && setShowMenu(false, selectedWatchlist)
  }

  handleOpenDeleteWatchlistModal = () => {
    const { selectedWatchlist, setShowMenu } = this.props
    const { watchlistId } = selectedWatchlist as watchlistObj
    this.setState({
      openDeleteWatchlistModal: true,
      deleteId: watchlistId
    })
    setShowMenu && setShowMenu(false, selectedWatchlist)
  }

  validateWatchlistName = (name: string) => {
    if (name === '') {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Name can’t be empty'
      })
    } else if (name.length >= 18) {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Watchlist name should be 1-18 characters long'
      })
    } else {
      this.setState({
        watchlistNameError: false,
        watchlistNameHelperText: ''
      })
    }
  }

  handleWatchlistName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const watchlistName = e.target.value
    this.setState({ watchlistName })
    this.validateWatchlistName(watchlistName.trim())
  }

  handleCloseRenameWatchlistNameModal = () => {
    this.setState({
      openRenameWatchListNameModal: false,
      renameId: '',
      watchlistName: '',
      watchlistNameError: false,
      watchlistNameHelperText: ''
    })
  }

  handleRenameWatchlist = async () => {
    const { watchlistName, renameId } = this.state
    const {
      actions,
      selectedWatchlist,
      watchList,
      renameSelectedWatchlistName
    } = this.props

    let duplicateFound = false
    let duplicateFoundId

    for (let i = 0; i < watchList.length; i++) {
      const watchListObj = watchList[i]
      const { watchlistName: WATCHLIST_NAME, watchlistId } = watchListObj
      if (WATCHLIST_NAME === watchlistName.trim()) {
        duplicateFoundId = watchlistId
        duplicateFound = true
        break
      }
    }

    if (duplicateFound && duplicateFoundId !== renameId) {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Watchlist name should be unique'
      })
      return
    }

    if (!duplicateFound) {
      const requestData: renameWatchlistPayload = {
        params: {
          watchlistId: renameId
        },
        dataPayload: {
          watchlistName: watchlistName.trim()
        }
      }
      const renameWatchlistResponse = await actions.renameWatchlist(requestData)
      if (renameWatchlistResponse?._isCustomError) {
        enqueueNotistack({
          message: 'Request failed!'
        })
        console.log('failed API - renameWatchlist')
        return
      }
      enqueueNotistack({
        message: 'Watchlist renamed!'
      })
      // remove prev name of the watchlist and insert entry inside pre kept names if name is of pattern Watchlist 1
      renameSelectedWatchlistName(
        selectedWatchlist as watchlistObj,
        watchlistName
      )
    }

    this.handleCloseRenameWatchlistNameModal()
    window.scrollTo(0, 0)
  }

  renderRenamePopup = () => {
    const {
      openRenameWatchListNameModal,
      watchlistName,
      watchlistNameError,
      watchlistNameHelperText
    } = this.state

    const { breakpoints } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        <DsPopup
          open={openRenameWatchListNameModal}
          onClose={this.handleCloseRenameWatchlistNameModal}
          primaryButtonText='Save'
          secondaryButtonText='Cancel'
          showClose={!isDesktop ? true : false}
          primaryButtonProps={{
            onClick: this.handleRenameWatchlist,
            disabled: watchlistName.length === 0 || watchlistNameError,
            className: 'watchlistRename__btnSave'
          }}
          secondaryButtonProps={{
            onClick: this.handleCloseRenameWatchlistNameModal,
            className: 'watchlistRename__btnCancel'
          }}
          title={WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.NAME_YOUR_WATCHLIST}
        >
          <DsTextField
            autoFocus
            fullWidth
            type='text'
            value={watchlistName}
            error={watchlistNameError}
            helperText={watchlistNameHelperText}
            onChange={this.handleWatchlistName}
          />
        </DsPopup>
      </>
    )
  }

  handleCloseDeleteWatchlistModal = () => {
    this.setState({
      openDeleteWatchlistModal: false,
      deleteId: ''
    })
  }

  handleDeleteWatchlist = async () => {
    // update watchlist sequence logic post deletion and update redux, thus UI
    const { deleteId } = this.state
    const {
      actions,
      breakpoints,
      selectedWatchlist,
      preDefinedWatchlistCount
    } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const deletedSecuritiesArray = selectedWatchlist?.watchlistSecurities
    const deleteWatchlistResponse = await actions.deleteWatchlist({
      watchlistId: deleteId
    })
    if (deleteWatchlistResponse?._isCustomError) {
      enqueueNotistack({
        message: 'Request failed!'
      })
      console.log('failed API - deleteWatchlist')
      return
    }

    const { watchList } = this.props
    // write logic to remove watchlistIds from stockWatchlistMaster
    if (deletedSecuritiesArray?.length) {
      await actions.removeWatchlistIdFromStocks({
        watchListIdToDelete: deleteId,
        scriptIdArray: deletedSecuritiesArray
      })
    }

    await this.unsubscribeWatchListStocks()
    isDesktop && actions.setSelectedWatchlistTab(0)
    //NOTE - setting 2nd watchlist as Selectedwatchlist in redux as the current selected is deleted
    actions.setSelectedWatchlist(watchList[preDefinedWatchlistCount])
    this.handleCloseDeleteWatchlistModal()
    window.scrollTo(0, 0)
    enqueueNotistack({
      message: 'Watchlist Deleted!'
    })
  }

  unsubscribeWatchListStocks = async () => {
    const { selectedWatchlist, unSubscribeLtpData } = this.props
    const deletedSecuritiesArray = selectedWatchlist?.watchlistSecurities
    if (deletedSecuritiesArray && deletedSecuritiesArray.length > 0) {
      unSubscribeLtpData(deletedSecuritiesArray)
    }
  }

  renderDeletePopup = () => {
    const { openDeleteWatchlistModal } = this.state
    const { breakpoints } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        <DsPopup
          open={openDeleteWatchlistModal}
          onClose={this.handleCloseDeleteWatchlistModal}
          primaryButtonText='Yes'
          secondaryButtonText='No'
          showClose={!isDesktop ? true : false}
          primaryButtonProps={{
            onClick: this.handleDeleteWatchlist,
            className: 'watchlistDelete__btnYes'
          }}
          secondaryButtonProps={{
            onClick: this.handleCloseDeleteWatchlistModal,
            className: 'watchlistDelete__btnNo'
          }}
          title={WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.DELETE_YOUR_WATCHLIST}
        >
          <DsTypography variant='bodyRegularMedium'>
            {WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.DELETE_SURE}
          </DsTypography>
        </DsPopup>
      </>
    )
  }

  handleEdit = () => {
    const { actions, navigate, singleWatchlistData } = this.props
    const { watchlistSecurities } = singleWatchlistData
    const isEmptyWatchlist =
      !watchlistSecurities || watchlistSecurities?.length === 0
    if (isEmptyWatchlist) {
      enqueueNotistack({
        message: 'No stocks added!'
      })
      return
    }
    actions.setSelectedWatchlist(singleWatchlistData)
    navigate(APP_ROUTES.MOBILE_EDIT_WATCHLIST.pathname)
  }

  renderDesktopMenu = () => {
    const { singleWatchlistData, showMenu, anchorEl, setShowMenu } = this.props
    const { watchlistId } = singleWatchlistData
    return (
      <>
        <DsMenu
          key={watchlistId}
          sx={{
            display: {
              md: 'block',
              xs: 'none'
            },
            maxWidth: '100%',
            '& .MuiPaper-root': {
              minWidth: 300,
              boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.08)'
            }
          }}
          anchorEl={anchorEl}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
          id='basic-menu'
          open={showMenu as boolean}
          onClose={() => setShowMenu && setShowMenu(false, singleWatchlistData)}
        >
          <DsMenuItem
            onClick={this.handleOpenRenameWatchlistModal}
            className='manageWatchlist__btnRename'
          >
            Rename
          </DsMenuItem>
          <DsMenuItem
            onClick={this.handleOpenDeleteWatchlistModal}
            className='manageWatchlist__btnDelete'
          >
            Delete
          </DsMenuItem>
        </DsMenu>
      </>
    )
  }

  renderMobileMenu = () => {
    const { selectedWatchlist, singleWatchlistData } = this.props
    const { watchlistId: currentWatchlistId = '' } =
      (selectedWatchlist as watchlistObj) || {}
    const { watchlistId } = singleWatchlistData
    const isWatchlistSelected = currentWatchlistId === watchlistId

    return (
      <>
        {isWatchlistSelected && (
          <DsBox
            sx={{
              display: {
                md: 'none',
                xs: 'block'
              },
              width: '100%',
              borderBottomLeftRadius: 'var(--ds-spacing-glacial)',
              borderBottomRightRadius: 'var(--ds-spacing-glacial)',
              backgroundColor: 'var(--ds-colour-surfaceSecondary)'
            }}
          >
            <DsGrid
              sx={{
                p: 'var(--ds-spacing-glacial) var(--ds-spacing-bitterCold)',
                display: 'flex',
                flexDirection: 'row'
              }}
              container
            >
              <DsGrid sx={{ alignSelf: 'center' }} item xs={3}>
                <DsListItemButton
                  sx={{
                    display: 'block',
                    p: 0,
                    py: 'var(--ds-spacing-glacial) '
                  }}
                  className='ManageWatchlist__btnEditMobile'
                  onClick={this.handleEdit}
                >
                  <DsBox
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      textAlign: 'center',
                      gap: 'var(--ds-spacing-frostbite)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-split-cells-vertical'
                      fontSize='bitterCold'
                      sx={{
                        color: 'var(--ds-colour-iconActionTertiary)'
                      }}
                    />
                    <DsTypography
                      variant='bodyBoldSmall'
                      color={'var(--ds-colour-iconActionTertiary)'}
                    >
                      Edit
                    </DsTypography>
                  </DsBox>
                </DsListItemButton>
              </DsGrid>
              <DsGrid
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                item
                xs={1.5}
              >
                <DsDivider
                  sx={{
                    height: '100%',
                    width: '1px'
                  }}
                  orientation='vertical'
                />
              </DsGrid>
              <DsGrid sx={{ alignSelf: 'center' }} item xs={3}>
                <DsListItemButton
                  sx={{
                    display: 'block',
                    p: 0,
                    py: 'var(--ds-spacing-glacial) '
                  }}
                  className='manageWatchlist__btnRenameMobile'
                  onClick={this.handleOpenRenameWatchlistModal}
                >
                  <DsStack
                    sx={{
                      flexDirection: 'row',
                      gap: 'var(--ds-spacing-frostbite)',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-pencil-line'
                      fontSize='bitterCold'
                      sx={{
                        color: 'var(--ds-colour-iconActionTertiary)'
                      }}
                    />

                    <DsTypography
                      variant='bodyBoldSmall'
                      color={'var(--ds-colour-iconActionTertiary)'}
                    >
                      Rename
                    </DsTypography>
                  </DsStack>
                </DsListItemButton>
              </DsGrid>
              <DsGrid
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                item
                xs={1.5}
              >
                <DsDivider
                  sx={{
                    height: '100%',
                    width: '1px'
                  }}
                  orientation='vertical'
                />
              </DsGrid>
              <DsGrid sx={{ alignSelf: 'center' }} item xs={3}>
                <DsListItemButton
                  sx={{
                    display: 'block',
                    p: 0,
                    py: 'var(--ds-spacing-glacial) '
                  }}
                  className='manageWatchlist__btnDeleteMobile'
                  onClick={this.handleOpenDeleteWatchlistModal}
                >
                  <DsStack
                    sx={{
                      flexDirection: 'row',
                      gap: 'var(--ds-spacing-frostbite)',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-delete-bin-line'
                      fontSize='bitterCold'
                      sx={{
                        color: 'var(--ds-colour-iconActionTertiary)'
                      }}
                    />

                    <DsTypography
                      variant='bodyBoldSmall'
                      color={'var(--ds-colour-iconActionTertiary)'}
                    >
                      Delete
                    </DsTypography>
                  </DsStack>
                </DsListItemButton>
              </DsGrid>
            </DsGrid>
          </DsBox>
        )}
      </>
    )
  }

  render() {
    return (
      <>
        {/* Desktop Menu */}
        {this.renderDesktopMenu()}

        {/* Mobile Menu */}
        {this.renderMobileMenu()}

        {/* POPUP FOR RENAME */}
        {this.renderRenamePopup()}

        {/* POPUP FOR DELETE */}
        {this.renderDeletePopup()}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const watchList = getWatchListData(state)
  const selectedWatchlist = getSelectedWatchlist(state)
  const preDefinedWatchlistCount = preDefinedWatchlistCountSelector(state)
  return { watchList, selectedWatchlist, preDefinedWatchlistCount }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    renameWatchlist: (requestData: renameWatchlistPayload) =>
      dispatch(renameWatchlistAction(requestData)),
    deleteWatchlist: (requestData: DeleteWatchlistParams) =>
      dispatch(deleteWatchlistAction(requestData)),
    removeWatchlistIdFromStocks: (requestData: {
      watchListIdToDelete: string
      scriptIdArray: T_SCRIPTS_OBJ[]
    }) => dispatch(removeWatchlistIdFromStocksAction(requestData)),
    setSelectedWatchlistTab: (selectedWatchlistTab: number | string) =>
      dispatch(
        selectWatchlistTab({
          watchlistTab: selectedWatchlistTab
        })
      ),
    setSelectedWatchlist: (selectedWatchlist: watchlistObj) =>
      dispatch(selectWatchlist(selectedWatchlist))
  }
})

export default withStockSubUnSub(
  withBreakpoints(
    withErrorConnect(mapStateToProps, mapDispatchToProps)(WatchlistMenuActions)
  )
)
