export const QUOTES_LABEL = {
  STOCKDETAILS: {
    LLT: 'LTT',
    OPTION_CHAIN: 'Option Chain',
    BUY: 'BUY',
    SELL: 'SELL'
  },
  OVERVIEW: {
    MARKET_DEPTH: {
      HEADING: 'Market Depth',
      MAXIMUM: 'Maximum',
      PRICE_PICK: ' Click on a price to pick it for your current order',
      QUANTITY: 'Qty.',
      ORDERS: 'Orders',
      BID: 'Bid',
      ASK: 'Ask'
    },
    PERFORMANCE: {
      HEADING: 'Performance',
      OPEN: 'Open',
      CLOSE: 'Prev. Close',
      LOWER_CIRCUIT: 'Lower Circuit',
      UPPER_CIRCUIT: 'Upper Circuit',
      VOLUME: 'Volume',
      AVG_PRICE: 'Avg. Price',
      OI: 'OI',
      OI_CHANGE: 'OI Change (%)',
      MARKET_LOT: 'Mkt. Lot',
      TODAY: 'Today',
      HIGH: 'High',
      LOW: 'Low',
      FIVE_TWO_WEEK: '52 Week'
    }
  },
  OPTION_CHAIN: {
    HEADING: 'Option Chain',
    FUTURE_PRICE: 'Future Price',
    ITM: 'In the money (ITM)',
    ITM_MOBILE: 'ITM',
    CALL: 'Call',
    PUT: 'Put',
    OI: 'OI',
    CHANGE: 'Change %',
    LTP: 'LTP',
    PRICE: 'Price',
    SPOT_PRICE: 'Spot Price'
  }
}

export const DASHBOARD_LABEL = {
  DASHBOARD_TOP_SECTION: {
    MARKET_UPDATE: 'Today’s Market Update',
    LEDGER_BALANCE: 'Ledger Balance',
    FUND_BALANCE: 'Funds Balance',
    EQUITIES: 'Equities',
    COMMODITIES: 'Commodities',
    LLT: 'LTT',
    OPTION_CHAIN: 'Option Chain',
    BUY: 'BUY',
    SELL: 'SELL',
    AVAILABLE_MARGIN_TEXT: 'Available Margin'
  },
  OVERVIEW: {
    MARKET_DEPTH: {
      HEADING: 'Market Depth',
      MAXIMUM: 'Maximum',
      PRICE_PICK: ' Click on a price to pick it for your current order',
      QUANTITY: 'Qty.',
      ORDERS: 'Orders',
      BID: 'Bid',
      ASK: 'Ask'
    },
    PERFORMANCE: {
      HEADING: 'Performance',
      OPEN: 'Open',
      CLOSE: 'Prev. Close',
      LOW_CIRCUIT: 'Low Circuit',
      UPPER_CIRCUIT: 'Upper Circuit',
      VOLUME: 'Volume',
      AVG_PRICE: 'Avg.Price',
      TODAY: 'Today',
      HIGH: 'High',
      LOW: 'Low',
      FIVE_TWO_WEEK: '52 Week'
    }
  },
  OPTION_CHAIN: {
    HEADING: 'Option Chain',
    FUTURE_PRICE: 'Future Price',
    ITM: 'In the money (ITM)',
    CALL: 'Call',
    PUT: 'Put',
    OI: 'OI',
    CHANGE: 'Change %',
    LTP: 'LTP',
    PRICE: 'Price',
    SPOT_PRICE: 'Spot Price'
  },
  PORTFOLIO: {
    PORTFOLIO_HEADING: 'Your Portfolio',
    HOLDINGS: 'Holdings',
    POSITIONS: 'Positions',
    ORDERS: 'Orders',
    INVESTED: 'Invested',
    CURRENT_VALUE: 'Current Value',
    OVERALL_PL: 'Overall P&L',
    TODAY_PL: "Today's P&L",
    UNREALISED_PL: 'Unrealised P&L',
    REALISED_PL: 'Realised P&L',
    TOTAL_POSITIONS: 'Total positions',
    TOTAL_ORDERS: 'Total Orders',
    TOTAL_PL: 'Total P&L',
    OPEN_POSITIONS: 'Open Positions'
  },
  SCREENERS: {
    SCREENERS_HEADING: 'Screeners',
    NIFTY50: 'NIFTY50',
    TOP_GAINERS: 'Top Gainers',
    TOP_LOSERS: 'Top Losers'
  },
  NEWS: {
    NEWS_HEADING: 'News'
  }
}
export const DEMAT_LABEL = {
  TOTAL_COLLATERAL: 'Total Collateral',
  UNSETTLED: 'Unsettled'
}

export const EMARGIN_LABEL = {
  EMARGIN_MODAL: {
    HEADER: 'Buy Now Pay Later (BMPL)',
    DESCRIPTION: 'Buy stocks on delivery with BMPL',
    FEATURE_1: 'Low Margin',
    FEATURE_2: 'Year-Long Trade',
    FEATURE_1_DESCRIPTION: 'Margin as low as 25%',
    FEATURE_2_DESCRIPTION: 'Carry it for the next 365 trading days',
    ACCEPTANCE: 'I accept the',
    TNC: 'Terms and Conditions',
    CANCEL: 'Cancel',
    ACTIVATE: 'Activate BMPL',
    MOBILE_DESCRIPTION:
      'Buy the selected stocks instantly for a discounted price!',
    BUY_STOCKS: 'Buy stocks on delivery with BMPL'
  },
  EMARGIN_INFO: {
    HEADING: 'Buy Now Pay Later (BMPL)',
    NEXT_STEPS: 'Next steps to follow',
    DESCRIPTION:
      'As part of the process, the shares bought under BMPL need to be mandatorily pledged. Please follow below simple steps to complete the further process -',
    STEP_1: 'Step 01',
    STEP_1_DESCRIPTION:
      'Click on the link shared by the depository on your email/SMS on the day of BMPL purchase',
    STEP_2: 'Step 02',
    STEP_2_DESCRIPTION:
      'Enter the four digit OTP shared on your registered mobile number',
    STEP_3: 'Step 03',
    STEP_3_DESCRIPTION: 'Select Stocks & Confirm Pledge',
    TIP: 'Shares bought under BMPL should be pledged by T+5 days in order to avoid open BMPL positions getting squared off',
    BTN: 'Okay'
  },
  EMARGIN_TNC: {
    HEADER: 'Buy Now Pay Later (BMPL)',
    TNC: 'Terms and Conditions',
    DESCRIPTION:
      'Rights and obligations relating to margin trading facility (MTF) provided by stock brokers/trading member to clients',
    BSE: 'BSE LIMITED (BSE)',
    TNC_1:
      '1. Stock Broker/ Trading Member is eligible to provide Margin Trading Facility (MTF) in accordance with SEBI & Exchange Guidelines as specified from time to time.',
    TNC_2:
      '2. Stock Broker/ Trading Member desirous of extending MTF to their clients is required to obtain prior permission of BSE. Stock Broker / Trading Member may note that BSE has the right to withdraw the permission at anytime.',
    TNC_3:
      '3. Stock Broker/ Trading Member shall extend the MTF to the client, on such terms and conditions as specified by the Stock Exchange / SEBI from time to time. Stock Broker/ Trading Member and the client shall abide by the requirements of the margin trading framework, including rights and obligations, as prescribed by Stock Exchange/ SEBI/ Stock Broker/ Trading Member.',
    TNC_4:
      '4. Stock Broker/ Trading Member shall intimate all the terms and conditions, including maximum allowable exposure, specific stock exposures etc., as well as the rights and obligations to the client desirous of availing MTF.',
    TNC_5:
      '5. Stock Broker/ Trading Member may, at its sole and absolute discretion, increase the limit of initial and/or maintenance margin, from time to time. The Client shall abide by such revision, and where there is an upward.',
    ACCEPTANCE: 'I accept the',
    ACTIVATE: 'Activate BMPL'
  }
}

export const ORDERS = {
  TOP_SECTION: {
    OPEN_POSITIONS: 'OPEN POSITIONS',
    UNREALISED_PL: 'Unrealised P&L:',
    TODAYS_ORDER: 'Today’s Orders',
    FILTER: 'Filter',
    SCHEDULED_GTD: 'Scheduled GTD',
    GTD_AMOUNT: 'Estimated Amount:',
    MOBILE_GTD_AMOUNT: 'Est. Amount:'
  },
  DATA: {
    LTP: 'LTP',
    QTY: 'Qty',
    AVG_PRICE: 'Avg Price',
    PRICE: 'Price'
  },
  ORDER_DETAILS: {
    HEAD: 'Order Details',
    TRADE_ID: 'Trade ID:',
    QTY: 'Qty.',
    TOTAL: 'Total',
    ORDER_TRAILS: 'ORDER TRAILS',
    REQUEST_REC: 'Request Recieved',
    ORDER_ID: 'Order ID:',
    ORDER_PLACED: 'Order Placed on',
    ORDER_REJECTED: 'Order Rejected',
    ORDER_CANCEL: 'Order Canceled',
    EXCHANGE_ID: 'Exchange ID:',
    VIEW_ORDER_TRAIL: 'View Order trail',
    MODIFY_ORDER: 'Modify Order',
    OPEN: 'Open',
    EXECUTED: 'Executed',
    TRANSIT: 'Transit',
    CANCELLED: 'Cancelled',
    REJECTED: 'Rejected',
    AMOACCEPTED: 'Open'
  },
  ORDER_OVERVIEW: {
    STATUS: 'STATUS',
    EXCHANGE: 'EXCHANGE',
    PRODUCT_TYPE: 'PRODUCT TYPE',
    QUANTITY: 'QUANTITY',
    PRICE_TYPE: 'PRICE TYPE',
    ORDER_PRICE: 'ORDER PRICE',
    ATP: 'AVERAGE TRADED PRICE',
    TOTAL_ORDER_VALUE: 'TOTAL ORDER VALUE'
  },
  ORDER_TRAILS: {
    HEAD: 'Order Details',
    QUANTITY: 'QUANTITY:',
    FILLED_QTY: 'FILLED QUANTITY',
    DISCLOSED_QTY: 'DISCLOSED QUANTITY',
    ORDER_PRICE: 'ORDER PRICE',
    VALIDITY: 'VALIDITY'
  },
  ORDER_BOOK: {
    CANCEL_ORDER_MODAL_TITLE: 'Cancel Order(s)?',
    CANCEL_ORDER_MODAL_DESCRIPTION:
      'Are you sure you want to Cancel the order(s)?'
  },
  GTD_ORDER_DETAILS: {
    HEADING: 'GTD Details'
  },
  GTD_ORDER_OVERVIEW: {
    INT_ORDER_ID: 'INTERNAL ORDER ID',
    EXG_ORDER_ID: 'EXCHANGE ORDER ID',
    CREATED_ON: 'CREATED ON',
    VALIDITY: 'VALIDITY',
    PRICE: 'PRICE',
    QUANTITY: 'QUANTITY',
    DISCLOSED_QUANTITY: 'DISCLOSED QUANTITY',
    TOTAL_ORDER_VALUE: 'TOTAL ORDER VALUE'
  }
}

export const WATCHLIST_LABEL = {
  WATCHLIST: {
    BUY: 'Buy',
    SELL: 'Sell'
  },
  BOTTOM_BUTTONS: {
    SORT: 'Sort Scrip',
    SORTS: 'Sort Scrips',
    ADD_NEW_WATCHLIST: 'Add New',
    MANAGE_WATCHLIST: 'Manage'
  },
  MANAGE_WATCHLIST_SECTION: {
    MANAGE_WATCHLIST_LABEL: 'Manage Watchlist',
    NEW_WATCHLIST: 'NEW WATCHLIST',
    NAME_YOUR_WATCHLIST: 'Name your watchlist',
    DELETE_YOUR_WATCHLIST: 'Delete watchlist?',
    SAVE_EDITED_WATCHLIST: 'Save watchlist?',
    DELETE_SURE: 'Are you sure you want to delete the watchlist?',
    EDIT_SURE: 'Are you sure you want to save the new changes?'
  }
}

export const HOLDINGS_LABEL = {
  HOLDING_HEAD: 'Holding Details',
  QTY_BREAKDOWN: 'qty breakdown',
  T1: 'T1',
  FREE: 'FREE',
  PLEDGE: 'PLEDGE',
  E_MARGIN: 'E-MARGIN',
  UNSALEABLE: 'UNSALEABLE',
  TRADE_HISTORY: 'Trade History',
  BUY: 'BUY',
  SELL: 'SELL',
  AUTH: 'Authorisation Confirmation',
  AUTH_SCRIPS: 'Authorised Scrips',
  AVAIL_QTY: 'Available Qty:',
  INSUFFI_QTY: 'Insufficient quantity available in holdings',
  INVALID_QTY: 'Invalid quantity',
  NOTE: `Don't want to authorise everyday?`,
  NOTE2: 'Provide POA to save your time and money.',
  FORGOT: 'Forgot T-pin?',
  AUTHORIZE: 'Authorize',
  AUTH_EDIS: 'Auth E-Dis',
  INSTANT_LIQUIDITY: `Pledge Stocks for Instant Liquidity!`,
  TURN_CASH: 'Turn your stocks into cash without selling them.',
  DESCRIPTION: 'Get ₹1,00,000.54 margin instantly by pledging your holdings',
  SORT: 'SORT & FILTER',
  NET_QTY: 'Net Qty',
  AVG_PRICE: 'Average Traded price',
  INV_AMT: 'Invested Amount',
  CURR_AMT: 'Current Amount',
  UNREALISED_PNL: 'un-realised p&l',
  REALISED_PNL: 'realised P&L',
  BULK_SQ_OFF: 'Bulk Square Off',
  FILTER: 'Filter'
}

export const POSITIONS_LABEL = {
  FILTER: 'Filter',
  BULK_SQ_OFF: 'Bulk Square Off',
  LTP: 'LTP',
  QTY: 'Qty',
  PNL: 'P&L',
  SORT: 'SORT & FILTER',
  SQ_OFF: 'Square Off Multiple Positions',
  POSITION_DETAILS: 'Positions Details',
  SUMMARY: 'POSITIONS SUMMARY',
  REALISED: 'Realised P&L',
  UNREALISED: 'Un-realised P&L',
  OVERALLPNL: 'Overall P&L',
  TRADE_HIS: 'Trade history',
  TOTAL: 'Total',
  NET_QTY: ' Net Quantity',
  BUY_QTY: 'Buy Qty & Price',
  ATP: 'Average Traded Price',
  TOTAL_VAL: 'Total Value'
}

export const PLEDGE_SUMMARY_LABEL = {
  PLEDGED_SCRIPS: 'Pledged Scrips',
  PORTFOLIO_SIZE: 'Portfolio Size',
  EXPECTED_COLLATERAL: 'Expected Collateral',
  TOTAL_AVAILABLE_MARGIN: 'Total Available Margin'
}

export const SIDE_BUTTONS_LABEL = [
  {
    label: 'Add GTD',
    id: 1,
    icon: 'ri-calendar-event-line',
    identifier: 'gtd'
  },
  {
    label: 'Start SIP',
    id: 2,
    icon: 'ri-notification-3-line',
    identifier: 'sip'
  },
  {
    label: 'Add Alert',
    id: 3,
    icon: 'ri-donut-chart-line',
    identifier: 'alert'
  }
]

export const PORTFOLIO_LABEL = {
  CONSOLIDATED_CURRENT_VALUE: 'Consolidated current value'
}
