import { To } from 'react-router-dom'
import APP_ROUTES from './APP_ROUTES'

export const AXIS_SECURITIES_ABOUTUS_LINK =
  'https://simplehai.axisdirect.in/aboutus'

export const AXIS_SECURITIES_CONTACTUS_LINK =
  'https://simplehai.axisdirect.in/contactus'

export const PLEDGE_TNC_HOME_LINK =
  'https://simplehai.axisdirect.in/terms-and-conditions-home'

export const REFER_AND_EARN =
  'https://simplehai.axisdirect.in/offerings/refer-n-earn'

const netAvailableMargin = 200000

export const PROFILE_MENU_OPTIONS = [
  {
    id: 'PROFILE',
    header: 'Profile',
    subHeader: 'Profile',
    icon: 'ri-user-line',
    subMenu: [],
    type: 'INTERNAL',
    link: APP_ROUTES.PROFILE.pathname
  },
  {
    id: 'NET_AVAILABLE_MARGIN',
    header: 'NET_MARGIN',
    subHeader: 'Net Available Margin',
    icon: 'ri-coins-line',
    subMenu: [],
    type: 'MARGIN',
    link: '/'
  },
  {
    id: 'PRICE_ALERTS',
    header: 'Price Alerts',
    icon: 'ri-notification-line',
    subHeader: 'Get notified at your price',
    subMenu: [],
    type: 'INTERNAL',
    link: APP_ROUTES.ALERTS.pathname
  },
  {
    id: 'PLEDGE',
    header: 'Pledge',
    subHeader: 'Get margin or Unpledge',
    icon: 'ri-hand-coin-line',
    subMenu: [],
    type: 'PLEDGE',
    link: APP_ROUTES.PLEDGE.pathname
  },
  {
    id: 'BANK_DP_DETAILS',
    header: 'Bank & DP Details',
    subHeader: 'Account No., DMAT No., etc.',
    icon: 'ri-bank-line',
    subMenu: [],
    type: 'INTERNAL',
    link: APP_ROUTES.BANKDETAILS.pathname
  },
  {
    id: 'REPORTS',
    header: 'Reports',
    subHeader: 'Ledger, Contract Notes, etc.',
    icon: 'ri-file-chart-line',
    subMenu: [],
    type: 'INTERNAL',
    link: APP_ROUTES.REPORTS.pathname
  },
  {
    id: 'PARTNERS',
    header: 'Partner App',
    subHeader: 'Smallcase, Axis Intellect, etc.',
    type: 'PARTNERS',
    icon: 'ri-logout-circle-r-line',
    subMenu: []
  },
  {
    id: 'SETTINGS',
    header: 'Settings',
    subHeader: 'Password, Theme, etc.',
    icon: 'ri-settings-line',
    subMenu: [
      {
        id: 'CHANGE_PASSWORD',
        label: 'Change Password',
        type: 'CHANGE_PASSWORD',
        link: ''
      },
      {
        id: 'DARK_THEME',
        label: 'Dark Theme',
        type: 'DARK_THEME',
        link: ''
      }
    ]
  },
  {
    id: 'EXCHANGE_NOTIFICATIONS',
    header: 'Exchange Notifications',
    subHeader: 'Messages & Market Status',
    icon: 'ri-wechat-line',
    subMenu: [
      {
        id: 'MESSAGES',
        label: 'Messages',
        type: 'INTERNAL',
        link: APP_ROUTES.EXCHANGEMESSAGES.pathname
      },
      {
        id: 'MARKET_STATUS',
        label: 'Market Status',
        type: 'INTERNAL',
        link: APP_ROUTES.MARKETSTATUS.pathname
      }
    ]
  },
  {
    id: 'AXIS_SECURITIES',
    header: 'Axis Securities',
    subHeader: 'About Us, Contact Us, Share Feedback',
    icon: 'ri-building-2-line',
    subMenu: [
      {
        id: 'ABOUT_US',
        label: 'About Us',
        type: 'EXTERNAL',
        link: AXIS_SECURITIES_ABOUTUS_LINK
      },
      {
        id: 'CONTACT_US',
        label: 'Contact Us',
        type: 'EXTERNAL',
        link: AXIS_SECURITIES_CONTACTUS_LINK
      },
      {
        id: 'FEEDBACK',
        label: 'Share Feedback',
        type: 'FEEDBACK'
      }
    ]
  },
  // { //NOTE - commentted as requsted by Vivek and Suyash
  //   id: 'REFER_EARN',
  //   header: 'Refer & Earn',
  //   subHeader: 'Invite and earn up to ₹12 Lakh a year',
  //   icon: 'ri-gift-line',
  //   subMenu: [],
  //   type: 'EXTERNAL',
  //   link: REFER_AND_EARN
  // },
  {
    id: 'LOGOUT',
    header: 'Logout',
    subHeader: '',
    type: 'LOGOUT',
    icon: 'ri-logout-circle-r-line',
    subMenu: []
  }
]

export const AXIS_DIRECT_URL =
  'https://simplehai.axisdirect.in/app/index.php/user/auth/LoginSupport'

export const OLD_USERNAME_FLOW =
  'https://simplehai.axisdirect.in/app/index.php/user/auth/activateUser'

export const TERMS_CONDITIONS =
  'https://simplehai.axisdirect.in/terms-and-conditions-home'

export const OPEN_ACCOUNT = 'https://digitalaccount.axisdirect.in/register'

export const REPORTS = {
  LEDGER_REPORT: 'LEDGER_REPORT',
  CONTRACT_NOTE: 'CONTRACT_NOTE'
}

export enum DEEP_LINK_KEYS {
  WATCHLIST = 'watchlist',
  SEARCH = 'search',
  QUOTES = 'quotes',
  SCREENER = 'screener',
  REPORTS = 'reports',
  ORDER_FORM = 'order_form',
  FUNDS = 'funds',
  SMALLCASE = 'smallcase',
  HOLDINGS_BOOK = 'holdings_book',
  ORDER_BOOK = 'order_book',
  POSITIONS_BOOK = 'positions_book',
  DASHBOARD = 'dashboard',
  NOTIFICATIONS_MENU = 'notifications_menu',
  MARKET_NEWS = 'market_news',
  SETTING = 'setting',
  REFER_EARN = 'refer_and_earn',
  CUST_SUPPORT = 'customer_support'
}

type DEEP_LINK_ROUTE_ITEM = {
  desktop: To
  mobile: To
  externalLink?: boolean
}

type DEEP_LINK_ROUTES = {
  [key: string]: DEEP_LINK_ROUTE_ITEM
}

export const DEEP_LINK_ROUTES: DEEP_LINK_ROUTES = {
  [DEEP_LINK_KEYS.WATCHLIST]: {
    desktop: APP_ROUTES.DASHBOARD.pathname,
    mobile: APP_ROUTES.MOBILE_WATCHLIST.pathname
  },
  [DEEP_LINK_KEYS.SEARCH]: {
    desktop: APP_ROUTES.DASHBOARD.pathname,
    mobile: APP_ROUTES.MOBILE_SEARCH.pathname
  },
  [DEEP_LINK_KEYS.QUOTES]: {
    desktop: APP_ROUTES.QUOTES.pathname,
    mobile: APP_ROUTES.QUOTES.pathname
  },
  [DEEP_LINK_KEYS.SCREENER]: {
    desktop: APP_ROUTES.RESEARCH.pathname,
    mobile: APP_ROUTES.RESEARCH.pathname
  },
  [DEEP_LINK_KEYS.REPORTS]: {
    desktop: APP_ROUTES.REPORTS.pathname,
    mobile: APP_ROUTES.REPORTS.pathname
  },
  [DEEP_LINK_KEYS.ORDER_FORM]: {
    desktop: APP_ROUTES.DASHBOARD.pathname,
    mobile: APP_ROUTES.DASHBOARD.pathname
  },
  [DEEP_LINK_KEYS.FUNDS]: {
    desktop: '',
    mobile: ''
  },
  [DEEP_LINK_KEYS.SMALLCASE]: {
    desktop: APP_ROUTES.PARTNERS_INFO.pathname,
    mobile: APP_ROUTES.PARTNERS_INFO.pathname
  },
  [DEEP_LINK_KEYS.HOLDINGS_BOOK]: {
    desktop: APP_ROUTES.MULTIPLE_PORTFOLIO.pathname,
    mobile: APP_ROUTES.MULTIPLE_PORTFOLIO.pathname
  },
  [DEEP_LINK_KEYS.ORDER_BOOK]: {
    desktop: APP_ROUTES.ORDERBOOK.pathname,
    mobile: APP_ROUTES.ORDERBOOK.pathname
  },
  [DEEP_LINK_KEYS.POSITIONS_BOOK]: {
    desktop: APP_ROUTES.MULTIPLE_PORTFOLIO.pathname,
    mobile: APP_ROUTES.MULTIPLE_PORTFOLIO.pathname
  },
  [DEEP_LINK_KEYS.DASHBOARD]: {
    desktop: '',
    mobile: ''
  },
  [DEEP_LINK_KEYS.NOTIFICATIONS_MENU]: {
    desktop: APP_ROUTES.NOTIFICATION.pathname,
    mobile: APP_ROUTES.NOTIFICATION.pathname
  },
  [DEEP_LINK_KEYS.MARKET_NEWS]: {
    desktop: APP_ROUTES.DASHBOARD_NEWS.pathname,
    mobile: APP_ROUTES.DASHBOARD_NEWS.pathname
  },
  [DEEP_LINK_KEYS.SETTING]: {
    desktop: APP_ROUTES.DASHBOARD.pathname,
    mobile: APP_ROUTES.PROFILEMENU.pathname
  },
  [DEEP_LINK_KEYS.REFER_EARN]: {
    desktop: REFER_AND_EARN,
    mobile: REFER_AND_EARN,
    externalLink: true
  },
  [DEEP_LINK_KEYS.CUST_SUPPORT]: {
    desktop: AXIS_SECURITIES_CONTACTUS_LINK,
    mobile: AXIS_SECURITIES_CONTACTUS_LINK,
    externalLink: true
  }
}

export const NRI_TYPES: { [key: string]: number } = {
  'NRO-PIS': 1,
  'NRO-NONPIS': 2,
  'NRE-PIS': 3,
  'NRE-NONPIS': 4
}
