import { TOrderBookWithAdditionalKeysObject } from '../Orders/TYPES'

export type TDeepLink = {
  path: string
  parameterList: string[]
}

export type TAppDrawerData = {
  scriptId: string
  orderStatus?: string
}

export type TAppDrawerState = {
  open: boolean
  data: TOrderBookWithAdditionalKeysObject | null
}

export type TAppConfigState = {
  surveillance_indicator: string
}

export interface IAppStateReducer {
  dialog: object
  deepLinkData: TDeepLink | null
  orderDrawer: TAppDrawerState
  cancelOrderDrawer: TAppDrawerState
  optionChainDrawer: TAppDrawerState
  config: TAppConfigState
  servilience: any //TODO - define a proper type
}

export const INITIAL_STATE: IAppStateReducer = {
  dialog: {},
  deepLinkData: null,
  orderDrawer: {
    open: false,
    data: null
  },
  cancelOrderDrawer: {
    open: false,
    data: null
  },
  optionChainDrawer: {
    open: false,
    data: null
  },
  config: {
    surveillance_indicator: ''
  },
  servilience: {}
}
