import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'
import {
  getAlertsActions,
  removeTriggeredAlertAction,
  toggleAlertsFormActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { _map, _orderBy, _uniqBy } from '~/src/Utils/lodash'

export type T_ALERT_FORM_DATA = {
  alertId?: string
  scriptId: string
  value: number
  condition: string
  description: string
}

export type T_ALERT_FORM_DETAILS = {
  toggle: boolean
  scriptId: string
  isModify: boolean
  data: T_ALERT_FORM_DATA | null
}
const INITIAL_STATE = {
  data: [],
  toggleAlertsForm: {
    toggle: false,
    scriptId: '',
    isModify: false,
    data: {
      scriptId: '',
      value: 0,
      condition: '',
      description: ''
    }
  }
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getAlertsActions.success, (state, { payload }) => {
      if (payload !== null) {
        state.data = reduceData(payload)
      }
    })

    builder.addCase(toggleAlertsFormActions, (state, { payload }) => {
      state.toggleAlertsForm = payload
    })

    builder.addCase(removeTriggeredAlertAction, (state, { payload }) => {
      const { alertId: triggeredAlertId } = payload
      state.data = state.data.filter((alertData: T_ALERT_FORM_DATA) => {
        const { alertId: currentAlertID } = alertData
        return currentAlertID !== triggeredAlertId
      })
    })
  }
}

function reduceData(alertsData: any[]) {
  return _uniqBy(
    _map(alertsData, item => {
      const {
        alertId,
        scriptId,
        value,
        condition,
        createdAt,
        status,
        description,
        updatedAt
      } = item
      const { exchange, exchangeSymbol } =
        (window as any).secMaster.getByScriptId(scriptId) || {}
      return {
        alertId,
        scriptId,
        value,
        condition,
        createdAt,
        updatedAt,
        exchangeSymbol,
        exchange,
        description,
        status
      }
    }),
    'alertId'
  )
}

const slice = createSlice(sliceOptions)
export default slice.reducer
