import React, { Component } from 'react'
import { DsBox, DsSkeleton, DsTypography } from '@am92/react-design-system'

import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'

import { TAppStore } from '~/src/Configurations/AppStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import StockTickerNew from '../TickerBasedComponents/StockTickerNew'

interface IStockDataOnHeaderProps extends IWithRouterProps {
  scriptId: string
  stockDetails: T_SCRIPTDATA
  showHoldings?: boolean
  handleOnClickOpenAddToWatchlist?: (scripId: string) => void
}

class StockDataOnHeader extends Component<IStockDataOnHeaderProps> {
  state = {}

  render() {
    const { stockDetails = {}, scriptId }: any = this.props
    const { coname, closeprice, segment, exchangeSymbol } = stockDetails || {}

    if (coname == null || coname == undefined) {
      return <DsSkeleton variant='text' width={80} height={30} />
    }

    return (
      <DsBox
        key={scriptId}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 'var(--ds-spacing-glacial)',
            alignItems: 'center'
          }}
        >
          <DsTypography
            variant='subheadingSemiboldDefault'
            sx={{
              color: 'var(--ds-colour-typoOnSurfaceDynamic)'
            }}
          >
            {exchangeSymbol}
          </DsTypography>
          <StockTickerNew
            scriptId={scriptId}
            closeprice={closeprice}
            segment={segment}
            WrapperProps={{
              sx: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 'var(--ds-spacing-quickFreeze)'
              }
            }}
            LtpTypoProps={{
              color: 'var(--ds-colour-typoOnSurfaceDynamic)',
              variant: 'subheadingSemiboldDefault'
            }}
            NetChangeWrapperProps={{
              sx: {
                gap: 'var(--ds-spacing-deepFreeze)'
              }
            }}
            PercentChangeTypoProps={{
              color: 'var(--ds-colour-typoOnSurfaceDynamic)',
              variant: 'subheadingSemiboldDefault',
              sx: {
                '&::before': {
                  content: '"("',
                  color: 'var(--ds-colour-typoOnSurfaceDynamic)'
                },
                '&::after': {
                  content: '")"',
                  color: 'var(--ds-colour-typoOnSurfaceDynamic)'
                }
              }
            }}
            NetChangeIconProps={{
              sx: {
                color: 'var(--ds-colour-surfaceBackground)'
              },
              fontSize: 'bitterCold'
            }}
            showNetChange={false}
            withCurrencySymbol={false}
          />
        </DsBox>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: TAppStore, ownProps: any) => {
  const { scriptId } = ownProps
  const stockDetails = getStockMasterData(scriptId)(state)
  return {
    stockDetails
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(StockDataOnHeader)
