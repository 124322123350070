import {
  DsButtonBase,
  DsStack,
  DsTypography,
  DsRemixIcon
} from '@am92/react-design-system'
import React, { useEffect } from 'react'
import { TAppStore } from '../Configurations/AppStore'
import {
  getCommodityValueSelector,
  getEquityValueSelector
} from '../Redux/Inquiry/Selectors'
import { getCurrencyFormat } from '../Utils/global'
import APP_ROUTES from '../Constants/APP_ROUTES'
import getAvailableCommodityLimitsAction from '../Redux/Inquiry/Services/getAvailableCommodityLimits'
import getAvailableEquityLimitsAction from '../Redux/Inquiry/Services/getAvailableEquityLimits'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { useNavigate } from 'react-router-dom'

interface IFundsLimit {
  type: string
}
const FundsLimit = (props: IFundsLimit) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const commodityValue = useAppSelector((state: TAppStore) =>
    Number(getCommodityValueSelector(state))
  )
  const equityValue = useAppSelector((state: TAppStore) =>
    Number(getEquityValueSelector(state))
  )
  const getEquityValueSelect = useAppSelector((state: TAppStore) =>
    getCommodityValueSelector(state)
  )
  const getCommodityValueSelect = useAppSelector((state: TAppStore) =>
    getEquityValueSelector(state)
  )
  const { type } = props

  const totalAvalFunds = equityValue + commodityValue

  const HeaderFunds =
    !totalAvalFunds && totalAvalFunds !== 0
      ? 'Funds : '
      : `Funds : ${getCurrencyFormat(totalAvalFunds)}`
  const handleRedirectTo = () => {
    navigate(APP_ROUTES.FUNDSDASHBOARD.pathname)
  }
  const handleGetEquityLimits = async () => {
    const requestPayload = { segment: 'EQ' }
    const response = dispatch(getAvailableEquityLimitsAction(requestPayload))
    if (response._isCustomError) {
      // handleError(response)
      console.log('failed API - getAvailableEquityLimits')
      return
    }
  }
  const handleGetCommodityLimits = async () => {
    const requestPayload = { segment: 'COMM' }
    const response = dispatch(getAvailableCommodityLimitsAction(requestPayload))
    if (response._isCustomError) {
      // handleError(response)
      console.log('failed API - getAvailableCommodityLimits')
      return
    }
  }
  useEffect(() => {
    if (getEquityValueSelect === 0) {
      handleGetEquityLimits()
    }
    if (getCommodityValueSelect === 0) {
      handleGetCommodityLimits()
    }
  }, [])
  if (type === 'button') {
    return (
      <DsButtonBase>
        <DsStack
          sx={{
            p: 'var(--ds-spacing-glacial) var(--ds-spacing-bitterCold)',
            border: '1px solid var(--ds-colour-strokeDefault)',
            backgroundColor: 'var(--ds-colour-surfaceSecondary)',
            borderRadius: 'var(--ds-radius-glacial)'
          }}
          onClick={() => handleRedirectTo()}
        >
          <DsTypography
            variant='bodyBoldSmall'
            sx={{
              whiteSpace: 'nowrap',
              color: 'var(--ds-colour-actionPrimary)',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {HeaderFunds}
            {!totalAvalFunds && totalAvalFunds !== 0 && (
              <DsRemixIcon
                fontSize='bitterCold'
                className='ri-error-warning-line'
                color='primary'
              />
            )}
          </DsTypography>
        </DsStack>
      </DsButtonBase>
    )
  }
  if (type === 'typography') {
    return <DsTypography>{getCurrencyFormat(totalAvalFunds)}</DsTypography>
  }
}

export default FundsLimit
