import React from 'react'
import { DsTypography } from '@am92/react-design-system'

import {
  getTotalBuyQty,
  getTotalSellQty
} from '~/src/Redux/StockTicker/Selectors'

import { TTickerStore } from '~/src/Configurations/TickerStore'
import { getCurrencyFormat } from '~/src/Utils/global'

import { useTickerSelector } from '~/src/hooks/useRedux'

interface ITotalB5QtyProps {
  type: string
  scriptId: string
}

const TotalB5Qty = (props: ITotalB5QtyProps) => {
  const { type, scriptId } = props
  const TOTAL_QTY =
    type === 'B5Buy'
      ? useTickerSelector((state: TTickerStore) =>
          getTotalBuyQty(state, scriptId)
        )
      : useTickerSelector((state: TTickerStore) =>
          getTotalSellQty(state, scriptId)
        )
  return (
    <DsTypography variant='bodyRegularSmall'>
      {getCurrencyFormat(TOTAL_QTY || 0, {
        signDisplay: 'never',
        style: 'decimal'
      })}
    </DsTypography>
  )
}
export default TotalB5Qty
