import { createSelector } from '@reduxjs/toolkit'
import { TTickerStore } from '~/src/Configurations/TickerStore'
import { calculateNetChange } from '~/src/Utils/global'
import { IB5 } from './Reducer'

export const SLICE_NAME = 'ticker'

const selectTicker = (state: TTickerStore) => state[SLICE_NAME]
const selectTickerLTP = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.LTP
const selectTickerClosePrice = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.CLOSE_PRICE
const selectTickerNetChange = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.NET_CHANGE
const selectTickerPercentChange = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.PERCENT_CHANGE
const selectB5Buy = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.B5BUY
const selectB5Sell = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.B5SELL
const selectTotalBuyQty = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.TOTAL_BUY_QTY
const selectTotalSellQty = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.TOTAL_SELL_QTY
const selectTotalVolume = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME]?.[scriptId]?.VOLUME

export const getLastTradedTime = (scriptId: string) =>
  createSelector(selectTicker, ticker => ticker?.[scriptId]?.LTT)
export const getOpenInterest = (scriptId: string) =>
  createSelector(selectTicker, ticker => ticker?.[scriptId]?.OPEN_INTEREST)

export const getTickerData = (scriptId: string) =>
  createSelector(selectTicker, ticker => ticker?.[scriptId])

export const getLtpTickSelector = createSelector(selectTickerLTP, LTP => LTP)
export const getTickerVolumeSelector = createSelector(
  selectTotalVolume,
  VOLUME => VOLUME
)

export const getRequiredTickerDataSelector = createSelector(
  [
    selectTickerLTP,
    selectTickerNetChange,
    selectTickerPercentChange,
    (state, scriptId, closeprice, segment) => scriptId,
    (state, scriptId, closeprice, segment) => closeprice,
    (state, scriptId, closeprice, segment) => segment
  ],
  (LTP, NET_CHANGE, PERCENT_CHANGE, scriptId, closeprice, segment) => {
    let FINAL_NET_CHANGE, FINAL_PERCENT_CHANGE

    FINAL_NET_CHANGE =
      (NET_CHANGE && NET_CHANGE) || calculateNetChange(LTP, closeprice)[0] || 0

    FINAL_PERCENT_CHANGE =
      (PERCENT_CHANGE && PERCENT_CHANGE) ||
      calculateNetChange(LTP, closeprice)[1] ||
      0

    return {
      LTP,
      NET_CHANGE: FINAL_NET_CHANGE,
      PERCENT_CHANGE: FINAL_PERCENT_CHANGE
    }
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)
export const getB5Buy = createSelector(
  [selectB5Buy],
  (B5BUY: IB5[]) => {
    return B5BUY
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)
export const getB5Sell = createSelector(
  [selectB5Sell],
  (B5SELL: IB5[]) => {
    return B5SELL
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)
export const getTotalBuyQty = createSelector(
  [selectTotalBuyQty],
  TOTAL_BUY_QTY => {
    return TOTAL_BUY_QTY
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)
export const getTotalSellQty = createSelector(
  [selectTotalSellQty],
  TOTAL_SELL_QTY => {
    return TOTAL_SELL_QTY
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getFullTickerData = createSelector(selectTicker, ticker => ticker)

export const getIndividualStockHoldingValues = createSelector(
  [
    selectTickerLTP,
    selectTickerClosePrice,
    (state, scriptIdObj, scriptId) => scriptIdObj,
    (state, scriptIdObj, scriptId) => scriptId
  ],
  (LTP, CLOSE_PRICE, scriptIdObj) => {
    let currentValue = 0
    let overallPL = 0
    let todaysPL = 0
    const {
      scriptId,
      holdingQty,
      investedValue,
      CLOSE_PRICE: cp = 0
    } = scriptIdObj
    const { closeprice = 0 } =
      (window as any).secMaster.getByScriptId(scriptId) || {}
    currentValue =
      Number(holdingQty) * Number(LTP) ||
      Number(holdingQty) * Number(closeprice || cp)
    overallPL = currentValue - Number(investedValue)
    todaysPL = currentValue - Number(holdingQty) * Number(CLOSE_PRICE || cp)
    return { currentValue, overallPL, todaysPL }
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getPositionsValue = (scriptIdArray: any) =>
  createSelector(selectTicker, ticker => {
    let totalOpenPL = 0
    let todaysPL = 0
    let unrealisedOpenPL = 0
    let unrealisedPL = 0
    let currentValue = 0
    let realisePL = 0
    let totalInvestedAmount = 0
    const openPositions = scriptIdArray?.length
    scriptIdArray?.forEach((scriptIdObj: any) => {
      const {
        scriptId,
        buyQty = 0,
        sellQty = 0,
        realizedPL = 0,
        avgBuyPrice = 0,
        avgSellPrice = 0,
        positionSide,
        netPrice = 0,
        netQty = 0
      } = scriptIdObj
      const buy = Number(sellQty) - Number(buyQty)
      let investedValue = 0
      let avgPrice = positionSide === 'buy' ? avgBuyPrice : avgSellPrice
      if (avgPrice <= 0) avgPrice = netPrice
      investedValue = Number(netPrice) * Number(netQty)
      const tickerValue = (ticker && ticker[scriptId]) || {}
      const { LTP = 0 } = tickerValue || {}
      let currentLtpIntoPositionValue = Number(netQty) * Number(LTP)
      currentValue = currentValue + currentLtpIntoPositionValue
      realisePL = realisePL + realizedPL
      unrealisedPL =
        unrealisedPL + (currentLtpIntoPositionValue - Number(investedValue))
      todaysPL = realisePL + unrealisedPL
      totalInvestedAmount = totalInvestedAmount + investedValue
      totalOpenPL =
        totalOpenPL + (currentLtpIntoPositionValue - Number(investedValue))
      unrealisedOpenPL = totalOpenPL - realisePL
    })
    return {
      currentValue,
      unrealisedPL,
      todaysPL,
      realisePL,
      openPositions,
      totalInvestedAmount,
      totalOpenPL,
      unrealisedOpenPL
    }
  })

export const getHoldingsCalculatedOverallValueSelector = (scriptIdArray: any) =>
  createSelector(selectTicker, ticker => {
    let currentValue = 0
    let overallPL = 0
    let todaysPL = 0
    scriptIdArray.forEach((scriptIdObj: any) => {
      const { scriptId, holdingQty, investedValue, closeprice } = scriptIdObj
      const tickerValue = (ticker && ticker[scriptId]) || {}
      const { LTP = closeprice, CLOSE_PRICE = 0 } = tickerValue || {}
      let currentLtpIntoHoldingValue = Number(holdingQty) * Number(LTP)
      currentValue = currentValue + currentLtpIntoHoldingValue
      overallPL =
        overallPL + (currentLtpIntoHoldingValue - Number(investedValue))
      todaysPL =
        todaysPL +
        currentLtpIntoHoldingValue -
        Number(holdingQty) * Number(CLOSE_PRICE)
    })
    return { currentValue, overallPL, todaysPL }
  })

export const getOverallPnlUsingHolding = createSelector(
  [
    selectTickerLTP,
    (state, scriptId, holdings, investedValue, closeprice) => scriptId,
    (state, scriptId, holdings, investedValue, closeprice) => holdings,
    (state, scriptId, holdings, investedValue, closeprice) => investedValue,
    (state, scriptId, holdings, investedValue, closeprice) => closeprice
  ],
  (LTP, scriptId, holdings, investedValue, closeprice) => {
    const currentValue = Number(holdings) * Number(LTP || closeprice)
    const unrealisedPnL = currentValue - investedValue
    let unrealisedPnlPercentChange = 0
    if (investedValue) {
      unrealisedPnlPercentChange = (unrealisedPnL / investedValue) * 100
    }
    return { value: unrealisedPnL, percentChange: unrealisedPnlPercentChange }
  }
)

export const getCurrentValueUsingHolding = createSelector(
  [
    selectTickerLTP,
    (state, scriptId, holdings, investedValue, closeprice) => scriptId,
    (state, scriptId, holdings, investedValue, closeprice) => holdings,
    (state, scriptId, holdings, investedValue, closeprice) => investedValue,
    (state, scriptId, holdings, investedValue, closeprice) => closeprice
  ],
  (LTP, scriptId, holdings, investedValue, closeprice) => {
    const currentValue = Number(holdings) * Number(LTP || closeprice)
    const unrealisedPnL = currentValue - investedValue
    const currentValueSign = (unrealisedPnL >= 0 && 1) || -1
    return currentValue * currentValueSign
  }
)
