import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import { updateStockTickerAction } from '../StockTicker/Actions'
import {
  INITIAL_STATE,
  T_OPEN_POSITION_SCRIPTS,
  T_SCRIPT_TO_DOCUMENTID_MAPPER,
  T_TODAY_POSITION_SCRIPTS
} from './TYPES'
import {
  flushOpenAndTodayPositionDataInTickerAction,
  flushOpenPositionDataInTickerAction,
  flushTodayPositionDataInTickerAction,
  updateOpenPositionDataInTickerAction,
  updateTodayPositionDataInTickerAction
} from './Actions'
import { TOpenPosition, TTodayPosition } from '../Positions/TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(updateOpenPositionDataInTickerAction, (state, action) => {
      const { payload } = action
      const { openPositions: openPositionData, totalInvestedValue } = payload
      let initialTotalPnlValue = 0
      let initialTotalPnlPercentage = 0
      let initialTotalUnrealisedPnlValue = 0
      let initialTotalUnrealisedPnlPercentage = 0
      let initialTotalRealisedPnlValue = 0
      let initialTotalRealisedPnlValuePercentage = 0
      let scriptsInOpenPosition: T_OPEN_POSITION_SCRIPTS = {}
      let scriptToDocumentIdMapperForOpenPositions: T_SCRIPT_TO_DOCUMENTID_MAPPER =
        {}

      openPositionData.length &&
        openPositionData.map((individualOpenPositionData: TOpenPosition) => {
          const {
            id = 0,
            scriptId = 0,
            closeprice: closePriceFromSecurityMaster = 0,
            netQty = 0,
            investedValue = 0,
            realizedPL = 0
          } = individualOpenPositionData

          if (scriptId) {
            //SECTION - individual positions's calculations
            const initialIndividualCurrentValue =
              netQty * closePriceFromSecurityMaster

            const initialIndividualRealisedPnlValue = realizedPL

            const initialIndividualUnrealisedPnlValue =
              initialIndividualCurrentValue - investedValue
            const initialIndividualUnrealisedPnlPercentage =
              (investedValue &&
                (initialIndividualUnrealisedPnlValue / investedValue) * 100) ||
              0

            //SECTION - initial total position's calculations
            initialTotalUnrealisedPnlValue =
              initialTotalUnrealisedPnlValue +
              initialIndividualUnrealisedPnlValue

            initialTotalRealisedPnlValue =
              initialTotalRealisedPnlValue + initialIndividualRealisedPnlValue

            //SECTION - initial individual open position object maker
            const scriptToIdMapperKey = `${scriptId}_${id}`
            scriptsInOpenPosition[scriptToIdMapperKey] = {
              scriptId,
              closeprice: closePriceFromSecurityMaster,
              netQty,
              investedValue,
              unRealisedPnl: {
                value: initialIndividualUnrealisedPnlValue,
                percentChange: initialIndividualUnrealisedPnlPercentage
              }
            }

            //SECTION - Script to Id Mapper for Open Positions
            if (scriptToDocumentIdMapperForOpenPositions[scriptId]) {
              scriptToDocumentIdMapperForOpenPositions[scriptId].push(
                scriptToIdMapperKey
              )
              return
            }
            scriptToDocumentIdMapperForOpenPositions[scriptId] = [
              scriptToIdMapperKey
            ]
          }
        })

      //SECTION - total pnl calculation
      initialTotalPnlValue =
        initialTotalUnrealisedPnlValue + initialTotalRealisedPnlValue

      //SECTION - percentage calculations
      initialTotalPnlPercentage =
        (totalInvestedValue &&
          (initialTotalPnlValue / totalInvestedValue) * 100) ||
        0

      initialTotalUnrealisedPnlPercentage =
        (totalInvestedValue &&
          (initialTotalUnrealisedPnlValue / totalInvestedValue) * 100) ||
        0

      initialTotalRealisedPnlValuePercentage =
        (totalInvestedValue &&
          (initialTotalRealisedPnlValue / totalInvestedValue) * 100) ||
        0

      state.typeOfPosition = 'open'
      state.totalInvestedValue = totalInvestedValue
      state.totalPnl.value = initialTotalPnlValue
      state.totalPnl.percentChange = initialTotalPnlPercentage
      state.unRealisedPnl.value = initialTotalUnrealisedPnlValue
      state.unRealisedPnl.percentChange = initialTotalUnrealisedPnlPercentage
      state.realisedPnl.value = initialTotalRealisedPnlValue
      state.realisedPnl.percentChange = initialTotalRealisedPnlValuePercentage
      state.scriptsInOpenPosition = scriptsInOpenPosition
      state.scriptToDocumentIdMapperForOpenPositions =
        scriptToDocumentIdMapperForOpenPositions
    })

    builder.addCase(updateTodayPositionDataInTickerAction, (state, action) => {
      const { payload } = action
      const { todayPositions: todayPositionData } = payload
      let scriptsInTodayPosition: T_TODAY_POSITION_SCRIPTS = {}
      let scriptToDocumentIdMapperForTodayPositions: T_SCRIPT_TO_DOCUMENTID_MAPPER =
        {}

      todayPositionData.length &&
        todayPositionData.map((individualTodayPositionData: TTodayPosition) => {
          const {
            id = 0,
            scriptId = 0,
            closeprice: closePriceFromSecurityMaster = 0,
            netQty = 0,
            investedValue = 0
          } = individualTodayPositionData

          if (scriptId) {
            //SECTION - individual positions's calculations
            const initialIndividualCurrentValue =
              netQty * closePriceFromSecurityMaster

            const initialIndividualUnrealisedPnlValue =
              initialIndividualCurrentValue - investedValue
            const initialIndividualUnrealisedPnlPercentage =
              (investedValue &&
                (initialIndividualUnrealisedPnlValue / investedValue) * 100) ||
              0

            //SECTION - initial individual today position object maker
            const scriptToIdMapperKey = `${scriptId}_${id}`
            scriptsInTodayPosition[scriptToIdMapperKey] = {
              scriptId,
              closeprice: closePriceFromSecurityMaster,
              netQty,
              investedValue,
              unRealisedPnl: {
                value: initialIndividualUnrealisedPnlValue,
                percentChange: initialIndividualUnrealisedPnlPercentage
              }
            }

            //SECTION - Script to Id Mapper
            if (scriptToDocumentIdMapperForTodayPositions[scriptId]) {
              scriptToDocumentIdMapperForTodayPositions[scriptId].push(
                scriptToIdMapperKey
              )
              return
            }
            scriptToDocumentIdMapperForTodayPositions[scriptId] = [
              scriptToIdMapperKey
            ]
          }
        })

      state.typeOfPosition = 'today'
      state.scriptsInTodayPosition = scriptsInTodayPosition
      state.scriptToDocumentIdMapperForTodayPositions =
        scriptToDocumentIdMapperForTodayPositions
    })

    builder.addCase(flushOpenPositionDataInTickerAction, (state, action) => {
      state.scriptsInOpenPosition = {}
      state.scriptToDocumentIdMapperForOpenPositions = {}
    })

    builder.addCase(flushTodayPositionDataInTickerAction, (state, action) => {
      state.scriptsInTodayPosition = {}
      state.scriptToDocumentIdMapperForTodayPositions = {}
    })

    builder.addCase(
      flushOpenAndTodayPositionDataInTickerAction,
      (state, action) => {
        state.scriptsInOpenPosition = {}
        state.scriptToDocumentIdMapperForOpenPositions = {}
        state.scriptsInTodayPosition = {}
        state.scriptToDocumentIdMapperForTodayPositions = {}
      }
    )

    builder.addCase(updateStockTickerAction, (state, { payload }) => {
      const [processedData, scriptId] = payload
      const { LTP } = processedData
      const isOpenTypeOfPosition = state.typeOfPosition === 'open'
      if (isOpenTypeOfPosition) {
        handleOpenPositionsData(state, scriptId, LTP)
        return
      }
      handleOpenPositionsData(state, scriptId, LTP)
      handleTodayPositionsData(state, scriptId, LTP)
    })
  }
}

const handleOpenPositionsData = (state: any, scriptId: string, LTP: number) => {
  const scriptToDocumentIdMapperForOpenPositions =
    state['scriptToDocumentIdMapperForOpenPositions'][scriptId] || null
  if (
    scriptToDocumentIdMapperForOpenPositions &&
    scriptToDocumentIdMapperForOpenPositions.length
  ) {
    scriptToDocumentIdMapperForOpenPositions.forEach((item: string) => {
      const scriptInOpenPositionsData =
        state['scriptsInOpenPosition'][item] || null

      if (scriptInOpenPositionsData) {
        //SECTION - destructuring total values
        const {
          unRealisedPnl: PREVIOUS_TOTAL_UNREALISED_PNL,
          totalInvestedValue
        } = state
        const { value: PREVIOUS_TOTAL_UNREALISED_PNL_VALUE } =
          PREVIOUS_TOTAL_UNREALISED_PNL

        //SECTION - destructuring individual values
        const {
          closeprice: closePriceFromSecurityMaster,
          netQty,
          investedValue,
          unRealisedPnl: previousIndividualUnrealisedPnl
        } = scriptInOpenPositionsData
        const { value: previousIndividualUnrealisedPnlValue = 0 } =
          previousIndividualUnrealisedPnl

        //SECTION - individual positions's calculations
        const latestIndividualCurrentValue =
          netQty * (LTP || closePriceFromSecurityMaster)
        const latestIndividualUnrealisedPnlValue =
          latestIndividualCurrentValue - investedValue
        const latestIndividualUnrealisedPnlPercentage =
          (investedValue &&
            (latestIndividualUnrealisedPnlValue / investedValue) * 100) ||
          0

        //SECTION - individual positions differences calculations
        const differenceInIndividualUnrealisedPnlValue =
          latestIndividualUnrealisedPnlValue -
          previousIndividualUnrealisedPnlValue

        //SECTION - total positions's calculations
        const totalUnrealisedPnlValue =
          PREVIOUS_TOTAL_UNREALISED_PNL_VALUE +
          differenceInIndividualUnrealisedPnlValue
        const totalUnrealisedPnlPercentage =
          (totalInvestedValue &&
            (totalUnrealisedPnlValue / totalInvestedValue) * 100) ||
          0

        const totalPnlValue = totalUnrealisedPnlValue + state.realisedPnl.value
        const totalPnlPercentage =
          (totalInvestedValue && (totalPnlValue / totalInvestedValue) * 100) ||
          0

        //SECTION - setting new data in position summary
        state.totalPnl.value = totalPnlValue
        state.totalPnl.percentChange = totalPnlPercentage
        state.unRealisedPnl.value = totalUnrealisedPnlValue
        state.unRealisedPnl.percentChange = totalUnrealisedPnlPercentage

        //SECTION - setting new data in individual positions
        state['scriptsInOpenPosition'][item].unRealisedPnl.value =
          latestIndividualUnrealisedPnlValue
        state['scriptsInOpenPosition'][item].unRealisedPnl.percentChange =
          latestIndividualUnrealisedPnlPercentage
      }
    })
  }
}

const handleTodayPositionsData = (
  state: any,
  scriptId: string,
  LTP: number
) => {
  const scriptToDocumentIdMapperForTodayPositions =
    state['scriptToDocumentIdMapperForTodayPositions'][scriptId] || null

  if (
    scriptToDocumentIdMapperForTodayPositions &&
    scriptToDocumentIdMapperForTodayPositions.length
  ) {
    scriptToDocumentIdMapperForTodayPositions.forEach((item: string) => {
      const scriptInTodayPositionsData =
        state['scriptsInTodayPosition'][item] || null
      if (scriptInTodayPositionsData) {
        //SECTION - destructuring individual values
        const {
          closeprice: closePriceFromSecurityMaster,
          netQty,
          investedValue
        } = scriptInTodayPositionsData

        //SECTION - individual positions's calculations
        const latestIndividualCurrentValue =
          netQty * (LTP || closePriceFromSecurityMaster)
        const latestIndividualUnrealisedPnlValue =
          latestIndividualCurrentValue - investedValue
        const latestIndividualUnrealisedPnlPercentage =
          (investedValue &&
            (latestIndividualUnrealisedPnlValue / investedValue) * 100) ||
          0

        //SECTION - setting new data in individual positions
        state['scriptsInTodayPosition'][item].unRealisedPnl.value =
          latestIndividualUnrealisedPnlValue
        state['scriptsInTodayPosition'][item].unRealisedPnl.percentChange =
          latestIndividualUnrealisedPnlPercentage
      }
    })
  }
}

const holdingTickerSlice = createSlice(sliceOptions)
export default holdingTickerSlice.reducer
