import React, { PureComponent } from 'react'
import {
  DsBox,
  DsTypography,
  DsSelect,
  withBreakpoints,
  DsStack,
  DsPopup,
  DsRadio,
  DsRadioGroup,
  DsButton,
  DsRemixIcon
} from '@am92/react-design-system'
import { compareAsc } from 'date-fns'

// components

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

// constants
import { QUOTES_LABEL } from '~/src/Constants/LABEL'

// reducers
import { setSelectedDate } from '~/src/Redux/Derivatives/Reducer'

// selectors
import {
  getSelectedDateSelector,
  getGroupedOptionsSelector
} from '~/src/Redux/Derivatives/Selectors'

// utils
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import { getFullStockMasterData } from '~/src/Redux/StockMaster/Selectors'

import { convertDatestamp, getCurrencyFormat } from '~/src/Utils/global'
import StockTickerNew from '~/src/Components/TickerBasedComponents/StockTickerNew'

export interface ActionTypes {
  setSelectedDate: (selectedDate: string) => void
}

export interface IOptionChainProps extends IWithRouterProps {
  handleDialog: any
  breakpoints: any
  groupOptions: any
  StockMasterData: any
  selectedDate: string
  actions: ActionTypes
  scriptId: string
  subscribeLtpData: (scriptIdArray: any[]) => void
  unSubscribeLtpData: (scriptIdArray: any[]) => void
}

class OptionChainHeader extends PureComponent<IOptionChainProps> {
  state = { openDateSelector: false }

  setOpenDateSelector = (val: boolean) => {
    this.setState({ openDateSelector: val })
  }

  handleDateChange = (e: any) => {
    const { actions } = this.props
    const { value } = e.target
    actions.setSelectedDate(value)
  }

  getSelectDropDownOptions = () => {
    const { groupOptions } = this.props
    const result = Object.keys(groupOptions).map(item => {
      return {
        label: `${convertDatestamp(new Date(item).toISOString())}`,
        value: item
      }
    })
    result.sort(
      (
        a: { value: string | number | Date },
        b: { value: string | number | Date }
      ) => compareAsc(new Date(a.value), new Date(b.value))
    )
    return result
  }
  getLtp = () => {
    const { selectedDate, groupOptions, StockMasterData, subscribeLtpData } =
      this.props
    const scriptId = groupOptions?.[selectedDate]?.FUTURE?.scriptId
    subscribeLtpData([
      {
        scriptId: scriptId
      }
    ])
    return getCurrencyFormat(StockMasterData?.[scriptId]?.closeprice)
  }

  componentDidMount() {
    const { actions, selectedDate } = this.props
    if (!selectedDate) {
      actions.setSelectedDate(this.getSelectDropDownOptions()?.[0]?.value)
    }
  }
  componentDidUpdate(
    prevProps: Readonly<IOptionChainProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    const { groupOptions, StockMasterData } = this.props
    if (
      groupOptions !== prevProps.groupOptions ||
      StockMasterData !== prevProps.StockMasterData
    ) {
      const { actions, StockMasterData = {}, scriptId } = this.props
      const { expiryDate = '' } = StockMasterData?.[scriptId] || {}
      const hasValue = this.getSelectDropDownOptions()?.some(
        obj => obj.value === expiryDate
      )
      if (hasValue) {
        actions.setSelectedDate(expiryDate)
      }
    }
  }

  render() {
    const {
      handleDialog,
      breakpoints,
      selectedDate,
      groupOptions,
      StockMasterData = {}
    } = this.props
    const { openDateSelector } = this.state
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const scriptId = groupOptions?.[selectedDate]?.FUTURE?.scriptId
    const { closeprice = 0, segment = '' } = StockMasterData?.[scriptId] || {}
    return (
      <>
        <DsStack
          sx={{
            ml: {
              md: 'var(--ds-spacing-zero)',
              xs: 'var(--ds-spacing-bitterCold)'
            },
            mr: {
              md: 'var(--ds-spacing-zero)',
              xs: 'var(--ds-spacing-bitterCold)'
            },
            mt: { md: 'var(--ds-spacing-zero)', xs: 'var(--ds-spacing-warm)' }
          }}
        >
          <DsBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <DsTypography variant='headingBoldMedium'>
              {QUOTES_LABEL.OPTION_CHAIN.HEADING}
            </DsTypography>
            <DsBox sx={{ display: 'flex', alignItems: 'center' }}>
              <DsBox
                sx={{
                  width:
                    'calc(var(--ds-spacing-plasma) + var(--ds-spacing-warm))'
                }}
              >
                {isDesktop ? (
                  <>
                    <DsSelect
                      fullWidth
                      size='small'
                      value={selectedDate}
                      onChange={this.handleDateChange}
                      options={this.getSelectDropDownOptions()}
                    />
                  </>
                ) : (
                  <>
                    <DsButton
                      fullWidth
                      color='secondary'
                      onClick={() => this.setOpenDateSelector(true)}
                      endIcon={<DsRemixIcon className='ri-calendar-2-fill' />}
                    >
                      {selectedDate &&
                        convertDatestamp(new Date(selectedDate).toISOString())}
                    </DsButton>
                    <DsPopup
                      showClose={true}
                      // DsDialogProps={{
                      //   open: openDateSelector,
                      //   onClose: () => this.setOpenDateSelector(false)
                      // }}
                      open={openDateSelector}
                      onClose={() => this.setOpenDateSelector(false)}
                    >
                      <DsRadioGroup
                        onChange={this.handleDateChange}
                        defaultValue={selectedDate}
                      >
                        {this.getSelectDropDownOptions().map((item: any) => {
                          return (
                            <DsRadio
                              key={item.value}
                              label={item.label}
                              value={item.value}
                              onChange={() => {
                                this, this.setState({ priceType: 'Market' })
                                this.setOpenDateSelector(false)
                              }}
                            />
                          )
                        })}
                      </DsRadioGroup>
                    </DsPopup>
                  </>
                )}
              </DsBox>

              {isDesktop && (
                <DsRemixIcon
                  className='ri-close-line'
                  sx={{ ml: 'var(--ds-spacing-mild)', cursor: 'pointer' }}
                  onClick={handleDialog}
                />
              )}
            </DsBox>
          </DsBox>
          <DsBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 'var(--ds-spacing-frostbite)',
              mb: 'var(--ds-spacing-frostbite)'
            }}
          >
            <DsBox>
              <DsTypography
                variant='bodyRegularSmall'
                color={'var(--ds-colour-typoSecondary)'}
              >
                {QUOTES_LABEL.OPTION_CHAIN.FUTURE_PRICE}
              </DsTypography>
              <DsTypography variant='bodyBoldSmall' sx={{ p: '0.2rem' }}>
                <StockTickerNew
                  scriptId={scriptId}
                  closeprice={closeprice}
                  segment={segment}
                  LtpTypoProps={{
                    variant: 'bodyRegularSmall'
                  }}
                  showNetChange={false}
                  showPercentChange={false}
                  showChangeIcon={false}
                />
              </DsTypography>
            </DsBox>
            <DsBox sx={{ display: 'flex', alignItems: 'center' }}>
              <DsBox
                sx={{
                  width: 'var(--ds-spacing-bitterCold)',
                  height: 'var(--ds-spacing-bitterCold)',
                  backgroundColor: 'var(--ds-colour-neutral3)',
                  borderRadius: 'var(--ds-radius-deepFreeze)',
                  mr: 'var(--ds-spacing-quickFreeze)'
                }}
              />
              <DsTypography variant='bodyRegularSmall'>
                {isDesktop
                  ? QUOTES_LABEL.OPTION_CHAIN.ITM
                  : QUOTES_LABEL.OPTION_CHAIN.ITM_MOBILE}
              </DsTypography>
            </DsBox>
          </DsBox>
          <DsBox sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <DsTypography variant='bodyBoldLarge'>
              {QUOTES_LABEL.OPTION_CHAIN.CALL}
            </DsTypography>
            <DsTypography variant='bodyBoldLarge'>
              {QUOTES_LABEL.OPTION_CHAIN.PUT}
            </DsTypography>
          </DsBox>
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const selectedDate = getSelectedDateSelector(state)
  const groupOptions = getGroupedOptionsSelector(state)
  const StockMasterData = getFullStockMasterData(state)
  return {
    groupOptions,
    selectedDate,
    StockMasterData
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setSelectedDate: (selectedDate: string) =>
      dispatch(setSelectedDate(selectedDate))
  }
})

export default withBreakpoints(
  withStockSubUnSub(
    withErrorConnect(mapStateToProps, mapDispatchToProps)(OptionChainHeader)
  )
)
