import { enqueueNotistack } from '@am92/react-design-system'
import { useEffect } from 'react'
import { TAppStore } from '~/src/Configurations/AppStore'
import { useAppDispatch, useAppSelector } from '~/src/hooks/useRedux'
import getNotificationsAction from '~/src/Redux/Notifications/Services/getNotifications.Service'
import { getReverseFeedAlertData } from '~/src/Redux/ReverseFeed/Selectors'
import { getSSOAccessTokenSubAccountIdSelector } from '~/src/Redux/SSO/Selectors'
import ReverseFeedSocket from './ReverseFeedSocket'
import { X_API_CLIENT_ID } from '~/src/Configurations/env'
import { removeTriggeredAlertAction } from '~/src/Redux/Alerts/Actions'
import getOrderBookAction, {
  T_GET_ORDER_BOOK_PAYLOAD
} from '~/src/Redux/Orders/Services/getOrderBook.Service'

const ReverseFeedNotifier: React.FunctionComponent = props => {
  const dispatch = useAppDispatch()
  const { token, subAccountId } = useAppSelector((state: TAppStore) =>
    getSSOAccessTokenSubAccountIdSelector(state)
  )
  const reverseFeedAlertData = useAppSelector((state: TAppStore) =>
    getReverseFeedAlertData(state)
  )
  const { alertTime, alertId = '' } = reverseFeedAlertData

  useEffect(() => {
    //SECTION - this will initiate reversefeed socket
    if (subAccountId) {
      new ReverseFeedSocket(`${X_API_CLIENT_ID}|${subAccountId}|${token}`)
    }
  }, [])

  const fetchOrderBook = async () => {
    const requestPayload: T_GET_ORDER_BOOK_PAYLOAD = {
      segment: 'EQ',
      omsOrderId: '',
      orderStatus: '-1'
    }

    const response = await dispatch(getOrderBookAction(requestPayload))
    if ((response as any)?._isCustomError) {
      enqueueNotistack({
        message: 'Get order book API Failed.',
        autoHideDuration: 2000
      })
      console.log('failed API - getOrderBook')
      return
    }
  }

  useEffect(() => {
    //SECTION - this will trigger reversefeed enquestack card
    if (alertTime) {
      const reverseFeedMessage = JSON.stringify(reverseFeedAlertData)
      enqueueNotistack({
        message: reverseFeedMessage,
        variant: 'custom',
        autoHideDuration: 6000,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      })

      dispatch(getNotificationsAction({}))
      //SECTION - if alertId is present then dispatch action to remove the
      // alert from alert table as this alertid is triggered when set alert is met
      if (alertId) {
        dispatch(removeTriggeredAlertAction({ alertId }))
        return
      }
      fetchOrderBook() //TODO - call this api based on url path
    }
  }, [alertTime])

  return null
}

export default ReverseFeedNotifier
