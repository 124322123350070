import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import {
  removeWatchlistIdFromStocksAction,
  setNewStockWatchListArrayAction,
  updateStockWatchlistMasterAction
} from './Actions'
import { T_SCRIPTS_OBJ } from '../WatchList/Reducer'

export type T_StockWatchlistMasterData = {
  [key: number | string]: string[]
}

export interface I_StockWatchlistMasterStore {
  data: T_StockWatchlistMasterData //NOTE - scriptId = [watchlist ids array], this array gives information in how many watchlists the script is present
  temporaryData: T_StockWatchlistMasterData
}

const INITIAL_STATE: I_StockWatchlistMasterStore = {
  data: {},
  temporaryData: {}
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setTemporaryStockWatchlistData: (state, action) => {
      state.temporaryData = action?.payload
    },
    setNewTemporaryStockWatchlistData: (state, action) => {
      const { stockAvailableInWatchListArray, bookMarkedScriptId } =
        action?.payload
      if (!stockAvailableInWatchListArray.length) {
        delete state.temporaryData[bookMarkedScriptId]
        return
      }
      state.temporaryData[bookMarkedScriptId] = stockAvailableInWatchListArray
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    // updateStockWatchlistMaster
    builder.addCase(updateStockWatchlistMasterAction, (state, { payload }) => {
      state.data = payload
    })

    // setNewStockWatchListArrayAction
    builder.addCase(setNewStockWatchListArrayAction, (state, { payload }) => {
      const { stockAvailableInWatchListArray, bookMarkedScriptId } = payload
      if (!stockAvailableInWatchListArray.length) {
        delete state.data[bookMarkedScriptId]
        return
      }
      state.data[bookMarkedScriptId] = stockAvailableInWatchListArray
    })

    // removeWatchlistIdFromStocks
    builder.addCase(removeWatchlistIdFromStocksAction, (state, { payload }) => {
      const { watchListIdToDelete, scriptIdArray } = payload
      scriptIdArray.forEach((scriptObj: T_SCRIPTS_OBJ) => {
        const { scriptId } = scriptObj
        const currentWatchListArray = [...state.data[scriptId]]
        const newWatchListArray = currentWatchListArray.filter(
          watchListId => watchListId !== watchListIdToDelete
        )
        state.data[scriptId] = newWatchListArray
        if (!newWatchListArray.length) {
          delete state.data[scriptId]
        }
      })
    })
  }
}

const stockWatchlistMasterSlice = createSlice(sliceOptions)

export const {
  setTemporaryStockWatchlistData,
  setNewTemporaryStockWatchlistData
} = stockWatchlistMasterSlice.actions

export default stockWatchlistMasterSlice.reducer
