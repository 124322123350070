import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const getOpenPositionsServiceName = 'positions/getOpenPositions'
export const getOpenPositionsActions = traceActionsCreator(
  getOpenPositionsServiceName
)

export const getTodayPositionsServiceName = 'positions/getTodayPositions'
export const getTodayPositionsActions = traceActionsCreator(
  getTodayPositionsServiceName
)

export const flushOpenPositionDataAction = createAction(
  'positions/flushOpenPositionDataAction'
)

export const flushTodayPositionDataAction = createAction(
  'positions/flushTodayPositionDataAction'
)
