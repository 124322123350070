import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsTooltip,
  DsButton,
  DsBox,
  DsRemixIcon
} from '@am92/react-design-system'
import { getAllDerivativeSelector } from '~/src/Redux/Derivatives/Selectors'
import { setSelectedExchange } from '~/src/Redux/Orders/reducer'
import { openOptionDialogActions } from '~/src/Redux/AppState/actions'
import { TAppDrawerData } from '~/src/Redux/AppState/TYPES'

type ActionTypes = {
  setSelectedExchange: (requestData: any) => Promise<any>
  openAppStateDialog: (data: TAppDrawerData) => void
}

export interface ISingleSecurityProps extends IWithRouterProps {
  actions: ActionTypes
  getAllDerivative: any
  scriptId: string
}

class OptionChainSheet extends Component<ISingleSecurityProps> {
  showOptionChain = () => {
    const { getAllDerivative, scriptId } = this.props
    const mappedId = (window as any)?.secMaster?._getScripIdForBSEDerivative(
      scriptId
    )
    const derivatives: any = getAllDerivative
    const futures = derivatives?.[mappedId]?.['FUTURES'] || []
    const options = derivatives?.[mappedId]?.['OPTIONS'] || []
    const show: boolean =
      futures.length > 0 && options.length > 0 ? true : false
    return show
  }

  handleSelectedStock = () => {
    const { actions, navigate, scriptId } = this.props
    const { exchange } = (window as any).secMaster.getByScriptId(scriptId) || {}
    actions.setSelectedExchange({
      selectedExchange: exchange
    })
    navigate(`/quotes/${scriptId}`)
  }

  handleOptionChain = () => {
    const { actions, scriptId } = this.props
    this.handleSelectedStock()
    actions.openAppStateDialog({ scriptId })
  }

  render() {
    if (!this.showOptionChain()) {
      return null
    }

    return (
      <DsTooltip
        heading='Option chain'
        placement='bottom'
        arrow={false}
        disableTouchListener
        disableInteractive
      >
        <DsButton
          color='secondary'
          sx={{
            minWidth:
              'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
          }}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            this.handleOptionChain()
          }}
        >
          <DsBox
            sx={{
              height:
                'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))',
              width:
                'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
            }}
          >
            <DsRemixIcon
              className='ri-stock-line'
              fontSize='bitterCold'
              sx={{
                fontWeight: 'var(--ds-typo-fontWeightRegular)'
              }}
            />
          </DsBox>
        </DsButton>
      </DsTooltip>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const getAllDerivative = getAllDerivativeSelector(state)
  return {
    getAllDerivative
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setSelectedExchange: (requestData: any) =>
      dispatch(setSelectedExchange(requestData)),
    openAppStateDialog: (data: TAppDrawerData) =>
      dispatch(openOptionDialogActions(data))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(OptionChainSheet)
