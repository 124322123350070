import {
  DsBox,
  DsButtonBase,
  DsRemixIcon,
  DsTooltip,
  DsTypography,
  DsBottomSheet
} from '@am92/react-design-system'
import { format } from 'date-fns'
import React from 'react'

export interface IWhatisDematHoldingsProps {}
export interface IWhatisDematHoldingsState {
  showDemat: string
}

export default class WhatisDematHoldings extends React.PureComponent<
  IWhatisDematHoldingsProps,
  IWhatisDematHoldingsState
> {
  state = {
    showDemat: ''
  }

  handleCrossClick = () => {
    this.setState({ showDemat: 'hide' })
  }

  handleClose = () => {
    this.setState({ showDemat: '' })
  }

  render() {
    const { showDemat } = this.state

    const dematInfoText =
      'A Demat Account or Dematerialised Account provides the facility of holding shares and securities in an electronic format. During online trading, shares are bought and held in a Demat Account, thus, facilitating easy trade for the users. A Demat account holds all the investments an individual makes in shares, government securities, exchange-traded funds, bonds and mutual funds in one place.'

    const isBottomSheetOpen = showDemat === 'showBottomSheet'

    return (
      <>
        <DsBox
          sx={{
            display: showDemat === 'hide' ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            p: {
              xs: 'var(--ds-spacing-zero) var(--ds-spacing-glacial) var(--ds-spacing-glacial) var(--ds-spacing-glacial)',
              md: 'var(--ds-spacing-zero)'
            }
          }}
        >
          {/* For desktop screens */}
          <DsBox
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              justifyContent: 'center',
              gap: 'var(--ds-spacing-glacial)'
            }}
          >
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 'var(--ds-spacing-glacial)'
              }}
            >
              <DsTooltip
                heading={dematInfoText}
                placement='bottom'
                arrow={true}
                color='inherit'
                disableTouchListener
                disableInteractive
              >
                <DsButtonBase>
                  <DsRemixIcon
                    fontSize='small'
                    className='ri-information-line'
                  />
                </DsButtonBase>
              </DsTooltip>
              <DsTypography variant='bodyRegularSmall'>
                What is Demat Holding?
              </DsTypography>
            </DsBox>
          </DsBox>

          {/* For mobile screens */}
          <DsBox
            sx={{
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center',
              justifyContent: 'center',
              gap: 'var(--ds-spacing-glacial)'
            }}
          >
            <DsButtonBase
              onClick={() => this.setState({ showDemat: 'showBottomSheet' })}
            >
              <DsRemixIcon fontSize='small' className='ri-information-line' />
            </DsButtonBase>
            <DsTypography variant='bodyRegularSmall'>
              What is Demat Holding?
            </DsTypography>
          </DsBox>

          <DsButtonBase
            sx={{ display: { xs: 'flex', md: 'none' } }}
            onClick={this.handleCrossClick}
          >
            <DsRemixIcon className='ri-close-line' fontSize='medium' />
          </DsButtonBase>
        </DsBox>

        {/* Bottom sheet for mobile */}
        <DsBottomSheet
          open={isBottomSheetOpen}
          onClose={this.handleClose}
          showClose={true}
          title='Demat Holding'
        >
          <DsBox
            sx={{
              backgroundColor: 'var(--ds-colour-surfaceSecondary)',
              padding: 'var(--ds-spacing-glacial)',
              borderRadius: 'var(--ds-spacing-glacial)'
            }}
          >
            <DsTypography variant='bodyRegularMedium'>
              {dematInfoText}
            </DsTypography>
          </DsBox>
        </DsBottomSheet>
      </>
    )
  }
}
