import React from 'react'
import { DsTypography, DsTypographyProps } from '@am92/react-design-system'
import { connect, ConnectedProps } from 'react-redux'

// context
import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'

// selectors
import { getLtpTickSelector } from '~/src/Redux/StockTicker/Selectors'

// utils
import { getCurrencyFormat } from '~/src/Utils/global'

interface IStockTickerWithLtpProps {
  scriptId: string
  closeprice: number
  segment: string
  withCurrencySymbol?: boolean
  LtpTypoProps?: DsTypographyProps
}
class StockTickerWithLTP extends React.PureComponent<
  IStockTickerWithLtpProps & PropsFromRedux
> {
  render() {
    const { segment, withCurrencySymbol = true, LtpTypoProps, LTP } = this.props
    const options = {
      minimumFractionDigits: segment === 'CURR' ? 4 : 2
    }

    const ltp = getCurrencyFormat(
      LTP || 0,
      withCurrencySymbol ? options : { ...options, style: 'decimal' }
    )
    return (
      <DsTypography variant='bodyRegularSmall' {...LtpTypoProps}>
        {ltp}
      </DsTypography>
    )
  }
}

const mapStateToProps = (
  state: TTickerStore,
  ownProps: IStockTickerWithLtpProps
) => {
  const { scriptId, closeprice = 0 } = ownProps
  const LTP = getLtpTickSelector(state, scriptId) || closeprice
  return {
    LTP
  }
}

const connector = connect(mapStateToProps, null, null, {
  context: tickerContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(StockTickerWithLTP)
