import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'
import { getAlertsActions, getAlertsServiceName } from '../Actions'

async function getAlerts() {
  const options = {
    url: `/alerts`,
    method: 'GET',
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body

  const result: any = []
  if (data) {
    data.forEach((item: any) => {
      const { scriptId } = item
      const { segment, closeprice } =
        (window as any).secMaster.getByScriptId(scriptId) || {}

      result.push({
        ...item,
        closeprice,
        segment
      })
    })
  }
  return result
}

export const getAlertsService = serviceActionCreator(
  getAlertsActions,
  getAlerts
)

export default getAlertsService
export { getAlertsServiceName }
