//NOTE - See if the add to watchlist component can be moved inside bookmark component - REMAINING IN WATCHLIST
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
  DsBox,
  DsDivider,
  DsListItemButton,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import BookmarkButtonNew from '~/src/Components/BookmarkButtonNew'
import TradableStockButtons from '~/src/Components/Watchlist/TradableStockButtons'

import RenderSearchTabs from './RenderSearchTabs'
import ZeroSearchResult from './ZeroSearchResult'

// actions
import { setSelectedExchange } from '~/src/Redux/Orders/reducer'
// selectors
import {
  getFilteredSearchResult,
  getSearchSelectedTabSelector
} from '~/src/Redux/Search/Selectors'
import createRecentSearchConfigAction from '~/src/Redux/Search/Services/createRecentSearch.Service'
import getRecentSearchConfigAction from '~/src/Redux/Search/Services/getRecentSearch.Service'

import { I_SCRIPT_SEARCH_STRUCTURE } from '~/src/Constants/SEC_MASTER'
// context
import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'
import { triggerCleverTapEvent } from '~/src/Utils/global'

import { isNonTradableStock } from '~/src/Lib/SecMaster/helpers'

export interface IRenderSearchListProps {
  type?: string
  handleStockSelect?: (scriptId: string) => void
}

export interface IRenderSearchListState {
  hover: string | number
}

class RenderSearchList extends React.PureComponent<
  IRenderSearchListProps & PropsFromRedux,
  IRenderSearchListState
> {
  constructor(props: IRenderSearchListProps & PropsFromRedux) {
    super(props)
    this.state = {
      hover: -1
    }
  }

  handleOnSelectStock = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    scripId: string
  ) => {
    const { attributes = '' } = (e?.target as Element) || {}
    const { datatype = '' } = (attributes as any) || {}
    if (datatype.nodeValue !== 'navigateToQuotes') {
      return
    }
    const { actions, handleStockSelect } = this.props
    const { exchange, exchangeSymbol, coname } =
      (window as any)?.secMaster?.getByScriptId(scripId) || {}
    const customAttributes = {
      'Event Status': 'Pass',
      'Screen Name': 'Search',
      Action: 'Script Clicked',
      Keyword: scripId
    }
    triggerCleverTapEvent('Search_Done', customAttributes)
    if (exchange) {
      await actions.setSelectedExchange({ selectedExchange: exchange })
    }
    await actions.createRecentSearch({
      scriptId: scripId.toString(),
      exchange,
      symbol: exchangeSymbol,
      coName: coname
    })
    await actions.getRecentSearch()
    if (handleStockSelect) {
      handleStockSelect(scripId)
    }
  }
  handleHover = (scriptId: string | number) => {
    this.setState({ hover: scriptId })
  }

  handleLeaveHover = () => {
    this.setState({ hover: -1 })
  }

  renderSearchResults = () => {
    const { hover } = this.state
    const { filteredSearchResult, type = '' } = this.props
    const noSearchResult = !(
      filteredSearchResult && filteredSearchResult.length
    )

    if (noSearchResult) {
      return <ZeroSearchResult typeOfSearch={'stringSearch'} />
    }

    return (
      <>
        {filteredSearchResult.map((searchItem: I_SCRIPT_SEARCH_STRUCTURE) => {
          const {
            scriptId,
            instrumentType,
            exchangeSymbol,
            exchange,
            coname,
            aslAllowed
          } = searchItem

          const isEquity = instrumentType === 'EQUITY'
          const notTradable = isNonTradableStock(aslAllowed)
          const showBuySellButtons = scriptId === hover && !notTradable && !type

          return (
            <>
              <DsListItemButton
                key={scriptId}
                sx={{
                  p: 'var(--ds-spacing-bitterCold)'
                }}
                onClick={e => {
                  this.handleOnSelectStock(e, scriptId)
                }}
                onMouseEnter={() => {
                  this.handleHover(scriptId)
                }}
                onMouseLeave={() => {
                  this.handleLeaveHover()
                }}
                datatype='navigateToQuotes'
              >
                <DsStack
                  sx={{ display: 'flex', gap: '0.5rem' }}
                  datatype='navigateToQuotes'
                >
                  <DsBox
                    sx={{
                      display: 'flex',
                      gap: '0.5rem',
                      alignItems: 'center'
                    }}
                    datatype='navigateToQuotes'
                  >
                    <DsTypography
                      variant='bodyBoldSmall'
                      datatype='navigateToQuotes'
                    >
                      {isEquity ? exchangeSymbol : coname}
                    </DsTypography>
                    <DsTypography
                      variant='supportRegularInfo'
                      sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                      datatype='navigateToQuotes'
                    >
                      {exchange}
                    </DsTypography>
                  </DsBox>

                  <DsTypography
                    variant='bodyRegularSmall'
                    sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                    datatype='navigateToQuotes'
                  >
                    {isEquity ? coname : exchangeSymbol}
                  </DsTypography>
                </DsStack>

                {/* BOOKMARK ICON  */}
                {!type && (
                  <DsBox
                    sx={{
                      position: 'absolute',
                      right: 10,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 'var(--ds-spacing-glacial)'
                    }}
                  >
                    <BookmarkButtonNew scriptId={scriptId} />
                  </DsBox>
                )}

                {showBuySellButtons && (
                  <DsBox
                    sx={{
                      position: 'absolute',
                      right: 60,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 'var(--ds-spacing-glacial)'
                    }}
                  >
                    <TradableStockButtons
                      scriptId={scriptId}
                      showMarketDepth={false}
                    />
                  </DsBox>
                )}
              </DsListItemButton>
              <DsStack sx={{ alignItems: 'center' }}>
                <DsDivider sx={{ width: '94%' }} />
              </DsStack>
            </>
          )
        })}
      </>
    )
  }

  render() {
    const { type = '' } = this.props
    return (
      <>
        {type !== 'sip' && <RenderSearchTabs />}
        {this.renderSearchResults()}
      </>
    )
  }
}

const mapStateToProps = (
  state: TAppStore,
  ownProps: IRenderSearchListProps
) => {
  const { type = '' } = ownProps
  const selectedTab = getSearchSelectedTabSelector(state)
  const filteredSearchResult = getFilteredSearchResult(
    type === 'sip' ? 'EQ' : selectedTab
  )(state)
  return {
    filteredSearchResult
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setSelectedExchange: (requestData: { selectedExchange: string }) =>
      dispatch(setSelectedExchange(requestData)),
    createRecentSearch: (requestData: any) =>
      dispatch(createRecentSearchConfigAction(requestData)),
    getRecentSearch: () => dispatch(getRecentSearchConfigAction())
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(RenderSearchList)
