import {
  DsBox,
  DsTypography,
  DsSelect,
  DsTextField,
  DsTextArea,
  DsCard,
  DsRemixIcon,
  DsBottomSheet,
  DsFormControl,
  DsFormControlLabel,
  DsRadio,
  DsRadioGroup,
  DsButton,
  enqueueNotistack
} from '@am92/react-design-system'
import React, { useEffect, useState } from 'react'
import NumberFormatter from '../NumberFormatter'
import {
  T_SCRIPT_ALERT_OBJ,
  SCRIPT_ALERT
} from '~/src/Constants/SCRIPT_ALERT_CONSTANTS'
import { useAppDispatch, useAppSelector } from '~/src/hooks/useRedux'
import { TAppStore } from '~/src/Configurations/AppStore'
import {
  getAlertsFormDetails,
  getAlertsFormToggleSelector
} from '~/src/Redux/Alerts/Selectors'
import alertsFormService, {
  createModifyAlertsFormServiceName
} from '~/src/Redux/Alerts/Services/alertsForm.Service'
import getAlertsService from '~/src/Redux/Alerts/Services/getAlerts.Service'
import useResponsiveBreakpoints from '~/src/hooks/useResponsiveBreakpoints'
import { isServiceLoading } from '~/src/Redux/ServiceTracker/Selectors'

interface IAlertsFormContentProps {
  handleClose: () => void
  handleError: (error: any) => void
}

const AlertsFormContent = (props: IAlertsFormContentProps) => {
  const dispatch = useAppDispatch()
  const toggle = useAppSelector((state: TAppStore) =>
    getAlertsFormToggleSelector(state)
  )
  const alertFormDetails = useAppSelector((state: TAppStore) =>
    getAlertsFormDetails(state)
  )
  const { handleClose, handleError } = props
  const { data, isModify } = alertFormDetails || {}
  const { condition = SCRIPT_ALERT.GT.value, value, description } = data || {}
  const SCRIPT_ALERT_OPTIONS: T_SCRIPT_ALERT_OBJ[] = Object.values(SCRIPT_ALERT)
  const [conditionStaggerOpen, setConditionStaggerOpen] =
    useState<boolean>(false)
  const [selectedComparison, setSelectedComparison] = useState<string>(
    isModify ? SCRIPT_ALERT[condition].value : ''
  )
  const [price, setPrice] = useState<number | null>(isModify ? value : null)
  const [descriptionInput, setDescriptionInput] = useState<string>(
    isModify ? description : ''
  )
  const isLoading = useAppSelector((state: TAppStore) =>
    isServiceLoading(state, [createModifyAlertsFormServiceName])
  )
  const { isDesktop } = useResponsiveBreakpoints()
  const handleComparision = (
    event:
      | (Event & { target: { value: unknown; name: string } })
      | React.ChangeEvent<HTMLInputElement>,
    child: React.ReactNode
  ) => {
    event.preventDefault()
    typeof event.target.value === 'string' &&
      setSelectedComparison(event.target.value)
    setConditionStaggerOpen(false)
  }

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Number(e.target.value))
  }
  const handleReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionInput(e.target.value)
  }
  useEffect(() => {
    if (alertFormDetails) {
      const {
        condition = SCRIPT_ALERT.GT.value,
        value = null,
        description = ''
      } = data || {}
      setSelectedComparison(condition)
      setPrice(value)
      setDescriptionInput(description)
    } else {
      setSelectedComparison(SCRIPT_ALERT.GT.value) // Default condition
      setPrice(null)
      setDescriptionInput('')
    }
  }, [alertFormDetails, toggle])
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { scriptId = '', isModify, data } = alertFormDetails || {}
    const { alertId = '' } = data || {}
    const reqData = {
      ...(isModify && { alertId }),
      scriptId: scriptId.toString(),
      value: Number(price),
      condition: selectedComparison,
      description: descriptionInput
    }
    console.log('dummy commit')
    const response = await dispatch(alertsFormService(reqData))
    if (response._isCustomError) {
      handleError(response)
      return false
    }
    enqueueNotistack({
      message: 'Your alert has been set',
      autoHideDuration: 5000,
      icon: <DsRemixIcon className='ri-checkbox-circle-fill' />
    })

    handleClose()

    await dispatch(getAlertsService())
  }
  const isButtonDisabled = !price || selectedComparison === '' || isLoading
  return (
    <>
      <DsBox
        sx={{
          overflowY: 'scroll',
          scrollBehavior: 'smooth',
          pb: 'var(--ds-spacing-blazing)',
          height: { md: '63vh', xs: '70vh' }
        }}
      >
        <DsBox display='flex' flexDirection='column' p='1rem' gap='1rem'>
          <DsBox display='flex' flexDirection='column' gap='0.5rem'>
            <DsTypography variant='bodyRegularMedium'>
              Set Alert for
            </DsTypography>
            <DsSelect
              fullWidth
              value={selectedComparison}
              onChange={handleComparision}
              options={SCRIPT_ALERT_OPTIONS}
              onClick={() => setConditionStaggerOpen(true)}
              disabled={!isDesktop}
              sx={{
                '& .MuiInputBase-root.Mui-disabled': {
                  background: {
                    xs: 'var(--ds-colour-surfacePrimary) !important',
                    md: 'inherit'
                  },
                  cursor: { md: 'inherit', xs: 'pointer !important' }
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  '-webkit-text-fill-color': {
                    xs: 'var(--palette-text-primary)',
                    md: 'inherit'
                  },
                  cursor: { md: 'inherit', xs: 'pointer !important' }
                }
              }}
            />
            <DsTypography variant='supportRegularInfo'>
              You’ll get an alert when price will be greater than ₹{price}
            </DsTypography>
          </DsBox>
          <DsTextField
            label='Target Price (₹)'
            inputComponent={NumberFormatter}
            fullWidth
            value={price}
            inputProps={{
              inputMode: 'numeric'
            }}
            onChange={handleValueChange}
          />
          <DsTextArea
            label='Alert Reason (optional)'
            maxLength={320}
            onChange={handleReason}
            placeholder='For eg. Looking to sell my Holdings for better profit.'
            value={descriptionInput}
          />

          <DsCard
            sx={{
              border: '1px solid #E2E2E2',
              boxShadow: 'none',
              borderRadius: '0.8rem'
            }}
          >
            <DsBox display='flex' gap='1rem' p='1rem'>
              <DsBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'var(--ds-colour-neutral2)',
                  borderRadius: '4px',
                  width: '2rem',
                  height: '2rem',
                  p: '0.5rem'
                }}
              >
                <DsRemixIcon
                  className='ri-lightbulb-flash-fill'
                  sx={{ color: 'var(--ds-colour-iconActionTertiary)' }}
                />
              </DsBox>
              <DsTypography variant='bodyRegularSmall' color='darkgrey'>
                The Alert will be active for maximum 365 days or until deleted.
              </DsTypography>
            </DsBox>
          </DsCard>
        </DsBox>
      </DsBox>
      <DsBox
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        p='1rem'
        gap='0.5rem'
        sx={{
          bottom: 0,
          position: 'absolute',
          boxShadow: '1px 0px 10px 0px rgba(0, 0, 0, 0.3)',
          width: '100%',
          background: 'var(--ds-colour-surfacePrimary)',
          zIndex: 1
        }}
      >
        <DsTypography variant='supportRegularInfo'>
          Price alert will be triggered at {price}
        </DsTypography>
        <DsButton fullWidth onClick={handleSubmit} disabled={isButtonDisabled}>
          {isModify ? 'Save Changes' : 'Set Price Alert'}
        </DsButton>
      </DsBox>
      {!isDesktop && (
        <DsBottomSheet
          open={conditionStaggerOpen}
          onClose={() => setConditionStaggerOpen(false)}
          showClose
          title='Add Alert for'
        >
          <DsFormControl>
            <DsRadioGroup
              value={
                isModify ? SCRIPT_ALERT[condition].value : selectedComparison
              }
            >
              {SCRIPT_ALERT_OPTIONS.map((item: any) => {
                return (
                  <DsFormControlLabel
                    sx={{ ml: '0' }}
                    key={item}
                    control={
                      <DsRadio
                        {...item}
                        onChange={handleComparision}
                        componentsProps={{
                          typography: {
                            sx: {
                              fontWeight:
                                selectedComparison === item.value
                                  ? '700 !important'
                                  : 'inherit'
                            }
                          }
                        }}
                      />
                    }
                    label=''
                  />
                )
              })}
            </DsRadioGroup>
          </DsFormControl>
        </DsBottomSheet>
      )}
    </>
  )
}

export default AlertsFormContent
