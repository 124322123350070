import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsBox,
  DsImage,
  DsButton,
  DsTooltip,
  enqueueNotistack
} from '@am92/react-design-system'
import { DELETE, MORE_OPTIONS } from '~/src/Constants/ASSET_MAP'
import {
  T_SCRIPTS_OBJ,
  setSecurityArray,
  setWatchlistToSecuritiesArray
} from '~/src/Redux/WatchList/Reducer'
import {
  getSelectedWatchlistTab,
  getWatchlist
} from '~/src/Redux/WatchList/Selectors'
import updateScriptsSequenceAction, {
  IUpdateScriptsSequencePayload
} from '~/src/Redux/WatchList/Services/UpdateScriptsSequence.Service'
import { T_SET_SECURITIES_REQ_DATA } from './WatchlistContainer'
import { removeWatchlistIdFromStocksAction } from '~/src/Redux/StockWatchlistMaster/Actions'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

type ActionTypes = {
  setSecurityArray: (reqData: T_SET_SECURITIES_REQ_DATA) => Promise<any>
  updateScriptsSequence: (
    reqData: IUpdateScriptsSequencePayload
  ) => Promise<any>
  removeWatchlistIdFromStocks: (requestData: {
    watchListIdToDelete: string
    scriptIdArray: T_SCRIPTS_OBJ[]
  }) => Promise<any>
  setWatchlistToSecuritiesArray: (
    reqData: T_SET_SECURITIES_REQ_DATA
  ) => Promise<any>
}

export interface ICustomWatchListOptionsProps extends IWithRouterProps {
  actions: ActionTypes

  currentWatchlistId: string
  watchListType: string
  currentWatchlistSecurities: T_SCRIPTS_OBJ[] | null

  selectedScript: string
  sequenceNumber: number
  securitiesIndex: number
  handleViewMore: (sequenceNumber: any) => void
  subscribeLtpData: (scriptArray: any[]) => void
  unSubscribeLtpData: (scriptArray: any[]) => void
}

class CustomWatchListOptions extends Component<ICustomWatchListOptionsProps> {
  handleDelete = async (index: number) => {
    const {
      actions,
      currentWatchlistSecurities: securitiesArray,
      currentWatchlistId,
      unSubscribeLtpData
    } = this.props

    const toBeDeletedScript = securitiesArray?.[index]
    const arrayTillDeletedObj: T_SCRIPTS_OBJ[] =
      securitiesArray?.slice(0, index) || []
    const arrayFromDeletedObject: T_SCRIPTS_OBJ[] =
      securitiesArray?.slice(index + 1) || []

    const finalArrayAfterDeletion = [
      ...arrayTillDeletedObj,
      ...arrayFromDeletedObject
    ]

    const newSecurityArray: T_SCRIPTS_OBJ[] = []

    finalArrayAfterDeletion
      .reverse()
      .forEach((item: T_SCRIPTS_OBJ, index: number) => {
        newSecurityArray.push({
          ...item,
          sequenceNumber: index
        })
      })

    actions.setSecurityArray({
      securitiesArray: newSecurityArray,
      watchlistId: currentWatchlistId
    })

    const requestData: IUpdateScriptsSequencePayload = {
      params: {
        watchlistId: currentWatchlistId
      },
      dataPayload: {
        watchlistSecurities: newSecurityArray
      }
    }

    const updateScriptsSequenceResponse =
      await actions.updateScriptsSequence(requestData)

    if (updateScriptsSequenceResponse?._isCustomError) {
      actions.setSecurityArray({
        securitiesArray: securitiesArray?.length
          ? securitiesArray?.reverse()
          : [],
        watchlistId: currentWatchlistId
      })

      console.log('failed API - updateScriptsSequence')

      enqueueNotistack({
        message: 'Request failed!'
      })
      return
    }

    // update the default securities array in WatchlistToSecuritiesArray redux
    actions.setWatchlistToSecuritiesArray({
      securitiesArray: newSecurityArray,
      watchlistId: currentWatchlistId
    })

    // update the scriptToWatchlistMaster redux
    if (toBeDeletedScript) {
      await actions.removeWatchlistIdFromStocks({
        watchListIdToDelete: currentWatchlistId,
        scriptIdArray: [toBeDeletedScript]
      })
    }

    enqueueNotistack({
      message: 'Script Deleted!'
    })

    unSubscribeLtpData([toBeDeletedScript])

    //TODO - check how this can be done using redux maybe , check !!
    this.setState({
      selectedScript: '0'
    })
  }

  render() {
    const {
      sequenceNumber,
      selectedScript,
      watchListType,
      securitiesIndex,
      handleViewMore
    } = this.props
    const isSelectedScript = sequenceNumber === Number(selectedScript)
    const isCustomWatchlist = watchListType !== 'PRE-DEFINED'
    if (!isCustomWatchlist) {
      return null
    }
    if (isSelectedScript && isCustomWatchlist) {
      return (
        <DsButton
          color='secondary'
          sx={{
            minWidth:
              'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
          }}
          onClick={e => {
            e.stopPropagation()
            this.handleDelete(securitiesIndex)
          }}
        >
          <DsBox
            style={{
              height:
                'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))',
              width:
                'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
            }}
          >
            <DsImage srcSet={DELETE} />
          </DsBox>
        </DsButton>
      )
    }
    if (!isSelectedScript && isCustomWatchlist) {
      return (
        <DsTooltip
          heading='More'
          placement='bottom'
          arrow={false}
          disableTouchListener
          disableInteractive
        >
          <DsButton
            color='secondary'
            sx={{
              minWidth:
                'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
            }}
            onClick={e => {
              e.stopPropagation()
              handleViewMore(sequenceNumber)
            }}
          >
            <DsBox
              style={{
                height:
                  'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))',
                width:
                  'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
              }}
            >
              <DsImage srcSet={MORE_OPTIONS} />
            </DsBox>
          </DsButton>
        </DsTooltip>
      )
    }
    return null
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const {
    currentWatchlistId,
    currentWatchlistType: watchListType,
    currentWatchlistSecurities
  } = getWatchlist(selectedWatchlistTab)(state) || {}

  return {
    watchListType,
    currentWatchlistId,
    currentWatchlistSecurities
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setSecurityArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setSecurityArray(requestData)),
    updateScriptsSequence: (requestData: IUpdateScriptsSequencePayload) =>
      dispatch(updateScriptsSequenceAction(requestData)),
    setWatchlistToSecuritiesArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setWatchlistToSecuritiesArray(requestData)),
    removeWatchlistIdFromStocks: (requestData: {
      watchListIdToDelete: string
      scriptIdArray: T_SCRIPTS_OBJ[]
    }) => dispatch(removeWatchlistIdFromStocksAction(requestData))
  }
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(CustomWatchListOptions)
)
