import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import { updateStockTickerAction } from '../StockTicker/Actions'
import { updateDpHoldDataInTickerAction } from './Actions'
import { SLICE_NAME } from './Selectors'

export type TDpHoldScript = {
  [key: number | string]: TIndividualDpHoldData
}

export type TIndividualDpHoldData = {
  scriptId: string
  holdingQty: number
  closeprice: number
  dematValue?: number
  assetClass?: string
  onHoldQuantity?: number
  exchangeSymbol?: string
  coCode?: string
  segment: string
  exchange: string
}

type TInstrumentIdentity = {
  isinCode: string
  instrumentIdType: number
  instrumentId: string
}

export type TSecurityLimitData = {
  instrumentIdentity: TInstrumentIdentity
  intradayQuantity: string
  instrumentType: string
  totalQuantity: string
  accountSettlementType: string
  utilizedQuantity: string
  quantityAvailableRelease: string
  collateralQuantity: string
  collateralAmount: string
  dematHeldQuantity: string
  interSettlementQuantity: string
  portfolioQuantity: string
  withheldQuantity: string
}

export type TSecurityLimitFormattedData = {
  instrumentId: string
  totalQuantity: string
  collateralQuantity: string
  collateralAmount: string
  interSettlementQuantity: string
  scriptId: string
  closeprice: string
  segment: string
  exchangeSymbol?: string
  coCode?: string
  exchange: string
}

export interface IDPHOLDTICKER {
  dematValue: number
  scriptsInDpHold: TDpHoldScript
}

const INITIAL_STATE: IDPHOLDTICKER = {
  dematValue: 0,
  scriptsInDpHold: {}
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(updateDpHoldDataInTickerAction, (state, action) => {
      const { payload: dpHoldData } = action
      const scriptsInDpHold: TDpHoldScript = {}
      let initialTotalDematValuation = 0
      dpHoldData.length &&
        dpHoldData.map((individualDpHoldData: TIndividualDpHoldData) => {
          const {
            holdingQty,
            closeprice: closePriceFromSecurityMaster,
            scriptId
          } = individualDpHoldData
          //SECTION - individual holding's calculations
          const initialIndividualDmatValuation =
            holdingQty * closePriceFromSecurityMaster
          initialTotalDematValuation =
            initialTotalDematValuation + initialIndividualDmatValuation
          scriptsInDpHold[scriptId] = {
            ...individualDpHoldData,
            dematValue: initialIndividualDmatValuation
          }
        })
      state.dematValue = initialTotalDematValuation
      state.scriptsInDpHold = scriptsInDpHold
    })
    builder.addCase(updateStockTickerAction, (state, { payload }) => {
      const { LTP, scriptId } = payload
      //SECTION - destructuring total values
      const { dematValue: PREVIOUS_TOTAL_DEMAT_VALUE } = state
      const scriptData = state['scriptsInDpHold'][scriptId] || null
      if (scriptData) {
        //SECTION - destructuring individual values
        const {
          holdingQty,
          closeprice: closePriceFromSecurityMaster,
          dematValue: previousIndividualDematValue = 0
        } = scriptData

        //SECTION - new values definitions
        let latestIndividualDematValue = 0
        let differenceInIndividualDematValue = 0
        let totalDematValue = 0

        //SECTION - individual dp hold's calculations
        latestIndividualDematValue =
          holdingQty * (LTP || closePriceFromSecurityMaster)

        //SECTION - individual dp hold differences calculations
        differenceInIndividualDematValue =
          latestIndividualDematValue - previousIndividualDematValue

        //SECTION - total dp hold's calculations
        totalDematValue =
          PREVIOUS_TOTAL_DEMAT_VALUE + differenceInIndividualDematValue

        //SECTION - setting new data in dp hold summary
        state.dematValue = totalDematValue

        //SECTION - setting new data in individual dp hold
        state['scriptsInDpHold'][scriptId] = {
          ...state['scriptsInDpHold'][scriptId],
          LTP,
          dematValue: latestIndividualDematValue
        }
      }
    })
  }
}

const dpHoldTickerSlice = createSlice(sliceOptions)
export default dpHoldTickerSlice.reducer
