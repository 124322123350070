// NOTE - CLEANUP: @pratik
// 1. DsPopup - sx is not props in DsPopup

import React, { Component } from 'react'
// Lib
// Ds Components
import {
  DsBox,
  DsCheckbox,
  DsImage,
  DsLink,
  DsPopup,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'

//Component
import EmarginTNC from './EmarginTNC'

import { setCustomerEmargin } from '~/src/Redux/Customer/Reducer'
import setEmarginAction from '~/src/Redux/Customer/Services/setEmargin.Service'

// Constants
import { BNPL } from '~/src/Constants/ASSET_MAP'
import { EMARGIN_LABEL } from '~/src/Constants/LABEL'
import { TAppDispatch } from '~/src/Configurations/AppStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'

type ActionTypes = {
  setEMargin: () => Promise<any>
  setCustomerEmargin: (requestedData: boolean) => Promise<any>
}

export interface IEmarginModalProps extends IWithRouterProps {
  actions: ActionTypes
  showActivateBNPLModal: boolean
  setShowActivateBNPLModal: any
  handleError: (res: any) => void
}

class EmarginModal extends Component<IEmarginModalProps> {
  state = { checked: false, bnplTNC: false }

  setBnplTNC = (value: boolean) => {
    this.setState({ bnplTNC: value })
  }

  handleActivateBNPL = async () => {
    const { actions, handleError } = this.props
    const setEmarginResponse = await actions.setEMargin()
    this.handleCloseActivateBNPLModal()
    if (setEmarginResponse._isCustomError) {
      console.log('failed API - setEMargin - funds/e-margin/set-emargin')
      handleError(setEmarginResponse)
      return
    }
    actions.setCustomerEmargin(true)
  }

  handleCloseActivateBNPLModal = () => {
    const { setShowActivateBNPLModal } = this.props
    this.setState({ checked: false })
    setShowActivateBNPLModal()
  }

  render() {
    const { showActivateBNPLModal, setShowActivateBNPLModal } = this.props
    const { checked, bnplTNC } = this.state

    return (
      <>
        {/* Desktop Header  */}
        <DsPopup
          primaryButtonText='Activate BMPL'
          secondaryButtonText='Cancel'
          showClose={true}
          primaryButtonProps={{
            onClick: this.handleActivateBNPL,
            disabled: !checked
          }}
          secondaryButtonProps={{
            onClick: this.handleCloseActivateBNPLModal
          }}
          open={showActivateBNPLModal}
          onClose={this.handleCloseActivateBNPLModal}
          DsDialogProps={{
            sx: {
              '.MuiDialogActions-root': { marginTop: 'var(--ds-spacing-zero)' },
              '.MuiDialogContent-root': { marginTop: 'var(--ds-spacing-zero)' },
              display: { md: 'block', xs: 'none' }
            }
          }}
          // DsDialogProps={{
          //   open: showActivateBNPLModal,
          //   onClose: this.handleCloseActivateBNPLModal,
          //   sx: {
          //     '.MuiDialogActions-root': { marginTop: 'var(--ds-spacing-zero)' },
          //     '.MuiDialogContent-root': { marginTop: 'var(--ds-spacing-zero)' },
          //     display: { md: 'block', xs: 'none' }
          //   }
          // }}
        >
          <DsStack sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <DsTypography variant='headingBoldLarge'>
              {EMARGIN_LABEL.EMARGIN_MODAL.HEADER}
            </DsTypography>
            <DsTypography
              variant='bodyRegularMedium'
              color={'text.secondary'}
              sx={{
                mt: 'var(--ds-spacing-glacial)',
                mb: 'var(--ds-spacing-bitterCold)'
              }}
            >
              {EMARGIN_LABEL.EMARGIN_MODAL.DESCRIPTION}
            </DsTypography>
          </DsStack>
          <DsStack
            sx={{
              p: 'var(--ds-spacing-bitterCold)',
              border: '1px solid var(--ds-colour-strokeDefault)',
              borderRadius: 'var(--ds-radius-quickFreeze)',
              flexDirection: 'row',
              width: '100%',
              mb: 'var(--ds-spacing-mild)'
            }}
          >
            <DsBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 'var(--ds-spacing-frostbite)',
                alignItems: 'flex-start',
                flex: 1
              }}
            >
              <DsStack
                sx={{
                  borderRadius: 'var(--ds-radius-quickFreeze)',
                  border: '1px solid var(--ds-colour-strokeDefault)',
                  p: 'var(--ds-spacing-glacial)',
                  mr: 'var(--ds-spacing-frostbite)',
                  backgroundColor: 'var(--ds-colour-neutral1)'
                }}
              >
                <DsRemixIcon className='ri-link-m' fontSize='bitterCold' />
              </DsStack>
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--ds-spacing-quickFreeze)'
                }}
              >
                <DsTypography variant='bodyBoldMedium'>
                  {EMARGIN_LABEL.EMARGIN_MODAL.FEATURE_1}
                </DsTypography>
                <DsTypography
                  variant='supportRegularInfo'
                  color='var(--ds-colour-typoSecondary)'
                >
                  {EMARGIN_LABEL.EMARGIN_MODAL.FEATURE_1_DESCRIPTION}
                </DsTypography>
              </DsBox>
            </DsBox>
            <DsBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 'var(--ds-spacing-frostbite)',
                alignItems: 'flex-start',
                flex: 1
              }}
            >
              <DsStack
                sx={{
                  borderRadius: 'var(--ds-radius-quickFreeze)',
                  border: '1px solid var(--ds-colour-strokeDefault)',
                  p: 'var(--ds-spacing-glacial)',
                  mr: 'var(--ds-spacing-frostbite)',
                  backgroundColor: 'var(--ds-colour-neutral1)'
                }}
              >
                <DsRemixIcon
                  className='ri-smartphone-line'
                  fontSize='bitterCold'
                />
              </DsStack>
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--ds-spacing-quickFreeze)'
                }}
              >
                <DsTypography variant='bodyBoldMedium'>
                  {EMARGIN_LABEL.EMARGIN_MODAL.FEATURE_2}
                </DsTypography>
                <DsTypography
                  variant='supportRegularInfo'
                  color='var(--ds-colour-typoSecondary)'
                >
                  {EMARGIN_LABEL.EMARGIN_MODAL.FEATURE_2_DESCRIPTION}
                </DsTypography>
              </DsBox>
            </DsBox>
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              gap: 'var(--ds-spacing-glacial)',
              alignItems: 'center',
              justifyContent: 'flex-start',
              mb: 'var(--ds-spacing-frostbite)'
            }}
          >
            <DsCheckbox
              checked={checked}
              onChange={() => this.setState({ checked: !checked })}
            />
            <DsTypography variant='bodyRegularSmall'>
              {EMARGIN_LABEL.EMARGIN_MODAL.ACCEPTANCE}{' '}
              <DsLink>{EMARGIN_LABEL.EMARGIN_MODAL.TNC}</DsLink>{' '}
            </DsTypography>
          </DsStack>
        </DsPopup>
        {/* Mobile Header  */}
        <DsPopup
          primaryButtonText='Activate BMPL'
          primaryButtonProps={{
            onClick: this.handleActivateBNPL,
            disabled: !checked
          }}
          title={EMARGIN_LABEL.EMARGIN_MODAL.HEADER}
          open={showActivateBNPLModal}
          onClose={this.handleCloseActivateBNPLModal}
          DsBottomSheetProps={{
            sx: {
              display: { md: 'none', xs: 'block' },
              '.MuiDialogActions-root': { marginTop: 'var(--ds-spacing-zero)' },
              '.MuiDialogContent-root': { marginTop: 'var(--ds-spacing-zero)' }
            }
          }}
          // DsDialogProps={{
          //   open: showActivateBNPLModal,
          //   onClose: this.handleCloseActivateBNPLModal,
          //   sx: {
          //     display: { md: 'none', xs: 'block' },
          //     '.MuiDialogActions-root': { marginTop: 'var(--ds-spacing-zero)' },
          //     '.MuiDialogContent-root': { marginTop: 'var(--ds-spacing-zero)' }
          //   }
          // }}
        >
          <DsTypography
            variant='bodyRegularSmall'
            color={'text.secondary'}
            sx={{
              mt: 'var(--ds-spacing-glacial)',
              mb: 'var(--ds-spacing-bitterCold)'
            }}
          >
            {EMARGIN_LABEL.EMARGIN_MODAL.MOBILE_DESCRIPTION}
          </DsTypography>
          <DsBox>
            <DsImage
              srcSet={BNPL}
              style={{
                width: '100%',
                marginBottom: 'var(--ds-spacing-mild)'
              }}
            />
          </DsBox>
          <DsStack
            sx={{
              flexDirection: 'column',
              width: '100%',
              mb: 'var(--ds-spacing-mild)',
              gap: 'var(--ds-spacing-frostbite)'
            }}
          >
            <DsTypography variant='bodyBoldMedium'>
              {EMARGIN_LABEL.EMARGIN_MODAL.BUY_STOCKS}
            </DsTypography>
            <DsBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 'var(--ds-spacing-glacial)',
                alignItems: 'center',
                flex: 1
              }}
            >
              <DsStack
                sx={{
                  borderRadius: 'var(--ds-radius-quickFreeze)',
                  border: '1px solid var(--ds-colour-strokeDefault)',
                  p: 'var(--ds-spacing-glacial)',
                  mr: 'var(--ds-spacing-frostbite)',
                  backgroundColor: 'var(--ds-colour-neutral1)'
                }}
              >
                <DsRemixIcon
                  className='ri-percent-line'
                  fontSize='bitterCold'
                />
              </DsStack>

              <DsTypography
                variant='bodyRegularMedium'
                color={'text.secondary'}
              >
                {EMARGIN_LABEL.EMARGIN_MODAL.FEATURE_1_DESCRIPTION}
              </DsTypography>
            </DsBox>
            <DsBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 'var(--ds-spacing-glacial)',
                alignItems: 'center',
                flex: 1
              }}
            >
              <DsStack
                sx={{
                  borderRadius: 'var(--ds-radius-quickFreeze)',
                  border: '1px solid var(--ds-colour-strokeDefault)',
                  p: 'var(--ds-spacing-glacial)',
                  mr: 'var(--ds-spacing-frostbite)',
                  backgroundColor: 'var(--ds-colour-neutral1)'
                }}
              >
                <DsRemixIcon
                  className='ri-calendar-line'
                  fontSize='bitterCold'
                />
              </DsStack>

              <DsTypography
                variant='bodyRegularMedium'
                color={'text.secondary'}
              >
                {EMARGIN_LABEL.EMARGIN_MODAL.FEATURE_2_DESCRIPTION}
              </DsTypography>
            </DsBox>
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              gap: 'var(--ds-spacing-glacial)',
              alignItems: 'center',
              justifyContent: 'flex-start',
              mb: 'var(--ds-spacing-frostbite)'
            }}
          >
            <DsCheckbox
              sx={{
                padding: 'var(--ds-spacing-zero)'
              }}
              onChange={() => this.setState({ checked: !checked })}
            />
            <DsTypography variant='bodyRegularSmall'>
              {EMARGIN_LABEL.EMARGIN_MODAL.ACCEPTANCE}{' '}
              <DsLink onClick={() => this.setState({ bnplTNC: true })}>
                {EMARGIN_LABEL.EMARGIN_MODAL.TNC}
              </DsLink>
            </DsTypography>
          </DsStack>
        </DsPopup>
        {/* Terms and Conditions  */}
        {bnplTNC && (
          <EmarginTNC
            showTNCLModal={bnplTNC}
            setShowTNCLModal={this.setBnplTNC}
            setActivateModal={setShowActivateBNPLModal}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = () => {}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setEMargin: () => dispatch(setEmarginAction()),
    setCustomerEmargin: (requestedData: boolean) =>
      dispatch(setCustomerEmargin(requestedData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(EmarginModal)
)
