export type T_GET_HOLDING_DETAILS_PAYLOAD = {
  segment: string
  pledgeDetailsRequired?: boolean
  subAccountId?: string
}

interface RecommendationCalls {
  isin: string
  calls: string[]
}
export type IRecommendationCalls = RecommendationCalls[]
export type T_PLEDGE_DATA_ALLOWED = object // TODO - PRIORITY: define valid type

export type T_HOLDING_SUMMARY_SCRIPTS_IN_HOLDING = {
  holdingQty: string
  investedValue: number
  scriptId: string
  symbol: string
  avgPrice: number
  isinCode: string
  haircutPct: number
  collateralValue: number
  calls: string[]
}

export type T_HOLDING_SUMMARY_INDEX_INDIVIDUAL_HOLDING_OBJECT = {
  holdings: string
  avgPrice: number
  investedValue: string
  unrealizedGain: string
  dmatfreeQty?: number
  emarginNetQuantity?: number
  collateralQty?: number
}

export type T_HOLDING_SUMMARY_INDEX = {
  [key: string | number]: T_HOLDING_SUMMARY_INDEX_INDIVIDUAL_HOLDING_OBJECT
} // TODO - PRIORITY: define valid type

export interface T_HOLDING_DETAILS_SCRIPTS_IN_HOLDING {
  id: string
  holdingQty: string
  investedValue: number
  scriptId: string
  symbol: string
  avgPrice: number
  exchange: string
  isinCode: string
  realizedGain: string
  interSettlementQty: string
  freeQty: number
  collateralQty: number
  emarginNetQuantity: number
  unrealizedGain: string
  dmatfreeQty: number
  haircutPct: number
  collateralValue: number
  totalFreeQty: number
  initialDmatQty: number
  calls: string[]
  segment: string
  closeprice: number
  assetClass: string
  closePriceFromTicker: number
  LTP: number
  NET_CHANGE: number
  PERCENT_CHANGE: number
}

export type T_SECURITY_INFO = {
  scriptId: string
  exchange: string
  symbol: string
  haircutPct: number
  collateralAllowed: string
  currentLtp: number
}

export type T_HOLDING_DETAILS_HOLDINGS_OBJECT = {
  isinCode: string
  securityInfo: T_SECURITY_INFO[]
  totalFreeQty: number
  dpFreeQty: number
  poolFreeQty: number
  t1Qty: number
  avgPrice: number
  ltp: number
  pnl: number
  currentValue: number
  investedValue: number
  product: string
  collateralQty: number
  collateralValue: number
  closePrice: number
  holdingQty: string
  unrealizedGain: string
  realizedGain: string
  realizedGainPercentage: string
  interSettlementQty: string
  emarginNetQuantity: number
  dematHeldQty: number
  pledge: number
  emargin: number
  dmatfreeQty: number
  maxCollateral?: number
}

export type T_HOLDINGS_FILTER = {
  stocksFilter: string[]
  sortBy: string
}

export type T_PLEDGE_DETAILS = object // TODO - PRIORITY: define valid type
export type T_PLEDGE_DATA = object // TODO - PRIORITY: define valid type
export interface T_PLEDGE_FILTER extends T_HOLDINGS_FILTER {
  stocksFilter: string[]
} // TODO - PRIORITY: define valid type

export type T_TRADING_HISTORY = {
  transactionType: 'BUY' | 'SELL'
} // TODO - PRIORITY: define valid type
export type T_RECOMMENDATION_DATA_BY_SCRIPT_ID = object // TODO - PRIORITY: define valid type

export type T_HOLDING_DETAILS_SCRIPT_INDEX = {
  [key: string]: T_HOLDING_DETAILS_SCRIPTS_IN_HOLDING
}

export type T_DASHBOARD_REDUCER = {
  // Holding Summary Redux values
  HOLDING_SUMMARY_SUMMED_INVESTED_VALUE: number
  HOLDING_SUMMARY_SCRIPTS_IN_HOLDING: T_HOLDING_SUMMARY_SCRIPTS_IN_HOLDING[]
  HOLDING_SUMMARY_INDEX: T_HOLDING_SUMMARY_INDEX | null

  // Holding Details Redux values
  holdingDetails: T_HOLDING_DETAILS_HOLDINGS_OBJECT[]
  holdingDetailsScriptIndex: T_HOLDING_DETAILS_SCRIPT_INDEX
  SCRIPTS_IN_HOLDING: T_HOLDING_DETAILS_SCRIPTS_IN_HOLDING[]
  SUMMED_INVESTED_VALUE: number
  holdingsFilter: T_HOLDINGS_FILTER
  isDefaultFiltersEnabled: boolean
  scriptsArray: string | number[] //TODO - define a proper type for scriptId here
  selectedFilterTabInHolding: 'All' | 'Stocks' | 'ETF'
  holdingQuantitiesForPrimaryPortfolio: T_HOLDING_DETAILS_SCRIPT_INDEX

  // Pledge Details Redux values
  pledgeDetails: T_PLEDGE_DETAILS[]
  pledgeData: T_PLEDGE_DATA[]
  pledgeFilter: T_PLEDGE_FILTER
  pledgeDataNotAllowed: T_PLEDGE_DATA_ALLOWED[]
  isPledgeDefaultFiltersEnabled: boolean

  tradingHistory: T_TRADING_HISTORY[]
  recommendationDataByScripId: T_RECOMMENDATION_DATA_BY_SCRIPT_ID[]
}

export const INITIAL_STATE: T_DASHBOARD_REDUCER = {
  // Holding Summary Redux values
  HOLDING_SUMMARY_SUMMED_INVESTED_VALUE: 0,
  HOLDING_SUMMARY_SCRIPTS_IN_HOLDING: [],
  HOLDING_SUMMARY_INDEX: {},

  // Holding Details Redux values
  SUMMED_INVESTED_VALUE: 0,
  SCRIPTS_IN_HOLDING: [],
  holdingDetails: [],
  holdingsFilter: {
    stocksFilter: [],
    sortBy: 'INVESTED_HIGH_TO_LOW'
  },
  isDefaultFiltersEnabled: true,
  scriptsArray: [],
  selectedFilterTabInHolding: 'All',
  holdingDetailsScriptIndex: {},
  holdingQuantitiesForPrimaryPortfolio: {},

  // Pledge Details Redux values
  pledgeFilter: {
    stocksFilter: [],
    sortBy: 'HAIRCUT_HIGH_TO_LOW'
  },
  pledgeDetails: [],
  pledgeData: [],
  isPledgeDefaultFiltersEnabled: true,
  pledgeDataNotAllowed: [],

  // Others
  tradingHistory: [],
  recommendationDataByScripId: []
}
