import { createSelector } from '@reduxjs/toolkit'
import { TTickerStore } from '~/src/Configurations/TickerStore'
export const SLICE_NAME = 'positionTicker'

const selectTicker = (state: TTickerStore) => state[SLICE_NAME]
const selectIndividualOpenPosition = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME].scriptsInOpenPosition[scriptId]
const selectIndividualTodayPosition = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME].scriptsInTodayPosition[scriptId]

export const getOpenPositionsSummaryValues = createSelector(
  selectTicker,
  positionTicker => {
    const { totalPnl, unRealisedPnl, realisedPnl, totalInvestedValue } =
      positionTicker
    return {
      totalPnl,
      unRealisedPnl,
      realisedPnl,
      totalInvestedValue
    }
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getOpenPositionsTotalRealisedPnl = createSelector(
  selectTicker,
  positionTicker => {
    const { realisedPnl } = positionTicker || {}
    const { value = 0, percentChange = 0 } = realisedPnl || {}
    return { value, percentChange }
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getOpenPositionsTotalUnRealisedPnl = createSelector(
  selectTicker,
  positionTicker => {
    const { unRealisedPnl } = positionTicker || {}
    const { value = 0, percentChange = 0 } = unRealisedPnl || {}
    return { value, percentChange }
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getOpenPositionsTotalPnl = createSelector(
  selectTicker,
  positionTicker => {
    const { totalPnl } = positionTicker || {}
    const { value = 0, percentChange = 0 } = totalPnl || {}
    return { value, percentChange }
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getIndividualOpenPositionPnlValue = createSelector(
  selectIndividualOpenPosition,
  individualOpenPositionTicker => {
    const { unRealisedPnl } = individualOpenPositionTicker || {}
    const { value = 0 } = unRealisedPnl || {}
    return value
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getIndividualOpenPositionPnlPercentage = createSelector(
  selectIndividualOpenPosition,
  individualOpenPositionTicker => {
    const { unRealisedPnl } = individualOpenPositionTicker || {}
    const { percentChange = 0 } = unRealisedPnl || {}
    return percentChange
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getIndividualTodayPositionPnlValue = createSelector(
  selectIndividualTodayPosition,
  individualTodayPositionTicker => {
    const { unRealisedPnl } = individualTodayPositionTicker || {}
    const { value = 0 } = unRealisedPnl || {}
    return value
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getIndividualTodayPositionPnlPercentage = createSelector(
  selectIndividualTodayPosition,
  individualTodayPositionTicker => {
    const { unRealisedPnl } = individualTodayPositionTicker || {}
    const { percentChange = 0 } = unRealisedPnl || {}
    return percentChange
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)
