import React from 'react'

// actions
import { appContext, TAppStore } from '~/src/Configurations/AppStore'
import { manageWatchlistIndicator } from '~/src/Redux/WatchList/Selectors'
import WatchlistHeader from '../../Watchlist/WatchlistHeader'
import ManageWatchlistHeader from '../../ManageWatchlist/ManageWatchlistHeader'
import { IwithBreakpoints, withBreakpoints } from '@am92/react-design-system'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import { connect } from 'react-redux'

export interface ISidebarHeaderState {}

export interface ISidebarHeaderProps extends IwithBreakpoints {
  isManageWatchlistClicked: boolean
}

class SidebarHeader extends React.PureComponent<ISidebarHeaderProps> {
  render() {
    const { isManageWatchlistClicked, breakpoints } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const showWatchlistHeader = !isDesktop || !isManageWatchlistClicked

    return (
      <>
        {(showWatchlistHeader && <WatchlistHeader />) || null}
        {(isManageWatchlistClicked && <ManageWatchlistHeader />) || null}
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const isManageWatchlistClicked = manageWatchlistIndicator(state)
  return {
    isManageWatchlistClicked
  }
}

export default withBreakpoints(
  connect(mapStateToProps, null, null, {
    context: appContext
  })(SidebarHeader)
)
