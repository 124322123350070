import React from 'react'
import { connect } from 'react-redux'

// components

// context
import { appContext } from '~/src/Configurations/AppStore'
import {
  DsBox,
  DsBoxProps,
  DsRemixIconProps,
  DsStackProps,
  DsTypographyProps
} from '@am92/react-design-system'
import StockTickerWithLTP from './StockTickerWithLTP'
import StockTickerWithNetPercentChange from './StockTickerWithNetPercentChange'

interface IStockTickerNew {
  scriptId: string
  closeprice: number
  segment: string
  showLTP?: boolean
  showNetChange?: boolean
  showPercentChange?: boolean
  showChangeIcon?: boolean
  withCurrencySymbol?: boolean
  WrapperProps?: DsBoxProps
  LtpTypoProps?: DsTypographyProps
  NetChangeWrapperProps?: DsStackProps
  NetChangeTypoProps?: DsTypographyProps
  PercentChangeTypoProps?: DsTypographyProps
  NetChangeIconProps?: DsRemixIconProps
  netChangeNumberFormatOptions?: Intl.NumberFormatOptions
  percentChangeNumberFormatOptions?: Intl.NumberFormatOptions
}

class StockTickerNew extends React.PureComponent<IStockTickerNew, any> {
  renderData = () => {
    const {
      scriptId,
      closeprice,
      segment,
      showNetChange = true,
      showPercentChange = true,
      showLTP = true,
      showChangeIcon = true,
      withCurrencySymbol,
      LtpTypoProps,
      NetChangeWrapperProps,
      NetChangeTypoProps,
      PercentChangeTypoProps,
      NetChangeIconProps,
      netChangeNumberFormatOptions,
      percentChangeNumberFormatOptions
    }: any = this.props

    const jsx = []

    if (showLTP) {
      jsx.push(
        <StockTickerWithLTP
          closeprice={closeprice}
          segment={segment}
          scriptId={scriptId}
          withCurrencySymbol={withCurrencySymbol}
          LtpTypoProps={LtpTypoProps}
        />
      )
    }

    if (showNetChange || showPercentChange) {
      jsx.push(
        <StockTickerWithNetPercentChange
          scriptId={scriptId}
          closeprice={closeprice}
          segment={segment}
          showPercentChange={showPercentChange}
          showNetChange={showNetChange}
          showChangeIcon={showChangeIcon}
          NetChangeWrapperProps={NetChangeWrapperProps}
          NetChangeTypoProps={NetChangeTypoProps}
          PercentChangeTypoProps={PercentChangeTypoProps}
          NetChangeIconProps={NetChangeIconProps}
          netChangeNumberFormatOptions={netChangeNumberFormatOptions}
          percentChangeNumberFormatOptions={percentChangeNumberFormatOptions}
        />
      )
    }

    return jsx
  }

  render() {
    const { WrapperProps } = this.props
    return (
      <DsBox {...WrapperProps} sx={{ ...WrapperProps?.sx }}>
        {this.renderData()}
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})(StockTickerNew)
