import React, { Component } from 'react'

// lib
import { DsStack } from '@am92/react-design-system'
import OrderHeaderTop from './OrderHeaderTop'
import OrderHeaderBottom from './OrderHeaderBottom'
import OrderHeaderMiddle from './OrderHeaderMiddle'

export interface IOrderHeaderProps {}

class OrderHeader extends Component<IOrderHeaderProps> {
  render() {
    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            zIndex: 2,
            padding: {
              xs: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-frostbite) var(--ds-spacing-mild)'
            }
          }}
          spacing={4}
        >
          <OrderHeaderTop />
          <OrderHeaderMiddle />
          <OrderHeaderBottom />
        </DsStack>
      </>
    )
  }
}

export default OrderHeader
