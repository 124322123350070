import React, { Component } from 'react'
import { DsDrawer } from '@am92/react-design-system'
import AlertsForm from './AlertsForm'
import {
  TAppStore,
  TAppDispatch,
  appContext
} from '~/src/Configurations/AppStore'
import { getAlertsFormToggleSelector } from '~/src/Redux/Alerts/Selectors'
import { connect } from 'react-redux'

interface IAlertsFormModalProps {
  alertFormToggle: boolean
}

export class AlertsFormModal extends Component<IAlertsFormModalProps> {
  render() {
    const { alertFormToggle } = this.props
    return (
      <DsDrawer
        PaperProps={{
          sx: {
            top: {
              md: '5.3rem',
              lg: '5.4rem',
              xs: '0%'
            },
            height: {
              md: 'calc(100% - 4.2rem)',
              lg: 'calc(100% - 5.4rem)',
              xs: '100%'
            },
            width: { md: 'auto', sm: '100%' },
            boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)'
          }
        }}
        keepMounted={false}
        sx={theme => ({ position: 'absolute', zIndex: theme.zIndex.drawer })}
        anchor='right'
        open={alertFormToggle}
        variant='persistent'
        hideBackdrop
      >
        <AlertsForm />
      </DsDrawer>
    )
  }
}
const mapStateToProps = (state: TAppStore) => {
  const alertFormToggle = getAlertsFormToggleSelector(state)
  return {
    alertFormToggle
  }
}

export default connect(mapStateToProps, null, null, {
  context: appContext
})(AlertsFormModal)
