// DONE - CLEANUP: @vasi
// 1. define types
// 2. use single selected if requiredimport React, { Component } from 'react'
import React, { Component } from 'react'
import {
  DsBox,
  DsButtonBase,
  DsDivider,
  DsRemixIcon,
  DsStack,
  DsTextField,
  enqueueNotistack
} from '@am92/react-design-system'

import NumberFormatter, {
  NumberFormatterWithNoDecimal
} from '../../NumberFormatter'
import SipScriptsDetailsLeft, { IItem } from './SipScriptsDetailsLeft'

import { T_SIP_INFO, TSelectedStock } from '~/src/Redux/Orders/TYPES'
import { ITicker } from '~/src/Redux/StockTicker/Reducer'

import withErrorConnect from '~/src/Lib/withErrorConnect'

export interface ISipScriptsDetailsProps {
  isModifing: boolean
  item: TSelectedStock
  sipInfo: T_SIP_INFO
  selectedSipScripts: TSelectedStock[]
  updateSelectedScriptsData: (item: any) => any
  stockPriceDetails: ITicker
}

export class SipScriptsDetails extends Component<ISipScriptsDetailsProps> {
  state = {
    value: 0
  }

  componentDidMount() {
    const { item, sipInfo } = this.props
    const { basedOn } = sipInfo
    const formattedValue = item?.[basedOn.toLowerCase() as keyof TSelectedStock]
    this.setState({ value: formattedValue })
  }
  handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target
    this.setState({ value })
  }

  handleDelete = async (item: IItem) => {
    const { selectedSipScripts, sipInfo, updateSelectedScriptsData } =
      this.props
    const { scriptId, coname } = item

    const updatedSipScripts = selectedSipScripts.filter(
      script => script.scriptId !== scriptId
    )

    const updatedSipInfo = {
      ...sipInfo,
      selectedScripts: updatedSipScripts
    }

    await updateSelectedScriptsData(updatedSipInfo)

    enqueueNotistack({
      message: `${coname} removed`
    })
  }

  render() {
    const { item, sipInfo, isModifing, selectedSipScripts } = this.props
    const { value } = this.state

    const { basedOn } = sipInfo
    const inputVal = item?.[basedOn?.toLowerCase() as keyof TSelectedStock]
    const formattedValue =
      inputVal && (basedOn === 'Quantity' || basedOn === 'quantity')
        ? Math.round(Number(inputVal))
        : inputVal
    const formattedInputComponent =
      basedOn === 'Quantity' || basedOn === 'quantity'
        ? NumberFormatterWithNoDecimal
        : NumberFormatter
    return (
      <DsBox>
        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SipScriptsDetailsLeft
            item={item}
            sipInfo={sipInfo}
            value={value}
            isModifing={isModifing}
            selectedSipScripts={selectedSipScripts}
          />
          <DsStack gap={'var(--ds-spacing-glacial)'} alignItems={'flex-end'}>
            <DsBox sx={{ display: 'flex', flexDirection: 'row' }}>
              <DsTextField
                name={item.scriptId}
                placeholder='10000'
                value={formattedValue || ''}
                inputComponent={formattedInputComponent}
                sx={{ flex: 1 }}
                onChange={e => this.handleChange(e)}
              />
            </DsBox>

            <DsButtonBase>
              <DsRemixIcon
                className='ri-delete-bin-line'
                onClick={() => this.handleDelete(item)}
              />
            </DsButtonBase>
          </DsStack>
        </DsBox>
        <DsDivider
          sx={{
            margin: 'var(--ds-spacing-bitterCold) var(--ds-spacing-zero)'
          }}
        />
      </DsBox>
    )
  }
}

const mapStateToProps = () => {}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(SipScriptsDetails)
