import React, { forwardRef, PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  closeNotistack,
  DsBox,
  DsButton,
  DsDivider,
  DsNotistackKey,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { appContext } from '~/src/Configurations/AppStore'

import { IWithRouterProps } from '~/src/Lib/withRouter'
import withRouterForwardRef from '~/src/Lib/withRouterForwardRef'

interface IReverseFeedAlertCardProps extends IWithRouterProps {
  innerRef: React.ForwardedRef<HTMLDivElement>
  id: DsNotistackKey
  message: string
}

class ReverseFeedAlertCard extends PureComponent<IReverseFeedAlertCardProps> {
  handleCloseAlert = () => {
    const { id } = this.props
    closeNotistack(id)
  }

  handleNavigation = (path: string) => {
    const { navigate } = this.props
    navigate(path)
    this.handleCloseAlert()
  }

  render() {
    const { innerRef, message } = this.props
    const reverseFeedMessage = JSON.parse(message)
    const {
      alertColor,
      alertIcon,
      alertIconColor,
      alertHeading,
      alertDescription,
      alertNavigationButtonTypo,
      alertNavigationPath
    } = reverseFeedMessage

    return (
      <>
        <DsStack
          ref={innerRef}
          sx={{
            padding:
              'calc(var(--ds-spacing-quickFreeze) + 1px) var(--ds-spacing-bitterCold) calc(var(--ds-spacing-quickFreeze) + 1px) var(--ds-spacing-quickFreeze)',
            gap: 'var(--ds-spacing-frostbite)',
            flexDirection: 'row',
            background: 'var(--ds-colour-surfacePrimary)',
            borderRadius: 'var(--ds-spacing-glacial)',
            boxShadow: '0px 9px 14px 0px #00000014',
            minWidth: {
              md: '350px',
              xs: '250px'
            }
          }}
        >
          <DsBox
            sx={{
              display: 'flex'
            }}
          >
            <DsDivider
              orientation='vertical'
              sx={{
                borderWidth: 'calc(var(--ds-spacing-quickFreeze) - 1px)',
                borderRadius: 'var(--ds-spacing-quickFreeze)',
                borderColor: alertColor
              }}
            />
          </DsBox>
          <DsStack
            sx={{
              flexDirection: 'row',
              gap: 'var(--ds-spacing-frostbite)',
              padding:
                'calc(var(--ds-spacing-glacial) + var(--ds-spacing-deepFreeze)) var(--ds-spacing-zero) calc(var(--ds-spacing-glacial) + var(--ds-spacing-deepFreeze)) var(--ds-spacing-zero)',
              flexGrow: '1'
            }}
          >
            <DsRemixIcon className={`ri-${alertIcon}`} color={alertIconColor} />
            <DsStack
              sx={{
                flexDirection: 'row',
                gap: 'var(--ds-spacing-bitterCold)',
                flexGrow: '1',
                justifyContent: 'space-between'
              }}
            >
              <DsStack
                sx={{
                  flexDirection: 'column',
                  gap: 'var(--ds-spacing-quickFreeze)'
                }}
              >
                <DsTypography variant='bodyBoldLarge' color={alertColor}>
                  {alertHeading}
                </DsTypography>
                <DsStack
                  sx={{
                    flexDirection: 'column',
                    gap: 'var(--ds-spacing-frostbite)'
                  }}
                >
                  <DsTypography
                    variant='bodyRegularSmall'
                    color='var(--ds-colour-typoSecondary)'
                    sx={{
                      maxWidth: { md: '350px', xs: '220px' },
                      overflowWrap: 'break-word'
                    }}
                  >
                    {alertDescription}
                  </DsTypography>
                  <DsButton
                    variant='text'
                    color='secondary'
                    sx={{
                      justifyContent: 'unset !important',
                      padding:
                        'var(--ds-spacing-glacial) var(--ds-spacing-quickFreeze) var(--ds-spacing-glacial) var(--ds-spacing-zero) !important'
                    }}
                    onClick={() => this.handleNavigation(alertNavigationPath)}
                  >
                    {alertNavigationButtonTypo}
                  </DsButton>
                </DsStack>
              </DsStack>
              <DsStack>
                <DsRemixIcon
                  onClick={this.handleCloseAlert}
                  className='ri-close-fill'
                  fontSize='bitterCold'
                  sx={{
                    padding: 'var(--ds-spacing-quickFreeze)',
                    borderRadius: 'var(--ds-spacing-mild)',
                    background: 'var(--ds-colour-surfaceSecondary)',
                    cursor: 'pointer'
                  }}
                />
              </DsStack>
            </DsStack>
          </DsStack>
        </DsStack>
      </>
    )
  }
}

const ReverseAlertForwardingRef = forwardRef<HTMLDivElement, any>(
  (props, ref) => <ReverseFeedAlertCard {...props} innerRef={ref} />
)

export default connect(null, null, null, {
  forwardRef: true,
  context: appContext
})(withRouterForwardRef(ReverseAlertForwardingRef))
