import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import {
  createWatchlistActions,
  deleteWatchlistActions,
  fetchWatchlistsActions,
  renameWatchlistActions
} from './Actions'
import { _keyBy } from '~/src/Utils/lodash'

export const preKeptNameEntries = [
  'Watchlist 1',
  'Watchlist 2',
  'Watchlist 3',
  'Watchlist 4',
  'Watchlist 5',
  'Watchlist 6',
  'Watchlist 7',
  'Watchlist 8',
  'Watchlist 9',
  'Watchlist 10'
]

export type T_SCRIPTS_OBJ = {
  scriptId: string
  sequenceNumber: number
}

export interface watchlistObj {
  watchlistId: string
  subAccountId: string
  watchlistName: string
  watchlistType: string
  watchlistSequenceNumber: number
  watchlistSecurities: T_SCRIPTS_OBJ[] | null
}

export interface WatchlistStore {
  selectedWatchlist: watchlistObj | null
  watchlistTab: number
  watchlist: watchlistObj[]
  subscribe: boolean
  watchListIndex: {
    [key: string]: number
  }
  watchlistSequenceNumberIndexing: {
    [key: string]: watchlistObj
  }
  watchListToSecuritiesArrayMapping: {
    [key: string]: T_SCRIPTS_OBJ[]
  }
  addScriptToParticularWatchlist: boolean
  sortScriptSelectedOption: {
    [key: string]: number
  }
  watchlistPreKeptAvailableNames: {
    [key: string]: number
  }
  bookMarkScriptData: {
    scriptId: string | number
    stockAvailableInWatchListArray: string[]
    tempAddScriptToParticularWatchlist: boolean
  }
  isManageWatchlistClicked: boolean
  scrollingScripts: { scriptId: string }[]
  preDefinedWatchlistCount: number
  isNewWatchlistCreated: boolean
  //NOTE - this indicator is introduced to trigger handleScrollToExtremeRight function from container tabs when new watchlist is created
}

const INITIAL_STATE: WatchlistStore = {
  selectedWatchlist: {} as watchlistObj,
  watchlistTab: 0,
  subscribe: true,
  watchlist: [],
  watchListIndex: {}, //NOTE - watchlistId <=> watchlistSequenceNumber
  watchlistSequenceNumberIndexing: {}, //NOTE - watchlistSequenceNumber <=> Entire WatchList
  watchListToSecuritiesArrayMapping: {}, //NOTE - watchlistId <=> securitiesArray
  sortScriptSelectedOption: {}, //NOTE - watchlistId <=> selected option number
  addScriptToParticularWatchlist: false,
  watchlistPreKeptAvailableNames: {
    'Watchlist 1': 1,
    'Watchlist 2': 1,
    'Watchlist 3': 1,
    'Watchlist 4': 1,
    'Watchlist 5': 1,
    'Watchlist 6': 1,
    'Watchlist 7': 1,
    'Watchlist 8': 1,
    'Watchlist 9': 1,
    'Watchlist 10': 1
  },
  bookMarkScriptData: {
    scriptId: '',
    stockAvailableInWatchListArray: [],
    tempAddScriptToParticularWatchlist: false
  },
  isManageWatchlistClicked: false,
  scrollingScripts: [
    { scriptId: '9926000' },
    { scriptId: '9919000' },
    { scriptId: '9926009' }
  ],
  preDefinedWatchlistCount: 0,
  isNewWatchlistCreated: false
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    selectWatchlist: (state, action) => {
      state.selectedWatchlist = action?.payload
    },
    selectWatchlistTab: (state, action) => {
      state.watchlistTab = action?.payload.watchlistTab
    },
    setWatchlist: (state, action) => {
      const { Watchlist } = action?.payload
      state.watchlist = Watchlist
      const newWatchListIndex: {
        [key: string]: number
      } = {}
      const newWatchlistSequenceNumberIndexing: {
        [key: string]: watchlistObj
      } = {}
      Watchlist.forEach((singleWatchlist: watchlistObj) => {
        const { watchlistId, watchlistSequenceNumber } = singleWatchlist
        newWatchListIndex[watchlistId] = watchlistSequenceNumber
        newWatchlistSequenceNumberIndexing[watchlistSequenceNumber] =
          singleWatchlist
      })
      state.watchListIndex = newWatchListIndex
      state.watchlistSequenceNumberIndexing = newWatchlistSequenceNumberIndexing
    },
    setSecurityArray: (state, action) => {
      const { watchlistId, securitiesArray } = action?.payload
      state.subscribe = false
      const watchlistSequenceNumber = state.watchListIndex[watchlistId]
      let watchlistObjectIndex = state.watchlist.findIndex(
        (watchlistObj: watchlistObj) => watchlistObj.watchlistId === watchlistId
      )
      state.watchlist[watchlistObjectIndex].watchlistSecurities =
        securitiesArray

      // updating watchlistSequenceNumberIndexing indexing also
      state.watchlistSequenceNumberIndexing[
        watchlistSequenceNumber
      ].watchlistSecurities = securitiesArray
    },
    setWatchlistIndex: (state, action) => {
      state.watchListIndex = action?.payload.watchListIndex
    },
    setAddScriptToParticularWatchlist: (state, action) => {
      state.addScriptToParticularWatchlist = action?.payload
    },
    subscribe: (state, action) => {
      state.subscribe = action?.payload
    },
    setWatchlistToSecuritiesArray: (state, action) => {
      const { watchlistId, securitiesArray } = action?.payload
      state.watchListToSecuritiesArrayMapping[watchlistId] = securitiesArray
    },
    setSortScriptSelectedOption: (state, action) => {
      const { watchlistId, sortScriptOption } = action?.payload
      state.sortScriptSelectedOption[watchlistId] = sortScriptOption
    },
    deleteUsedPreKeptName: (state, action) => {
      const { watchlistName } = action?.payload
      delete state.watchlistPreKeptAvailableNames[watchlistName]
    },
    insertPreKeptName: (state, action) => {
      const { watchlistName } = action?.payload
      state.watchlistPreKeptAvailableNames[watchlistName] = 1
    },
    toggleManageWatchlist: (state, action) => {
      const { isManageWatchlistClicked } = action?.payload
      state.isManageWatchlistClicked = isManageWatchlistClicked
    },
    setBookMarkScriptData: (state, action) => {
      const { bookMarkScriptData } = action?.payload
      state.bookMarkScriptData = bookMarkScriptData
    },
    setNewWatchlistCreatedIndicator: (state, action) => {
      state.isNewWatchlistCreated = action.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(fetchWatchlistsActions.success, (state, { payload }) => {
      if (payload.length === 1) {
        const [watchList] = payload
        const {
          watchlistId,
          watchlistSequenceNumber,
          watchlistSecurities,
          watchlistType
        } = watchList

        //NOTE - setting watchlist tab at the start
        state.watchlistTab = watchlistSequenceNumber

        //NOTE - creating watchlist id to watchlistSequenceNumber index
        state.watchListIndex[watchlistId] = watchlistSequenceNumber

        state.sortScriptSelectedOption[watchlistId] = 0

        state.preDefinedWatchlistCount =
          (watchlistType === 'PRE-DEFINED' && 1) || 0

        const securitiesArrayPostSanity =
          getSecuritiesArrayPostSanity(watchlistSecurities)

        const newWatchList = {
          ...watchList,
          watchlistSecurities: securitiesArrayPostSanity
        }

        state.watchListToSecuritiesArrayMapping[watchlistId] =
          securitiesArrayPostSanity
        state.watchlist = [newWatchList]
        state.watchlistSequenceNumberIndexing[watchlistSequenceNumber] =
          newWatchList
        return
      }

      const sortByWatchListSequence = [...payload].sort(
        (a: watchlistObj, b: watchlistObj) => {
          return a.watchlistSequenceNumber - b.watchlistSequenceNumber
        }
      )

      const newWatchListArray: watchlistObj[] = []
      let preDefinedWatchlistCount = 0
      //NOTE - setting watchlist tab at the start
      state.watchlistTab = sortByWatchListSequence[0].watchlistSequenceNumber
      sortByWatchListSequence.forEach((watchListObject: watchlistObj) => {
        const {
          watchlistId,
          watchlistSequenceNumber,
          watchlistSecurities,
          watchlistName,
          watchlistType
        } = watchListObject

        if (watchlistType === 'PRE-DEFINED') {
          preDefinedWatchlistCount += 1
        }

        if (state.watchlistPreKeptAvailableNames[watchlistName]) {
          delete state.watchlistPreKeptAvailableNames[watchlistName]
        }

        state.watchListIndex[watchlistId] = watchlistSequenceNumber
        state.sortScriptSelectedOption[watchlistId] = 0

        const securitiesArrayPostSanity =
          getSecuritiesArrayPostSanity(watchlistSecurities)

        const newWatchList = {
          ...watchListObject,
          watchlistSecurities: securitiesArrayPostSanity
        }

        newWatchListArray.push(newWatchList)

        state.watchListToSecuritiesArrayMapping[watchlistId] =
          securitiesArrayPostSanity

        state.watchlistSequenceNumberIndexing[watchlistSequenceNumber] =
          newWatchList
      })

      state.preDefinedWatchlistCount = preDefinedWatchlistCount
      state.watchlist = newWatchListArray
    })

    // createWatchlistActions
    builder.addCase(createWatchlistActions.success, (state, { payload }) => {
      const { watchlistId, watchlistSequenceNumber, watchlistName } = payload
      state.subscribe = false
      state.watchlist = [...state.watchlist, payload]
      state.watchlistSequenceNumberIndexing[watchlistSequenceNumber] = payload
      state.watchListIndex[watchlistId] = watchlistSequenceNumber
      state.sortScriptSelectedOption[watchlistId] = 0
      state.watchListToSecuritiesArrayMapping[watchlistId] = []
      if (state.watchlistPreKeptAvailableNames[watchlistName]) {
        delete state.watchlistPreKeptAvailableNames[watchlistName]
      }
    })

    // deleteWatchlistActions
    builder.addCase(deleteWatchlistActions.success, (state, { payload }) => {
      const { reqData } = payload
      const { watchlistId: deletedWatchlistId } = reqData
      const newWatchlistArray: watchlistObj[] = []
      let isDeletedWatchListFound = false
      state.watchlist.forEach((item: watchlistObj) => {
        const { watchlistId, watchlistSequenceNumber, watchlistName } = item
        if (watchlistId === deletedWatchlistId) {
          isDeletedWatchListFound = true
          delete state.watchListIndex[watchlistId]
          delete state.sortScriptSelectedOption[watchlistId]
          delete state.watchListToSecuritiesArrayMapping[watchlistId]
          if (preKeptNameEntries.includes(watchlistName)) {
            state.watchlistPreKeptAvailableNames[watchlistName] = 1
          }
          return
        }
        state.watchListIndex[watchlistId] = !isDeletedWatchListFound
          ? watchlistSequenceNumber
          : watchlistSequenceNumber - 1

        newWatchlistArray.push(
          !isDeletedWatchListFound
            ? item
            : {
                ...item,
                watchlistSequenceNumber: watchlistSequenceNumber - 1
              }
        )
      })
      state.watchlist = newWatchlistArray
      const newWatchlistSequenceNumberIndexing = _keyBy(
        newWatchlistArray,
        'watchlistSequenceNumber'
      )
      state.watchlistSequenceNumberIndexing = newWatchlistSequenceNumberIndexing
    })

    // renameWatchlistActions
    builder.addCase(renameWatchlistActions.success, (state, { payload }) => {
      const { watchlistName, watchlistId, watchlistSequenceNumber } = payload
      let prevWatchlistName = ''
      state.watchlist = state.watchlist.map((item: watchlistObj) => {
        const {
          watchlistId: PREV_WATCHLISTID,
          watchlistName: PREV_WATCHLISTNAME
        } = item
        if (PREV_WATCHLISTID === watchlistId) {
          prevWatchlistName = PREV_WATCHLISTNAME
          state.watchlistSequenceNumberIndexing[watchlistSequenceNumber] =
            payload
          return payload
        }
        return item
      })
      if (state.watchlistPreKeptAvailableNames[watchlistName]) {
        delete state.watchlistPreKeptAvailableNames[watchlistName]
      }
      if (preKeptNameEntries.includes(prevWatchlistName)) {
        state.watchlistPreKeptAvailableNames[prevWatchlistName] = 1
      }
    })
  }
}

const watchlistSlice = createSlice(sliceOptions)

export const {
  selectWatchlist,
  selectWatchlistTab,
  setWatchlist,
  subscribe,
  setSecurityArray,
  setWatchlistIndex,
  setWatchlistToSecuritiesArray,
  setAddScriptToParticularWatchlist,
  setSortScriptSelectedOption,
  deleteUsedPreKeptName,
  setBookMarkScriptData,
  toggleManageWatchlist,
  setNewWatchlistCreatedIndicator
} = watchlistSlice.actions

export default watchlistSlice.reducer

const getSecuritiesArrayPostSanity = (
  securitiesArray: T_SCRIPTS_OBJ[] | null
) => {
  if (!securitiesArray?.length) {
    return securitiesArray
  }

  const securitiesArrayPostSanity = securitiesArray.filter(
    (scriptObj: T_SCRIPTS_OBJ) => {
      const { scriptId } = scriptObj
      return (window as any).secMaster._isScriptAvailableInSecurityMaster(
        +scriptId
      )
    }
  )

  return securitiesArrayPostSanity
}
