import { createSelector } from '@reduxjs/toolkit'
import { TTickerStore } from '~/src/Configurations/TickerStore'
export const SLICE_NAME = 'holdingTicker'

const selectTicker = (state: TTickerStore) => state[SLICE_NAME]
const selectIndividualHolding = (state: TTickerStore, scriptId: string) =>
  state[SLICE_NAME].scriptsInHolding[scriptId]

export const getOverallTodayCurrentValuesForHoldings = createSelector(
  selectTicker,
  holdingTicker => {
    const { currentValue, overallPnl, todayPnl } = holdingTicker
    return {
      currentValue,
      overallPnl,
      todayPnl
    }
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getIndividualCurrentValue = createSelector(
  selectIndividualHolding,
  individualHoldingTicker => {
    const { currentValue } = individualHoldingTicker || {}
    return currentValue
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getIndividualOverallPnlData = createSelector(
  selectIndividualHolding,
  individualHoldingTicker => {
    const { overallPnl } = individualHoldingTicker || {}
    return overallPnl
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getIndividualTodayPnlData = createSelector(
  selectIndividualHolding,
  individualHoldingTicker => {
    const { todayPnl } = individualHoldingTicker || {}
    return todayPnl
  },
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)
