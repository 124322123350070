import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import { updateStockTickerAction } from '../StockTicker/Actions'
import {
  flushHoldingDataInTickerAction,
  updateHoldingDataInTickerAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, T_HOLDING_SCRIPTS, T_SINGLE_HOLDING } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(updateHoldingDataInTickerAction, (state, action) => {
      const { payload: holdingPayload } = action
      const {
        holdingsWithStockMasterDetails: holdingData,
        totalInvestedAmount
      } = holdingPayload
      const scriptsInHolding: T_HOLDING_SCRIPTS = {}
      let initialTotalCurrentValue = 0
      let initialTotalOverallPnlValue = 0
      let initialTotalOverallPnlPercentage = 0
      let initialTotalTodayPnlValue = 0
      let initialTotalTodayPnlPercentage = 0

      holdingData.length &&
        holdingData.map((individualHoldingData: T_SINGLE_HOLDING) => {
          const {
            holdingQty,
            closeprice: closePriceFromSecurityMaster,
            closePriceFromTicker,
            investedValue,
            scriptId,
            LTP,
            PERCENT_CHANGE
          } = individualHoldingData
          if (scriptId) {
            //SECTION - individual holding's calculations
            const initialIndividualCurrentValue =
              Number(holdingQty) * (LTP || closePriceFromSecurityMaster)
            const initialIndividualOverallPnlValue =
              initialIndividualCurrentValue - investedValue
            const initialIndividualOverallPnlPercentage =
              (investedValue &&
                (initialIndividualOverallPnlValue / investedValue) * 100) ||
              0
            const initialIndividualTodayPnlValue =
              initialIndividualCurrentValue -
              Number(holdingQty) * closePriceFromTicker
            const initialIndividualTodayPnlPercentage =
              PERCENT_CHANGE ??
              (investedValue &&
                (initialIndividualTodayPnlValue / investedValue) * 100)

            //SECTION - initial total holding's calculations
            initialTotalCurrentValue =
              initialTotalCurrentValue + initialIndividualCurrentValue
            initialTotalOverallPnlValue =
              initialTotalOverallPnlValue + initialIndividualOverallPnlValue
            initialTotalOverallPnlPercentage =
              (totalInvestedAmount &&
                (initialTotalOverallPnlValue / totalInvestedAmount) * 100) ||
              0
            initialTotalTodayPnlValue =
              initialTotalTodayPnlValue + initialIndividualTodayPnlValue
            initialTotalTodayPnlPercentage =
              (totalInvestedAmount &&
                (initialTotalTodayPnlValue / totalInvestedAmount) * 100) ||
              0
            scriptsInHolding[scriptId] = {
              holdingQty,
              scriptId,
              closeprice: closePriceFromSecurityMaster,
              closePriceFromTicker,
              currentValue: initialIndividualCurrentValue,
              overallPnl: {
                value: initialIndividualOverallPnlValue,
                percentChange: initialIndividualOverallPnlPercentage
              },
              todayPnl: {
                value: initialIndividualTodayPnlValue,
                percentChange: initialIndividualTodayPnlPercentage
              },
              investedValue
            }
          }
        })

      state.totalInvestedValue = totalInvestedAmount
      state.currentValue = initialTotalCurrentValue
      state.overallPnl.value = initialTotalOverallPnlValue
      state.overallPnl.percentChange = initialTotalOverallPnlPercentage
      state.todayPnl.value = initialTotalTodayPnlValue
      state.todayPnl.percentChange = initialTotalTodayPnlPercentage
      state.scriptsInHolding = scriptsInHolding
    })
    builder.addCase(updateStockTickerAction, (state, { payload }) => {
      const [processedData, scriptId] = payload
      const {
        LTP,
        CLOSE_PRICE: closePriceFromTicker,
        PERCENT_CHANGE
      } = processedData
      const scriptData = state['scriptsInHolding'][scriptId] || null
      if (scriptData) {
        //SECTION - destructuring total values
        const {
          currentValue: PREVIOUS_TOTAL_CURRENT_VALUE,
          todayPnl: PREVIOUS_TOTAL_TODAY_PNL,
          overallPnl: PREVIOUS_TOTAL_OVERALL_PNL,
          totalInvestedValue
        } = state
        const { value: PREVIOUS_TOTAL_OVERALL_PNL_VALUE } =
          PREVIOUS_TOTAL_OVERALL_PNL
        const { value: PREVIOUS_TOTAL_TODAY_PNL_VALUE } =
          PREVIOUS_TOTAL_TODAY_PNL

        //SECTION - destructuring individual values
        const {
          holdingQty,
          overallPnl: previousIndividualOverallPnl,
          todayPnl: previousIndividualTodayPnl,
          currentValue: previousIndividualCurrentValue = 0,
          investedValue
        } = scriptData

        const { value: previousIndividualOverallPnlValue = 0 } =
          previousIndividualOverallPnl
        const { value: previousIndividualTodayPnlValue = 0 } =
          previousIndividualTodayPnl

        //SECTION - new values definitions
        let latestIndividualCurrentValue = 0
        let latestIndividualOverallPnlValue = 0
        let latestIndividualOverallPnlPercentage = 0
        let latestIndividualTodayPnlValue = 0
        let latestIndividualTodayPnlPercentage = 0

        let differenceInIndividualCurrentValue = 0
        let differenceInIndividualOverallPnlValue = 0
        let differenceInIndividualTodayPnlValue = 0

        let totalCurrentValue = 0
        let totalOverallPnlValue = 0
        let totalOverallPnlPercentage = 0
        let totalTodayPnlValue = 0
        let totalTodayPnlPercentage = 0

        //SECTION - individual holding's calculations
        latestIndividualCurrentValue = Number(holdingQty) * LTP
        latestIndividualOverallPnlValue =
          latestIndividualCurrentValue - investedValue
        latestIndividualOverallPnlPercentage =
          (investedValue &&
            (latestIndividualOverallPnlValue / investedValue) * 100) ||
          0
        latestIndividualTodayPnlValue =
          latestIndividualCurrentValue -
          Number(holdingQty) * closePriceFromTicker
        latestIndividualTodayPnlPercentage =
          PERCENT_CHANGE ??
          (investedValue &&
            (latestIndividualTodayPnlValue / investedValue) * 100)

        //SECTION - individual holding differences calculations
        differenceInIndividualCurrentValue =
          latestIndividualCurrentValue - previousIndividualCurrentValue
        differenceInIndividualOverallPnlValue =
          latestIndividualOverallPnlValue - previousIndividualOverallPnlValue
        differenceInIndividualTodayPnlValue =
          latestIndividualTodayPnlValue - previousIndividualTodayPnlValue

        //SECTION - total holding's calculations
        totalCurrentValue =
          PREVIOUS_TOTAL_CURRENT_VALUE + differenceInIndividualCurrentValue
        totalOverallPnlValue =
          PREVIOUS_TOTAL_OVERALL_PNL_VALUE +
          differenceInIndividualOverallPnlValue
        totalOverallPnlPercentage =
          (totalInvestedValue &&
            (totalOverallPnlValue / totalInvestedValue) * 100) ||
          0
        totalTodayPnlValue =
          PREVIOUS_TOTAL_TODAY_PNL_VALUE + differenceInIndividualTodayPnlValue
        totalTodayPnlPercentage =
          (totalInvestedValue &&
            (totalTodayPnlValue / totalInvestedValue) * 100) ||
          0

        //SECTION - setting new data in holding summary
        state.currentValue = totalCurrentValue
        state.overallPnl.value = totalOverallPnlValue
        state.overallPnl.percentChange = totalOverallPnlPercentage
        state.todayPnl.value = totalTodayPnlValue
        state.todayPnl.percentChange = totalTodayPnlPercentage

        //SECTION - setting new data in individual holdings
        state['scriptsInHolding'][scriptId].currentValue =
          latestIndividualCurrentValue
        state['scriptsInHolding'][scriptId].overallPnl.value =
          latestIndividualOverallPnlValue
        state['scriptsInHolding'][scriptId].overallPnl.percentChange =
          latestIndividualOverallPnlPercentage
        state['scriptsInHolding'][scriptId].todayPnl.value =
          latestIndividualTodayPnlValue
        state['scriptsInHolding'][scriptId].todayPnl.percentChange =
          latestIndividualTodayPnlPercentage
      }
    })
    builder.addCase(flushHoldingDataInTickerAction, state => {
      state.scriptsInHolding = { ...INITIAL_STATE.scriptsInHolding }
    })
  }
}

const holdingTickerSlice = createSlice(sliceOptions)
export default holdingTickerSlice.reducer
