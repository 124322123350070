import {
  DsBox,
  DsIconButton,
  DsImage,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { LIST_DRAGGER } from '~/src/Constants/ASSET_MAP'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { selectWatchlist, watchlistObj } from '~/src/Redux/WatchList/Reducer'
import WatchlistMenuActions from './WatchlistMenuActions'
import { getSelectedWatchlist } from '~/src/Redux/WatchList/Selectors'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

type ActionTypes = {
  setSelectedWatchlist: (selectedWatchlist: watchlistObj) => Promise<any>
}

export interface ISingleWatchlistProps extends IWithRouterProps {
  actions: ActionTypes
  draggableKey: string
  draggableId: string
  isDragDisabled: boolean
  itemIndex: number
  singleWatchlistData: watchlistObj
  selectedWatchlist: watchlistObj
  breakpoints: any
}

class SingleWatchlist extends Component<ISingleWatchlistProps> {
  state = {
    showMenu: false,
    anchorEl: null
  }

  setShowMenu = (value: boolean, selectedWatchlist?: watchlistObj) => {
    const { actions } = this.props
    this.setState({ showMenu: value })
    actions.setSelectedWatchlist(selectedWatchlist as watchlistObj)
  }

  renameSelectedWatchlistName = (
    selectedWatchlist: watchlistObj,
    watchlistName: string
  ) => {
    const { actions } = this.props
    actions.setSelectedWatchlist({
      ...selectedWatchlist,
      watchlistName
    })
  }

  setAnchorEl = (value: any) => {
    this.setState({ anchorEl: value.currentTarget })
  }

  renderMenu = () => {
    const { singleWatchlistData } = this.props
    const { watchlistSequenceNumber, watchlistType } = singleWatchlistData
    const { showMenu, anchorEl } = this.state
    const isPreDefinedWatchlist = watchlistType === 'PRE-DEFINED'
    return (
      <>
        <DsBox
          sx={{
            p: 'var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-bitterCold)',
            display: {
              xs: 'none',
              md: (isPreDefinedWatchlist && 'none') || 'block'
            }
          }}
        >
          <DsIconButton>
            <DsBox
              key={watchlistSequenceNumber}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={event => {
                this.setShowMenu(true, singleWatchlistData)
                this.setAnchorEl(event)
              }}
            >
              <DsRemixIcon className='ri-more-2-line' fontSize='mild' />
            </DsBox>

            <WatchlistMenuActions
              singleWatchlistData={singleWatchlistData}
              showMenu={showMenu}
              anchorEl={anchorEl}
              setShowMenu={this.setShowMenu}
              renameSelectedWatchlistName={this.renameSelectedWatchlistName}
            />
          </DsIconButton>
        </DsBox>
      </>
    )
  }

  renderBottomMenuInMobile = () => {
    const { singleWatchlistData } = this.props
    return (
      <>
        <WatchlistMenuActions
          singleWatchlistData={singleWatchlistData}
          renameSelectedWatchlistName={this.renameSelectedWatchlistName}
        />
      </>
    )
  }

  handleMobileSelectWatchlist = () => {
    const { actions, selectedWatchlist, singleWatchlistData } = this.props
    if (selectedWatchlist === singleWatchlistData) {
      actions.setSelectedWatchlist({} as watchlistObj)
      return
    }
    actions.setSelectedWatchlist(singleWatchlistData)
  }

  render() {
    const {
      draggableKey,
      draggableId,
      isDragDisabled,
      itemIndex,
      singleWatchlistData,
      breakpoints,
      selectedWatchlist
    } = this.props
    const { watchlistId: currentSelectedWatchlistId = '' } =
      selectedWatchlist || {}
    const { watchlistName, watchlistSecurities, watchlistType, watchlistId } =
      singleWatchlistData
    const watchlistLength = watchlistSecurities?.length
    const stockKeyWord = (watchlistLength === 1 && 'stock') || 'stocks'
    const isPreDefinedWatchlist = watchlistType === 'PRE-DEFINED'
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const isSelectedWatchlist = currentSelectedWatchlistId === watchlistId
    // const
    return (
      <>
        <Draggable
          index={itemIndex}
          key={draggableKey}
          draggableId={draggableId}
          isDragDisabled={isDragDisabled}
        >
          {provided => (
            <DsBox
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'var(--ds-colour-surfaceBackground)',
                border: {
                  md: 'unset',
                  xs: '1px solid var(--ds-colour-strokeDefault)'
                },
                borderRadius: {
                  md: 'unset',
                  xs: 'var(--ds-spacing-glacial)'
                },
                mb: {
                  md: 'var(--ds-spacing-frostbite)',
                  xs: 'var(--ds-spacing-bitterCold)'
                },
                padding: {
                  md: 'unset',
                  xs:
                    (isSelectedWatchlist && 'unset') ||
                    'var(--ds-spacing-bitterCold)'
                }
              }}
            >
              <DsStack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: {
                    md: 'unset',
                    xs:
                      (isSelectedWatchlist && 'var(--ds-spacing-bitterCold)') ||
                      'unset'
                  }
                }}
                {...(!isDesktop &&
                  !isPreDefinedWatchlist && {
                    onClick: this.handleMobileSelectWatchlist
                  })}
              >
                <DsBox
                  sx={{
                    padding: 'var(--ds-spacing-bitterCold)',
                    display: {
                      xs: 'none',
                      md: (isPreDefinedWatchlist && 'none') || 'block'
                    }
                  }}
                >
                  <DsImage
                    srcSet={LIST_DRAGGER}
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                </DsBox>
                <DsStack
                  sx={{
                    width: {
                      md: '82%',
                      xs: '100%'
                    },
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottom: {
                      md: '1px solid var(--ds-colour-strokeDefault)',
                      xs: 'none'
                    },
                    padding: {
                      md: 'var(--ds-spacing-bitterCold) var(--ds-spacing-zero)',
                      xs: 'none'
                    },
                    marginLeft: {
                      xs: 'none',
                      md:
                        (isPreDefinedWatchlist &&
                          'calc(var(--ds-spacing-blazing) + var(--ds-spacing-glacial))') ||
                        'none'
                    }
                  }}
                >
                  <DsStack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <DsTypography
                      variant='bodyBoldSmall'
                      sx={{ flexGrow: '1' }}
                    >
                      {watchlistName}
                    </DsTypography>
                    <DsTypography
                      variant='bodyRegularSmall'
                      color='var(--ds-colour-typoSecondary)'
                      sx={{
                        flexGrow: '1',
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      {(watchlistLength && watchlistLength) || 0} {stockKeyWord}
                    </DsTypography>
                  </DsStack>
                  {this.renderMenu()}
                  <DsBox
                    sx={{
                      display: {
                        xs: (isPreDefinedWatchlist && 'none') || 'block',
                        md: 'none'
                      },
                      marginLeft: 'calc(var(--ds-spacing-mild) + 1px)'
                    }}
                  >
                    <DsImage
                      srcSet={LIST_DRAGGER}
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    />
                  </DsBox>
                </DsStack>
              </DsStack>
              {this.renderBottomMenuInMobile()}
            </DsBox>
          )}
        </Draggable>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const selectedWatchlist = getSelectedWatchlist(state)
  return { selectedWatchlist }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setSelectedWatchlist: (selectedWatchlist: watchlistObj) =>
      dispatch(selectWatchlist(selectedWatchlist))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SingleWatchlist)
)
