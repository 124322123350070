export type T_SCRIPT_ALERT = {
  [key: string]: T_SCRIPT_ALERT_OBJ
}
export type T_SCRIPT_ALERT_OBJ = {
  label: string
  value: string
}
export const SCRIPT_ALERT: T_SCRIPT_ALERT = {
  GT: {
    label: 'Price greater than Target Price',
    value: 'GT'
  },
  LT: {
    label: 'Price less than Target Price',
    value: 'LT'
  },
  GTE: {
    label: 'Price greater than equals to Target Price',
    value: 'GTE'
  },
  LTE: {
    label: 'Price less than equals to Target Price',
    value: 'LTE'
  }
}
