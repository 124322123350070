import { createAction } from '@reduxjs/toolkit'

export const updateOpenPositionDataInTickerAction = createAction<any, string>(
  'stockMaster/updateOpenPositionDataInTickerAction'
)

export const updateTodayPositionDataInTickerAction = createAction<any, string>(
  'stockMaster/updateTodayPositionDataInTickerAction'
)

export const flushOpenPositionDataInTickerAction = createAction(
  'stockMaster/flushOpenPositionDataInTickerAction'
)

export const flushTodayPositionDataInTickerAction = createAction(
  'stockMaster/flushTodayPositionDataInTickerAction'
)

export const flushOpenAndTodayPositionDataInTickerAction = createAction(
  'stockMaster/flushOpenAndTodayPositionDataInTickerAction'
)
