import React, { Component } from 'react'
import {
  DsBox,
  DsImage,
  DsPopup,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'

import { servilienceEventsServiceName } from '~/src/Redux/AppState/actions'
import { getPlatformConfigsSelector } from '~/src/Redux/AppState/selectors'
import getPlatformConfigsAction from '~/src/Redux/AppState/Services/GetPlatformConfigs.Service'
import servilienceEventsAction, {
  IServilienceEventPayload
} from '~/src/Redux/AppState/Services/SurveillanceEvents.Service'
import { isServiceError } from '~/src/Redux/ServiceTracker/Selectors'
import { getSurScript } from '~/src/Redux/StockMaster/Selectors'

import { WARNINGS } from '~/src/Constants/ASSET_MAP'
import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

type ActionTypes = {
  getPlatformConfigs: () => void
  triggerServilienceEvent: (item: IServilienceEventPayload) => Promise<any>
}
export interface IScriptSurveillanceProps extends IWithRouterProps {
  actions: ActionTypes
  breakpoints: any
  primaryButtonOnClick: () => void
  secondaryButtonOnClick: () => void
  openSurveillanceAlert: boolean
  surveillanceFlagName: string
  scriptId: string
  surScriptErrors: any
  surScriptCodes: string[]
  isServilienceError: boolean
}
class ScriptSurveillance extends Component<IScriptSurveillanceProps> {
  state = {
    isServilienceEventsApiFailed: false
  }

  componentDidMount() {
    const { actions } = this.props
    actions.getPlatformConfigs()
  }

  componentDidUpdate(prevProps: Readonly<IScriptSurveillanceProps>): void {
    const { isServilienceError: prevIsServilienceError } = prevProps
    const { isServilienceError } = this.props
    if (isServilienceError !== prevIsServilienceError) {
      this.setState({ isServilienceEventsApiFailed: isServilienceError })
    }
  }

  handleTriggerServeillanceEvent = async (userConsent: boolean) => {
    const { actions, surScriptErrors, surScriptCodes, scriptId } = this.props
    const jointMessage =
      surScriptCodes
        .map((item: string) => {
          const trimmedItem = item.trim()
          const { desc = '', value = '' } = surScriptErrors?.[trimmedItem] || {}
          return `${value} - ${desc}`
        })
        .join(' - ') || ''
    const requestPayload = {
      message: jointMessage,
      scriptId: scriptId.toString(),
      isPersist: false,
      metadata: {},
      userConcent: userConsent
    }
    const eventResponse = await actions.triggerServilienceEvent(requestPayload)
    if (eventResponse?._isCustomError) {
      return false
    }
    return true
  }

  render() {
    const {
      primaryButtonOnClick,
      secondaryButtonOnClick,
      breakpoints,
      surScriptCodes,
      surScriptErrors
    } = this.props
    const { isServilienceEventsApiFailed } = this.state
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const show = !!surScriptCodes.length
    return (
      <>
        <DsPopup
          open={show}
          onClose={secondaryButtonOnClick}
          DsDialogProps={{
            sx: {
              '.MuiDialogContent-root': {
                marginTop: 'var(--ds-spacing-zero)'
              }
            }
          }}
          primaryButtonText={
            isServilienceEventsApiFailed ? 'Retry' : 'Order Anyway'
          }
          secondaryButtonText='Cancel'
          showClose={!isDesktop}
          primaryButtonProps={{
            onClick: async () => {
              if (await this.handleTriggerServeillanceEvent(true)) {
                primaryButtonOnClick()
                secondaryButtonOnClick()
              }
            }
          }}
          secondaryButtonProps={{
            onClick: () => {
              this.handleTriggerServeillanceEvent(false)
              secondaryButtonOnClick()
            }
          }}
        >
          <DsStack
            spacing={'var(--ds-spacing-frostbite)'}
            textAlign={{ md: 'center', xs: 'unset' }}
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <DsBox sx={{ display: { md: 'flex', xs: 'none' } }}>
              <DsImage srcSet={WARNINGS} />
            </DsBox>
            <DsStack
              spacing={4}
              sx={{ alignItems: { md: 'center', xs: 'unset' } }}
              width={'100%'}
            >
              <DsStack
                sx={{
                  flexDirection: { md: 'unset', xs: 'row' },
                  alignItems: { md: 'unset', xs: 'center' }
                }}
                gap={'var(--ds-spacing-frostbite)'}
              >
                <DsRemixIcon
                  className='ri-alert-fill'
                  sx={{
                    display: { md: 'none', xs: 'block' },
                    color: 'var(--ds-colour-supportWarning)'
                  }}
                />
                <DsTypography
                  variant={isDesktop ? 'headingBoldLarge' : 'headingBoldMedium'}
                >
                  Scrip under surveillance
                </DsTypography>
              </DsStack>
              <DsBox
                sx={{
                  background: {
                    md: 'unset',
                    xs: 'var(--ds-colour-surfaceSecondary)'
                  },
                  padding: {
                    md: 'unset',
                    xs: 'var(--ds-spacing-glacial) var(--ds-spacing-frostbite)'
                  },
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <ul>
                  {surScriptCodes.map((item: string) => {
                    const trimmedItem = item.trim()
                    const { desc = '', value = '' } =
                      surScriptErrors?.[trimmedItem] || {}
                    return <li key={trimmedItem}>{`${value} - ${desc}`}</li>
                  })}
                </ul>
              </DsBox>
            </DsStack>
            {isServilienceEventsApiFailed ? (
              <DsBox
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '40px',
                  borderRadius: '8px',
                  gap: '4px',
                  background: '#F9EBEF'
                }}
              >
                <DsRemixIcon className='ri-error-warning-fill' color='error' />
                <DsTypography color='error' variant='bodyRegularMedium'>
                  Something went wrong please try again
                </DsTypography>
              </DsBox>
            ) : null}
          </DsStack>
        </DsPopup>
      </>
    )
  }
}

const mapStateToProps = (
  state: TAppStore,
  ownProps: IScriptSurveillanceProps
) => {
  const { scriptId } = ownProps
  const surScriptCodes = getSurScript(scriptId)(state)
  const surScriptErrors = getPlatformConfigsSelector(state)
  const isServilienceError = isServiceError(state, [
    servilienceEventsServiceName
  ])
  return { surScriptCodes, surScriptErrors, isServilienceError }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    getPlatformConfigs: () => dispatch(getPlatformConfigsAction()),
    triggerServilienceEvent: (req: IServilienceEventPayload) =>
      dispatch(servilienceEventsAction(req))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(ScriptSurveillance)
)
