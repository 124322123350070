import React from 'react'
import { DsBox } from '@am92/react-design-system'

// actions
import { appContext, TAppStore } from '~/src/Configurations/AppStore'
import { manageWatchlistIndicator } from '~/src/Redux/WatchList/Selectors'
import WatchlistContainer from '../../Watchlist/WatchlistContainer'
import ManageWatchlistContainer from '../../ManageWatchlist/ManageWatchlistContainer'
import { connect } from 'react-redux'

export interface ISidebarBodyState {}

export interface ISidebarBodyProps {
  isManageWatchlistClicked: boolean
}

class SidebarBody extends React.PureComponent<ISidebarBodyProps> {
  renderBody = () => {
    const { isManageWatchlistClicked } = this.props
    if (isManageWatchlistClicked) {
      return <ManageWatchlistContainer />
    }
    return <WatchlistContainer />
  }

  render() {
    return (
      <>
        <DsBox
          sx={{
            overflowY: { xs: 'unset', md: 'auto' },
            height: '100%',
            minHeight: { xs: '70vh', md: 'unset' },
            overscrollBehavior: { xs: 'unset', md: 'contain' },
            backgroundColor: 'var(--ds-colour-surfaceBackground)'
          }}
        >
          {this.renderBody()}
        </DsBox>
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const isManageWatchlistClicked = manageWatchlistIndicator(state)
  return {
    isManageWatchlistClicked
  }
}

export default connect(mapStateToProps, null, null, {
  context: appContext
})(SidebarBody)
