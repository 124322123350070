import React from 'react'
import {
  DsBox,
  DsButton,
  DsDialog,
  DsImage,
  DsLink,
  DsList,
  DsListItem,
  DsListItemText,
  DsRemixIcon,
  DsStack,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import { RISK_DISCLOSURE } from '../Constants/ASSET_MAP'
import { SEBI_DISCLOSURE } from '../Configurations/env'
import { getDeviceActiveScreen } from '../Utils/deviceDetails'
import { TAppDispatch, TAppStore } from '../Configurations/AppStore'
import withErrorConnect from '../Lib/withErrorConnect'
import { updateRiskDisclosureFlagAction } from '../Redux/SSO/Actions'
import { isNSEFOEnabled } from '../Redux/Customer/Selectors'
import { haveSeenRiskDisclosureSelect } from '../Redux/SSO/Selectors'
export interface IRiskDisclosureProps extends IwithBreakpoints {
  actions: {
    updateRiskDisclosureFlag: (flag: boolean) => void
  }
  isNSEFOEnabledSelector: boolean
  haveSeenRiskDisclosure: boolean
}

class RiskDisclosure extends React.PureComponent<IRiskDisclosureProps> {
  handleOnClose = () => {
    const { actions } = this.props
    actions.updateRiskDisclosureFlag(true)
  }
  handleOnClick = () => {
    window.open(SEBI_DISCLOSURE)
  }
  render() {
    const { breakpoints, haveSeenRiskDisclosure, isNSEFOEnabledSelector } =
      this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const showDisclosure = !haveSeenRiskDisclosure && isNSEFOEnabledSelector
    return (
      <>
        <DsDialog
          sx={{
            justifyContent: 'space-between',
            '.MuiPaper-root': {
              borderRadius: {
                xs: 'var(--ds-spacing-zero)',
                md: 'var(--ds-spacing-bitterCold)'
              },
              '& .MuiStack-root': {}
            }
          }}
          keepMounted={false}
          showClose={false}
          fullScreen={!isDesktop}
          open={showDisclosure}
        >
          <DsStack
            spacing='var(--ds-spacing-mild)'
            sx={{
              textAlign: 'center',
              height: '100%'
            }}
            justifyContent={'space-between'}
          >
            <DsStack>
              <DsBox sx={{ display: { xs: 'flex', md: 'none' } }}>
                <DsImage srcSet={RISK_DISCLOSURE} />
              </DsBox>
              <DsStack
                sx={{
                  background: {
                    xs: 'var(--ds-colour-neutral1)',
                    md: 'none',
                    padding: 'var(--ds-spacing-frostbite)'
                  },
                  borderRadius: 'var(--ds-spacing-glacial)'
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'flex-start', md: 'center' },
                    gap: 'var(--ds-spacing-glacial)'
                  }}
                >
                  <DsBox
                    sx={{
                      display: { xs: 'flex', md: 'none' },
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'var(--ds-colour-surfaceBackground)',
                      borderRadius: 'var(--ds-spacing-quickFreeze)',
                      width: 'var(--ds-spacing-mild)',
                      height: 'var(--ds-spacing-mild)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-shield-check-line'
                      fontSize='frigid'
                      color='error'
                    />
                  </DsBox>
                  <DsTypography variant='headingBoldLarge' component={'div'}>
                    Risk Disclosure on Derivatives
                  </DsTypography>
                </DsBox>
                <DsStack spacing='var(--ds-spacing-cool)'>
                  <DsList
                    sx={{
                      listStyleType: 'disc',
                      paddingInlineStart: 'var(--ds-spacing-bitterCold)'
                    }}
                  >
                    <DsListItem sx={{ display: 'list-item' }}>
                      <DsListItemText secondary='9 out of 10 individual traders incurred net-losses.' />
                    </DsListItem>
                    <DsListItem sx={{ display: 'list-item' }}>
                      <DsListItemText secondary='On average, loss makers registered net trading loss close to ₹ 50,000.' />
                    </DsListItem>
                    <DsListItem sx={{ display: 'list-item' }}>
                      <DsListItemText secondary='Loss makers expended an additional 28% of net trading losses as transaction costs.' />
                    </DsListItem>
                    <DsListItem sx={{ display: 'list-item' }}>
                      <DsListItemText secondary='Those making net trading profits incurred between 15% to 50% of such profits as transaction cost.' />
                    </DsListItem>
                  </DsList>
                  <DsLink underline='always' onClick={this.handleOnClick}>
                    Read Full Sebi Disclosure
                  </DsLink>
                </DsStack>
              </DsStack>
            </DsStack>
            <DsButton
              color='primary'
              size='large'
              sx={{ mb: 'var(--ds-spacing-frostbite)' }}
              onClick={this.handleOnClose}
            >
              Okay, Understood
            </DsButton>
          </DsStack>
        </DsDialog>
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const isNSEFOEnabledSelector = isNSEFOEnabled(state)
  const haveSeenRiskDisclosure = haveSeenRiskDisclosureSelect(state)

  return {
    isNSEFOEnabledSelector,
    haveSeenRiskDisclosure
  }
}
const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    updateRiskDisclosureFlag: (flag: boolean) =>
      dispatch(updateRiskDisclosureFlagAction(flag))
  }
})
export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(RiskDisclosure)
)
