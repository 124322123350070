import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
  DsInputAdornment,
  DsRemixIcon,
  DsTextField
} from '@am92/react-design-system'
import { debounce } from 'lodash'

import {
  clearSearchAction,
  openSearchResultContainerAction
} from '~/src/Redux/Search/Actions'

// context
import { appContext, TAppDispatch } from '~/src/Configurations/AppStore'

// workers
import searchManager from '~/src/Workers/SearchMasterWorker/SearchManager'

const MIN_LENGTH_TO_INITIATE_SEARCH = 2

interface ISearchInputProps extends PropsFromRedux {
  open: boolean
  searchString: string
  setString: (searchString: string) => void
}

class SearchInput extends React.PureComponent<ISearchInputProps> {
  handleOnClickClose = () => {
    const { actions, setString } = this.props
    actions.clearSearch()
    if (setString) setString('')
  }

  handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const { setString } = this.props
    const searchString = value.trimStart()
    setString(searchString)
    if (searchString.length >= MIN_LENGTH_TO_INITIATE_SEARCH) {
      this.handleInitiateSearch()
    }
  }

  handleInitiateSearch = debounce(() => {
    const { searchString } = this.props
    searchManager.search(searchString)
  }, 500)

  handleOnFocus = () => {
    const { actions } = this.props
    actions.openSearchResultContainer(true)
  }

  render() {
    const { open, searchString } = this.props
    const searchStringLength = (searchString && searchString.length) || 0
    return (
      <DsTextField
        id='textField'
        fullWidth
        sx={{
          '.MuiInputBase-root': {
            borderRadius: 'var(--ds-radius-glacial) !important',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
            borderBottomLeftRadius: open
              ? 'unset !important'
              : 'var(--ds-radius-glacial) !important',
            borderBottomRightRadius: open
              ? 'unset !important'
              : 'var(--ds-radius-glacial) !important'
          },
          '.Mui-focused': {
            borderColor: 'var(--ds-colour-strokeDefault) !important',
            borderBottomLeftRadius: 'unset !important',
            borderBottomRightRadius: 'unset !important'
          }
        }}
        name='stockName'
        placeholder={'Search to buy/sell stocks'}
        value={searchString}
        startAdornment={
          <DsInputAdornment position='start'>
            <DsRemixIcon
              className='ri-search-line'
              {...(open && {
                color: 'secondary'
              })}
            />
          </DsInputAdornment>
        }
        endAdornment={
          searchStringLength ? (
            <DsInputAdornment
              sx={{ cursor: 'pointer' }}
              onClick={this.handleOnClickClose}
              position='end'
            >
              <DsRemixIcon className='ri-close-line' />
            </DsInputAdornment>
          ) : null
        }
        onChange={this.handleOnChange}
        onFocus={this.handleOnFocus}
      />
    )
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    openSearchResultContainer: (value: boolean) =>
      dispatch(openSearchResultContainerAction(value)),
    clearSearch: () => dispatch(clearSearchAction())
  }
})

const connector = connect(null, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SearchInput)
