import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsButton, DsTooltip, DsBox, DsImage } from '@am92/react-design-system'
import { MARKET_DEPTH } from '~/src/Constants/ASSET_MAP'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import { isNonTradableStock } from '~/src/Lib/SecMaster/helpers'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Redux/Orders/TYPES'
import {
  setSelectedExchange,
  updateOrderPageModalIndicator
} from '~/src/Redux/Orders/reducer'

type ActionTypes = {
  updateOrderPageModalIndicator: (
    reqData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  setSelectedExchange: (requestData: any) => Promise<any>
}

export interface ISingleSecurityProps extends IWithRouterProps {
  actions: ActionTypes
  scriptId: string
  showMarketDepth?: boolean
}

class TradableStockButtons extends Component<ISingleSecurityProps> {
  handleSelectedStock = () => {
    const { actions, navigate, scriptId } = this.props
    const { exchange } = (window as any).secMaster.getByScriptId(scriptId) || {}
    actions.setSelectedExchange({
      selectedExchange: exchange
    })
    navigate(`/quotes/${scriptId}`)
  }

  handleBuySell = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string
  ) => {
    const { actions, scriptId } = this.props
    e.stopPropagation()
    const { exchange } = (window as any).secMaster.getByScriptId(scriptId) || {}
    actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: true,
      orderScriptId: scriptId,
      orderBuyOrSell: value,
      selectedExchange: exchange,
      isModifyOrderRequest: false
    })
  }

  render() {
    const { scriptId, showMarketDepth = true } = this.props
    const { aslAllowed } =
      (window as any).secMaster.getByScriptId(scriptId) || {}
    const nonTradableStock = isNonTradableStock(aslAllowed)

    if (nonTradableStock) {
      return null
    }

    return (
      <>
        <DsButton
          color='success'
          onClick={e => {
            this.handleBuySell(e, 'BUY')
          }}
        >
          {WATCHLIST_LABEL.WATCHLIST.BUY}
        </DsButton>
        <DsButton
          color='error'
          onClick={e => {
            this.handleBuySell(e, 'SELL')
          }}
        >
          {WATCHLIST_LABEL.WATCHLIST.SELL}
        </DsButton>
        {(showMarketDepth && (
          <DsTooltip
            heading='Market Depth'
            placement='bottom'
            arrow={false}
            disableTouchListener
            disableInteractive
          >
            <DsButton
              color='secondary'
              sx={{
                minWidth:
                  'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
              }}
              onClick={e => {
                e.stopPropagation()
                this.handleSelectedStock()
              }}
            >
              <DsBox
                sx={{
                  height:
                    'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))',
                  width:
                    'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                }}
              >
                <DsImage srcSet={MARKET_DEPTH} />
              </DsBox>
            </DsButton>
          </DsTooltip>
        )) ||
          null}
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    setSelectedExchange: (requestData: any) =>
      dispatch(setSelectedExchange(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(TradableStockButtons)
