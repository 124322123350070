import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsBox, DsImage } from '@am92/react-design-system'
import { LIST_DRAGGER } from '~/src/Constants/ASSET_MAP'
import {
  getSelectedWatchlistTab,
  getWatchlist
} from '~/src/Redux/WatchList/Selectors'
import TradableStockButtons from './TradableStockButtons'
import OptionChainSheet from './OptionChainSheet'
import CustomWatchListOptions from './CustomWatchListOptions'

type ActionTypes = {}

export interface IHoverItemsProps extends IWithRouterProps {
  actions: ActionTypes
  selectedScript: string
  hover: number
  sequenceNumber: number
  scriptId: string
  watchListType: string
  securitiesIndex: number
  handleViewMore: (sequenceNumber: any) => void
}

class HoverItems extends Component<IHoverItemsProps> {
  render() {
    const {
      selectedScript,
      hover,
      sequenceNumber,
      securitiesIndex,
      handleViewMore,
      watchListType,
      scriptId
    } = this.props
    const isItemHovered = sequenceNumber === hover
    const isCustomWatchlist = watchListType !== 'PRE-DEFINED'
    return (
      <>
        {isItemHovered && (
          <DsBox
            sx={{
              width: (isCustomWatchlist && '100%') || 'unset',
              position: 'absolute',
              right: 0,
              display: { md: 'flex', xs: 'none' },
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {isCustomWatchlist && (
              <DsBox
                sx={{
                  ml: 1
                }}
              >
                <DsImage
                  srcSet={LIST_DRAGGER}
                  style={{
                    width: '15px',
                    height: '15px'
                  }}
                />
              </DsBox>
            )}

            <DsBox
              sx={{
                display: 'flex',
                gap: 1,
                mr: 2
              }}
            >
              <TradableStockButtons scriptId={scriptId} />
              <OptionChainSheet scriptId={scriptId} />
              <CustomWatchListOptions
                sequenceNumber={sequenceNumber}
                selectedScript={selectedScript}
                securitiesIndex={securitiesIndex}
                handleViewMore={handleViewMore}
              />
            </DsBox>
          </DsBox>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const { currentWatchlistType: watchListType } =
    getWatchlist(selectedWatchlistTab)(state) || {}
  return {
    watchListType
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(HoverItems)
