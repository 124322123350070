import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'
import { getHoldingQuantitiesOfPrimaryPortfolioActions } from '../Actions'
import { T_GET_HOLDING_DETAILS_PAYLOAD } from '../TYPES'

async function getHoldingQuantitiesOfPrimaryPortfolio(
  payload: T_GET_HOLDING_DETAILS_PAYLOAD
) {
  const { pledgeDetailsRequired = false, segment, subAccountId = '' } = payload
  const getHoldingsPayload = {
    segment
  }
  const options = {
    url: '/trading/portfolio/get-holdings',
    method: 'POST',
    data: getHoldingsPayload,
    params: {
      pledgeDetails: pledgeDetailsRequired
    },
    headers: {
      'X-SubAccountID': subAccountId || getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getHoldingQuantitiesOfPrimaryPortfolioAction = serviceActionCreator(
  getHoldingQuantitiesOfPrimaryPortfolioActions,
  getHoldingQuantitiesOfPrimaryPortfolio
)

export default getHoldingQuantitiesOfPrimaryPortfolioAction
