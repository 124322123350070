import React from 'react'
import {
  DsBox,
  DsButtonBase,
  DsHeader,
  DsImage
} from '@am92/react-design-system'

import StockScrollingHeader from '../StockScrollingHeader/StockScrollingHeader'
// components
import HeaderRightMenuAction from './HeaderRightMenuAction'
import HeaderTabs from './HeaderTabs'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
// constants
import { HEADER_ASSETS } from '~/src/Constants/ASSET_MAP'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import {
  TAppStore,
  TAppDispatch,
  appContext
} from '~/src/Configurations/AppStore'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { connect, ConnectedProps } from 'react-redux'

export interface IHeaderProps extends IWithRouterProps, PropsFromRedux {}

class Header extends React.Component<IHeaderProps> {
  render() {
    const { navigateTo } = this.props
    return (
      <>
        <DsHeader
          sx={{
            display: 'flex',
            position: 'absolute',
            height: {
              md: 'calc(var(--ds-spacing-superheated) + var(--ds-spacing-quickFreeze))'
            }
          }}
          logo={
            <>
              <DsButtonBase
                onClick={() => navigateTo(APP_ROUTES.DASHBOARD.pathname)}
              >
                <DsImage
                  onClick={() => navigateTo(APP_ROUTES.DASHBOARD.pathname)}
                  srcSet={HEADER_ASSETS}
                  style={{
                    width: '100vw'
                  }}
                />
              </DsButtonBase>
              <StockScrollingHeader />
            </>
          }
        />
        <DsBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            top: 'calc(var(--ds-spacing-warm) - var(--ds-spacing-deepFreeze))',
            px: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <HeaderTabs />
          <HeaderRightMenuAction />
        </DsBox>
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  return {}
}

const mapDispatchToProps = (dispatch: TAppDispatch) => {
  return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>
export default withErrorConnect(mapStateToProps, mapDispatchToProps)(Header)
