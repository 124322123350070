import { DsStack, DsRemixIcon, DsTypography } from '@am92/react-design-system'
import React, { useEffect, useState } from 'react'
import { TAppStore } from '~/src/Configurations/AppStore'
import { useAppDispatch, useAppSelector } from '~/src/hooks/useRedux'
import { getHoldingFromHoldingSummary } from '~/src/Redux/Dashboard/Selectors'
import { triggerCleverTapEvent } from '~/src/Utils/global'
import {
  getOrderBuyOrSell,
  getOrderScriptId
} from '~/src/Redux/Orders/selectors'
import HoldingDetailsPopUp from './HoldingDetailsPopUp'
import getHoldingsSummaryAction, {
  getHoldingsSummaryPayload
} from '~/src/Redux/Dashboard/Services/getHoldingsSummary.Service'

interface IHoldingBagProps {}

const HoldingBag: React.FunctionComponent<IHoldingBagProps> = (
  props: IHoldingBagProps
) => {
  const dispatch = useAppDispatch()
  const [holdingDetailsPopUpIndicator, setHoldingDetailsPopUpIndicator] =
    useState<boolean>(false)
  const scriptId = useAppSelector((state: TAppStore) => getOrderScriptId(state))
  const holdingQtyFromHoldingSummary = useAppSelector((state: TAppStore) =>
    getHoldingFromHoldingSummary(state, scriptId)
  )
  const orderBuyOrSell = useAppSelector((state: TAppStore) =>
    getOrderBuyOrSell(state)
  )

  useEffect(() => {
    !holdingQtyFromHoldingSummary && handleGetHoldingsSummaryDetails()
  }, [])

  useEffect(() => {
    holdingDetailsPopUpIndicator && handleCloseHoldingDetailsPopUp()
  }, [scriptId])

  const handleGetHoldingsSummaryDetails = async () => {
    const requestData: getHoldingsSummaryPayload = {
      segment: 'EQ'
    }
    const getHoldingsSummaryResponse = await dispatch(
      getHoldingsSummaryAction(requestData)
    )

    if (getHoldingsSummaryResponse._isCustomError) {
      console.log('failed API - getHoldingsSummary')
      return
    }
  }

  const handleOpenHoldingDetailsPopUp = () => {
    const customAttributes = {
      Event_Status: 'Pass',
      Screen_Name: 'Transaction_Holdings',
      Action: orderBuyOrSell
    }
    triggerCleverTapEvent('Order', customAttributes)
    setHoldingDetailsPopUpIndicator(true)
  }

  const handleCloseHoldingDetailsPopUp = () => {
    setHoldingDetailsPopUpIndicator(false)
  }

  if (!holdingQtyFromHoldingSummary) {
    return null
  }

  return (
    <DsStack>
      <DsStack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'var(--ds-colour-neutral1)',
          p: 'var(--ds-spacing-quickFreeze)',
          border: '1px solid var(--ds-colour-strokeDefault)',
          gap: 'var(--ds-spacing-quickFreeze)',
          borderRadius: 'var(--ds-radius-quickFreeze)',
          cursor: 'pointer'
        }}
        onClick={handleOpenHoldingDetailsPopUp}
      >
        <DsRemixIcon className='ri-suitcase-line' fontSize='mild' />
        <DsTypography variant='bodyRegularSmall'>
          {holdingQtyFromHoldingSummary}
        </DsTypography>
      </DsStack>

      <HoldingDetailsPopUp
        holdingDetailsPopUpIndicator={holdingDetailsPopUpIndicator}
        handleCloseHoldingDetailsPopUp={handleCloseHoldingDetailsPopUp}
      />
    </DsStack>
  )
}

export default HoldingBag
