import { AS_DEFAULT_WSS_URL } from '~/src/Configurations/env'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { T_SCRIPTS_OBJ } from '~/src/Redux/WatchList/Reducer'

export const SOCKET_ERRORS = {
  INVALID_AUTH: 422

  // 'Authentication Error : Invalid user authentication params, Code : 3'
}

export const SESSION_EXPIRED_EVENT_FROM_SOCKET =
  '__SESSION_EXPIRED_EVENT_FROM_SOCKET__'

export const APP_TO_WORKER_ACTIONS = {
  WEB_SOCKET_INITIALIZE: 'WEB_SOCKET_INITIALIZE',
  WEB_SOCKET_RECONNECT: 'WEB_SOCKET_RECONNECT',
  WEB_SOCKET_SUBSCRIBE_TO_LTP: 'WEB_SOCKET_SUBSCRIBE_TO_LTP',
  WEB_SOCKET_SUBSCRIBE_TO_MARKET_DEPTH: 'WEB_SOCKET_SUBSCRIBE_TO_MARKET_DEPTH',
  WEB_SOCKET_SUBSCRIBE_TO_TOTAL_BID_TOTAL_ASK:
    'WEB_SOCKET_SUBSCRIBE_TO_TOTAL_BID_TOTAL_ASK',
  WEB_SOCKET_UNSUBSCRIBE_TO_LTP: 'WEB_SOCKET_UNSUBSCRIBE_TO_LTP',
  WEB_SOCKET_UNSUBSCRIBE_TO_MARKET_DEPTH:
    'WEB_SOCKET_UNSUBSCRIBE_TO_MARKET_DEPTH',
  WEB_SOCKET_UNSUBSCRIBE_TO_TOTAL_BID_TOTAL_ASK:
    'WEB_SOCKET_UNSUBSCRIBE_TO_TOTAL_BID_TOTAL_ASK'
}

export const WORKER_TO_APP_ACTIONS = {
  WEB_SOCKET_ONOPEN: 'WEB_SOCKET_ONOPEN',
  ODIN_DATA_RECEIVED: 'ODIN_DATA_RECEIVED',
  GET_DATA_BY_SCRIPTID: 'GET_DATA_BY_SCRIPTID',
  WEB_SOCKET_RECONNECT: 'WEB_SOCKET_RECONNECT'
}

export type T_ACTIONS_STATUS_TYPES = 'SUCCESS' | 'FAILURE'

export interface T_STOCKDATA extends T_SCRIPTDATA {
  scriptId: string
  ODIN_LLFC_SEGMENTID: number
}

export type T_SUBSCRIBE_COUNT_DATA = {
  CMOTS_COCODE: number | string
  segment: string
  exchange: string
  odinTokenId: string | number
  ltp_count: number
  md_count: number
  tbtn_count: number
  isNiftySensexIndex?: boolean
}

export interface T_SUBSCRIBE_STOCK_DATA {
  scriptId: string
  CMOTS_COCODE: number | string
  exchange: string
  subscriptionType?: string
  segment: string
  odinTokenId: string
  instrumentType: string
}

export interface T_SUBSCRIPTION_TYPE {
  [key: string]: string
}

export const subscriptionTypeMapper: T_SUBSCRIPTION_TYPE = {
  1: 'md',
  2: 'tbtn'
}

export type T_ACTION_STATUS = {
  SUCCESS: string
  FAILURE: string
}

export const ACTION_STATUS: T_ACTION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export type T_ONMESSAGE_CTX = {
  func: (event: MessageEvent) => void
  ctx: any
}

export type T_ONERROR_CTX = {
  func: (event: MessageEvent) => void
  ctx: any
}

export type T_WORKER_TO_APP_ACTIONS_TYPES =
  | 'WEB_SOCKET_ONOPEN'
  | 'ODIN_DATA_RECEIVED'
  | 'GET_DATA_BY_SCRIPTID'

export type T_APP_TO_WORKER_ACTIONS_TYPES =
  | 'WEB_SOCKET_INITIALIZE'
  | 'WEB_SOCKET_SUBSCRIBE'

export type T_ACTION_SWITCHER_RESULT_TYPES = {
  action: T_APP_TO_WORKER_ACTIONS_TYPES | string
  data: any
  status: T_ACTIONS_STATUS_TYPES | string
  error?: any
}

export type T_SOCKET_DATA_CALLBACK = (
  data: any,
  action: T_WORKER_TO_APP_ACTIONS_TYPES,
  status: T_ACTIONS_STATUS_TYPES
) => void

export type T_SUBSCRIBE = {
  ODIN_TOKEN_ID: number
  SEGMENT: number
  OPRATION: number
}

export type T_UNSUBSCRIBE = {
  ODIN_TOKEN_ID: number
  SEGMENT: number
  OPRATION: number
}

export const DEFAULT_WSS_URL = AS_DEFAULT_WSS_URL

export type T_LOGIN_DATA = {
  token: string
  ent_id: string
}

export const getSubscriptionRoomType = (
  data: T_SUBSCRIBE_STOCK_DATA | any,
  isNiftySensexIndex?: boolean
) => {
  const { CMOTS_COCODE, exchange, subscriptionType = null, segment } = data

  if (isNiftySensexIndex) {
    return `${getExchangeType(exchange, isNiftySensexIndex)}_${CMOTS_COCODE}`
  }

  if (segment === 'EQ' || segment === 'COMM') {
    if (subscriptionType === 'md') {
      return `md_${exchange}_${CMOTS_COCODE}`
    }
    if (subscriptionType === 'tbtn') {
      return `md_${exchange}_tbtn_${CMOTS_COCODE}`
    }
    return `${exchange}_${CMOTS_COCODE}`
  }
  if (segment === 'FO') {
    if (subscriptionType === 'md') {
      return `md_nsefao_${CMOTS_COCODE}`
    }
    if (subscriptionType === 'tbtn') {
      return `md_nsefao_tbtn_${CMOTS_COCODE}`
    }
    return `nsefao_${CMOTS_COCODE}`
  }
  // currenncy
  if (subscriptionType === 'md') {
    return `md_cdx_${CMOTS_COCODE}`
  }
  if (subscriptionType === 'tbtn') {
    return `md_cdx_tbtn_${CMOTS_COCODE}`
  }
  return `cdx_${CMOTS_COCODE}`
}

export const processLtpData = (data: any) => {
  //NOTE - Only keep those values which are required in frontend
  const {
    y: EXCHANGE,
    r: LTT,
    c: CMOTS_COCODE,
    t: ODIN_TOKEN_ID,
    p: LTP,
    a: AVERAGE_TRADE_PRICE,
    o: OPEN_PRICE,
    h: HIGH_PRICE,
    l: LOW_PRICE,
    e: CLOSE_PRICE,
    q: LTQ,
    v: TRADE_QTY_ATP,
    f: NET_CHANGE,
    g: PERCENT_CHANGE,
    d: CURRENT_CHANGE,
    m: NET_CHANGE_1,
    n: TICK_SIZE,
    w: VOLUME,
    b: TOTAL_BUY_QTY,
    s: TOTAL_SELL_QTY,
    ah: LIFE_TIME_HIGH,
    al: LIFE_TIME_LOW,
    j: OPEN_INTEREST,
    k: OPEN_INTEREST_CHANGE,
    op: OPEN_INTEREST_PERCENTAGE_CHANGE,
    x: FIFTY_TWO_WEEK_HIGH,
    z: FIFTY_TWO_WEEK_LOW,
    u: UPPER_CIRCUIT,
    i: LOWER_CIRCUIT
  } = data

  return {
    EXCHANGE,
    LTT,
    CMOTS_COCODE,
    ODIN_TOKEN_ID,
    LTP,
    AVERAGE_TRADE_PRICE,
    OPEN_PRICE,
    HIGH_PRICE,
    LOW_PRICE,
    CLOSE_PRICE,
    LTQ,
    TRADE_QTY_ATP,
    NET_CHANGE,
    PERCENT_CHANGE,
    NET_CHANGE_1,
    TICK_SIZE,
    VOLUME,
    TOTAL_BUY_QTY,
    TOTAL_SELL_QTY,
    LIFE_TIME_HIGH,
    LIFE_TIME_LOW,
    CURRENT_CHANGE,
    OPEN_INTEREST,
    OPEN_INTEREST_CHANGE,
    OPEN_INTEREST_PERCENTAGE_CHANGE,
    FIFTY_TWO_WEEK_HIGH,
    FIFTY_TWO_WEEK_LOW,
    UPPER_CIRCUIT,
    LOWER_CIRCUIT
  }
}

export const processMdData = (data: any) => {
  const { y: EXCHANGE, c: CMOTS_COCODE, t: ODIN_TOKEN_ID, md } = data
  const B5BUY = md.slice(0, 5)
  const B5SELL = md.slice(5, 10)

  return {
    EXCHANGE,
    CMOTS_COCODE,
    ODIN_TOKEN_ID,
    B5BUY,
    B5SELL
  }
}

export const processTbTsData = (data: any) => {
  const {
    y: EXCHANGE,
    c: CMOTS_COCODE,
    t: ODIN_TOKEN_ID,
    tb: TOTAL_BID_QTY,
    ts: TOTAL_ASK_QTY
  } = data
  return {
    EXCHANGE,
    CMOTS_COCODE,
    ODIN_TOKEN_ID,
    TOTAL_BID_QTY,
    TOTAL_ASK_QTY
  }
}

export const getProcessedSocketData = (
  socketDataType: 'md' | 'tbtn' | 'ltp',
  data: any
) => {
  switch (socketDataType) {
    case 'md':
      return processMdData(data)
    case 'tbtn':
      return processTbTsData(data)
    case 'ltp':
      return processLtpData(data)
    default:
      break
  }
}

export const getExchangeType = (
  exchange: string,
  isNiftySensexIndex?: boolean
) => {
  if (isNiftySensexIndex) {
    return `${exchange?.toLowerCase()}_index`
  }

  return exchange
}

export const getSubscriptionArray = (scriptsArray: T_SCRIPTS_OBJ[] | any[]) => {
  const scriptsDetailedArray: T_STOCKDATA[] = []
  const subscriptionArray: T_SUBSCRIBE_STOCK_DATA[] = []
  scriptsArray.forEach((scriptObj: T_SCRIPTS_OBJ | any) => {
    const { scriptId } = scriptObj
    if (scriptId) {
      const {
        coname,
        exchange,
        odinTokenId,
        segment,
        exchangeSymbol,
        closeprice,
        maxSingleOrderQty,
        ticksize,
        lotsize,
        isinCode,
        instrumentType,
        asm_flag,
        aslAllowed,
        optionType,
        expiryDate,
        assetClass,
        yesterdayOpenInt,
        ODIN_LLFC_SEGMENTID,
        underlying,
        CMOTS_COCODE,
        DPRLOW,
        DPRHIGH,
        FIFTY_TWO_WEEK_LOW,
        FIFTY_TWO_WEEK_HIGH
      } = (window as any).secMaster.getByScriptId(scriptId) || {}
      const scriptData = {
        scriptId,
        coname,
        exchange,
        odinTokenId,
        segment,
        exchangeSymbol,
        closeprice,
        maxSingleOrderQty,
        ticksize,
        lotsize,
        isinCode,
        instrumentType,
        asm_flag,
        aslAllowed,
        optionType,
        expiryDate,
        assetClass,
        yesterdayOpenInt,
        ODIN_LLFC_SEGMENTID,
        underlying,
        CMOTS_COCODE,
        DPRLOW,
        DPRHIGH,
        FIFTY_TWO_WEEK_LOW,
        FIFTY_TWO_WEEK_HIGH
      }
      scriptsDetailedArray.push(scriptData)
      if (CMOTS_COCODE) {
        const subscriptionScripData = {
          CMOTS_COCODE,
          scriptId,
          exchange: exchange?.toLowerCase(),
          segment,
          odinTokenId,
          instrumentType
        }

        subscriptionArray.push(subscriptionScripData)
      }
    }
  })

  return { scriptsDetailedArray, subscriptionArray }
}
