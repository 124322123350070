import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import {
  TReverseFeedResponse,
  reverseFeedResponse
} from '~/src/Components/ReverseFeedAlert/ReverseFeedResponseMapper'

export type TReverseFeedStore = {
  reverseFeedAlertData: TReverseFeedResponse
}

const INITIAL_STATE: TReverseFeedStore = {
  reverseFeedAlertData: reverseFeedResponse
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateReverseFeedAlertData: (state, { payload }) => {
      state.reverseFeedAlertData = reverseFeedResponse
      state.reverseFeedAlertData = payload.reverseFeedAlertData
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {}
}

const reverseFeedSlice = createSlice(sliceOptions)

export const { updateReverseFeedAlertData } = reverseFeedSlice.actions

export default reverseFeedSlice.reducer
