import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import {
  deletePayoutRequestActions,
  getAvailableCommodityLimitsActions,
  getAvailableEquityLimitsActions,
  getFundPayoutStatusActions,
  getWithdrawCOMMLimitsActions,
  getWithdrawEQLimitsActions
} from './Actions'
import { SLICE_NAME } from './Selectors'

export type T_LIMITS_OBJECT = {
  title: string
  moreInfoKey: string
  value: string
  subGroup: {
    name: string
    value: string
  }[]
}
export type T_AVAILABLE_LIMIT = {
  availableToTransfer?: number
  currentMarginAvailable: number
  marginAddedToday: number
  marginAgainstCollateral: number
  marginUtilizedToday: number
}

export type T_INQUIRY = {
  availableEquityLimits: T_AVAILABLE_LIMIT
  availableCommodityLimits: T_AVAILABLE_LIMIT
  fundPayoutStatus: {}
  withdrawEQLimits: {}
  withdrawCOMMLimits: {}
  commLimits: T_LIMITS_OBJECT[]
  eqLimits: T_LIMITS_OBJECT[]
}

const INITIAL_STATE: T_INQUIRY = {
  availableEquityLimits: {
    availableToTransfer: 0,
    currentMarginAvailable: 0,
    marginAddedToday: 0,
    marginAgainstCollateral: 0,
    marginUtilizedToday: 0
  },
  availableCommodityLimits: {
    availableToTransfer: 0,
    currentMarginAvailable: 0,
    marginAddedToday: 0,
    marginAgainstCollateral: 0,
    marginUtilizedToday: 0
  },
  fundPayoutStatus: {},
  withdrawEQLimits: {},
  withdrawCOMMLimits: {},
  commLimits: [
    {
      title: 'Current Margin Available',
      moreInfoKey: 'CURRENT_MARGIN_AVAILABLE',
      value: '-',
      subGroup: [
        {
          name: 'Delivery',
          value: '-'
        },
        {
          name: 'Margin',
          value: '-'
        },
        {
          name: 'Options Buy',
          value: '-'
        }
      ]
    },
    {
      title: 'Opening Balance',
      moreInfoKey: 'OPENING_BALANCE',
      value: '-',
      subGroup: [
        {
          name: 'Ledger Balance',
          value: '-'
        },
        {
          name: 'Unreaslised Ledger Balance',
          value: '-'
        }
      ]
    },
    {
      title: 'Margin against collateral',
      moreInfoKey: 'MARGIN_AGAINST_COLLATERAL',
      value: '-',
      subGroup: [
        {
          name: 'Stocks',
          value: '-'
        },
        {
          name: 'Mutual Funds',
          value: '-'
        }
      ]
    },
    {
      title: 'Margin Added Today',
      moreInfoKey: 'MARGIN_ADDED_TODAY',
      value: '-',
      subGroup: [
        {
          name: 'Funds Transferred',
          value: '-'
        },
        {
          name: 'Bank Hold',
          value: '-'
        },
        {
          name: 'Credit against sale',
          value: '-'
        },
        {
          name: 'Adhoc Limit',
          value: '-'
        }
      ]
    },
    {
      title: 'Margin Utilised Today',
      moreInfoKey: 'MARGIN_UTILISED_TODAY',
      value: '-',
      subGroup: [
        {
          name: 'Delivery',
          value: '-'
        },
        {
          name: 'Cash Margin',
          value: '-'
        },
        {
          name: 'E - Margin',
          value: '-'
        },
        {
          name: 'Span + Exposure',
          value: '-'
        },
        {
          name: 'Premium',
          value: '-'
        },
        {
          name: 'MARK TO MARKET ',
          value: '-'
        }
      ]
    }
  ],
  eqLimits: [
    {
      title: 'Current Margin Available',
      moreInfoKey: 'CURRENT_MARGIN_AVAILABLE',
      value: '-',
      subGroup: [
        {
          name: 'Delivery',
          value: '-'
        },
        {
          name: 'Margin',
          value: '-'
        },
        {
          name: 'Options Buy',
          value: '-'
        }
      ]
    },
    {
      title: 'Opening Balance',
      moreInfoKey: 'OPENING_BALANCE',
      value: '-',
      subGroup: [
        {
          name: 'Ledger Balance',
          value: '-'
        },
        {
          name: 'Unreaslised Ledger Balance',
          value: '-'
        }
      ]
    },
    {
      title: 'Margin against collateral',
      moreInfoKey: 'MARGIN_AGAINST_COLLATERAL',
      value: '-',
      subGroup: [
        {
          name: 'Stocks',
          value: '-'
        },
        {
          name: 'Mutual Funds',
          value: '-'
        }
      ]
    },
    {
      title: 'Margin Added Today',
      moreInfoKey: 'MARGIN_ADDED_TODAY',
      value: '-',
      subGroup: [
        {
          name: 'Funds Transferred',
          value: '-'
        },
        {
          name: 'Bank Hold',
          value: '-'
        },
        {
          name: 'Credit against sale',
          value: '-'
        },
        {
          name: 'Adhoc Limit',
          value: '-'
        }
      ]
    },
    {
      title: 'Margin Utilised Today',
      moreInfoKey: 'MARGIN_UTILISED_TODAY',
      value: '-',
      subGroup: [
        {
          name: 'Delivery',
          value: '-'
        },
        {
          name: 'Cash Margin',
          value: '-'
        },
        {
          name: 'E - Margin',
          value: '-'
        },
        {
          name: 'Span + Exposure',
          value: '-'
        },
        {
          name: 'Premium',
          value: '-'
        },
        {
          name: 'MARK TO MARKET ',
          value: '-'
        }
      ]
    }
  ]
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getAvailableEquityLimitsActions.success,
      (state, { payload }: any) => {
        state.availableEquityLimits = payload
        const { withdrawEQLimits } = state
        state.eqLimits = setLimitsHelper(payload, withdrawEQLimits)
      }
    )
    builder.addCase(
      getAvailableCommodityLimitsActions.success,
      (state, { payload }: any) => {
        state.availableCommodityLimits = payload
        const { withdrawCOMMLimits } = state
        state.commLimits = setLimitsHelper(payload, withdrawCOMMLimits)
      }
    )
    builder.addCase(
      getFundPayoutStatusActions.success,
      (state: { fundPayoutStatus: any }, { payload }: any) => {
        state.fundPayoutStatus = payload
      }
    )
    builder.addCase(
      getWithdrawEQLimitsActions.success,
      (state: { withdrawEQLimits: any }, { payload }: any) => {
        state.withdrawEQLimits = payload
      }
    )
    builder.addCase(
      getWithdrawCOMMLimitsActions.success,
      (state: { withdrawCOMMLimits: any }, { payload }: any) => {
        state.withdrawCOMMLimits = payload
      }
    )
    builder.addCase(
      deletePayoutRequestActions.success,
      (state: { transactionStatus: any }, { payload }: any) => {
        // state.transactionStatus = payload
      }
    )
  }
})

const setLimitsHelper = (payload: any, withdrawLimits: any) => {
  const limits = [
    {
      title: 'Current Margin Available',
      moreInfoKey: 'CURRENT_MARGIN_AVAILABLE',
      value: payload.currentMarginAvailable,
      subGroup: [
        payload.deliveryCurrentMargin !== undefined
          ? {
              name: 'Delivery',
              value: payload.deliveryCurrentMargin
            }
          : null,
        payload.equityMargin !== undefined
          ? {
              name: 'Equity Margin',
              value: payload.equityMargin
            }
          : null,
        payload.derivativeMargin !== undefined
          ? {
              name: 'Derivatives Margin',
              value: payload.derivativeMargin
            }
          : null,
        payload.optionBuy !== undefined
          ? {
              name: 'Options Buy',
              value: payload.optionBuy
            }
          : null
      ]
    },
    {
      title: 'Ledger Balance',
      moreInfoKey: 'LEDGER_BALANCE',
      value: payload.ledgerBalance || null,
      subGroup: []
    },
    {
      title: 'Margin Against Collateral',
      moreInfoKey: 'MARGIN_AGAINST_COLLATERAL',
      value: payload.marginAgainstCollateral,
      subGroup: []
    },
    {
      title: 'Margin Added Today',
      moreInfoKey: 'MARGIN_ADDED_TODAY',
      value: payload.marginAddedToday,
      subGroup: [
        payload.bankHold !== undefined
          ? {
              name: 'Bank Hold',
              value: payload.bankHold
            }
          : null,
        payload.creditAgainstSale !== undefined
          ? {
              name: 'Credit against sale',
              value: payload.creditAgainstSale
            }
          : null,
        payload.realizedProfitLossAndPremium !== undefined
          ? {
              name: 'Realised P&L & premium',
              value: payload.realizedProfitLossAndPremium
            }
          : null,
        payload.adhocLimit !== undefined
          ? {
              name: 'Adhoc Limit',
              value: payload.adhocLimit
            }
          : null
      ]
    },
    {
      title: 'Margin Utilised Today',
      moreInfoKey: 'MARGIN_UTILISED_TODAY',
      value: payload.marginUtilizedToday,
      subGroup: [
        payload.deliveryMarginUtilized !== undefined
          ? {
              name: 'Delivery Margin utilised',
              value: payload.deliveryMarginUtilized
            }
          : null,
        payload.cashMargin !== undefined
          ? {
              name: 'Cash Margin',
              value: payload.cashMargin
            }
          : null,
        payload.emargin !== undefined
          ? {
              name: 'E - Margin',
              value: payload.emargin
            }
          : null,
        payload.spanExposure !== undefined
          ? {
              name: 'Span + Exposure',
              value: payload.spanExposure
            }
          : null,
        payload.premium !== undefined
          ? {
              name: 'Premium',
              value: payload.premium
            }
          : null,
        payload.mtm !== undefined
          ? {
              name: 'MARK TO MARKET ',
              value: payload.mtm
            }
          : null
      ]
    },
    {
      title: 'Funds Released',
      moreInfoKey: 'FUNDS_RELEASED',
      // value: (payload.marginUtilizedToday) ,
      subGroup: [
        withdrawLimits.releasableFunds !== undefined
          ? {
              name: 'Available To release',
              value: withdrawLimits.releasableFunds
            }
          : null,
        withdrawLimits.fundsHeld !== undefined
          ? {
              name: 'Funds Hold',
              value: withdrawLimits.fundsHeld
            }
          : null,
        withdrawLimits.payoutAmount !== undefined &&
        (withdrawLimits.releasableFunds !== undefined ||
          withdrawLimits.fundsHeld !== undefined)
          ? {
              name:
                withdrawLimits.payoutAmount !== undefined &&
                withdrawLimits.fundsHeld !== undefined
                  ? 'Available to Withdraw'
                  : 'Available to transfer',
              value: withdrawLimits.payoutAmount
            }
          : null
      ]
    },
    {
      title: 'Withdrawal Amount',
      moreInfoKey: 'WITHDRAWAL_AMOUNT',
      value:
        withdrawLimits.fundsHeld === undefined &&
        withdrawLimits.releasableFunds === undefined
          ? withdrawLimits.payoutAmount
          : null,
      subGroup: []
    }
  ]
  const filteredLimits = limits.map(obj => ({
    ...obj,
    subGroup: obj.subGroup.filter(item => item !== null)
  }))
  return filteredLimits
}

export default slice.reducer
