import { DsBox, DsTypography } from '@am92/react-design-system'
import React, { PureComponent } from 'react'
import { QUOTES_LABEL } from '~/src/Constants/LABEL'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import StockTickerNew from '~/src/Components/TickerBasedComponents/StockTickerNew'

export interface ISpotPriceProps extends IWithRouterProps {
  stockMasterData: any
  mappedId: string
}

export class SpotPrice extends PureComponent<ISpotPriceProps> {
  render() {
    const { stockMasterData, mappedId } = this.props
    const { closeprice = 0, segment } = stockMasterData || {}

    return (
      <DsBox
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          border: '1px solid var(--ds-colour-strokeDefault)',
          p: 'var(--ds-spacing-bitterCold)',
          borderRadius: 'var(--ds-radius-glacial)',
          mt: 'var(--ds-spacing-bitterCold)',
          mb: 'var(--ds-spacing-bitterCold)'
        }}
      >
        <DsTypography
          variant='bodyRegularMedium'
          color={'var(--ds-colour-typoSecondary)'}
        >
          {QUOTES_LABEL.OPTION_CHAIN.SPOT_PRICE}{' '}
        </DsTypography>
        <StockTickerNew
          scriptId={mappedId}
          closeprice={closeprice}
          segment={segment}
          WrapperProps={{
            sx: {
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 'var(--ds-spacing-glacial)'
            }
          }}
          LtpTypoProps={{
            variant: 'bodyBoldLarge'
          }}
          NetChangeTypoProps={{
            variant: 'bodyRegularLarge'
          }}
          NetChangeWrapperProps={{
            sx: {
              gap: 'var(--ds-spacing-deepFreeze)'
            }
          }}
          PercentChangeTypoProps={{
            sx: {
              '&::before': {
                content: '"("'
              },
              '&::after': {
                content: '")"'
              }
            }
          }}
          NetChangeIconProps={{
            fontSize: 'cool'
          }}
        />
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const stockMasterData = getStockMasterData(scriptId)(state)

  return {
    stockMasterData
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(SpotPrice)
