import { DsTableCell, DsTypography } from '@am92/react-design-system'
import React from 'react'
import { TTickerStore } from '~/src/Configurations/TickerStore'
import { useTickerSelector } from '~/src/hooks/useRedux'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { getOpenInterest } from '~/src/Redux/StockTicker/Selectors'

interface IOptionChainCellOIProps {
  color: 'default' | 'highlight'
  data: T_SCRIPTDATA
  handleNavigateToQuotes: (scriptId: string) => void
}

const OptionChainCellOI = (props: IOptionChainCellOIProps) => {
  const { color = 'default', data, handleNavigateToQuotes } = props
  const { scriptId = '', yesterdayOpenInt, segment } = data || {}
  const openInterest = useTickerSelector((state: TTickerStore) =>
    getOpenInterest(scriptId)(state)
  )
  const bgColor =
    color === 'default'
      ? 'var(--ds-colour-surfaceSecondary)'
      : 'var(--ds-colour-neutral3)'
  const handleGetOIChange = (yesterdayOpenInt: any, segment: string) => {
    if (
      segment !== 'FO' ||
      !yesterdayOpenInt ||
      !openInterest ||
      yesterdayOpenInt == '0'
    ) {
      return 0
    } else {
      const oiChange = openInterest - yesterdayOpenInt
      const percentChange = (oiChange / yesterdayOpenInt) * 100
      return percentChange
    }
  }
  return (
    <DsTableCell
      onClick={() => handleNavigateToQuotes(scriptId)}
      align='center'
      sx={{
        borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
        borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
        pt: 'var(--ds-spacing-quickFreeze)',
        pb: 'var(--ds-spacing-quickFreeze)',
        backgroundColor: bgColor,
        cursor: 'pointer'
      }}
    >
      <DsTypography
        variant='bodyRegularMedium'
        color={'var(--ds-colour-typoSecondary)'}
      >
        {yesterdayOpenInt === 0 ? '0' : openInterest || '-'}
      </DsTypography>
      <br />
      <DsTypography
        variant='bodyRegularMedium'
        color={'var(--ds-colour-typoSecondary)'}
      >
        {!handleGetOIChange(yesterdayOpenInt, segment)
          ? '-'
          : handleGetOIChange(yesterdayOpenInt, segment).toFixed(1)}
      </DsTypography>
    </DsTableCell>
  )
}

export default OptionChainCellOI
