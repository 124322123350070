import React from 'react'

// worker
import { socketWorkerManager } from '~/src/Workers/tcs/SocketWorkerManager'
import { odinWorkerManager } from '../Workers/Odin/OdinWorkerManager'
import { CONNECT_ODIN_SOCKET } from '../Configurations/env'

export interface IWithStockSubUnSubProps {
  odinWorkerManager: any
  subscribeWatchListStocks: (securitiesArray: any) => void
  unSubscribeWatchListStocks: (securitiesArray: any) => void
  subscribeLtpData: (securitiesArray: any) => void
  subscribeMarketDepthData: (securitiesArray: any) => void
  subscribeTotalBidTotalAskData: (securitiesArray: any) => void
  unSubscribeLtpData: (securitiesArray: any) => void
  unSubscribeMarketDepthData: (securitiesArray: any) => void
  unSubscribeTotalBidTotalAskData: (securitiesArray: any) => void
}

function withStockSubUnSub(
  Child: React.ComponentType<any>
): React.ComponentType<any> {
  class WithStockSubUnSub extends React.Component {
    socketWorkerManager = null as any
    odinWorkerManager = null as any

    //  subscribe function
    subscribeLtpData = (securitiesArray: any) => {
      if (CONNECT_ODIN_SOCKET) {
        this.subscribeWatchListStocks(securitiesArray)
        return
      }
      this.socketWorkerManager = socketWorkerManager
      if (securitiesArray && securitiesArray?.length > 0) {
        this.socketWorkerManager.subscribeLtpData(securitiesArray)
      }
    }

    subscribeMarketDepthData = (securitiesArray: any) => {
      if (CONNECT_ODIN_SOCKET) {
        this.subscribeWatchListStocks(securitiesArray)
        return
      }
      this.socketWorkerManager = socketWorkerManager
      if (securitiesArray && securitiesArray?.length > 0) {
        this.socketWorkerManager.subscribeMarketDepthData(securitiesArray)
      }
    }

    subscribeTotalBidTotalAskData = (securitiesArray: any) => {
      if (CONNECT_ODIN_SOCKET) {
        this.subscribeWatchListStocks(securitiesArray)
        return
      }
      this.socketWorkerManager = socketWorkerManager
      if (securitiesArray && securitiesArray?.length > 0) {
        this.socketWorkerManager.subscribeTotalBidTotalAskData(securitiesArray)
      }
    }

    //  unsubscribe function
    unSubscribeLtpData = (securitiesArray: any) => {
      if (CONNECT_ODIN_SOCKET) {
        this.unSubscribeWatchListStocks(securitiesArray)
        return
      }
      this.socketWorkerManager = socketWorkerManager
      if (securitiesArray && securitiesArray.length > 0) {
        this.socketWorkerManager.unSubscribeLtpData(securitiesArray)
      }
    }

    unSubscribeMarketDepthData = (securitiesArray: any) => {
      if (CONNECT_ODIN_SOCKET) {
        this.unSubscribeWatchListStocks(securitiesArray)
        return
      }
      this.socketWorkerManager = socketWorkerManager
      if (securitiesArray && securitiesArray.length > 0) {
        this.socketWorkerManager.unSubscribeMarketDepthData(securitiesArray)
      }
    }

    unSubscribeTotalBidTotalAskData = (securitiesArray: any) => {
      if (CONNECT_ODIN_SOCKET) {
        this.unSubscribeWatchListStocks(securitiesArray)
        return
      }
      this.socketWorkerManager = socketWorkerManager
      if (securitiesArray && securitiesArray.length > 0) {
        this.socketWorkerManager.unSubscribeTotalBidTotalAskData(
          securitiesArray
        )
      }
    }

    //NOTE - Old odin socket methods, to be deleted later
    //  subscribe function
    subscribeWatchListStocks = (securitiesArray: any) => {
      this.odinWorkerManager = odinWorkerManager
      if (securitiesArray && securitiesArray?.length > 0) {
        this.odinWorkerManager.subscribeStocks(securitiesArray)
      }
    }

    //  unsubscribe function
    unSubscribeWatchListStocks = (securitiesArray: any) => {
      this.odinWorkerManager = odinWorkerManager
      if (securitiesArray && securitiesArray.length > 0) {
        this.odinWorkerManager.unSubscribeStocks(securitiesArray)
      }
    }

    render() {
      return (
        <Child
          {...this.props}
          socketWorkerManager={this.socketWorkerManager}
          odinWorkerManager={this.odinWorkerManager}
          subscribeLtpData={this.subscribeLtpData}
          subscribeMarketDepthData={this.subscribeMarketDepthData}
          subscribeTotalBidTotalAskData={this.subscribeTotalBidTotalAskData}
          unSubscribeLtpData={this.unSubscribeLtpData}
          unSubscribeMarketDepthData={this.unSubscribeMarketDepthData}
          unSubscribeTotalBidTotalAskData={this.unSubscribeTotalBidTotalAskData}
        />
      )
    }
  }

  return WithStockSubUnSub
}

export default withStockSubUnSub
