import traceActionsCreator from '../traceActionsCreator'

import { createAction } from '@reduxjs/toolkit'

export const setSearchResultAction = createAction<any, string>('search')

export const setSearchSelectedTabAction = createAction<any, string>(
  'search/setSearchSelectedTabAction'
)

export const setSearchStringAction = createAction<any, string>(
  'search/setSearchStringAction'
)

export const clearSearchAction = createAction('search/clearSearchAction')

export const openSearchResultContainerAction = createAction<boolean>(
  'search/openSearchResultContainerAction'
)

export const globalSearchCallerClickAction = createAction<boolean>(
  'search/globalSearchCallerClickAction'
)

export const getRecentSearchServiceName = 'search/getRecentSearch'
export const getRecentSearchActions = traceActionsCreator(
  getRecentSearchServiceName
)

export const createRecentSearchName = 'search/createRecentSearch'
export const createRecentSearchActions = traceActionsCreator(
  createRecentSearchName
)

export const deleteRecentSearchName = 'search/deleteRecentSearch'
export const deleteRecentSearchActions = traceActionsCreator(
  deleteRecentSearchName
)
