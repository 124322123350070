import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'

export const createModifyAlertsFormServiceName = 'alerts/createModifyAlertsForm'
export const createModifyAlertsFormActions = traceActionsCreator(
  createModifyAlertsFormServiceName
)

export const removeAlertsServiceName = 'alerts/removeAlerts'
export const removeAlertsActions = traceActionsCreator(removeAlertsServiceName)

export const getAlertsServiceName = 'alerts/getAlerts'
export const getAlertsActions = traceActionsCreator(getAlertsServiceName)

export const toggleAlertsFormServiceName = 'alerts/toggleAlertsForm'
export const toggleAlertsFormActions = createAction<any>(
  toggleAlertsFormServiceName
)

export const removeTriggeredAlertServiceName = 'alerts/removeTriggeredAlert'
export const removeTriggeredAlertAction = createAction<any, string>(
  removeTriggeredAlertServiceName
)
