import { createSelector } from '@reduxjs/toolkit'
import { TAppStore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'search'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getSearch = createSelector(select, search => search.searchResult)
export const getSearchStringSelector = createSelector(
  select,
  search => search.searchString
)
export const getIsSearchResultContainerOpenSelector = createSelector(
  select,
  search => search.openSearchResultContainer
)
export const getIsGlobalSearchCallerClickedSelector = createSelector(
  select,
  search => search.isGlobalSearchCallerClicked
)
export const getSearchSelectedTabSelector = createSelector(
  select,
  search => search.selectedTab
)

export const getRecentSearch = createSelector(
  select,
  search => search.recentSearchData
)

export const getFilteredSearchResult = (selectedTab: string) =>
  createSelector(select, (searchStore: any) => {
    const { searchResult } = searchStore || {}
    if (selectedTab === 'ALL') {
      return searchResult
    }

    return searchResult.filter((item: { segment: string }) => {
      return item.segment === selectedTab
    })
  })
