import {
  DsBox,
  enqueueNotistack,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import {
  selectWatchlist,
  setWatchlist,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import {
  IUpdateWatchListSequencePayload,
  updateWatchlistsSequencePayload
} from '~/src/Redux/WatchList/Services/UpdateWatchlistsSequence.Service'
import {
  getSelectedWatchlist,
  getWatchListData,
  preDefinedWatchlistCountSelector
} from '~/src/Redux/WatchList/Selectors'
import SingleWatchlist from './SingleWatchlist'
import { ISetWatchlistActionPayload } from '../Sidebar/Components/ManageWatchlist'
import updateWatchlistSequenceAction from '~/src/Redux/WatchList/Services/UpdateWatchlistsSequence.Service'
import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'

type ActionTypes = {
  updateWatchlistSequence: (
    reqData: updateWatchlistsSequencePayload
  ) => Promise<any>
  setWatchlist: (reqData: ISetWatchlistActionPayload) => Promise<any>
  setSelectedWatchlist: (selectedWatchlist: watchlistObj) => Promise<any>
}

export interface IManageWatchlistContainerProps extends IWithRouterProps {
  actions: ActionTypes
  watchList: watchlistObj[]
  selectedWatchlist: watchlistObj
  preDefinedWatchlistCount: number
}

class ManageWatchlistContainer extends Component<IManageWatchlistContainerProps> {
  componentDidMount(): void {
    const { selectedWatchlist, actions, watchList, preDefinedWatchlistCount } =
      this.props
    if (Object.keys(selectedWatchlist).length === 0) {
      const isCustomWatchListAvailable = !(
        watchList.length === preDefinedWatchlistCount
      )
      isCustomWatchListAvailable &&
        actions.setSelectedWatchlist(watchList[preDefinedWatchlistCount])
    }
  }

  handleDragEnd = async (result: DropResult) => {
    const { preDefinedWatchlistCount } = this.props
    const source = result.source.index
    const destination = result.destination?.index

    if (
      destination === source ||
      destination === null ||
      destination === undefined
    ) {
      return
    }

    if (
      (source >= 0 && source < preDefinedWatchlistCount) ||
      (destination >= 0 && destination < preDefinedWatchlistCount)
    ) {
      enqueueNotistack({
        message: 'Can not update the position of this watchlist.'
      })
      return
    }

    const { actions, watchList } = this.props
    const newItems = Array.from(watchList)
    const currentWatchlist = Array.from(watchList)

    const [removed] = newItems.splice(source, 1)
    newItems.splice(destination, 0, removed)

    let payloadArray: IUpdateWatchListSequencePayload[] = []
    let newWatchlistArray: watchlistObj[] = []

    newItems.forEach((item: watchlistObj, index: number) => {
      payloadArray.push({
        watchlistId: item.watchlistId,
        sequenceNumber: index
      })
      newWatchlistArray.push({
        ...item,
        watchlistSequenceNumber: index
      })
    })

    // changing sequence at local/redux
    actions.setWatchlist({
      Watchlist: newWatchlistArray
    })

    // changing sequence in db/backend
    const requestData: updateWatchlistsSequencePayload = {
      watchlists: payloadArray
    }

    const updateWatchlistSequenceResponse =
      await actions.updateWatchlistSequence(requestData)
    if (updateWatchlistSequenceResponse?._isCustomError) {
      // if api fails then keeping the previous sequence ready
      actions.setWatchlist({
        Watchlist: currentWatchlist
      })

      console.log('failed API - updateWatchlistSequence')

      enqueueNotistack({
        message: 'Request failed!'
      })
      return
    }

    enqueueNotistack({
      message: 'Watchlist Sequence Updated!'
    })
  }

  render() {
    const { watchList } = this.props
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <DsBox
          sx={{
            paddingX: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-zero)'
            },
            paddingBottom: {
              xs: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-deepFreeze))',
              md: 'var(--ds-spacing-zero)'
            },
            backgroundColor: 'var(--ds-colour-surfaceBackground)'
          }}
        >
          <DsBox
            sx={{
              mb: {
                md: 'calc(var(--ds-spacing-plasma) + var(--ds-spacing-plasma))',
                xs: 'unset'
              }
            }}
          >
            <Droppable droppableId='list'>
              {provided => (
                <DsBox {...provided.droppableProps} ref={provided.innerRef}>
                  {watchList.map(
                    (singleWatchlistData: watchlistObj, i: number) => {
                      const { watchlistId, watchlistType } = singleWatchlistData
                      const draggableKey = watchlistId + i
                      const draggableId = watchlistId + i
                      const isDragDisabled = watchlistType === 'PRE-DEFINED'
                      return (
                        <SingleWatchlist
                          itemIndex={i}
                          draggableKey={draggableKey}
                          draggableId={draggableId}
                          isDragDisabled={isDragDisabled}
                          singleWatchlistData={singleWatchlistData}
                        />
                      )
                    }
                  )}

                  {provided.placeholder}
                </DsBox>
              )}
            </Droppable>
          </DsBox>
        </DsBox>
      </DragDropContext>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const watchList = getWatchListData(state)
  const selectedWatchlist = getSelectedWatchlist(state)
  const preDefinedWatchlistCount = preDefinedWatchlistCountSelector(state)

  return {
    watchList,
    selectedWatchlist,
    preDefinedWatchlistCount
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    updateWatchlistSequence: (requestData: updateWatchlistsSequencePayload) =>
      dispatch(updateWatchlistSequenceAction(requestData)),
    setWatchlist: (requestData: ISetWatchlistActionPayload) =>
      dispatch(setWatchlist(requestData)),
    setSelectedWatchlist: (selectedWatchlist: watchlistObj) =>
      dispatch(selectWatchlist(selectedWatchlist))
  }
})

export default withBreakpoints(
  withErrorConnect(
    mapStateToProps,
    mapDispatchToProps
  )(ManageWatchlistContainer)
)
