import React from 'react'
import {
  DsBox,
  DsButton,
  DsDialog,
  DsImage,
  DsImageSrcSetProps,
  DsRemixIcon,
  DsStack,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

import { BULB } from '~/src/Constants/ASSET_MAP'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'


export type TPostAPIResponseDialog = {
  onClose?: () => void
  showClose?: boolean
  type?:
    | 'display'
    | 'dualAction'
    | 'singleAction'
    | 'none'
    | 'variable'
    | string
  buttonLocationInMiddle?: boolean
  image?: DsImageSrcSetProps[]
  heading?: string
  description?: string
  primaryText?: string
  secondaryText?: string
  noteText?: string
  primaryAction?: () => void
  secondaryAction?: () => void
  renderPostAPIDialog?: boolean
}
interface TPostAPIResponseDialogProps
  extends IwithBreakpoints,
    TPostAPIResponseDialog {}

interface TPostAPIResponseDialogState {}

class PostAPIResponseDialog extends React.Component<
  TPostAPIResponseDialogProps,
  TPostAPIResponseDialogState
> {
  handleRenderBottom = () => {
    const {
      type,
      primaryText,
      secondaryText,
      primaryAction,
      secondaryAction,
      noteText,
      breakpoints = {}
    } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    if (type === 'singleAction') {
      return (
        <DsBox
          sx={{
            p: { xs: 'var(--ds-spacing-bitterCold)', md: 'unset' },
            width: '100%'
          }}
        >
          <DsButton onClick={primaryAction} size='medium' fullWidth>
            {primaryText}
          </DsButton>
        </DsBox>
      )
    }
    if (type === 'dualAction') {
      return (
        <DsBox
          sx={{
            p: { xs: 'var(--ds-spacing-bitterCold)', md: 'unset' },
            width: '100%',
            gap: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <DsButton
            onClick={secondaryAction}
            size='medium'
            fullWidth
            color='secondary'
          >
            {secondaryText}
          </DsButton>
          <DsButton onClick={primaryAction} size='medium' fullWidth>
            {primaryText}
          </DsButton>
        </DsBox>
      )
    }
    if (type === 'display') {
      return (
        <DsBox
          sx={{
            p: 'var(--ds-spacing-frostbite)',
            width: '100%',
            gap: 'var(--ds-spacing-bitterCold)',
            flexDirection: 'row',
            border: '1px solid var(--ds-colour-strokeDefault)',
            mx: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-zero)'
            },
            borderRadius: 'var(--ds-radius-glacial)'
          }}
        >
          <DsBox>
            {' '}
            <DsImage srcSet={BULB} />
          </DsBox>
          <DsTypography
            variant='bodyRegularSmall'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            {noteText}
          </DsTypography>
        </DsBox>
      )
    }
    if (type === 'none') {
      return <></>
    }
    if (type === 'variable') {
      return (
        <DsBox
          sx={{
            width: { xs: '50%', md: '100%' },
            gap: 'var(--ds-spacing-bitterCold)'
          }}
        >
          {(isDesktop && (
            <DsButton
              onClick={secondaryAction}
              size='medium'
              fullWidth
              color='secondary'
            >
              {secondaryText}
            </DsButton>
          )) ||
            null}
          <DsButton onClick={primaryAction} size='medium' fullWidth>
            {primaryText}
          </DsButton>
        </DsBox>
      )
    }
  }

  render() {
    const {
      breakpoints = {},
      onClose,
      showClose,
      heading,
      description,
      renderPostAPIDialog,
      image,
      type
    } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const showCloseFlag = showClose === undefined ? !isDesktop : showClose
    const isButtonGroupTypeVariable = type === 'variable'
    return (
      <>
        <DsDialog
          sx={{
            '.MuiPaper-root': {
              display: 'flex',
              borderRadius: {
                xs: 'var(--ds-radius-zero)',
                md: 'var(--ds-radius-bitterCold)'
              },
              '& .MuiStack-root': {
                justifyContent: 'center',
                '& .MuiBox-root': {}
              },
              '& .MuiBox-root': {
                display: 'flex'
              },
              '.MuiDialogContent-root ': {
                marginTop: 'unset'
              }
            }
          }}
          showClose={false}
          open={renderPostAPIDialog}
          fullScreen={!isDesktop}
        >
          <DsStack
            sx={{
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {(showCloseFlag && (
              <DsStack
                sx={{
                  alignItems: 'flex-start',
                  width: '100%'
                }}
              >
                <DsRemixIcon
                  className='ri-close-fill'
                  style={{ cursor: 'pointer' }}
                  onClick={onClose}
                />
              </DsStack>
            )) ||
              null}
            <DsStack
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                gap: {
                  xs: 'var(--ds-spacing-mild)',
                  md: 'var(--ds-spacing-frostbite)'
                },
                textAlign: 'center',
                height: '100%',
                marginTop: {
                  xs: 'var(--ds-spacing-meltdown)',
                  md: 'unset'
                }
              }}
            >
              <DsBox>
                <DsImage srcSet={image} />
              </DsBox>
              <DsTypography variant='headingBoldSmall'>{heading}</DsTypography>
              <DsTypography
                variant='bodyRegularMedium'
                sx={{ color: 'var(--ds-colour-typoSecondary)' }}
              >
                {description}
              </DsTypography>
            </DsStack>
            <DsStack
              sx={{
                flexDirection: 'row',
                position: {
                  xs: (isButtonGroupTypeVariable && 'unset') || 'fixed',
                  md: 'unset'
                },
                bottom: {
                  xs: (isButtonGroupTypeVariable && 'unset') || '0',
                  md: 'unset'
                },
                width: '100%',
                mb: {
                  xs:
                    (isButtonGroupTypeVariable && 'var(--ds-spacing-zero)') ||
                    'var(--ds-spacing-bitterCold)',
                  md: 'var(--ds-spacing-zero)'
                },
                mt: {
                  xs:
                    (isButtonGroupTypeVariable && 'var(--ds-spacing-mild)') ||
                    'var(--ds-spacing-zero)',
                  md: 'var(--ds-spacing-mild)'
                }
              }}
            >
              {this.handleRenderBottom()}
            </DsStack>
          </DsStack>
        </DsDialog>
      </>
    )
  }
}

export default withBreakpoints(PostAPIResponseDialog)
