import React, { Component } from 'react'
import {
  DsBottomSheet,
  DsBox,
  DsButton,
  DsFade,
  DsMenu,
  DsMenuItem,
  DsPopup,
  DsRadio,
  DsRadioGroup,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import { FormikErrors } from 'formik'

import EncashSection from './EncashSection'
import { FormValues } from './OrderFormSection'

import { checkSpotActiveSelector } from '~/src/Redux/Customer/Selectors'
import {
  checkIfHoldingsAvailable,
  getHoldingAndAveragePrice
} from '~/src/Redux/Dashboard/Selectors'
import getHoldingsSummaryAction, {
  getHoldingsSummaryPayload
} from '~/src/Redux/Dashboard/Services/getHoldingsSummary.Service'

import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

type ActionTypes = {
  getHoldingsSummary: (requestData: getHoldingsSummaryPayload) => Promise<any>
}

export interface ISellableQtyAndEncashProps extends IWithRouterProps {
  actions: ActionTypes
  orderBuyOrSell: string
  orderScriptId: string
  dmatfreeQty: number
  emarginNetQuantity: number
  collateralQty: number
  breakpoints: any
  sellableQtyType: string
  handleChange: (e: React.ChangeEvent<any>) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>
  checkSpotActive: boolean
  showSellableRow: boolean
  encash: boolean
  orderType: string
  isModifyOrder: boolean
  areHoldingsAvailable: boolean
}

type ISellableQtyAndEncashStates = {
  openSellableInfo: boolean
  openSellableBottomSheet: boolean
  anchorEl: Element | (() => Element) | null | undefined
}

class SellableQtyAndEncash extends Component<
  ISellableQtyAndEncashProps,
  ISellableQtyAndEncashStates
> {
  constructor(props: ISellableQtyAndEncashProps) {
    super(props)
    this.state = {
      openSellableInfo: false,
      openSellableBottomSheet: false,
      anchorEl: null
    }
  }

  handleShowSellableInfo = async () => {
    const { openSellableInfo } = this.state
    if (!openSellableInfo) {
      const { areHoldingsAvailable } = this.props
      if (!areHoldingsAvailable) this.getHoldingsSummaryDetails()
    }
    this.setState({ openSellableInfo: !openSellableInfo })
  }

  getHoldingsSummaryDetails = async () => {
    const { actions } = this.props
    const requestData: getHoldingsSummaryPayload = {
      segment: 'EQ'
    }
    const getHoldingsSummaryResponse =
      await actions.getHoldingsSummary(requestData)
    if (getHoldingsSummaryResponse._isCustomError) {
      console.log('failed API - getHoldingsSummary')
      return
    }
  }

  handleOpenSellableMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | any
  ) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleCloseSellableMenu = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleOpenSellableBottomSheet = () => {
    this.setState({
      openSellableBottomSheet: true
    })
  }

  handleCloseSellableBottomSheet = () => {
    this.setState({
      openSellableBottomSheet: false
    })
  }

  handleChangeSellableType = () => {
    this.setState({
      openSellableBottomSheet: false,
      anchorEl: null
    })
  }

  render() {
    const {
      orderBuyOrSell,
      dmatfreeQty = 'NA',
      emarginNetQuantity = 'NA',
      collateralQty = 'NA',
      breakpoints,
      sellableQtyType,
      handleChange,
      setFieldValue,
      checkSpotActive,
      showSellableRow,
      encash,
      orderType,
      isModifyOrder
    } = this.props
    const { openSellableBottomSheet, openSellableInfo, anchorEl } = this.state
    const openSellableMenu = Boolean(anchorEl)
    const isBUY = orderBuyOrSell === 'BUY'
    const orderColor = isBUY ? 'supportPositive' : 'supportNegative'
    const sellableTypesConstants = ['DEMAT', 'Collateral (Pledged)', 'BMPL']
    const sellabledata = [
      {
        type: 'DEMAT',
        quantity: dmatfreeQty
      },
      {
        type: 'Collateral (Pledged)',
        quantity: collateralQty
      },
      {
        type: 'BMPL',
        quantity: emarginNetQuantity
      }
    ]
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const showEncashRow =
      showSellableRow &&
      sellableQtyType === 'DEMAT' &&
      checkSpotActive &&
      (orderType === 'EN_CASH' || orderType === 'DELIVERY')

    return (
      <>
        <DsStack
          sx={{
            paddingLeft: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-mild)'
            },
            paddingRight: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-mild)'
            },
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <DsStack
            onClick={this.handleShowSellableInfo}
            sx={{ flexDirection: 'column', cursor: 'pointer' }}
          >
            <DsTypography
              color={`var(--ds-colour-${orderColor})`}
              variant='bodyRegularSmall'
            >
              Sellable Qty.
            </DsTypography>
            <DsBox
              sx={{
                border: `1px dashed var(--ds-colour-${orderColor})`,
                height: '1px',
                width: '100%'
              }}
            ></DsBox>
          </DsStack>

          <DsButton
            color='secondary'
            id='demo-positioned-button'
            onClick={
              isDesktop
                ? this.handleOpenSellableMenu
                : this.handleOpenSellableBottomSheet
            }
            endIcon={<DsRemixIcon className='ri-arrow-down-s-line' />}
            disabled={isModifyOrder}
          >
            {sellableQtyType}
          </DsButton>

          <DsMenu
            transformOrigin={{
              horizontal: 'left',
              vertical: 'top'
            }}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom'
            }}
            anchorEl={anchorEl}
            sx={{
              display: { xs: 'none', md: 'block' }
            }}
            open={openSellableMenu}
            onClose={this.handleCloseSellableMenu}
            MenuListProps={{
              'aria-labelledby': 'fade-button'
            }}
            id='fade-menu'
            aria-labelledby='fade-menu'
            variant='menu'
            TransitionComponent={DsFade}
          >
            {sellableTypesConstants.map((item: string, index: number) => (
              <DsMenuItem
                onClick={() => {
                  setFieldValue('sellableQtyType', item)
                  this.handleChangeSellableType()
                }}
                value={item}
                key={index}
              >
                {item}
              </DsMenuItem>
            ))}
          </DsMenu>
        </DsStack>

        {showEncashRow && (
          <EncashSection
            encash={encash}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            isModifyOrder={isModifyOrder}
            isBUY={isBUY}
          />
        )}

        <DsPopup
          open={openSellableInfo}
          onClose={this.handleShowSellableInfo}
          showClose={true}
          title='Sellable Quantities'
        >
          <DsStack
            spacing={4}
            sx={{
              borderRadius: { xs: 'unset', md: 'var(--ds-spacing-glacial)' },
              border: {
                xs: 'unset',
                md: '1px solid var(--ds-colour-strokeDefault)'
              },
              padding: {
                xs: 'unset',
                md: 'var(--ds-spacing-bitterCold)'
              },
              marginBottom: {
                xs: 'var(--ds-spacing-bitterCold)',
                md: 'unset'
              }
            }}
          >
            {sellabledata.map((item, index) => {
              return (
                <DsStack
                  sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
                  key={index}
                >
                  <DsTypography variant='supportRegularMetadata'>
                    {item.type}
                  </DsTypography>
                  <DsTypography variant='bodyBoldSmall'>
                    {item.quantity}
                  </DsTypography>
                </DsStack>
              )
            })}
          </DsStack>
        </DsPopup>

        <DsBottomSheet
          onClose={this.handleCloseSellableBottomSheet}
          open={openSellableBottomSheet}
          title='Sell from?'
          sx={{
            display: { xs: 'block', md: 'none' }
          }}
        >
          <DsRadioGroup
            value={sellableQtyType}
            name='sellableQtyType'
            onChange={e => {
              handleChange(e)
              this.handleChangeSellableType()
            }}
          >
            {sellableTypesConstants.map((item: string, index: number) => {
              return (
                <DsRadio
                  sx={{
                    '& .Mui-checked': {
                      color: `var(--ds-colour-${orderColor})`
                    }
                  }}
                  label={item}
                  value={item}
                  key={index}
                />
              )
            })}
          </DsRadioGroup>
        </DsBottomSheet>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { orderScriptId } = ownProps
  const { dmatfreeQty, emarginNetQuantity, collateralQty } =
    getHoldingAndAveragePrice(orderScriptId)(state) || {}
  const checkSpotActive = checkSpotActiveSelector(state)
  const areHoldingsAvailable = checkIfHoldingsAvailable(state)

  return {
    dmatfreeQty,
    emarginNetQuantity,
    collateralQty,
    checkSpotActive,
    areHoldingsAvailable
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getHoldingsSummary: (requestData: getHoldingsSummaryPayload) =>
      dispatch(getHoldingsSummaryAction(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SellableQtyAndEncash)
)
