import {
  DsBox,
  DsDivider,
  DsRemixIcon,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { toggleManageWatchlist } from '~/src/Redux/WatchList/Reducer'
import CreateWatchlist from './CreateWatchlist'

type ActionTypes = {
  toggleManageWatchlist: (reqData: {
    isManageWatchlistClicked: boolean
  }) => Promise<any>
}

export interface IManageWatchlistHeaderProps extends IWithRouterProps {
  actions: ActionTypes
}

class ManageWatchlistHeader extends Component<IManageWatchlistHeaderProps> {
  handleBackButtonClick = () => {
    const { actions } = this.props
    actions.toggleManageWatchlist({ isManageWatchlistClicked: false })
  }

  render() {
    return (
      <>
        <DsBox
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            p: 'var(--ds-spacing-bitterCold)',
            gap: 'var(--ds-spacing-glacial)',
            position: 'sticky',
            top: 0,
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            zIndex: 2,
            marginTop: 'var(--ds-spacing-bitterCold)',
            borderRadius:
              'var(--ds-radius-glacial) var(--ds-radius-glacial) var(--ds-radius-zero) var(--ds-radius-zero)'
          }}
        >
          <DsRemixIcon
            onClick={this.handleBackButtonClick}
            className='ri-arrow-left-line'
            sx={{
              cursor: 'pointer'
            }}
          />
          <DsTypography variant='bodyBoldLarge'>
            {WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.MANAGE_WATCHLIST_LABEL}
          </DsTypography>
        </DsBox>
        <DsDivider
          sx={{
            position: 'sticky',
            top: {
              md: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-glacial))',
              xs: 'calc(var(--ds-spacing-molten) + var(--ds-spacing-molten) - var(--ds-spacing-glacial))'
            },
            zIndex: 3
          }}
        />
        <DsBox
          sx={{
            p: 'var(--ds-spacing-bitterCold)',
            position: 'sticky',
            top: {
              xs: 'calc(var(--ds-spacing-molten) + var(--ds-spacing-molten) - var(--ds-spacing-glacial))',
              md: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-glacial))'
            },
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            zIndex: 2,
            pt: 'var(--ds-spacing-pleasant)'
          }}
        >
          <DsBox
            sx={{
              border: '1px dashed var(--ds-colour-strokeDefault)',
              borderRadius: 'var(--ds-radius-glacial)',
              p: 'var(--ds-spacing-bitterCold)'
            }}
          >
            <CreateWatchlist componentType='manageWatchlist' />
          </DsBox>
        </DsBox>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    toggleManageWatchlist: (reqData: { isManageWatchlistClicked: boolean }) =>
      dispatch(toggleManageWatchlist(reqData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(ManageWatchlistHeader)
)
