import React from 'react'
import {
  DsStack,
  DsStackProps,
  DsTypography,
  DsTypographyProps
} from '@am92/react-design-system'
import { TTickerStore } from '~/src/Configurations/TickerStore'
import { getCurrencyFormat } from '~/src/Utils/global'
import { getIndividualOverallPnlData } from '~/src/Redux/HoldingTicker/Selectors'
import { useTickerSelector } from '~/src/hooks/useRedux'
import { getOverallPnlUsingHolding } from '~/src/Redux/StockTicker/Selectors'

interface IIndividualHoldingsOverallPnlValueTickerProps {
  scriptId: string
  holdings?: number | string
  investedValue?: number | string
  closeprice?: number
  overallPnlWrapperProps?: DsStackProps
  overallPnlTypoProps?: DsTypographyProps
  overallPnlPercentChangeTypoProps?: DsTypographyProps
  overallPnlNumberFormatOptions?: Intl.NumberFormatOptions
  overallPnlPercentChangeNumberFormatOptions?: Intl.NumberFormatOptions
}

const IndividualHoldingsOverallPnlValueTicker: React.FunctionComponent<
  IIndividualHoldingsOverallPnlValueTickerProps
> = (props: IIndividualHoldingsOverallPnlValueTickerProps) => {
  const {
    scriptId,
    holdings = null,
    investedValue,
    closeprice,
    overallPnlWrapperProps,
    overallPnlTypoProps,
    overallPnlPercentChangeTypoProps
  } = props

  const overallPnl = useTickerSelector(
    (state: TTickerStore) =>
      (holdings &&
        getOverallPnlUsingHolding(
          state,
          scriptId,
          holdings,
          investedValue,
          closeprice
        )) ||
      getIndividualOverallPnlData(state, scriptId)
  )
  const { percentChange = 0, value = 0 } = overallPnl || {}
  const getTypoColor = () => {
    return value
      ? value > 0
        ? 'var(--ds-colour-iconPositive)'
        : 'var(--ds-colour-iconNegative)'
      : 'var(--ds-colour-typoPrimary)'
  }

  const getOverallPnlValue = () => {
    const { overallPnlNumberFormatOptions } = props
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: 2,
      signDisplay: 'exceptZero'
    }
    return `${getCurrencyFormat(value, {
      ...options,
      ...overallPnlNumberFormatOptions
    })} `
  }

  const getOverallPnlPercentage = () => {
    const { overallPnlPercentChangeNumberFormatOptions } = props
    const options: Intl.NumberFormatOptions = {
      style: 'percent',
      minimumFractionDigits: 2,
      signDisplay: 'never'
    }
    return `${getCurrencyFormat(percentChange / 100, {
      ...options,
      ...overallPnlPercentChangeNumberFormatOptions
    })}`
  }

  const renderOverallPnlValues = () => {
    const typoColor = getTypoColor()
    const jsx = []
    jsx.push(
      <DsTypography
        color={typoColor}
        variant='bodyRegularMedium'
        {...overallPnlTypoProps}
      >
        {getOverallPnlValue()}
      </DsTypography>
    )
    jsx.push(
      <DsTypography
        color={typoColor}
        variant='bodyRegularMedium'
        {...overallPnlPercentChangeTypoProps}
      >
        {getOverallPnlPercentage()}
      </DsTypography>
    )
    return jsx
  }

  return (
    <DsStack
      flexDirection='column'
      alignItems='flex-end'
      {...overallPnlWrapperProps}
    >
      {renderOverallPnlValues()}
    </DsStack>
  )
}

export default IndividualHoldingsOverallPnlValueTicker
