import { T_OVERALL_PNL } from '../HoldingTicker/TYPES'

export interface T_TOTAL_PNL extends T_OVERALL_PNL {}
export interface T_UNREALISED_PNL extends T_OVERALL_PNL {}
export interface T_REALISED_PNL extends T_OVERALL_PNL {}

export type T_SINGLE_OPEN_POSITION = {
  scriptId: string
  closeprice: number
  netQty: number
  investedValue: number
  unRealisedPnl: T_UNREALISED_PNL
}

export type T_SINGLE_TODAY_POSITION = T_SINGLE_OPEN_POSITION

export type T_OPEN_POSITION_SCRIPTS = {
  [key: number | string]: T_SINGLE_OPEN_POSITION
}

export type T_TODAY_POSITION_SCRIPTS = {
  [key: number | string]: T_SINGLE_TODAY_POSITION
}

export type T_SCRIPT_TO_DOCUMENTID_MAPPER = {
  [key: number | string]: string[]
}

export interface T_POSITION_TICKER_REDUCER {
  typeOfPosition: 'open' | 'today'
  totalInvestedValue: number
  totalPnl: T_TOTAL_PNL
  unRealisedPnl: T_UNREALISED_PNL
  realisedPnl: T_REALISED_PNL
  scriptsInOpenPosition: T_OPEN_POSITION_SCRIPTS
  scriptsInTodayPosition: T_TODAY_POSITION_SCRIPTS
  scriptToDocumentIdMapperForOpenPositions: T_SCRIPT_TO_DOCUMENTID_MAPPER
  scriptToDocumentIdMapperForTodayPositions: T_SCRIPT_TO_DOCUMENTID_MAPPER
}

export const INITIAL_STATE: T_POSITION_TICKER_REDUCER = {
  typeOfPosition: 'open',
  totalInvestedValue: 0,
  totalPnl: {
    value: 0,
    percentChange: 0
  },
  unRealisedPnl: {
    value: 0,
    percentChange: 0
  },
  realisedPnl: {
    value: 0,
    percentChange: 0
  },
  scriptsInOpenPosition: {}, //scriptId_ID as key : T_OPEN_POSITION_SCRIPTS
  scriptsInTodayPosition: {}, //scriptId_ID as key : T_TODAY_POSITION_SCRIPTS
  scriptToDocumentIdMapperForOpenPositions: {}, // scriptId : ['scriptId_id1','scriptId_id2','scriptId_id3']
  scriptToDocumentIdMapperForTodayPositions: {} // scriptId : ['scriptId_id1','scriptId_id2','scriptId_id3']
}
