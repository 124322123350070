import {
  DsButton,
  DsIconButton,
  DsRemixIcon,
  DsTooltip
} from '@am92/react-design-system'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { TAppStore } from '../Configurations/AppStore'
import { useAppSelector } from '../hooks/useRedux'
import {
  getStockWatchlistMaster,
  getTempStockWatchlistMaster
} from '../Redux/StockWatchlistMaster/Selectors'
import AddToWatchlist from './ManageWatchlist/AddToWatchlist'
import { shouldAddScriptToParticularWatchlist } from '../Redux/WatchList/Selectors'

interface IBookmarkButtonNewProps {
  scriptId: string
  withToolTip?: Boolean
}

export interface IBookmarkButtonNewRef {
  handleClickOnBookMark: (
    e?:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | KeyboardEvent
  ) => void
}

const BookmarkButtonNew = forwardRef<
  IBookmarkButtonNewRef,
  IBookmarkButtonNewProps
>((props, ref) => {
  const { scriptId, withToolTip = false } = props
  const [bookMarkIconClicked, setBookMarkIconClick] = useState<Boolean>(false)
  const stockWatchlistMasterData = useAppSelector((state: TAppStore) =>
    getStockWatchlistMaster(state)
  )
  const shouldAddScriptToSingleWatchlist = useAppSelector((state: TAppStore) =>
    shouldAddScriptToParticularWatchlist(state)
  )
  const getTempStockWatchlistMasterData = useAppSelector((state: TAppStore) =>
    getTempStockWatchlistMaster(state)
  )

  useImperativeHandle(ref, () => ({
    handleClickOnBookMark
  }))

  const handleClickOnBookMark = (
    e?:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | KeyboardEvent
  ) => {
    e?.stopPropagation()
    setBookMarkIconClick(true)
  }

  const handleCloseModal = () => {
    setBookMarkIconClick(false)
  }

  let scriptInWatchlist = (scriptId && stockWatchlistMasterData[scriptId]) || []
  const tempAddScriptToParticularWatchlist = shouldAddScriptToSingleWatchlist
  if (shouldAddScriptToSingleWatchlist) {
    scriptInWatchlist = getTempStockWatchlistMasterData[scriptId] || []
  }
  const totalElements = scriptInWatchlist.length

  return (
    <>
      {(withToolTip && (
        <DsTooltip
          heading='Add to watchlist'
          placement='bottom'
          arrow={false}
          disableTouchListener
          disableInteractive
        >
          <DsButton
            onClick={e => handleClickOnBookMark(e)}
            color='secondary'
            sx={{
              maxWidth: 'var(--ds-spacing-blazing)',
              '&.MuiButton-root': {
                minWidth: 'var(--ds-spacing-zero)',
                maxWidth: 'var(--ds-spacing-blazing)',
                width: 'var(--ds-spacing-blazing)'
              }
            }}
          >
            <DsRemixIcon
              sx={{
                color: 'var(--ds-colour-actionPrimary)',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}
              className={
                totalElements ? 'ri-bookmark-3-fill' : 'ri-bookmark-3-line'
              }
              fontSize='bitterCold'
            />
          </DsButton>
        </DsTooltip>
      )) || (
        <DsIconButton
          sx={{
            width: 'var(--ds-spacing-tropical)',
            height: 'var(--ds-spacing-tropical)',
            p: 4
          }}
          onClick={e => handleClickOnBookMark(e)}
          {...(totalElements && {
            color: 'iconActionSecondary'
          })}
        >
          <DsRemixIcon
            className={
              totalElements ? 'ri-bookmark-3-fill' : 'ri-bookmark-3-line'
            }
            color={totalElements ? 'actionSecondary' : 'disabled'}
          />
        </DsIconButton>
      )}
      <AddToWatchlist
        openAddToWatchlistModal={bookMarkIconClicked}
        bookMarkIconClicked={bookMarkIconClicked}
        stockAvailableInWatchListArray={scriptInWatchlist}
        bookMarkedScriptId={scriptId}
        tempAddScriptToParticularWatchlist={tempAddScriptToParticularWatchlist}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
})

export default BookmarkButtonNew
