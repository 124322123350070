import React from 'react'
import { NumericFormat } from 'react-number-format'

const NumberFormatter = React.forwardRef(function NumberFormatter(
  props: any,
  ref: React.ForwardedRef<unknown>
) {
  const { onChange, format, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      valueIsNumericString
      allowNegative={false}
      decimalScale={2}
    />
  )
})

export default NumberFormatter

export const NumberFormatterWithNoDecimal = React.forwardRef(
  function NumberFormatter(props: any, ref: React.ForwardedRef<unknown>) {
    const { onChange, format, ...other } = props
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          })
        }}
        valueIsNumericString
        allowNegative={false}
        decimalScale={0}
        isAllowed={values => {
          const { floatValue } = values
          return floatValue === undefined || Number.isInteger(floatValue)
        }}
      />
    )
  }
)
