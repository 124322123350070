import React, { FunctionComponent } from 'react'
import SearchStocks from '../Components/Search2'
import { TAppStore } from '~/src/Configurations/AppStore'
import { useAppDispatch, useAppSelector } from '~/src/hooks/useRedux'
import {
  getIsGlobalSearchCallerClickedSelector,
  getIsSearchResultContainerOpenSelector,
  getSearchStringSelector
} from '~/src/Redux/Search/Selectors'
import { setSearchStringAction } from '~/src/Redux/Search/Actions'
import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'

interface TSidebarSearchWrapperProps extends IWithRouterProps {}

const SidebarSearchWrapper: FunctionComponent<
  TSidebarSearchWrapperProps
> = props => {
  const dispatch = useAppDispatch()
  const showSearch = useAppSelector((state: TAppStore) =>
    getIsSearchResultContainerOpenSelector(state)
  )
  const isGlobalSearchCallerClicked = useAppSelector((state: TAppStore) =>
    getIsGlobalSearchCallerClickedSelector(state)
  )
  const searchString = useAppSelector((state: TAppStore) =>
    getSearchStringSelector(state)
  )

  const setString = (searchString: string) => {
    dispatch(setSearchStringAction(searchString))
  }

  const handleStockSelect = (scriptId: string) => {
    const { navigateTo } = props
    navigateTo(`/quotes/${scriptId}`)
  }

  return (
    <>
      <SearchStocks
        openSearch={showSearch}
        isGlobalSearchCallerClicked={isGlobalSearchCallerClicked}
        searchString={searchString}
        setString={setString}
        handleStockSelect={handleStockSelect}
      />
    </>
  )
}

export default withRouter(SidebarSearchWrapper)
