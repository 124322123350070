import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsBadge,
  DsBox,
  DsFade,
  DsFormControl,
  DsFormControlLabel,
  DsGrid,
  DsImage,
  DsListItemButton,
  DsMenu,
  DsMenuItem,
  DsPopup,
  DsRadio,
  DsRadioGroup,
  DsStack,
  DsSvgIcon,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import { SORT_SCRIPT } from '~/src/Constants/ASSET_MAP'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import {
  T_SCRIPTS_OBJ,
  setSecurityArray,
  setSortScriptSelectedOption
} from '~/src/Redux/WatchList/Reducer'
import { getSortedWatchlistAction } from '~/src/Redux/WatchList/Actions'
import { getCurrentSecurityArrayAndSortScriptOption } from '~/src/Redux/WatchList/Selectors'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'

export type T_SET_SECURITIES_REQ_DATA = {
  securitiesArray: T_SCRIPTS_OBJ[] | null
  watchlistId: string
}

export type T_SET_SORT_SCRIPT_OPTION_DATA = {
  sortScriptOption: number
  watchlistId: string
}

export type T_GET_SORTED_SCRIPTS_PAYLOAD = {
  sortScriptOption: number
  watchlistId: string
  watchlistTab: string | number
}

export type actionTypes = {
  setSortScriptOption: (
    requestData: T_SET_SORT_SCRIPT_OPTION_DATA
  ) => Promise<any>
  getSortedWatchlist: (
    requestData: T_GET_SORTED_SCRIPTS_PAYLOAD
  ) => Promise<any>
  setSecurityArray: (reqData: T_SET_SECURITIES_REQ_DATA) => Promise<any>
}

export interface ISortWatchListScripts2Props
  extends IWithRouterProps,
    IwithBreakpoints {
  actions: actionTypes
  currentSortScriptSelectedOption: number
  currentStockListLength: number
  currentWatchlistId: string
  currentWatchlistTab: number | string
}

const SORT_SCRIPT_OPTIONS = [
  'Last Added (Default)',
  'LTP: High to low',
  'LTP: Low to High',
  'LTP%: High to low',
  'LTP%: Low to High',
  'Alphabetical: A to Z',
  'Alphabetical: Z to A'
]

class SortWatchListScripts2 extends Component<ISortWatchListScripts2Props> {
  state = {
    anchorEl: null,
    openSortByBottomSheet: false
  }

  handleCloseSortScriptsMenu = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleOpenCloseSortScriptBottomSheet = () => {
    const { openSortByBottomSheet } = this.state
    this.setState({
      openSortByBottomSheet: !openSortByBottomSheet
    })
  }

  handleOpenSortScriptsMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  renderDesktopSortOptions = () => {
    const { anchorEl } = this.state
    const openSortScriptsMenu = Boolean(anchorEl)
    const { currentSortScriptSelectedOption, currentStockListLength } =
      this.props
    const isAnyStockAvailable = currentStockListLength

    return (
      <>
        <DsGrid
          item
          xs={4}
          sx={{
            display: 'grid'
          }}
        >
          <DsMenu
            sx={{
              maxWidth: '100%',
              '& .MuiPaper-root': {
                minWidth: 288,
                boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.08)',
                marginLeft: 'calc(var(--ds-spacing-mild) - 1px)'
              }
            }}
            anchorEl={anchorEl}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'bottom'
            }}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'top'
            }}
            open={openSortScriptsMenu}
            keepMounted={false}
            onClose={this.handleCloseSortScriptsMenu}
            MenuListProps={{
              'aria-labelledby': 'fade-button'
            }}
            id='fade-menu'
            aria-labelledby='fade-menu'
            variant='menu'
            TransitionComponent={DsFade}
          >
            <DsBox
              sx={{
                padding: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <DsTypography variant='headingBoldExtraSmall'>
                Sort by
              </DsTypography>
            </DsBox>
            {SORT_SCRIPT_OPTIONS.map((sortOption: string, index: number) => (
              <DsMenuItem
                key={index}
                onClick={() => this.handleClickOnSortScriptOptions(index)}
              >
                <DsTypography
                  variant={
                    currentSortScriptSelectedOption === index
                      ? 'bodyBoldMedium'
                      : 'bodyRegularMedium'
                  }
                >
                  {sortOption}
                </DsTypography>
              </DsMenuItem>
            ))}
          </DsMenu>
          <DsListItemButton
            sx={{
              display: 'block',
              p: 'var(--ds-spacing-frostbite) var(--ds-spacing-zero) var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)'
            }}
            onClick={this.handleOpenSortScriptsMenu}
            id='fade-button'
          >
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                textAlign: 'center',
                gap: 'var(--ds-spacing-frostbite)',
                borderRight: '1px solid var(--ds-colour-strokeDefault)'
              }}
            >
              {/* NOTE - TODO - To revisit later */}
              <DsSvgIcon
                sx={{
                  fontSize: 'var(--ds-typo-fontSizeBitterCold)'
                }}
              >
                <svg
                  width='auto'
                  height='auto'
                  viewBox='0 0 18 16'
                  fill='var(--ds-colour-iconDefault)'
                >
                  <path d='M8 14C8.55229 14 9 14.4477 9 15C9 15.5523 8.55229 16 8 16H1C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14H8ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55229 12.5523 9 12 9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H12ZM17 0C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2H1C0.447716 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H17Z' />
                </svg>
              </DsSvgIcon>
              <DsStack
                sx={{
                  flexDirection: 'row'
                }}
              >
                <DsTypography variant='bodyRegularLarge'>
                  {isAnyStockAvailable > 1
                    ? WATCHLIST_LABEL.BOTTOM_BUTTONS.SORTS
                    : WATCHLIST_LABEL.BOTTOM_BUTTONS.SORT}
                </DsTypography>
                {currentSortScriptSelectedOption ? (
                  <DsBadge
                    variant='dot'
                    sx={{
                      marginTop:
                        'calc(var(--ds-spacing-quickFreeze) + var(--ds-spacing-deepFreeze))',
                      marginLeft: 'var(--ds-spacing-glacial)'
                    }}
                  />
                ) : null}
              </DsStack>
            </DsBox>
          </DsListItemButton>
        </DsGrid>
      </>
    )
  }

  renderMobileSortOptions = () => {
    const { currentSortScriptSelectedOption, currentStockListLength } =
      this.props
    const isAnyStockAvailable = currentStockListLength

    return (
      <>
        <DsGrid
          item
          xs={6}
          sx={{
            display: 'grid'
          }}
        >
          <DsListItemButton
            sx={{
              display: 'block',
              p: 0,
              borderRight: '1px solid var(--ds-colour-strokeDefault)'
            }}
            onClick={this.handleOpenCloseSortScriptBottomSheet}
          >
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)',
                cursor: 'pointer',
                textAlign: 'center'
              }}
            >
              <DsBox>
                <DsImage
                  srcSet={SORT_SCRIPT}
                  style={{
                    marginRight: 'var(--ds-spacing-frostbite)'
                  }}
                />
              </DsBox>
              <DsTypography
                variant='bodyBoldSmall'
                color={'var(--ds-colour-iconActionTertiary)'}
                textTransform={'uppercase'}
              >
                {isAnyStockAvailable > 1
                  ? WATCHLIST_LABEL.BOTTOM_BUTTONS.SORTS
                  : WATCHLIST_LABEL.BOTTOM_BUTTONS.SORT}
              </DsTypography>
              {currentSortScriptSelectedOption ? (
                <DsBadge
                  variant='dot'
                  sx={{
                    marginBottom: 'var(--ds-spacing-glacial)',
                    marginLeft: 'var(--ds-spacing-glacial)'
                  }}
                />
              ) : null}
            </DsBox>
          </DsListItemButton>
        </DsGrid>
      </>
    )
  }

  renderPopUp = () => {
    const { openSortByBottomSheet } = this.state
    const { currentSortScriptSelectedOption } = this.props
    return (
      <>
        <DsPopup
          DsDialogProps={{
            keepMounted: false
          }}
          open={openSortByBottomSheet}
          onClose={this.handleOpenCloseSortScriptBottomSheet}
          showClose={true}
          title={'Sort by'}
        >
          {openSortByBottomSheet && (
            <DsFormControl
              sx={{
                px: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <DsRadioGroup defaultValue={currentSortScriptSelectedOption}>
                {SORT_SCRIPT_OPTIONS.map(
                  (sortOption: string, index: number) => (
                    <DsFormControlLabel
                      key={index}
                      control={
                        <DsRadio
                          onChange={() =>
                            this.handleClickOnSortScriptOptions(index)
                          }
                        />
                      }
                      label={
                        <DsTypography
                          variant={
                            currentSortScriptSelectedOption === index
                              ? 'bodyBoldMedium'
                              : 'bodyRegularMedium'
                          }
                        >
                          {sortOption}
                        </DsTypography>
                      }
                      value={index}
                      sx={{
                        '.MuiFormControlLabel-root': {
                          marginRight: 'var(--ds-spacing-zero) !important'
                        }
                      }}
                    />
                  )
                )}
              </DsRadioGroup>
            </DsFormControl>
          )}
        </DsPopup>
      </>
    )
  }

  handleClickOnSortScriptOptions = async (selectedSortOption: number) => {
    const {
      actions,
      currentStockListLength,
      currentWatchlistId,
      currentWatchlistTab
    } = this.props
    if (!currentStockListLength) {
      return
    }

    await actions.setSortScriptOption({
      sortScriptOption: selectedSortOption,
      watchlistId: currentWatchlistId
    })

    const sortedSecuritiesArray = await actions.getSortedWatchlist({
      sortScriptOption: selectedSortOption,
      watchlistId: currentWatchlistId,
      watchlistTab: currentWatchlistTab
    })

    await actions.setSecurityArray({
      securitiesArray: sortedSecuritiesArray,
      watchlistId: currentWatchlistId
    })

    this.setState({
      openSortByBottomSheet: false
    })
    this.handleCloseSortScriptsMenu()
  }

  render() {
    const { breakpoints } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    return (
      <>
        {isDesktop && this.renderDesktopSortOptions()}
        {!isDesktop && (
          <>
            {this.renderMobileSortOptions()}
            {this.renderPopUp()}
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const {
    currentSortScriptSelectedOption,
    currentStockListLength,
    currentWatchlistId,
    currentWatchlistTab
  } = getCurrentSecurityArrayAndSortScriptOption(state)
  return {
    currentSortScriptSelectedOption,
    currentStockListLength,
    currentWatchlistId,
    currentWatchlistTab
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setSortScriptOption: (requestData: T_SET_SORT_SCRIPT_OPTION_DATA) =>
      dispatch(setSortScriptSelectedOption(requestData)),
    getSortedWatchlist: (requestData: T_GET_SORTED_SCRIPTS_PAYLOAD) =>
      dispatch(getSortedWatchlistAction(requestData)),
    setSecurityArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setSecurityArray(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SortWatchListScripts2)
)
