import React from 'react'
import {
  DsBox,
  DsGrid,
  DsListItemButton,
  DsRemixIcon,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

// actions
import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'
import {
  getSecuritiesLength,
  getSelectedWatchlistTab,
  getWatchlistType,
  manageWatchlistIndicator
} from '~/src/Redux/WatchList/Selectors'
import { toggleManageWatchlist } from '~/src/Redux/WatchList/Reducer'
import { triggerCleverTapEvent } from '~/src/Utils/global'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import CreateWatchlist from '../../ManageWatchlist/CreateWatchlist'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import FabIcon from './FabIcon'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import SortWatchListScripts2 from './SortWatchListScripts2'

type ActionTypes = {
  toggleManageWatchlist: (reqData: {
    isManageWatchlistClicked: boolean
  }) => Promise<any>
}

export interface ISidebarFooterState {
  oldScrollY: number
}

export interface ISidebarFooterProps extends IwithBreakpoints {
  actions: ActionTypes
  isManageWatchlistClicked: boolean
  currentSecuritiesLength: number
  selectedWatchlistTab: string | number
  watchlistType: string
}

class SidebarFooter extends React.PureComponent<
  ISidebarFooterProps,
  ISidebarFooterState
> {
  bottomActions: React.RefObject<HTMLInputElement>
  constructor(props: ISidebarFooterProps) {
    super(props)
    this.state = {
      oldScrollY: 0
    }
    this.bottomActions = React.createRef()
  }

  async componentDidMount(): Promise<void> {
    window.addEventListener('scroll', this.controlDirection)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', e => this.controlDirection)
  }

  controlDirection = () => {
    const { breakpoints } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    if (isDesktop) return
    const { oldScrollY } = this.state
    if (window.scrollY > oldScrollY) {
      this.bottomActions.current?.animate(
        {
          bottom: 'var(--ds-spacing-zero)'
        },
        400
      )
    } else {
      this.bottomActions.current?.animate(
        {
          bottom:
            'calc(var(--ds-spacing-molten) - var(--ds-spacing-deepFreeze))'
        },
        200
      )
    }
    this.setState({ oldScrollY: window.scrollY })
  }

  handleManageWatchlist = async () => {
    const { actions } = this.props
    const customAttributes = {
      'Event Status': 'Pass',
      Action: 'Manage'
    }
    triggerCleverTapEvent('Watchlist', customAttributes)
    await actions.toggleManageWatchlist({ isManageWatchlistClicked: true })
    window.scrollTo(0, 0)
  }

  renderFabIcon = () => {
    const {
      selectedWatchlistTab,
      currentSecuritiesLength,
      watchlistType,
      breakpoints
    } = this.props
    const isAnyStockAvailable = currentSecuritiesLength
    const isPredefinedWatchlist = watchlistType === 'PRE-DEFINED'
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const isFabIconNeeded =
      isAnyStockAvailable && !isDesktop && !isPredefinedWatchlist
    if (!isFabIconNeeded) {
      return null
    }
    return (
      <DsBox
        sx={{
          position: 'fixed',
          bottom: '150px',
          right: 'var(--ds-spacing-warm)'
        }}
      >
        <FabIcon selectedWatchlistTab={selectedWatchlistTab} />
      </DsBox>
    )
  }

  renderFooter = () => {
    const { currentSecuritiesLength, isManageWatchlistClicked, breakpoints } =
      this.props
    const isAnyStockAvailable = currentSecuritiesLength
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        <DsBox
          sx={{
            display: (isManageWatchlistClicked && 'none') || 'block',
            position: 'sticky',
            width: '100%',
            bottom: 0,
            border: '1px solid var(--ds-colour-strokeDefault)',
            borderTopLeftRadius: 'var(--ds-spacing-glacial)',
            borderTopRightRadius: 'var(--ds-spacing-glacial)',
            backgroundColor: 'var(--ds-colour-surfaceSecondary)',
            zIndex: 2
          }}
        >
          <DsGrid
            ref={this.bottomActions}
            container
            {...(!isDesktop && {
              sx: {
                position: 'fixed',
                bottom:
                  'calc(var(--ds-spacing-molten) - var(--ds-spacing-quickFreeze))',
                backgroundColor: 'var(--ds-colour-surfaceSecondary)',
                boxShadow: '0px 0px 10px 0px #0000001A'
              }
            })}
          >
            {(isAnyStockAvailable && <SortWatchListScripts2 />) || null}
            <DsGrid item xs={isAnyStockAvailable && isDesktop ? 4 : 6}>
              <CreateWatchlist componentType='sidebar' />
            </DsGrid>
            <DsGrid
              item
              xs={isAnyStockAvailable ? 4 : 6}
              sx={{
                display: {
                  md: 'grid',
                  xs: 'none'
                }
              }}
            >
              <DsListItemButton
                sx={{
                  display: 'block',
                  p: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold) var(--ds-spacing-frostbite) var(--ds-spacing-zero)'
                }}
                onClick={this.handleManageWatchlist}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    textAlign: 'center',
                    justifyContent: 'center',
                    gap: 'var(--ds-spacing-frostbite)'
                  }}
                >
                  <DsRemixIcon className='ri-settings-4-line' fontSize='mild' />
                  <DsTypography variant='bodyRegularLarge'>
                    {WATCHLIST_LABEL.BOTTOM_BUTTONS.MANAGE_WATCHLIST}
                  </DsTypography>
                </DsBox>
              </DsListItemButton>
            </DsGrid>
          </DsGrid>
        </DsBox>
        {this.renderFabIcon()}
      </>
    )
  }

  render() {
    const { currentSecuritiesLength, breakpoints } = this.props
    const isWatchListEmtpy = currentSecuritiesLength === 0
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    if (isWatchListEmtpy && !isDesktop) {
      return null
    }
    return this.renderFooter()
  }
}

const mapStateToProps = (state: TAppStore) => {
  const isManageWatchlistClicked = manageWatchlistIndicator(state)
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const currentSecuritiesLength =
    getSecuritiesLength(selectedWatchlistTab)(state)
  const watchlistType = getWatchlistType(selectedWatchlistTab)(state)
  return {
    isManageWatchlistClicked,
    currentSecuritiesLength,
    selectedWatchlistTab,
    watchlistType
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    toggleManageWatchlist: (reqData: { isManageWatchlistClicked: boolean }) =>
      dispatch(toggleManageWatchlist(reqData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
    context: appContext
  })(SidebarFooter)
)
