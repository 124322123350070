import React, { PureComponent } from 'react'

// components
import OptionChainList from './OptionChainList'

// constants
// selectors
import { getLtpTickSelector } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'

// reducers
// utils
import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

export interface ActionTypes {}

export interface IOptionChainProps extends IWithRouterProps {
  LTP: string | number
  scriptId: string
  mappedId: string
  handleDialog: any
}

class OptionChainListWrapper extends PureComponent<IOptionChainProps> {
  render() {
    const { LTP, scriptId, handleDialog, mappedId } = this.props

    return (
      <>
        <OptionChainList
          LTP={LTP}
          scriptId={scriptId}
          handleDialog={handleDialog}
          mappedId={mappedId}
        />
      </>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId } = ownProps
  const mappedId = (window as any).secMaster._getScripIdForBSEDerivative(
    scriptId
  )
  const LTP = getLtpTickSelector(state, mappedId) || {}

  return {
    LTP,
    mappedId
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(OptionChainListWrapper)
