import React from 'react'
import { connect } from 'react-redux'

// components
import SearchTabs from '../SearchTabs'
// constants
import { SEARCHTABS_LABEL } from '../SearchTabs.constants'

// actions
import { setSearchSelectedTabAction } from '~/src/Redux/Search/Actions'
// selectors
import {
  getIsSearchResultContainerOpenSelector,
  getSearch,
  getSearchSelectedTabSelector
} from '~/src/Redux/Search/Selectors'

// context
import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'

type ActionTypes = {
  setSearchSelectedTab: (value: string) => void
}

export interface IRenderSearchTabsProps {
  actions: ActionTypes
  selectedTab: string
  searchResult: any
}

class RenderSearchTabs extends React.PureComponent<IRenderSearchTabsProps> {
  handleOnChange = (value: any) => {
    const { actions } = this.props
    actions.setSearchSelectedTab(value)
  }

  render() {
    const { selectedTab, searchResult } = this.props
    return (
      <SearchTabs
        renderTabs={SEARCHTABS_LABEL}
        returnValue='id'
        enableArrow={true}
        containedTabs={false}
        onChange={this.handleOnChange}
        searchResults={searchResult}
        selectedTabs={selectedTab}
      />
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const searchResult = getSearch(state)
  const openSearchResult = getIsSearchResultContainerOpenSelector(state)
  const selectedTab = getSearchSelectedTabSelector(state)
  return {
    searchResult,
    open: openSearchResult,
    selectedTab
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setSearchSelectedTab: (value: string) =>
      dispatch(setSearchSelectedTabAction(value))
  }
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})(RenderSearchTabs)
