import React, { Component } from 'react'

import {
  DsDialog,
  DsDrawer,
  DsSlide,
  IwithBreakpoints,
  TransitionProps,
  withBreakpoints
} from '@am92/react-design-system'

import { IWithRouterProps } from '~/src/Lib/withRouter'
import OptionChain from './OptionChain'
import { appContext, TAppStore } from '~/src/Configurations/AppStore'
import { getOptionDrawerSelector } from '~/src/Redux/AppState/selectors'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { closeOptionDialogActions } from '~/src/Redux/AppState/actions'
import {
  getFuturesByScriptIdSelector,
  getOptionsByScriptIdSelector
} from '~/src/Redux/Derivatives/Selectors'
import { connect, ConnectedProps } from 'react-redux'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

export interface IOptionChainProps
  extends IWithRouterProps,
    IwithBreakpoints,
    PropsFromRedux {}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <DsSlide direction='up' ref={ref} {...props} />
})

class OptionChainDialog extends Component<IOptionChainProps> {
  handleDialog = () => {
    const { actions } = this.props
    actions.closeOptionDialog()
  }

  render() {
    const { breakpoints = {}, getOptionDrawer, futures, options } = this.props
    const {isDesktop} = getDeviceActiveScreen(breakpoints)
    const { data, open } = getOptionDrawer
    const { scriptId } = data || {}
    const showOptionChain = futures.length > 0 && options.length > 0
    const openDrawer = showOptionChain && open
    return (
      <>
        {isDesktop ? (
          <DsDialog
            fullScreen={!isDesktop}
            PaperProps={{
              style: {
                backgroundColor: 'var(--ds-colour-surfaceBackground)',
                boxShadow: 'none'
              }
            }}
            sx={{
              '.MuiPaper-root': {
                ...(isDesktop && {
                  bottom: '-50px',
                  minWidth: '45rem'
                })
              },
              '& .MuiDialog-paper': {
                ...(!isDesktop && {
                  borderRadius: 0,
                  top: '111px'
                })
              }
            }}
            open={openDrawer}
            showClose={false}
            TransitionComponent={Transition}
            onClose={this.handleDialog}
          >
            <OptionChain
              handleDialog={this.handleDialog}
              isDesktop={isDesktop}
              scriptId={scriptId}
            />
          </DsDialog>
        ) : (
          openDrawer && (
            <DsDrawer
              PaperProps={{
                sx: {
                  top: '111px',
                  zIndex: '10',
                  boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)'
                }
              }}
              anchor='bottom'
              open={openDrawer}
              variant='persistent'
              hideBackdrop
            >
              <OptionChain
                dialogOpen={openDrawer}
                handleDialog={this.handleDialog}
                isDesktop={isDesktop}
                scriptId={scriptId}
              />
            </DsDrawer>
          )
        )}
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const getOptionDrawer = getOptionDrawerSelector(state)
  const { data } = getOptionDrawer
  const { scriptId } = data || {}
  const futures = getFuturesByScriptIdSelector(scriptId)(state)
  const options = getOptionsByScriptIdSelector(scriptId)(state)
  return {
    getOptionDrawer,
    futures,
    options
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    closeOptionDialog: () => dispatch(closeOptionDialogActions())
  }
})
const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>
export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(OptionChainDialog)
)
