import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsBox,
  DsImage,
  DsTypography,
  DsButton
} from '@am92/react-design-system'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { ADD_SCRIPT_ILLUSTRATION } from '~/src/Constants/ASSET_MAP'
import {
  T_StockWatchlistMasterData,
  setTemporaryStockWatchlistData
} from '~/src/Redux/StockWatchlistMaster/Reducer'
import {
  T_SCRIPTS_OBJ,
  setAddScriptToParticularWatchlist
} from '~/src/Redux/WatchList/Reducer'
import {
  getSelectedWatchlistTab,
  getWatchlist
} from '~/src/Redux/WatchList/Selectors'
import {
  globalSearchCallerClickAction,
  openSearchResultContainerAction
} from '~/src/Redux/Search/Actions'

type ActionTypes = {
  setAddScriptToParticularWatchlist: (reqData: boolean) => Promise<any>
  setTemporaryStockWatchlistData: (requestData: any) => Promise<any>
  openSearchResultContainer: (value: boolean) => Promise<any>
  globalSearchCallerClickAction: (value: boolean) => Promise<any>
}

export interface IEmptyWatchListScreenProps extends IWithRouterProps {
  actions: ActionTypes
  selectedWatchlistTab: number
  currentWatchlistId: string
  currentWatchlistSecurities: T_SCRIPTS_OBJ[] | null
  currentWatchlistType: string
}

class EmptyWatchListScreen extends Component<IEmptyWatchListScreenProps> {
  handleAddScriptsClick = async () => {
    const {
      actions,
      currentWatchlistSecurities: watchlistSecurities,
      currentWatchlistId: watchlistId
    } = this.props
    const tempStockWatchlistData: T_StockWatchlistMasterData = {}
    watchlistSecurities?.forEach((stockObj: T_SCRIPTS_OBJ) => {
      const { scriptId } = stockObj
      if (!tempStockWatchlistData[scriptId]) {
        tempStockWatchlistData[scriptId] = [watchlistId]
        return
      }
      tempStockWatchlistData[scriptId] = [
        ...tempStockWatchlistData[scriptId],
        watchlistId
      ]
    })

    await actions.setAddScriptToParticularWatchlist(true)
    await actions.setTemporaryStockWatchlistData(tempStockWatchlistData)

    //NOTE -  review this setOpen field
    await actions.openSearchResultContainer(true)
    actions.globalSearchCallerClickAction(true)
  }

  render() {
    const { currentWatchlistType } = this.props
    const isPreDefinedWatchlist = currentWatchlistType !== 'PRE-DEFINED'
    return (
      <DsBox
        sx={{
          height: { xs: '75vh', md: '60vh' },
          display: 'flex',
          p: 'var(--ds-spacing-tropical)',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          borderTop: '1px solid var(--ds-colour-strokeDefault)'
        }}
      >
        <DsBox>
          <DsImage srcSet={ADD_SCRIPT_ILLUSTRATION} />
        </DsBox>

        <DsTypography variant='bodyRegularLarge'>
          Your watchlist looks empty! <br />
          Did you know you can add upto <b>50 scrips</b>?
        </DsTypography>
        {(isPreDefinedWatchlist && (
          <>
            <DsButton
              sx={{
                display: { md: 'block', xs: 'none' },
                mt: 'var(--ds-spacing-pleasant)'
              }}
              onClick={this.handleAddScriptsClick}
            >
              ADD SCRIPS
            </DsButton>
            <DsButton
              sx={{
                display: { md: 'none', xs: 'block' },
                mt: 'var(--ds-spacing-pleasant)'
              }}
              onClick={() => {
                const { navigate } = this.props
                this.handleAddScriptsClick()
                navigate(APP_ROUTES.MOBILE_SEARCH.pathname)
              }}
            >
              ADD SCRIPS
            </DsButton>
          </>
        )) ||
          null}
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const {
    currentWatchlistId,
    currentWatchlistSecurities,
    currentWatchlistType
  } = getWatchlist(selectedWatchlistTab)(state) || {}

  return {
    currentWatchlistId,
    currentWatchlistSecurities,
    currentWatchlistType
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setAddScriptToParticularWatchlist: (requestData: boolean) =>
      dispatch(setAddScriptToParticularWatchlist(requestData)),
    setTemporaryStockWatchlistData: (requestData: any) =>
      dispatch(setTemporaryStockWatchlistData(requestData)),
    openSearchResultContainer: (value: boolean) =>
      dispatch(openSearchResultContainerAction(value)),
    globalSearchCallerClickAction: (value: boolean) =>
      dispatch(globalSearchCallerClickAction(value))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(EmptyWatchListScreen)
