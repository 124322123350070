import React from 'react'
import SidebarSearchWrapper from './Components/SidebarSearchWrapper'
import SidebarWrapper from './Components/SidebarWrapper'
import useResponsiveBreakpoints from '~/src/hooks/useResponsiveBreakpoints'

interface ISidebarNewProps {}

const SidebarNew: React.FunctionComponent<ISidebarNewProps> = (
  props: ISidebarNewProps
) => {
  const { isDesktop } = useResponsiveBreakpoints()

  return (
    <>
      {isDesktop && <SidebarSearchWrapper />}
      <SidebarWrapper />
    </>
  )
}

export default SidebarNew
