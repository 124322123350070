import { useTheme, useMediaQuery } from '@am92/react-design-system'

interface Breakpoints {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

const useResponsiveBreakpoints = (): Breakpoints => {
  const theme = useTheme()

  const breakPoints: Breakpoints = {
    isMobile: useMediaQuery(theme.breakpoints.only('xs')),
    isTablet: useMediaQuery(theme.breakpoints.only('sm')),
    isDesktop: useMediaQuery(theme.breakpoints.up('md'))
  }

  return breakPoints
}

export default useResponsiveBreakpoints
