import { createSelector } from '@reduxjs/toolkit'
import TickerStore from '~/src/Configurations/TickerStore'
import { isEquity } from '~/src/Constants/SCREENERS'
import { TSortOption } from '~/src/Types/SortAndFilter.Types'

export const SLICE_NAME = 'screeners'

const select = (state: any) => state[SLICE_NAME]

export const getAllSelectedValues = createSelector(
  select,
  screeners => screeners
)

export const getGroupedScreener = createSelector(
  select,
  screeners => screeners?.group
)
export const getScreenersScriptId = createSelector(
  select,
  screeners => screeners?.screenersScriptIdArray
)

export const getScreenerSubCategory = (
  selectedExchange: string,
  selectedGroupName: string,
  selectedAssetClass: string
) =>
  createSelector(select, screener =>
    isEquity(selectedAssetClass)
      ? screener?.group?.[selectedAssetClass]?.[selectedExchange]?.[
          selectedGroupName
        ] || {}
      : screener?.group?.[selectedAssetClass]?.[selectedExchange] || {}
  )

export const getScreenerGroupNames = (
  selectedExchange: string,
  selectedGroupName: string,
  selectedAssetClass: string
) =>
  createSelector(
    select,
    screener => screener?.group?.[selectedAssetClass]?.[selectedExchange] || {}
  )

export const getScreenerAssetClasses = createSelector(
  select,
  screener => screener?.group || {}
)
export const getSelectedExpiryDate = createSelector(
  select,
  screener => screener?.selectedExpiryDate || ''
)
export const getExpiryDateOptions = createSelector(
  select,
  screener => screener?.expiryDateOptions || []
)
export const getSelectedSegment = createSelector(
  select,
  screener => screener?.selectedSegment || []
)
export const getSelectedCallPut = createSelector(
  select,
  screener => screener?.selectedCallPut || []
)
export const getScreenerExchange = (selectedAssetClass: string) =>
  createSelector(
    select,
    screener => screener?.group?.[selectedAssetClass] || {}
  )

export const getScreenerList = (
  selectedExchange: string,
  selectedGroupName: string,
  selectedAssetClass: string,
  selectedSubCategory: string
) =>
  createSelector(select, screener => {
    if (isEquity(selectedAssetClass)) {
      return (
        screener?.group?.[selectedAssetClass]?.[selectedExchange]?.[
          selectedGroupName
        ]?.[selectedSubCategory] || {}
      )
    }
    return (
      screener?.group?.[selectedAssetClass]?.[selectedExchange]?.[
        selectedSubCategory
      ] || {}
    )
  })

//
export const getScreenerSelectedExchange = createSelector(
  select,
  screeners => screeners?.selectedExchange
)

export const getScreenerSelectedGroupName = createSelector(
  select,
  screeners => screeners?.selectedGroupName
)

export const getScreenerSelectedAssetClass = createSelector(
  select,
  screeners => screeners?.selectedAssetClass
)

export const getScreenerSelectedSubCategory = createSelector(
  select,
  screeners => screeners?.selectedSubCategory
)

export const getIsDefaultFilterEnabledSelector = createSelector(
  select,
  screeners => screeners?.isDefaultFiltersEnabled
)
export const getFilteredScreenersSelector = createSelector(select, screeners =>
  getSortedList(screeners?.filteredScreeners, screeners?.selectedSortBy)
)
export const getSortedFilteredScreenersSelector = createSelector(
  select,
  screeners => {
    const { filteredScreeners } = screeners
    const result: any = []
    filteredScreeners?.forEach((item: any) => {
      const { scriptId, calls = [] } = item
      const { exchange, coname, closeprice, exchangeSymbol, segment } =
        (window as any).secMaster.getByScriptId(scriptId) || {}
      // TODO: move this to selector
      const tickerStore = TickerStore.getState()
      const displayName = segment === 'EQ' ? exchangeSymbol : coname
      const { ticker = {} } = tickerStore || {}
      const { LTP, NET_CHANGE, PERCENT_CHANGE, VOLUME } =
        ticker?.[scriptId] || {}
      result.push({
        name: displayName,
        exchange: exchange,
        scriptId: scriptId,
        closeprice: closeprice,
        ltp: LTP,
        netChange: NET_CHANGE,
        percentChange: PERCENT_CHANGE,
        volume: VOLUME,
        calls: calls
      })
    })
    return result
  }
)

// utils
export const getSortedList = (data: any, sortBy: TSortOption): any[] => {
  const tickerStore = TickerStore.getState()
  let newArray = [...data]

  if (sortBy) {
    if (sortBy === 'ATOZ') {
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { exchangeSymbol: symbolA } = ITEM_A
        const { exchangeSymbol: symbolB } = ITEM_B
        return symbolA.localeCompare(symbolB)
      })
    }
    if (sortBy === 'ZTOA') {
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { exchangeSymbol: symbolA } = ITEM_A
        const { exchangeSymbol: symbolB } = ITEM_B
        return symbolB.localeCompare(symbolA)
      })
    }
    if (sortBy === 'LTP_HTOL') {
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { scriptId: scriptIdA } = ITEM_A
        const tickerDataA = tickerStore?.ticker?.[scriptIdA]

        const { LTP: ltpA } = tickerDataA || {}
        const { scriptId: scriptIdB } = ITEM_B
        const tickerDataB = tickerStore?.ticker?.[scriptIdB]
        const { LTP: ltpB } = tickerDataB || {}
        return Number(ltpB) - Number(ltpA)
      })
    }
    if (sortBy === 'LTP_LTOH') {
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { scriptId: scriptIdA } = ITEM_A
        const tickerDataA = tickerStore?.ticker?.[scriptIdA]
        const { LTP: ltpA } = tickerDataA || {}
        const { scriptId: scriptIdB } = ITEM_B
        const tickerDataB = tickerStore?.ticker?.[scriptIdB]
        const { LTP: ltpB } = tickerDataB || {}

        return Number(ltpA) - Number(ltpB)
      })
    }

    if (sortBy === 'LTP_PERCENT_HTOL') {
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { scriptId: scriptIdA, closeprice: closepriceA } = ITEM_A
        const tickerDataA = tickerStore?.ticker?.[scriptIdA]

        const { percentChange: percentChangeA } = getNetAndPercentChange(
          ITEM_A,
          tickerDataA
        )
        const { scriptId: scriptIdB, closeprice: closepriceB } = ITEM_B
        const tickerDataB = tickerStore?.ticker?.[scriptIdB]

        const { percentChange: percentChangeB } = getNetAndPercentChange(
          ITEM_B,
          tickerDataB
        )

        return Number(percentChangeB) - Number(percentChangeA)
      })
    }
    if (sortBy === 'LTP_PERCENT_LTOH') {
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { scriptId: scriptIdA, closeprice: closepriceA } = ITEM_A
        const tickerDataA = tickerStore?.ticker?.[scriptIdA]

        const { percentChange: percentChangeA } = getNetAndPercentChange(
          ITEM_A,
          tickerDataA
        )
        const { scriptId: scriptIdB, closeprice: closepriceB } = ITEM_B
        const tickerDataB = tickerStore?.ticker?.[scriptIdB]

        const { percentChange: percentChangeB } = getNetAndPercentChange(
          ITEM_B,
          tickerDataB
        )

        return Number(percentChangeA) - Number(percentChangeB)
      })
    }
  }

  return newArray
}

function getNetAndPercentChange(stockData: any, stockTickerData: any) {
  const { LTP, PERCENT_CHANGE, NET_CHANGE } = stockTickerData || {}
  const { closeprice } = stockData
  let netChange = NET_CHANGE
  let percentChange = PERCENT_CHANGE
  if (NET_CHANGE === '+0.00') {
    netChange = Number(LTP - closeprice)
    percentChange = closeprice ? (netChange / closeprice) * 100 : 0
  }
  return { netChange, percentChange }
}
