import React from 'react'
import { To } from 'react-router-dom'
// DS Components
import {
  DsAvatar,
  DsBox,
  DsInputAdornment,
  DsRemixIcon,
  DsTextField,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

import { updateStockWatchlistMasterAction } from '~/src/Redux/StockWatchlistMaster/Actions'
import { T_StockWatchlistMasterData } from '~/src/Redux/StockWatchlistMaster/Reducer'
import {
  setAddScriptToParticularWatchlist,
  T_SCRIPTS_OBJ,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import { getWatchListLength } from '~/src/Redux/WatchList/Selectors'
import fetchWatchlistsAction from '~/src/Redux/WatchList/Services/FetchWatchlists.Service'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// Lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

import { connect, ConnectedProps } from 'react-redux'

export interface IMobileHeaderProps
  extends IWithRouterProps,
    IwithBreakpoints,
    PropsFromRedux {
  handleError: (res: any) => void
}

class MobileHeader extends React.Component<IMobileHeaderProps> {
  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleFocus = () => {
    const { actions } = this.props
    actions.setAddScriptToParticularWatchlist(false)
    this.navigateTo(APP_ROUTES.MOBILE_SEARCH.pathname)
  }

  componentDidMount(): void {
    const { breakpoints = {}, watchListLength } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    if (watchListLength === 0 && !isDesktop) {
      this.getWatchListData()
    }
  }

  getWatchListData = async () => {
    const { actions, handleError } = this.props
    const fetchWatchlistsResponse = await actions.fetchWatchlist()
    if (fetchWatchlistsResponse._isCustomError) {
      console.log('failed API - fetchWatchlist')
      handleError(fetchWatchlistsResponse)
      return
    }
    const stockWatchlistData: T_StockWatchlistMasterData = {}

    fetchWatchlistsResponse.forEach((watchlist: watchlistObj) => {
      const { watchlistSecurities, watchlistId, watchlistType } = watchlist
      if (watchlistType === 'PRE-DEFINED') {
        return false
      }
      watchlistSecurities?.forEach((stockObj: T_SCRIPTS_OBJ) => {
        const { scriptId } = stockObj
        if (!stockWatchlistData[scriptId]) {
          stockWatchlistData[scriptId] = [watchlistId]
          return
        }
        stockWatchlistData[scriptId] = [
          ...stockWatchlistData[scriptId],
          watchlistId
        ]
      })
    })

    await actions.updateStockWatchlistMaster(stockWatchlistData)
  }
  handleNotification = () => {
    this.navigateTo(APP_ROUTES.NOTIFICATION.pathname)
  }

  render() {
    return (
      <DsBox
        sx={{
          display: { xs: 'flex' },
          flexDirection: 'row',
          p: 'var(--ds-spacing-bitterCold)',
          gap: 'var(--ds-spacing-bitterCold)',
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          boxShadow:
            'var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-deepFreeze) var(--ds-spacing-zero) rgba(0, 0, 0, 0.10)'
        }}
      >
        <DsAvatar
          onClick={() => {
            this.navigateTo('/profile-menu')
          }}
          sx={{
            flexGrow: 1
          }}
        />
        <DsTextField
          fullWidth
          onFocus={this.handleFocus}
          placeholder={'Search to buy/sell stocks'}
          sx={{
            '.MuiInputBase-root': {
              borderRadius: 'var(--ds-radius-pleasant)'
            }
          }}
          startAdornment={
            <DsInputAdornment position='start'>
              <DsRemixIcon
                className='ri-search-line'
                color='disabled'
                fontSize='cool'
              />
            </DsInputAdornment>
          }
        />
        <DsBox
          sx={{
            borderRadius: '0.25rem',
            height: '2.5rem',
            width: '2.5rem',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={this.handleNotification}
        >
          <DsRemixIcon className='ri-notification-2-line' />
        </DsBox>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const watchListLength = getWatchListLength(state)
return {
    watchListLength
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setAddScriptToParticularWatchlist: (requestData: boolean) =>
      dispatch(setAddScriptToParticularWatchlist(requestData)),
    fetchWatchlist: () => dispatch(fetchWatchlistsAction()),
    updateStockWatchlistMaster: (requestData: T_StockWatchlistMasterData) =>
      dispatch(updateStockWatchlistMasterAction(requestData))
  }
})
const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(MobileHeader)
)
