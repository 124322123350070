import React from 'react'
import { DsBox, DsList, DsStack } from '@am92/react-design-system'

import RecentSearch from './RecentSearch'
// components
import RenderSearchList from './RenderSearchList'

export interface ISearchResultProps {
  open: boolean
  searchString: string
  type?: string
  handleStockSelect?: (scriptId: string) => void
}

class SearchResult extends React.PureComponent<ISearchResultProps> {
  ref: React.RefObject<any>

  constructor(props: ISearchResultProps) {
    super(props)
    this.ref = React.createRef()
  }

  handleDropDownRendering = () => {
    const { searchString, type, handleStockSelect } = this.props
    const showStringSearchList =
      (searchString && searchString.length > 1) || type === 'sip'
    if (showStringSearchList) {
      return (
        <RenderSearchList type={type} handleStockSelect={handleStockSelect} />
      )
    }
    return <RecentSearch type={type} handleStockSelect={handleStockSelect} />
  }

  render(): React.ReactNode {
    const { searchString, open } = this.props
    if (!open) return null
    return (
      <DsStack
        id='searchable'
        ref={this.ref}
        sx={{
          '.MuiInputBase-root > .MuiInputBase-input:focus': {
            outline: '0',
            borderColor: 'var(--ds-colour-strokeDefault)'
          }
        }}
      >
        <DsList
          sx={{
            height: '468px',
            overflow: searchString !== '' ? 'scroll' : 'auto',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            width: '99.5%',
            backgroundColor: 'var(--ds-colour-surfacePrimary)',
            top: '46px',
            left: '1px',
            zIndex: '2',
            boxShadow: ' 0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
            '::-webkit-scrollbar': {
              display: 'none'
            },
            '&.MuiList-root': { pt: '0px' }
          }}
        >
          <DsBox sx={{ width: '100%', zIndex: 1000, height: 'max-content' }}>
            {this.handleDropDownRendering()}
          </DsBox>
        </DsList>
      </DsStack>
    )
  }
}

export default SearchResult
