import { createSelector } from '@reduxjs/toolkit'
import { TTickerStore } from '~/src/Configurations/TickerStore'

export const SLICE_NAME = 'dpHoldTicker'

const selectTicker = (state: TTickerStore) => state[SLICE_NAME]
const selectTotalDematValue = (state: TTickerStore) =>
  state[SLICE_NAME]?.dematValue

export const selectIndividualDematValue = (
  state: TTickerStore,
  scriptId: string
) => state[SLICE_NAME]?.scriptsInDpHold?.[scriptId]?.dematValue || 0

export const getDpIndividualDematValueSelector = createSelector(
  [selectIndividualDematValue, (state, scriptId) => scriptId],
  dematValue => dematValue,
  {
    memoizeOptions: {
      maxSize: 1000
    }
  }
)

export const getTotalDematValue = createSelector(
  selectTotalDematValue,
  dematValue => dematValue
)
