import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsStack, DsTypography, DsSkeleton } from '@am92/react-design-system'
import {
  getOrderScriptId,
  isModifyOrderRequest,
  isRepeatOrderRequest
} from '~/src/Redux/Orders/selectors'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import {
  resetOrderData,
  setCommonIsinCodeScriptArray
} from '~/src/Redux/Orders/reducer'
import { getHoldingsSummaryPayload } from '~/src/Redux/Dashboard/Services/getHoldingsSummary.Service'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'
import HoldingBag from './HoldingBag'

type ActionTypes = {
  getHoldingsSummary: (requestData: getHoldingsSummaryPayload) => any
  setCommonIsinCodeScriptArray: (requestData: string[]) => Promise<any>
  resetOrderData: (requestData: string) => Promise<any>
}

export interface IOrderHeaderMiddleProps extends IWithRouterProps {
  actions: ActionTypes
  orderScriptId: string
  stockDetails: T_SCRIPTDATA
  isModifyOrder: boolean
  isRepeatOrder: boolean
  subscribeLtpData: (scriptIdArray: { scriptId: string }[]) => Promise<void>
  unSubscribeLtpData: (scriptIdArray: { scriptId: string }[]) => Promise<void>
}

class OrderHeaderMiddle extends Component<IOrderHeaderMiddleProps> {
  async componentDidMount(): Promise<void> {
    const { orderScriptId, subscribeLtpData } = this.props
    orderScriptId && (await subscribeLtpData([{ scriptId: orderScriptId }]))
    this.setCommonIsinCode()
  }

  async componentDidUpdate(
    prevProps: Readonly<IOrderHeaderMiddleProps>
  ): Promise<void> {
    const {
      orderScriptId,
      actions,
      isModifyOrder,
      isRepeatOrder,
      subscribeLtpData,
      unSubscribeLtpData
    } = this.props
    const { orderScriptId: PREV_ORDERSCRIPTID } = prevProps

    if (PREV_ORDERSCRIPTID !== orderScriptId) {
      PREV_ORDERSCRIPTID &&
        unSubscribeLtpData([{ scriptId: PREV_ORDERSCRIPTID }])
      orderScriptId && subscribeLtpData([{ scriptId: orderScriptId }])
      this.setCommonIsinCode()
      if (!isModifyOrder && !isRepeatOrder) {
        actions.resetOrderData('resetOrderData')
      }
    }
  }

  setCommonIsinCode = () => {
    const { orderScriptId, stockDetails, actions } = this.props
    const { isinCode } = stockDetails || {}
    const commonIsinCodeScriptIdArray = (window as any).__ISIN_CODE_INDEX__[
      isinCode
    ]

    actions.setCommonIsinCodeScriptArray(
      commonIsinCodeScriptIdArray?.length
        ? commonIsinCodeScriptIdArray
        : [orderScriptId]
    )
  }

  render() {
    const { stockDetails } = this.props
    const { label = '' } = (stockDetails as T_SCRIPTDATA) || {}

    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <DsStack sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <DsTypography
              variant='bodyRegularLarge'
              sx={{ mr: 'var(--ds-spacing-quickFreeze)' }}
            >
              {!label ? (
                <DsSkeleton
                  variant='text'
                  width={60}
                  sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
                />
              ) : (
                label
              )}
            </DsTypography>
          </DsStack>
          <HoldingBag />
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderScriptId = getOrderScriptId(state)
  const stockDetails = getStockMasterData(orderScriptId)(state)
  const isModifyOrder = isModifyOrderRequest(state)
  const isRepeatOrder = isRepeatOrderRequest(state)
  return {
    orderScriptId,
    stockDetails,
    isModifyOrder,
    isRepeatOrder
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setCommonIsinCodeScriptArray: (requestData: string[]) =>
      dispatch(setCommonIsinCodeScriptArray(requestData)),
    resetOrderData: (requestData: any) => dispatch(resetOrderData(requestData))
  }
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(OrderHeaderMiddle)
)
