import React from 'react'

// DS Components
import {
  DsBox,
  DsButtonBase,
  DsRemixIcon,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

// Lib
export interface ISearchTabsProps {
  renderTabs: any
  returnValue: string
  onChange: (returnValue: string) => void
  enableArrow?: boolean
  containedTabs: boolean
  searchResults: any
  selectedTabs: any
  breakpoints: any
}

class SearchTabs extends React.Component<ISearchTabsProps> {
  ref: React.RefObject<any>
  scrollPosition: number
  constructor(props: any) {
    super(props)
    this.ref = React.createRef()
    this.scrollPosition = 0
  }

  state = {
    leftScroll: false,
    rightScroll: true,
    scrollPosition: 0
  }

  handleScrollLeft = () => {
    if (this.ref.current !== null) {
      this.ref.current.scrollLeft -= 100
    }
  }
  handleScrollRight = () => {
    if (this.ref.current !== null) {
      this.ref.current.scrollLeft += 100
    }
  }

  handleSelec = (tabValue: string, tabIndex: number) => {
    const { onChange, returnValue, renderTabs } = this.props
    onChange(renderTabs[tabIndex][returnValue])
  }

  handleLength = (id: string) => {
    const { searchResults } = this.props

    if (searchResults.length > 0) {
      if (id === 'ALL') {
        return searchResults.length
      }
      const result = searchResults.filter((item: any) => item.segment === id)
      return result.length
    }
  }

  handleScroll = () => {
    const section = this.ref.current

    if (section) {
      const newScrollPosition = section.scrollLeft + section.clientWidth + 10
      this.setState({ scrollPosition: newScrollPosition })
      this.scrollPosition = newScrollPosition
    }
    if (this.scrollPosition > section?.scrollWidth) {
      this.setState({ rightScroll: false })
    }
    if (this.scrollPosition < section?.scrollWidth) {
      this.setState({ rightScroll: true })
    }
    if (this.scrollPosition - section.clientWidth - 10 < 15) {
      this.setState({ leftScroll: false })
    }
    if (this.scrollPosition - section.clientWidth - 10 > 15) {
      this.setState({ leftScroll: true })
    }
  }

  render() {
    const {
      renderTabs,
      enableArrow,
      breakpoints,
      selectedTabs: selectedTab
    } = this.props
    const { leftScroll, rightScroll } = this.state
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    return (
      <>
        <DsBox
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            px: 'var(--ds-spacing-zero)',
            position: 'sticky',
            top: {
              xs: 'calc(var(--ds-spacing-molten) + var(--ds-spacing-deepFreeze) + var(--ds-spacing-quickFreeze))',
              md: 'var(--ds-spacing-zero)'
            },
            zIndex: '1',
            backgroundColor: 'var(--ds-colour-surfaceBackground)'
          }}
        >
          {isDesktop && enableArrow && leftScroll && (
            <DsButtonBase
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'var(--ds-spacing-tepid)',
                height:
                  'calc(var(--ds-spacing-molten) - var(--ds-spacing-quickFreeze))',
                p: 'var(--ds-spacing-glacial) var(--ds-spacing-quickFreeze)',
                cursor: 'pointer'
              }}
              onClick={this.handleScrollLeft}
            >
              <DsRemixIcon className='ri-arrow-left-s-line' fontSize='mild' />
            </DsButtonBase>
          )}
          <DsBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 'var(--ds-spacing-glacial)',
              overflowX: 'auto',
              whiteSpace: 'pre',
              scrollBehavior: 'smooth',
              px: 'var(--ds-spacing-bitterCold)',
              py: {
                xs: 'var(--ds-spacing-glacial)',
                md: 'var(--ds-spacing-zero)'
              },
              '&::-webkit-scrollbar': { display: 'none' }
            }}
            ref={this.ref}
            onScroll={() => this.handleScroll()}
          >
            {renderTabs.map((item: any, index: any) => {
              const categoryCount = this.handleLength(item.id)
              return (
                <DsBox
                  key={index}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    color:
                      selectedTab === item.id
                        ? 'var(--ds-colour-typoOnSurface)'
                        : 'var(--ds-colour-primary)',
                    backgroundColor: `${
                      selectedTab === item.id
                        ? 'var(--ds-colour-actionSecondary)'
                        : 'var(--ds-colour-surfaceBackground)'
                    }`,
                    borderRadius: 'var(--ds-radius-bitterCold)',
                    p: 'var(--ds-spacing-quickFreeze) var(--ds-spacing-frostbite)',
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    cursor: 'pointer',
                    zIndex: 1000
                  }}
                  onClick={() => this.handleSelec(item.id, index)}
                >
                  <DsTypography variant='bodyRegularSmall'>
                    {item.label} {`(${categoryCount ? categoryCount : 0})`}
                  </DsTypography>
                </DsBox>
              )
            })}
          </DsBox>
          {isDesktop && enableArrow && rightScroll && (
            <DsButtonBase
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'var(--ds-spacing-tepid)',
                height:
                  'calc(var(--ds-spacing-molten) - var(--ds-spacing-quickFreeze))',
                p: 'var(--ds-spacing-glacial) var(--ds-spacing-quickFreeze)',
                cursor: 'pointer'
              }}
              onClick={this.handleScrollRight}
            >
              <DsRemixIcon className='ri-arrow-right-s-line' fontSize='mild' />
            </DsButtonBase>
          )}
        </DsBox>
      </>
    )
  }
}
export default withBreakpoints(SearchTabs)
