import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  clearSearchAction,
  createRecentSearchActions,
  deleteRecentSearchActions,
  getRecentSearchActions,
  globalSearchCallerClickAction,
  openSearchResultContainerAction,
  setSearchResultAction,
  setSearchSelectedTabAction,
  setSearchStringAction
} from './Actions'
import { SLICE_NAME } from './Selectors'

import { I_SCRIPT_SEARCH_STRUCTURE } from '~/src/Constants/SEC_MASTER'

export type IRecentSearchResponseData = {
  scriptId: string
  symbol: string
  exchange: string
  coName: string
}

type searchType = {
  openSearchResultContainer: boolean
  isGlobalSearchCallerClicked: boolean
  searchString: string
  selectedTab: string
  searchResult: I_SCRIPT_SEARCH_STRUCTURE[]
  recentSearchData: IRecentSearchResponseData[]
}

const INITIAL_STATE: searchType = {
  openSearchResultContainer: false,
  isGlobalSearchCallerClicked: false,
  searchString: '',
  selectedTab: 'ALL',
  searchResult: [],
  recentSearchData: []
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    search: (state, action) => {
      // TODO: filter result based on types
      state.searchResult = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(setSearchResultAction, (state, { payload }) => {
      state.searchResult = payload
    })
    builder.addCase(globalSearchCallerClickAction, (state, { payload }) => {
      state.isGlobalSearchCallerClicked = payload
    })
    builder.addCase(setSearchStringAction, (state, { payload }) => {
      state.searchString = payload
    })
    builder.addCase(clearSearchAction, state => {
      state.searchString = ''
      state.searchResult = []
      state.selectedTab = 'ALL'
    })
    builder.addCase(openSearchResultContainerAction, (state, { payload }) => {
      state.openSearchResultContainer = payload
    })
    builder.addCase(setSearchSelectedTabAction, (state, { payload }) => {
      state.selectedTab = payload
    })
    builder.addCase(getRecentSearchActions.success, (state, { payload }) => {
      state.recentSearchData = payload?.recentSearches || []
    })
    builder.addCase(createRecentSearchActions.success, () => {
      // state.recentSearch = payload.recentSearches
    })
    builder.addCase(deleteRecentSearchActions.success, state => {
      state.recentSearchData = []
    })
  }
}

const searchSlice = createSlice(sliceOptions)

export const { search } = searchSlice.actions

export default searchSlice.reducer
