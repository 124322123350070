import React from 'react'
import {
  DsDialog,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

import NriContent from './NriContent'

import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import { IWithRouterProps } from '~/src/Lib/withRouter'

export interface INriDialogProps extends IwithBreakpoints {
  onClick: () => void
  open: boolean
}

interface INriDialogStates {}

export class NriDialog extends React.Component<
  INriDialogProps,
  INriDialogStates
> {
  render() {
    const { breakpoints = {}, onClick, open } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <DsDialog
        className='ApiError'
        sx={{
          '.MuiPaper-root': {
            flex: '1',
            display: 'flex',
            flexGrow: '1',
            minWidth: { xs: '100%', md: '894px' },
            minHeight: { xs: '100%', md: '523px' },
            padding: '0',
            borderRadius: {
              xs: 'var(--ds-spacing-zero)',
              md: 'var(--ds-spacing-bitterCold)'
            },
            '& .MuiStack-root': {
              flexGrow: '1',
              flex: '1',
              justifyContent: 'center',
              '& .MuiBox-root': {}
            },
            '& .MuiBox-root': {
              flexGrow: '1',
              display: 'flex'
            }
          }
        }}
        showClose={false}
        onClose={onClick}
        open={open}
        fullScreen={!isDesktop}
      >
        <NriContent onClick={onClick} isDesktop={isDesktop} />
      </DsDialog>
    )
  }
}

export default withBreakpoints(NriDialog)
