import AppStore, {
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'
import traceActionsCreator from '../traceActionsCreator'
import TickerStore from '~/src/Configurations/TickerStore'
import { calculateNetChange } from '~/src/Utils/global'
import { T_SCRIPTS_OBJ } from './Reducer'
import { T_GET_SORTED_SCRIPTS_PAYLOAD } from '~/src/Components/Sidebar/Components/SortWatchListScripts2'

const alphabeticallySortKeys = ['label']

type T_SORT_SCRIPT_MAPPING = {
  [key: number]: [string, 1 | -1]
}

const SORT_SCRIPT_MAPPING: T_SORT_SCRIPT_MAPPING = {
  1: ['LTP', 1],
  2: ['LTP', -1],
  3: ['PERCENT_CHANGE', 1],
  4: ['PERCENT_CHANGE', -1],
  5: ['label', -1],
  6: ['label', 1]
}

export const createWatchlistServiceName = 'watchList/createWatchlist'
export const createWatchlistActions = traceActionsCreator(
  createWatchlistServiceName
)

export const deleteWatchlistServiceName = 'watchList/deleteWatchlist'
export const deleteWatchlistActions = traceActionsCreator(
  deleteWatchlistServiceName
)

export const fetchWatchlistsServiceName = 'watchList/fetchWatchlists'
export const fetchWatchlistsActions = traceActionsCreator(
  fetchWatchlistsServiceName
)

export const renameWatchlistServiceName = 'watchList/renameWatchlist'
export const renameWatchlistActions = traceActionsCreator(
  renameWatchlistServiceName
)

export const updateScriptsSequenceServiceName =
  'watchList/updateScriptsSequence'
export const updateScriptsSequenceActions = traceActionsCreator(
  updateScriptsSequenceServiceName
)

export const updateWatchlistsSequenceServiceName =
  'watchList/updateWatchlistsSequence'
export const updateWatchlistsSequenceActions = traceActionsCreator(
  updateWatchlistsSequenceServiceName
)

export const bulkUpdateWatchlistServiceName = 'watchList/bulkUpdateWatchlist'
export const bulkUpdateWatchlistActions = traceActionsCreator(
  bulkUpdateWatchlistServiceName
)

export const getSortedWatchlistAction = (
  data: T_GET_SORTED_SCRIPTS_PAYLOAD
) => {
  return (dispatch: TAppDispatch, state: TAppStore) => {
    const { sortScriptOption, watchlistId, watchlistTab } = data
    const watchlistState = AppStore.getState().watchList
    const {
      watchListToSecuritiesArrayMapping,
      watchlistSequenceNumberIndexing
    } = watchlistState

    if (sortScriptOption === 0) {
      const defaultWatchlistSecurityArray =
        watchListToSecuritiesArrayMapping[watchlistId]
      return defaultWatchlistSecurityArray
    }

    const currentSecuritiesArray =
      watchlistSequenceNumberIndexing[watchlistTab].watchlistSecurities

    const sortKey = SORT_SCRIPT_MAPPING[sortScriptOption][0]
    const sortWay = SORT_SCRIPT_MAPPING[sortScriptOption][1]

    const sortedSecuritiesArray = sortSecuritiesArray(
      currentSecuritiesArray,
      sortWay,
      sortKey
    )

    const newSortedSecuritiesArray: T_SCRIPTS_OBJ[] = []
    sortedSecuritiesArray.forEach((item: T_SCRIPTS_OBJ, index: number) => {
      newSortedSecuritiesArray.push({
        ...item,
        sequenceNumber: index
      })
    })

    return newSortedSecuritiesArray
  }
}

const sortSecuritiesArray = (
  arrayToSort: any[],
  sortWay: 1 | -1,
  sortKey: string
) => {
  const stockMasterData = AppStore.getState().stockMaster
  const sortedArray = [...arrayToSort].sort((STOCK_OBJ_1, STOCK_OBJ_2) => {
    // a data
    const { scriptId } = STOCK_OBJ_1
    const { exchangeSymbol, closeprice = 0, label } = stockMasterData[scriptId]
    const stockTickerData = TickerStore.getState().ticker
    const { LTP = closeprice, PERCENT_CHANGE = 0 } =
      stockTickerData[scriptId] || {}
    const NEW_STOCK_OBJ_1 = {
      ...STOCK_OBJ_1,
      LTP,
      PERCENT_CHANGE:
        (PERCENT_CHANGE && PERCENT_CHANGE) ||
        calculateNetChange(LTP, closeprice)[1] ||
        0,
      exchangeSymbol,
      label
    }

    // b data
    const { scriptId: SCRIPTID_B } = STOCK_OBJ_2
    const {
      exchangeSymbol: EXCHANGE_SYMBOL_B,
      closeprice: CLOSE_PRICE_B = 0,
      label: LABEL_B
    } = stockMasterData[SCRIPTID_B]
    const { LTP: LTP_B = CLOSE_PRICE_B, PERCENT_CHANGE: PERCENT_CHANGE_B = 0 } =
      stockTickerData[SCRIPTID_B] || {}
    const NEW_STOCK_OBJ_2 = {
      ...STOCK_OBJ_2,
      LTP: LTP_B,
      PERCENT_CHANGE:
        (PERCENT_CHANGE_B && PERCENT_CHANGE_B) ||
        calculateNetChange(LTP_B, CLOSE_PRICE_B)[1] ||
        0,
      exchangeSymbol: EXCHANGE_SYMBOL_B,
      label: LABEL_B
    }

    if (alphabeticallySortKeys.includes(sortKey)) {
      if (NEW_STOCK_OBJ_1[sortKey] < NEW_STOCK_OBJ_2[sortKey]) {
        return -1 * sortWay
      }
      if (NEW_STOCK_OBJ_1[sortKey] > NEW_STOCK_OBJ_2[sortKey]) {
        return 1 * sortWay
      }
      return 0
    }

    return sortWay * (NEW_STOCK_OBJ_1[sortKey] - NEW_STOCK_OBJ_2[sortKey])
  })
  return sortedArray
}
