import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook
} from 'react-redux'

import { tickerContext, TTickerDispatch } from '../Configurations/TickerStore'
import { appContext, TAppDispatch } from '~/src/Configurations/AppStore'

//SECTION - APP HOOKS
export const useAppStore = createStoreHook(appContext)
export const useAppDispatch: () => TAppDispatch = createDispatchHook(appContext)
export const useAppSelector = createSelectorHook(appContext)
//SECTION - TICKER HOOKS
export const useTickerStore = createStoreHook(tickerContext)
export const useTickerDispatch: () => TTickerDispatch =
  createDispatchHook(tickerContext)
export const useTickerSelector = createSelectorHook(tickerContext)
