import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

// context
import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'

// actions

// selectors
import { getRecentSearch } from '~/src/Redux/Search/Selectors'
import {
  DsBox,
  DsTypography,
  DsButton,
  DsDivider,
  DsListItemButton,
  DsStack,
  DsRemixIcon
} from '@am92/react-design-system'
import deleteRecentSearchConfigAction from '~/src/Redux/Search/Services/deleteAllSearch.Service'
import { setSelectedExchange } from '~/src/Redux/Orders/reducer'
import { triggerCleverTapEvent } from '~/src/Utils/global'
import { IRecentSearchResponseData } from '~/src/Redux/Search/Reducer'
import BookmarkButtonNew from '~/src/Components/BookmarkButtonNew'
import ZeroSearchResult from './ZeroSearchResult'

export interface IRecentSearchProps {
  type?: string
  handleStockSelect?: (scriptId: string) => void
}

class RecentSearch extends React.PureComponent<
  IRecentSearchProps & PropsFromRedux
> {
  ref: React.RefObject<any>

  constructor(props: IRecentSearchProps & PropsFromRedux) {
    super(props)
    this.ref = React.createRef()
  }

  handleClearAll = () => {
    const { actions } = this.props
    actions.deleteRecentSearch()
  }

  handleOnSelectStock = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    scripId: string
  ) => {
    const { attributes = '' } = (e?.target as Element) || {}
    const { datatype = '' } = (attributes as any) || {}
    if (datatype.nodeValue !== 'navigateToQuotes') {
      return
    }
    const { actions, handleStockSelect } = this.props
    const { exchange } = (window as any).secMaster.getByScriptId(scripId) || {}
    const customAttributes = {
      'Event Status': 'Pass',
      'Screen Name': 'Search',
      Action: 'Script Clicked',
      Keyword: scripId
    }
    triggerCleverTapEvent('Search_Done', customAttributes)
    await actions.setSelectedExchange({
      selectedExchange: exchange
    })
    handleStockSelect && handleStockSelect(scripId)
  }

  renderRecentlySearchedList = () => {
    const { recentSearchResult, type = '' } = this.props
    const noSearchResult = !(recentSearchResult && recentSearchResult.length)

    if (noSearchResult) {
      return <ZeroSearchResult typeOfSearch={'recentSearch'} />
    }

    return (
      <>
        <DsBox
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <DsTypography
            variant='subheadingSemiboldDefault'
            sx={{
              padding: 'var(--ds-spacing-bitterCold)'
            }}
            color='var(--ds-colour-typoSecondary)'
          >
            Recent Searches
          </DsTypography>
          <DsButton
            variant='text'
            color='secondary'
            sx={{
              display: { md: 'inline-block' },
              padding: 'var(--ds-spacing-zero)',
              justifyContent: 'flex-end',
              marginRight: '16px'
            }}
            onClick={this.handleClearAll}
          >
            Clear All
          </DsButton>
        </DsBox>
        {recentSearchResult.map((searchItem: IRecentSearchResponseData) => {
          const { scriptId, exchange, symbol, coName } = searchItem
          return (
            <>
              <DsListItemButton
                key={scriptId}
                sx={{
                  p: 'var(--ds-spacing-bitterCold)'
                }}
                onClick={e => {
                  this.handleOnSelectStock(e, scriptId)
                }}
                datatype='navigateToQuotes'
              >
                <DsStack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.25rem'
                  }}
                  datatype='navigateToQuotes'
                >
                  <DsRemixIcon
                    className={'ri-time-line'}
                    sx={{
                      m: 'var(--ds-spacing-glacial)',
                      color: '#B4B4B4'
                    }}
                    datatype='navigateToQuotes'
                  />
                  <DsStack
                    sx={{ display: 'flex', gap: '0.5rem' }}
                    datatype='navigateToQuotes'
                  >
                    <DsBox
                      sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center'
                      }}
                      datatype='navigateToQuotes'
                    >
                      <DsTypography
                        variant='bodyBoldSmall'
                        datatype='navigateToQuotes'
                      >
                        {symbol}
                      </DsTypography>
                      <DsTypography
                        variant='supportRegularInfo'
                        sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                        datatype='navigateToQuotes'
                      >
                        {exchange}
                      </DsTypography>
                    </DsBox>

                    <DsTypography
                      variant='bodyRegularSmall'
                      sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                      datatype='navigateToQuotes'
                    >
                      {coName}
                    </DsTypography>
                  </DsStack>
                </DsStack>
                {/* BOOKMARK ICON  */}
                {!type && (
                  <DsBox
                    sx={{
                      position: 'absolute',
                      right: 10,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 'var(--ds-spacing-glacial)'
                    }}
                  >
                    <BookmarkButtonNew scriptId={scriptId} />
                  </DsBox>
                )}
              </DsListItemButton>
              <DsStack sx={{ alignItems: 'center' }}>
                <DsDivider sx={{ width: '94%' }} />
              </DsStack>
            </>
          )
        })}
      </>
    )
  }

  render(): React.ReactNode {
    return <>{this.renderRecentlySearchedList()}</>
  }
}

const mapStateToProps = (state: TAppStore) => {
  const recentSearchResult = getRecentSearch(state)
  return {
    recentSearchResult
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    deleteRecentSearch: () => dispatch(deleteRecentSearchConfigAction()),
    setSelectedExchange: (requestData: any) =>
      dispatch(setSelectedExchange(requestData))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(RecentSearch)
