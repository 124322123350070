import {
  I_INDEX_STRUCTURE,
  I_INDEX_STRUCTURE_VALUE,
  I_SEC_MASTER_ARRAY,
  SEGMENT_DFAULT_MAPPING,
  T_FLATTEN_EQUITY_ARRAY,
  T_FLATTEN_UNDERLYING_ARRAY,
  T_FLATTEN_DERIVATIVES_INNER_ARRAY,
  T_SEARCH_STRING_CHUNK,
  I_DERIVATIVE_INDEX,
  I_ISIN_CODE_INDEX,
  T_EXCHANGE_TYPES
} from '../../Constants/SEC_MASTER'
import { GLOBAL_SUCCESS_EVENT_NAME } from '~/src/Workers/SecurityMaster/EVENT_CONTANTS'
import {
  mapDerivativeSegment,
  mapEquitySegment,
  mapSymbolData,
  mapUnderlyingSegment
} from './helpers'
import AppStore from '~/src/Configurations/AppStore'
import { securityMasterLoaded } from '~/src/Redux/Indicators/Reducer'
import { storeDerivativeIndex } from '~/src/Redux/Derivatives/Reducer'

interface IMapSegmentOptions {
  getSearchableStructure: boolean
}

const DEFAULT_VALUES_MAP_SEGMENT_OPTIONS = {
  getSearchableStructure: false
}
class SecMaster {
  MASTER_DATA: Partial<I_SEC_MASTER_ARRAY> = {}
  SCRIPT_ID_INDEX: I_INDEX_STRUCTURE = {}
  SEARCH_STRING_CHUNK: T_SEARCH_STRING_CHUNK = [[], [], []]
  DERIVATIVE_INDEX: I_DERIVATIVE_INDEX = {}
  ISIN_CODE_INDEX: I_ISIN_CODE_INDEX = {}

  STATE: 'INIT' | 'READY' = 'INIT'

  constructor() {
    const { __SEC_MASTER_LOADING__ } = (window as any) || {}
    if (__SEC_MASTER_LOADING__ === 'DONE') {
      this.initialize()
    } else {
      addEventListener(GLOBAL_SUCCESS_EVENT_NAME, this.initialize)
    }
  }

  initialize = () => {
    const {
      __MASTER_DATA__,
      __SCRIPT_ID_INDEX__,
      __SEARCH_STRING_CHUNK__,
      __DERIVATIVE_INDEX__,
      __ISIN_CODE_INDEX__
    } = window as any
    this.MASTER_DATA = __MASTER_DATA__
    this.SCRIPT_ID_INDEX = __SCRIPT_ID_INDEX__
    this.SEARCH_STRING_CHUNK = __SEARCH_STRING_CHUNK__
    this.DERIVATIVE_INDEX = __DERIVATIVE_INDEX__
    this.ISIN_CODE_INDEX = __ISIN_CODE_INDEX__
    this.STATE = 'READY'

    AppStore.dispatch(
      securityMasterLoaded({
        isSecurityMasterLoadedIndicator: true
      })
    )
    AppStore.dispatch(storeDerivativeIndex(__DERIVATIVE_INDEX__))
  }

  getByScriptId = (scriptId: number) => {
    const mappingArray = this.SCRIPT_ID_INDEX[scriptId]

    if (!mappingArray) {
      return undefined
    }

    const secSegmentKey = mappingArray[0]
    const { segment, exchange, instrumentType } =
      SEGMENT_DFAULT_MAPPING[secSegmentKey]
    const scriptInnerData = this._getAndMapFromMaster(mappingArray)

    const script = {
      ...scriptInnerData,
      segment,
      exchange,
      instrumentType
    }
    return script
  }

  getScriptIdsBasedOnIsinCode = (isinCode: string) => {
    return this.ISIN_CODE_INDEX[isinCode]
  }

  _getAndMapFromMaster = (
    mappingArray: I_INDEX_STRUCTURE_VALUE,
    options: IMapSegmentOptions = DEFAULT_VALUES_MAP_SEGMENT_OPTIONS
  ) => {
    const segmentKey = mappingArray[0]
    const { segment, exchange, instrumentType } =
      SEGMENT_DFAULT_MAPPING[segmentKey]

    if (instrumentType === 'EQUITY') {
      const segmentData = this.MASTER_DATA[
        segmentKey
      ] as T_FLATTEN_EQUITY_ARRAY[]
      const dataArray = segmentData[mappingArray[1]] as T_FLATTEN_EQUITY_ARRAY
      const script = mapEquitySegment(dataArray, options)
      return { ...script, segment, exchange, instrumentType }
    }

    if (instrumentType === 'UNDERLYING') {
      const segmentData = this.MASTER_DATA[
        segmentKey
      ] as T_FLATTEN_UNDERLYING_ARRAY[]
      const dataArray = segmentData[
        mappingArray[1]
      ] as T_FLATTEN_UNDERLYING_ARRAY
      const script = mapUnderlyingSegment(dataArray, options)
      return { ...script, segment, exchange, instrumentType }
    }

    const segmentData = this.MASTER_DATA[segmentKey]
    const symbolData = segmentData && segmentData[mappingArray[1]]
    const underlying: number | undefined = symbolData && symbolData[1]
    const intstrumentData =
      symbolData &&
      (symbolData[
        mappingArray[2] as number
      ] as T_FLATTEN_DERIVATIVES_INNER_ARRAY[])
    const dataArray =
      intstrumentData &&
      (intstrumentData[
        mappingArray[3] as number
      ] as T_FLATTEN_DERIVATIVES_INNER_ARRAY)

    const symbol = mapSymbolData(symbolData)
    const script = mapDerivativeSegment(
      dataArray as T_FLATTEN_DERIVATIVES_INNER_ARRAY,
      options
    )
    return {
      ...script,
      ...symbol,
      segment,
      exchange,
      instrumentType,
      underlying
    }
  }

  _isScriptAvailableInSecurityMaster = (scriptId: number) => {
    const mappingArray = this.SCRIPT_ID_INDEX[scriptId]
    if (!mappingArray) {
      return false
    }
    return true
  }

  _getScripIdForBSEDerivative = (scriptId: string): string | null => {
    const scripDetails = (window as any).secMaster.getByScriptId(scriptId)
    if (!scripDetails) {
      return null
    }
    const {
      isinCode = '',
      exchange = '',
      segment = '',
      underlying = ''
    } = scripDetails

    let mappedId: string | null = scriptId
    if (exchange === 'NSE' && segment === 'EQ') {
      return mappedId
    }

    // NOTE: since there max 2 possible value for a given isinCode
    // if current selected scripId exchange === NSE we get the other one from array

    const mappedScripIdsForIsinCode = this.ISIN_CODE_INDEX[isinCode]
    if (exchange === 'BSE') {
      if (mappedScripIdsForIsinCode && mappedScripIdsForIsinCode.length > 1) {
        mappedId =
          mappedScripIdsForIsinCode.find(
            mappedscripId => mappedscripId !== scriptId
          ) || null
      }
    } else {
      // NOTE: for derivatives
      mappedId = underlying
    }

    return mappedId
  }
}

const secMaster = new SecMaster()
;(window as any).secMaster = secMaster

export function getSecmaster() {
  return secMaster
}

export default secMaster
