import { createSelector } from '@reduxjs/toolkit'
import { compareAsc } from 'date-fns'
import TickerStore from '~/src/Configurations/TickerStore'
import { getFilterData, getCurrencyFormat } from '~/src/Utils/global'
import { TAppStore } from '~/src/Configurations/AppStore'
import { T_HOLDING_DETAILS_SCRIPTS_IN_HOLDING } from '../Dashboard/TYPES'
import { T_SCRIPTDATA } from './Reducer'

export const SLICE_NAME = 'stockMaster'
const select = (state: TAppStore) => state[SLICE_NAME]

export const getStockMasterData = (scriptId: number) =>
  createSelector(select, stockMaster => stockMaster?.[scriptId])

export const getShowCompanyLogo = (scriptId: string) =>
  createSelector(select, stockMaster => {
    const { CMOTS_COCODE, segment } = stockMaster?.[scriptId] || {}
    if (segment === 'EQ' && CMOTS_COCODE && CMOTS_COCODE !== 'None') {
      return true
    }
  })

export const getSurScript = (scriptId: number | string) =>
  createSelector(select, stockMaster => {
    const { asm_flag, exchange } = stockMaster?.[scriptId] || {}
    const asmArray = asm_flag
      ?.split('| ')
      .map((item: string) => `${exchange}_${item}`)
    return asmArray
  })

export const getFuturesMasterData = (scriptIdArray: any) =>
  createSelector(select, stockMaster =>
    _getFuturesStocksData(scriptIdArray, stockMaster)
  )

const _getFuturesStocksData = (scriptIdArray: any, stockMaster: any) => {
  let stocksDataArray: any = []
  scriptIdArray.filter((scriptId: any) => {
    if (stockMaster[scriptId]) {
      stocksDataArray.push({ ...stockMaster[scriptId], scriptId })
    }
  })
  stocksDataArray.sort(
    (
      a: { expiryDate: string | number | Date },
      b: { expiryDate: string | number | Date }
    ) => compareAsc(new Date(a.expiryDate), new Date(b.expiryDate))
  )
  return stocksDataArray
}

export const getOptionsMasterData = (scriptIdArray: any) =>
  createSelector(select, stockMaster =>
    _getOptionsStocksData(scriptIdArray, stockMaster)
  )

const _getOptionsStocksData = (scriptIdArray: any, stockMaster: any) => {
  const stocksDataArray = scriptIdArray?.map(
    (item: any) => stockMaster?.[item.scriptId]
  )
  return stocksDataArray
}

export const getFullStockMasterData = createSelector(
  select,
  stockMaster => stockMaster
)

export const getDprFromStockMasterData = (scriptId: string | number) =>
  createSelector(select, stockMaster => {
    const { DPRLOW = '', DPRHIGH = '' } = stockMaster?.[scriptId] || {}
    return { DPRLOW, DPRHIGH }
  })

export const getIsInCodeFromStockMasterData = (scriptId: number) =>
  createSelector(select, stockMaster => {
    const { isinCode = '' } = stockMaster?.[scriptId] || {}
    return isinCode
  })

export const getClosePriceFromStockMasterData = (scriptId: number) =>
  createSelector(select, stockMaster => {
    const { closeprice } = stockMaster?.[scriptId] || {}
    return closeprice
  })

export const getLabelFromStockMasterData = (scriptId: string) =>
  createSelector(select, stockMaster => {
    const { closeprice } = stockMaster?.[scriptId] || {}
    return closeprice
  })
