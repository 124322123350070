import { DsStack, DsBox, DsTypography } from '@am92/react-design-system'
import React, { useEffect } from 'react'
import { getCurrencyFormat } from '~/src/Utils/global'
import IndividualHoldingsCurrentValueTicker from '../../TickerBasedComponents/IndividualHoldingsCurrentValueTicker'
import IndividualHoldingsOverallPnlValueTicker from '../../TickerBasedComponents/IndividualHoldingsOverallPnlValueTicker'
import { TAppStore } from '~/src/Configurations/AppStore'
import { useAppDispatch, useAppSelector } from '~/src/hooks/useRedux'
import { holdingQuantitiesFromPrimaryPortfolioSelector } from '~/src/Redux/Dashboard/Selectors'
import {
  getClosePriceFromStockMasterData,
  getIsInCodeFromStockMasterData
} from '~/src/Redux/StockMaster/Selectors'
import getHoldingQuantitiesOfPrimaryPortfolioAction from '~/src/Redux/Dashboard/Services/getHoldingQuantitiesOfPrimaryPortfolio.Service'
import { T_GET_HOLDING_DETAILS_PAYLOAD } from '~/src/Redux/Dashboard/TYPES'
import { getOrderScriptId } from '~/src/Redux/Orders/selectors'
interface IHoldingDetailsPopUpContentProps {}

const HoldingDetailsPopUpContent: React.FunctionComponent<
  IHoldingDetailsPopUpContentProps
> = (props: IHoldingDetailsPopUpContentProps) => {
  const dispatch = useAppDispatch()
  const scriptId = useAppSelector((state: TAppStore) => getOrderScriptId(state))
  const isinCode = useAppSelector((state: TAppStore) =>
    getIsInCodeFromStockMasterData(scriptId)(state)
  )

  const {
    dmatfreeQty,
    collateralQty,
    emarginNetQuantity,
    holdingQty,
    avgPrice,
    investedValue
  } = useAppSelector((state: TAppStore) =>
    holdingQuantitiesFromPrimaryPortfolioSelector(state, isinCode)
  )

  const closeprice = useAppSelector((state: TAppStore) =>
    getClosePriceFromStockMasterData(scriptId)(state)
  )

  useEffect(() => {
    !holdingQty && handleGetHoldingQuantitiesOfPrimaryPortfolio()
  }, [])

  const handleGetHoldingQuantitiesOfPrimaryPortfolio = async () => {
    const requestData: T_GET_HOLDING_DETAILS_PAYLOAD = {
      segment: 'EQ'
    }
    const getHoldingQuantitiesOfPrimaryPortfolio = await dispatch(
      getHoldingQuantitiesOfPrimaryPortfolioAction(requestData)
    )
    if (getHoldingQuantitiesOfPrimaryPortfolio._isCustomError) {
      console.log('failed API - getHoldingsSummaryResponse')
      return
    }
  }

  return (
    <>
      <DsStack spacing={4} justifyContent={'flex-end'}>
        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DsTypography variant='bodyRegularSmall'>Quantity</DsTypography>
          <DsTypography variant='bodyRegularSmall'>{holdingQty}</DsTypography>
        </DsBox>

        <DsStack spacing={4} sx={{ width: '96%', ml: '1rem !important' }}>
          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTypography variant='bodyRegularSmall'>DEMAT</DsTypography>
            <DsTypography variant='bodyRegularSmall'>
              {(dmatfreeQty && dmatfreeQty) || 0}
            </DsTypography>
          </DsBox>
          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTypography variant='bodyRegularSmall'>
              Collateral (Pledged)
            </DsTypography>
            <DsTypography variant='bodyRegularSmall'>
              {(collateralQty && collateralQty) || 0}
            </DsTypography>
          </DsBox>
          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTypography variant='bodyRegularSmall'>BMPL</DsTypography>
            <DsTypography variant='bodyRegularSmall'>
              {(emarginNetQuantity && emarginNetQuantity) || 0}
            </DsTypography>
          </DsBox>
        </DsStack>

        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DsTypography variant='bodyRegularSmall'>Average Price</DsTypography>
          <DsTypography variant='bodyRegularSmall'>
            {getCurrencyFormat(avgPrice, {
              style: 'decimal',
              signDisplay: 'never',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </DsTypography>
        </DsBox>

        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DsTypography variant='bodyRegularSmall'>Unrealised P&L</DsTypography>
          <IndividualHoldingsOverallPnlValueTicker
            scriptId={scriptId.toString()}
            closeprice={closeprice}
            holdings={holdingQty}
            investedValue={investedValue}
            overallPnlWrapperProps={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 'var(--ds-spacing-deepFreeze)'
            }}
            overallPnlTypoProps={{ variant: 'bodyRegularSmall' }}
            overallPnlPercentChangeTypoProps={{
              variant: 'bodyRegularSmall',
              sx: {
                '&::before': {
                  content: '"("'
                },
                '&::after': {
                  content: '")"'
                }
              }
            }}
            overallPnlNumberFormatOptions={{
              style: 'decimal'
            }}
          />
        </DsBox>

        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DsTypography variant='bodyRegularSmall'>
            Invested Amount
          </DsTypography>
          <DsTypography variant='bodyRegularSmall'>
            {getCurrencyFormat(investedValue, {
              style: 'decimal',
              signDisplay: 'never',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </DsTypography>
        </DsBox>

        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DsTypography variant='bodyRegularSmall'>Current Value</DsTypography>
          <IndividualHoldingsCurrentValueTicker
            scriptId={scriptId.toString()}
            closeprice={closeprice}
            holdings={holdingQty}
            investedValue={investedValue}
            currentValueTypoProps={{ variant: 'bodyRegularSmall' }}
            currentValueNumberFormatOptions={{
              style: 'decimal',
              signDisplay: 'exceptZero'
            }}
          />
        </DsBox>
      </DsStack>
    </>
  )
}

export default HoldingDetailsPopUpContent
