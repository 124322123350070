import React from 'react'
import SidebarBody from './SidebarBody'
import SidebarFooter from './SidebarFooter'
import SidebarHeader from './SidebarHeader'

// actions

export interface ISidebarWrapperState {}

export interface ISidebarWrapperProps {}

const SidebarWrapper: React.FunctionComponent<ISidebarWrapperProps> = props => {
  return (
    <>
      <SidebarHeader />
      <SidebarBody />
      <SidebarFooter />
    </>
  )
}

export default SidebarWrapper
