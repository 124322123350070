import {
  DsDialog,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import LinkDDPIWrapper from './LinkDDPI.Wrapper'
import withRouter from '~/src/Lib/withRouter'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

export interface ILinkDDPIProps extends IwithBreakpoints {
  onClose: (item: boolean) => void
  openServiceModal: (value: boolean) => void
}

class LinkDDPI extends Component<ILinkDDPIProps> {
  render() {
    const { breakpoints, onClose, openServiceModal } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints) || {}
    return (
      <DsDialog
        sx={{
          '.MuiPaper-root': {
            display: 'flex',
            borderRadius: {
              xs: 'var(--ds-radius-zero)',
              md: 'var(--ds-radius-bitterCold)'
            },
            '& .MuiStack-root': {
              justifyContent: 'center',
              '& .MuiBox-root': {}
            },
            '& .MuiBox-root': {
              display: 'flex'
            }
          }
        }}
        showClose={false}
        onClose={onClose}
        open={true}
        fullScreen={!isDesktop}
      >
        <LinkDDPIWrapper
          onClose={onClose}
          openServiceModal={openServiceModal}
        />
      </DsDialog>
    )
  }
}

export default withBreakpoints(withRouter(LinkDDPI))
