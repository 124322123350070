// DONE - CLEANUP: @vasi
// 1. please update valid color code - color: 'var(--secondary-pink-pink-100, #ED1164)'
// CustomerSupportSomethingWentWrongErrorContainer - this was supposed to be in Pages Folder - as we have route for the same - move this to Pages Folder

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  DsBox,
  DsButton,
  DsButtonBase,
  DsDialog,
  DsImage,
  DsLink,
  DsRemixIcon,
  DsStack,
  DsTypography,
  IwithBreakpoints
} from '@am92/react-design-system'

import { getErrorCodeSelector } from '~/src/Redux/Error/Selectors'
import { appContext, TAppStore } from '~/src/Configurations/AppStore'
import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'
import { CUSTOMERSUPPORT } from '~/src/Constants/ASSET_MAP'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

export interface ICustomerSupportServiceNotAvailableErrorProps
  extends IWithRouterProps,
    IwithBreakpoints {
  errorCode: any
  setShowServiceModal: (data: boolean) => void
}

class CustomerSupportServiceNotAvailableError extends PureComponent<ICustomerSupportServiceNotAvailableErrorProps> {
  render() {
    const { setShowServiceModal, breakpoints } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    return (
      <DsDialog
        sx={{
          '.MuiPaper-root': {
            display: 'flex',
            height: { md: 'auto', xs: '100%' },
            borderRadius: {
              xs: 'var(--ds-radius-zero)',
              md: 'var(--ds-radius-bitterCold)'
            },
            '& .MuiStack-root': {
              '& .MuiBox-root': {}
            },
            '& .MuiBox-root': {
              display: 'flex'
            }
          }
        }}
        showClose={false}
        open={true}
        fullScreen={!isDesktop}
      >
        <>
          <DsStack
            sx={{
              justifyContent: 'space-between',
              gap: 'var(--ds-spacing-bitterCold)',
              alignItems: 'center',
              textAlign: 'center',
              height: '100%',
              flexDirection: 'column',
              position: ' relative'
            }}
          >
            {!isDesktop && (
              <DsRemixIcon
                className='ri-close-line'
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '0',
                  top: 'var(--ds-spacing-frostbite)'
                }}
                onClick={() => setShowServiceModal(false)}
              />
            )}
            <DsStack
              sx={{
                alignItems: 'center',
                flexDirection: 'column',
                gap: 'var(--ds-spacing-warm)',
                textAlign: 'center',
                mt: {
                  xs: 'var(--ds-spacing-plasma)',
                  md: 'var(--ds-spacing-zero)'
                }
              }}
            >
              <DsBox>
                <DsImage srcSet={CUSTOMERSUPPORT} />
              </DsBox>
              <DsTypography variant='headingBoldLarge'>
                Service not available
              </DsTypography>
              <DsTypography variant='bodyRegularLarge'>
                This feature is not yet available for your account. Please
                contact our customer care for more details.
              </DsTypography>

              <DsBox
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexGrow: '0 !important',
                  width: '94%'
                }}
              >
                <DsStack
                  sx={{
                    alignItems: 'flex-start',
                    width: '50%'
                  }}
                >
                  <DsTypography variant='supportRegularMetadata'>
                    Contact number
                  </DsTypography>
                  <DsTypography
                    sx={{
                      color: 'var(--ds-colour-actionSecondary)',
                      textAlign: 'start'
                    }}
                  >
                    <DsLink href='tel:022-40508080' underline='none'>
                      022-40508080
                    </DsLink>{' '}
                    |{' '}
                    <DsLink href='tel:022-61480808' underline='none'>
                      022-61480808
                    </DsLink>
                  </DsTypography>
                </DsStack>
                <DsStack
                  sx={{
                    alignItems: 'flex-start',
                    width: '50%'
                  }}
                >
                  <DsTypography variant='supportRegularMetadata'>
                    Email Address
                  </DsTypography>
                  <DsLink href='mailto:helpdesk@axisdirect.in' underline='none'>
                    <DsTypography
                      sx={{ color: 'var(--ds-colour-actionSecondary)' }}
                    >
                      helpdesk@axisdirect.in
                    </DsTypography>
                  </DsLink>
                </DsStack>
              </DsBox>
            </DsStack>

            <DsButtonBase
              sx={{
                display: 'flex',
                gap: '8px',
                width: '100%'
              }}
            >
              <DsButton
                onClick={() => setShowServiceModal(false)}
                size='medium'
                fullWidth={true}
              >
                Go Back
              </DsButton>
            </DsButtonBase>
          </DsStack>
        </>
      </DsDialog>
    )
  }
}
const mapStateToProps = (state: TAppStore) => {
  const errorCode = getErrorCodeSelector(state)
  return {
    errorCode
  }
}
const mapDispatchToProps = () => ({
  actions: {}
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { context: appContext })(
    CustomerSupportServiceNotAvailableError
  )
)
