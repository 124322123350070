import React, { PureComponent } from 'react'
import {
  DsBox,
  DsCheckbox,
  DsFormControlLabel,
  DsFormGroup,
  DsPopup,
  DsTypography,
  enqueueNotistack,
  withBreakpoints
} from '@am92/react-design-system'

import { T_SET_SECURITIES_REQ_DATA } from '../Sidebar/Components/WatchList'
import CreateWatchlist from './CreateWatchlist'

import { setNewStockWatchListArrayAction } from '~/src/Redux/StockWatchlistMaster/Actions'
import {
  setNewTemporaryStockWatchlistData,
  T_StockWatchlistMasterData
} from '~/src/Redux/StockWatchlistMaster/Reducer'
import {
  getStockWatchlistMaster,
  getTempStockWatchlistMaster
} from '~/src/Redux/StockWatchlistMaster/Selectors'
import {
  setSecurityArray,
  setWatchlistToSecuritiesArray,
  T_SCRIPTS_OBJ,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import {
  getSelectedWatchlistTab,
  getWatchListData,
  getWatchListIndex,
  getWatchlistSequenceNumberIndexing,
  shouldAddScriptToParticularWatchlist
} from '~/src/Redux/WatchList/Selectors'
import bulkUpdateWatchlistAction, {
  I_BULK_UPDATE_WATCHLIST_PAYLOAD
} from '~/src/Redux/WatchList/Services/BulkUpdateWatchlist.Service'
import updateScriptsSequenceAction, {
  IUpdateScriptsSequencePayload
} from '~/src/Redux/WatchList/Services/UpdateScriptsSequence.Service'

import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import { areEqual } from '~/src/Utils/global'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

// lib
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

type ActionTypes = {
  setSecurityArray: (reqData: T_SET_SECURITIES_REQ_DATA) => Promise<any>
  setWatchlistToSecuritiesArray: (
    reqData: T_SET_SECURITIES_REQ_DATA
  ) => Promise<any>
  updateScriptsSequence: (
    reqData: IUpdateScriptsSequencePayload
  ) => Promise<any>
  bulkUpdateWatchlist: (
    requestData: I_BULK_UPDATE_WATCHLIST_PAYLOAD
  ) => Promise<any>
  setNewStockWatchListArray: (requestData: {
    stockAvailableInWatchListArray: string[]
    bookMarkedScriptId: string
  }) => Promise<any>
  setNewTemporaryStockWatchlistData: (requestData: any) => Promise<any>
}

export interface IAddToWatchlistProps {
  actions: ActionTypes
  breakpoints: any
  scriptId: any
  watchListData: watchlistObj[]
  getStockWatchlistMasterData: T_StockWatchlistMasterData
  getTempStockWatchlistMasterData: T_StockWatchlistMasterData
  selectedWatchlistTab: string | number
  watchListIndex: {
    [key: string]: string
  }
  handleError: (res: any) => void
  addScriptToParticularWatchlist: boolean
  isBulkUpdateAPILoading: boolean
  bookMarkIconClicked: boolean
  stockAvailableInWatchListArray: string[]
  bookMarkedScriptId: string | number
  tempAddScriptToParticularWatchlist: boolean
  handleCloseModal: () => void
  openAddToWatchlistModal: boolean
  subscribeLtpData: (scriptIdArray: any[]) => void
  unSubscribeLtpData: (scriptIdArray: any[]) => void
  watchlistSequenceNumberIndexing: {
    [key: string]: watchlistObj
  }
}

export interface IAddToWatchlistState {
  bookMarkedScriptId: string | number
  stockAvailableInWatchListArray: string[]
  hasCheckBoxSelectionChanged: boolean
}

class AddToWatchlist extends PureComponent<
  IAddToWatchlistProps,
  IAddToWatchlistState
> {
  constructor(props: IAddToWatchlistProps) {
    super(props)
    this.state = {
      bookMarkedScriptId: 0,
      stockAvailableInWatchListArray: [],
      hasCheckBoxSelectionChanged: false
    }
  }

  componentDidUpdate(prevProps: Readonly<IAddToWatchlistProps>): void {
    const {
      bookMarkIconClicked,
      bookMarkedScriptId,
      stockAvailableInWatchListArray
    } = this.props
    const { bookMarkIconClicked: PREV_CLICKED } = prevProps
    if (bookMarkIconClicked !== PREV_CLICKED && bookMarkIconClicked) {
      this.setState(
        {
          bookMarkedScriptId,
          stockAvailableInWatchListArray
        },
        () => {
          const { tempAddScriptToParticularWatchlist, handleCloseModal } =
            this.props

          if (tempAddScriptToParticularWatchlist) {
            this.handleIndividualBookMarkChange()
            handleCloseModal()
            return
          }
        }
      )
    }
  }

  handleCloseAddToWatchlistModal = () => {
    const { handleCloseModal } = this.props
    this.setState({
      stockAvailableInWatchListArray: [],
      bookMarkedScriptId: 0,
      hasCheckBoxSelectionChanged: false
    })
    handleCloseModal()
  }

  handleAddRemoveScriptsLocally = async (
    requestData: I_BULK_UPDATE_WATCHLIST_PAYLOAD
  ) => {
    const { bookMarkedScriptId } = this.state
    const { checkedWatchlistIds = [], uncheckedWatchlistIds = [] } = requestData
    const {
      actions,
      selectedWatchlistTab,
      watchListIndex,
      subscribeLtpData,
      unSubscribeLtpData,
      watchlistSequenceNumberIndexing
    } = this.props

    // Adding script to checked Watchlist
    checkedWatchlistIds.forEach(async (watchListId: string) => {
      const watchListSequenceNumber = watchListIndex[watchListId]
      const currentWatchList =
        watchlistSequenceNumberIndexing[watchListSequenceNumber]
      const securitiesArray = currentWatchList.watchlistSecurities || []
      const newSequenceNumber =
        (securitiesArray?.[securitiesArray?.length - 1]
          ?.sequenceNumber as number) + 1 || 0
      const newSecuritiesArray = Array.from(securitiesArray)

      const newSecurityObj: T_SCRIPTS_OBJ = {
        scriptId: bookMarkedScriptId.toString(),
        sequenceNumber: newSequenceNumber
      }
      newSecuritiesArray.push(newSecurityObj)

      await actions.setSecurityArray({
        securitiesArray: newSecuritiesArray,
        watchlistId: watchListId
      })

      // update the default securities array in WatchlistToSecuritiesArray redux
      await actions.setWatchlistToSecuritiesArray({
        securitiesArray: newSecuritiesArray,
        watchlistId: watchListId
      })

      if (+selectedWatchlistTab === +watchListSequenceNumber) {
        subscribeLtpData([newSecurityObj])
      }
    })

    // // Removing script from the unchecked watchlist
    uncheckedWatchlistIds.forEach(async (watchListId: string) => {
      const watchListSequenceNumber = watchListIndex[watchListId]
      const currentWatchList =
        watchlistSequenceNumberIndexing[watchListSequenceNumber]
      let securitiesArray = currentWatchList.watchlistSecurities
      if (securitiesArray === null) {
        securitiesArray = []
      }

      const newSecuritiesArray = Array.from(securitiesArray).filter(
        (stock: T_SCRIPTS_OBJ) =>
          stock.scriptId !== bookMarkedScriptId.toString()
      )

      await actions.setSecurityArray({
        securitiesArray: newSecuritiesArray,
        watchlistId: watchListId
      })

      // update the default securities array in WatchlistToSecuritiesArray redux
      await actions.setWatchlistToSecuritiesArray({
        securitiesArray: newSecuritiesArray,
        watchlistId: watchListId
      })

      // forming data to unsubscribe
      const newSecurityObj = {
        scriptId: bookMarkedScriptId.toString()
      }
      if (+selectedWatchlistTab === +watchListSequenceNumber) {
        unSubscribeLtpData([newSecurityObj])
      }
    })
  }

  handleAddToWatchList = async () => {
    const { stockAvailableInWatchListArray, bookMarkedScriptId } = this.state
    const {
      getStockWatchlistMasterData,
      actions,
      handleError,
      addScriptToParticularWatchlist,
      getTempStockWatchlistMasterData,
      watchListIndex,
      watchlistSequenceNumberIndexing
    } = this.props

    let stockAvailableInWatchListArray_Before =
      getStockWatchlistMasterData[bookMarkedScriptId] || []

    if (addScriptToParticularWatchlist) {
      stockAvailableInWatchListArray_Before =
        getTempStockWatchlistMasterData[bookMarkedScriptId] || []
    }

    const removedWatchlistArray = stockAvailableInWatchListArray_Before.filter(
      (watchListId: string) =>
        !stockAvailableInWatchListArray.includes(watchListId)
    )

    const addedWatchlistArray = stockAvailableInWatchListArray.filter(
      (watchListId: string) =>
        !stockAvailableInWatchListArray_Before.includes(watchListId)
    )

    // bulkUpdateWatchlist
    if (removedWatchlistArray.length || addedWatchlistArray.length) {
      let requestData: I_BULK_UPDATE_WATCHLIST_PAYLOAD = {
        checkedWatchlistIds: addedWatchlistArray,
        uncheckedWatchlistIds: removedWatchlistArray,
        scriptId: bookMarkedScriptId.toString()
      }

      if (addScriptToParticularWatchlist) {
        if (addedWatchlistArray.length) {
          const currentWatchlistId = addedWatchlistArray[0]
          const watchListSequenceNumber = watchListIndex[currentWatchlistId]
          const currentWatchList =
            watchlistSequenceNumberIndexing[watchListSequenceNumber]
          const currentSecuritiesArray =
            currentWatchList.watchlistSecurities || []

          const newSequenceNumber =
            (currentSecuritiesArray?.[currentSecuritiesArray?.length - 1]
              ?.sequenceNumber as number) + 1 || 0

          const newSecuritiesArray: T_SCRIPTS_OBJ[] = [
            ...currentSecuritiesArray,
            {
              scriptId: bookMarkedScriptId.toString(),
              sequenceNumber: newSequenceNumber
            }
          ]

          const newSecuritiesArrayPostSequenceAdjust: T_SCRIPTS_OBJ[] = []

          newSecuritiesArray.forEach((item: T_SCRIPTS_OBJ, index: number) => {
            newSecuritiesArrayPostSequenceAdjust.push({
              ...item,
              sequenceNumber: index
            })
          })

          const updateScriptsRequestData: IUpdateScriptsSequencePayload = {
            params: {
              watchlistId: currentWatchlistId
            },
            dataPayload: {
              watchlistSecurities: newSecuritiesArrayPostSequenceAdjust
            }
          }

          const updateScriptsSequenceResponse =
            await actions.updateScriptsSequence(updateScriptsRequestData)

          if (updateScriptsSequenceResponse?._isCustomError) {
            // if api fails then keeping the previous sequence ready
            this.setState({
              stockAvailableInWatchListArray:
                stockAvailableInWatchListArray_Before
            })
            // const newResponse = updateScriptsSequenceResponse.error // TEMPORARY HANDLING, INFORMED STRUCTURE CHANGES TO SWASTIK
            // handleError(newResponse)
            console.log('failed API - updateScriptsSequenceResponse')
            handleError(updateScriptsSequenceResponse)
            return
          }
          enqueueNotistack({
            message: 'Watchlist Updated!'
          })
        } else if (removedWatchlistArray.length) {
          const bulkUpdateWatchlistResponse =
            await actions.bulkUpdateWatchlist(requestData)
          if (bulkUpdateWatchlistResponse._isCustomError) {
            console.log('failed API - bulkUpdateWatchlist')

            this.setState({
              stockAvailableInWatchListArray:
                stockAvailableInWatchListArray_Before
            })
            handleError(bulkUpdateWatchlistResponse)
            return
          }

          enqueueNotistack({
            message: 'Watchlist Updated!'
          })

          const { data } = bulkUpdateWatchlistResponse
          const { failureIds } = data
          let stockAvailableInWatchListArrayPostFailureExclusion: string[] = []

          if (failureIds?.length) {
            requestData = await this.getFinalRequestDataPostHandlingFailureIds(
              failureIds,
              requestData
            )

            stockAvailableInWatchListArrayPostFailureExclusion =
              stockAvailableInWatchListArray?.filter((watchListId: string) => {
                const flag = failureIds.includes(watchListId)
                if (!flag) {
                  stockAvailableInWatchListArrayPostFailureExclusion.push(
                    watchListId
                  )
                }
                return !flag
              })
          }
        }

        await this.handleAddRemoveScriptsLocally(requestData)

        // if (addScriptToParticularWatchlist) {
        stockAvailableInWatchListArray_Before =
          getStockWatchlistMasterData[bookMarkedScriptId] || []
        const tempStockAvailableInWatchListArray = [
          ...stockAvailableInWatchListArray_Before
        ]
        if (removedWatchlistArray.length) {
          const removedWatchListId = removedWatchlistArray[0]
          const output =
            stockAvailableInWatchListArray_Before.indexOf(removedWatchListId)
          tempStockAvailableInWatchListArray.splice(output, 1)
        } else if (addedWatchlistArray.length) {
          const addedWatchlistId = addedWatchlistArray[0]
          tempStockAvailableInWatchListArray.push(addedWatchlistId)
        }

        await actions.setNewStockWatchListArray({
          stockAvailableInWatchListArray: tempStockAvailableInWatchListArray,
          bookMarkedScriptId: bookMarkedScriptId.toString()
        })

        await actions.setNewTemporaryStockWatchlistData({
          stockAvailableInWatchListArray,
          bookMarkedScriptId: bookMarkedScriptId.toString()
        })

        return
        // }
      } else {
        const bulkUpdateWatchlistResponse =
          await actions.bulkUpdateWatchlist(requestData)

        if (bulkUpdateWatchlistResponse._isCustomError) {
          console.log('failed API - bulkUpdateWatchlist')

          this.setState({
            stockAvailableInWatchListArray:
              stockAvailableInWatchListArray_Before
          })
          handleError(bulkUpdateWatchlistResponse)
          return
        }

        enqueueNotistack({
          message: 'Watchlist Updated!'
        })

        const { data } = bulkUpdateWatchlistResponse
        const { failureIds } = data
        let stockAvailableInWatchListArrayPostFailureExclusion: string[] = []

        if (failureIds?.length) {
          requestData = await this.getFinalRequestDataPostHandlingFailureIds(
            failureIds,
            requestData
          )

          stockAvailableInWatchListArrayPostFailureExclusion =
            stockAvailableInWatchListArray?.filter((watchListId: string) => {
              const flag = failureIds.includes(watchListId)
              if (!flag) {
                stockAvailableInWatchListArrayPostFailureExclusion.push(
                  watchListId
                )
              }
              return !flag
            })
        }

        await this.handleAddRemoveScriptsLocally(requestData)

        const finalStockAvailableInWatchListArray = failureIds?.length
          ? stockAvailableInWatchListArrayPostFailureExclusion
          : stockAvailableInWatchListArray

        // Add Remove from stockWatchlist Master
        await actions.setNewStockWatchListArray({
          stockAvailableInWatchListArray: finalStockAvailableInWatchListArray,
          bookMarkedScriptId: bookMarkedScriptId.toString()
        })
        this.handleCloseAddToWatchlistModal()

        if (failureIds?.length) {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      }
    }
  }

  getFinalRequestDataPostHandlingFailureIds = async (
    failureIds: string[],
    requestData: I_BULK_UPDATE_WATCHLIST_PAYLOAD
  ) => {
    const { checkedWatchlistIds, uncheckedWatchlistIds, scriptId } = requestData

    const finalCheckedWatchlistIds = checkedWatchlistIds?.filter(
      (watchListId: string) => !failureIds.includes(watchListId)
    )

    const finalUnCheckedWatchlistIds = uncheckedWatchlistIds?.filter(
      (watchListId: string) => !failureIds.includes(watchListId)
    )

    const newRequestData = {
      checkedWatchlistIds: finalCheckedWatchlistIds,
      uncheckedWatchlistIds: finalUnCheckedWatchlistIds,
      scriptId
    }

    return newRequestData
  }

  handleCheckBoxChangeFinal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { stockAvailableInWatchListArray } = this.state
    const watchListId = e.target.value
    const output = stockAvailableInWatchListArray.indexOf(watchListId)

    const tempStockAvailableInWatchListArray = [
      ...stockAvailableInWatchListArray
    ]

    if (output !== -1) {
      tempStockAvailableInWatchListArray.splice(output, 1)
      this.setState(
        {
          stockAvailableInWatchListArray: tempStockAvailableInWatchListArray
        },
        () => {
          const { stockAvailableInWatchListArray, bookMarkedScriptId } =
            this.state
          const { getStockWatchlistMasterData } = this.props
          const stockAvailableInWatchListArray_Before =
            getStockWatchlistMasterData[bookMarkedScriptId] || []

          this.setState({
            hasCheckBoxSelectionChanged: !areEqual(
              stockAvailableInWatchListArray_Before,
              stockAvailableInWatchListArray
            )
          })
        }
      )
      return
    }
    this.setState(
      {
        stockAvailableInWatchListArray: [
          ...stockAvailableInWatchListArray,
          watchListId
        ]
      },
      () => {
        const { stockAvailableInWatchListArray, bookMarkedScriptId } =
          this.state
        const { getStockWatchlistMasterData } = this.props
        const stockAvailableInWatchListArray_Before =
          getStockWatchlistMasterData[bookMarkedScriptId] || []
        this.setState({
          hasCheckBoxSelectionChanged: !areEqual(
            stockAvailableInWatchListArray_Before,
            stockAvailableInWatchListArray
          )
        })
      }
    )
  }

  handleIndividualBookMarkChange = () => {
    const { stockAvailableInWatchListArray } = this.state
    const { watchlistSequenceNumberIndexing, selectedWatchlistTab } = this.props
    const watchListId =
      watchlistSequenceNumberIndexing[+selectedWatchlistTab].watchlistId
    const output = stockAvailableInWatchListArray.indexOf(watchListId)
    const tempStockAvailableInWatchListArray = [
      ...stockAvailableInWatchListArray
    ]

    if (output !== -1) {
      tempStockAvailableInWatchListArray.splice(output, 1)
      this.setState(
        {
          stockAvailableInWatchListArray: tempStockAvailableInWatchListArray
        },
        () => {
          this.handleAddToWatchList()
        }
      )
      return
    }
    this.setState(
      {
        stockAvailableInWatchListArray: [
          ...stockAvailableInWatchListArray,
          watchListId
        ]
      },
      () => {
        this.handleAddToWatchList()
      }
    )
  }

  render() {
    const {
      hasCheckBoxSelectionChanged,
      bookMarkedScriptId,
      stockAvailableInWatchListArray
    } = this.state

    const {
      watchListData,
      getStockWatchlistMasterData,
      isBulkUpdateAPILoading,
      breakpoints,
      openAddToWatchlistModal
    } = this.props

    const stockAvailableInWatchListArray_Before =
      getStockWatchlistMasterData[bookMarkedScriptId] || []

    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        <DsPopup
          primaryButtonText='Save'
          secondaryButtonText='Cancel'
          showClose={!isDesktop}
          primaryButtonProps={{
            onClick: this.handleAddToWatchList,
            disabled: !hasCheckBoxSelectionChanged || isBulkUpdateAPILoading
          }}
          secondaryButtonProps={{
            onClick: this.handleCloseAddToWatchlistModal,
            sx: { display: { xs: 'none', md: 'block' } }
          }}
          open={openAddToWatchlistModal}
          onClose={this.handleCloseAddToWatchlistModal}
          DsBottomSheetProps={{
            sx: {
              '.MuiDialogContent-root': {
                marginTop: 'var(--ds-spacing-zero)'
              }
            },
            keepMounted: false
          }}
          DsDialogProps={{
            sx: {
              '.MuiDialogContent-root': {
                marginTop: 'var(--ds-spacing-zero)'
              }
            },
            keepMounted: false
          }}
        >
          <DsBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 'var(--ds-spacing-mild)'
            }}
          >
            <DsTypography variant='headingBoldLarge'>
              Choose Watchlist{watchListData.length > 3 ? 's' : ''}
            </DsTypography>
            <CreateWatchlist componentType='search' />
          </DsBox>
          <DsFormGroup>
            {watchListData.map((item: watchlistObj) => {
              const {
                watchlistType,
                watchlistSecurities,
                watchlistId,
                watchlistName
              } = item
              if (watchlistType === 'PRE-DEFINED') {
                return false
              }

              const shouldCheck =
                stockAvailableInWatchListArray.includes(watchlistId)

              const wasPartOfWatchlistBefore =
                stockAvailableInWatchListArray_Before.includes(watchlistId)

              const shouldDisable =
                watchlistSecurities?.length === 50 && !wasPartOfWatchlistBefore

              return (
                <DsFormControlLabel
                  key={watchlistId}
                  disabled={shouldDisable}
                  sx={{ width: 'fit-content' }}
                  control={
                    <DsCheckbox
                      checked={shouldCheck}
                      value={watchlistId}
                      onChange={e => this.handleCheckBoxChangeFinal(e)}
                    />
                  }
                  label={watchlistName}
                />
              )
            })}
          </DsFormGroup>
        </DsPopup>
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const watchListData = getWatchListData(state)
  const getStockWatchlistMasterData = getStockWatchlistMaster(state)
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const watchListIndex = getWatchListIndex(state)
  const addScriptToParticularWatchlist =
    shouldAddScriptToParticularWatchlist(state)
  const getTempStockWatchlistMasterData = getTempStockWatchlistMaster(state)
  const watchlistSequenceNumberIndexing =
    getWatchlistSequenceNumberIndexing(state)
  return {
    watchListData,
    getStockWatchlistMasterData,
    selectedWatchlistTab,
    watchListIndex,
    addScriptToParticularWatchlist,
    getTempStockWatchlistMasterData,
    watchlistSequenceNumberIndexing
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setSecurityArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setSecurityArray(requestData)),
    setWatchlistToSecuritiesArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setWatchlistToSecuritiesArray(requestData)),
    updateScriptsSequence: (requestData: IUpdateScriptsSequencePayload) =>
      dispatch(updateScriptsSequenceAction(requestData)),
    bulkUpdateWatchlist: (requestData: I_BULK_UPDATE_WATCHLIST_PAYLOAD) =>
      dispatch(bulkUpdateWatchlistAction(requestData)),
    setNewStockWatchListArray: (requestData: {
      stockAvailableInWatchListArray: string[]
      bookMarkedScriptId: string
    }) => dispatch(setNewStockWatchListArrayAction(requestData)),
    setNewTemporaryStockWatchlistData: (requestData: any) =>
      dispatch(setNewTemporaryStockWatchlistData(requestData))
  }
})

export default withBreakpoints(
  withStockSubUnSub(
    withErrorConnect(mapStateToProps, mapDispatchToProps)(AddToWatchlist)
  )
)
