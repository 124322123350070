import React from 'react'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { DsBox, DsList, enqueueNotistack } from '@am92/react-design-system'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import fetchWatchlistsAction, {
  fetchWatchlistsServiceName
} from '~/src/Redux/WatchList/Services/FetchWatchlists.Service'
import {
  getSelectedWatchlistTab,
  getWatchlist,
  getWatchListIndex,
  getWatchListLength
} from '~/src/Redux/WatchList/Selectors'
import {
  selectWatchlistTab,
  setSecurityArray,
  setWatchlistToSecuritiesArray,
  T_SCRIPTS_OBJ,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import updateScriptsSequenceAction, {
  IUpdateScriptsSequenceObject,
  IUpdateScriptsSequencePayload
} from '~/src/Redux/WatchList/Services/UpdateScriptsSequence.Service'
import { T_StockWatchlistMasterData } from '~/src/Redux/StockWatchlistMaster/Reducer'
import { updateStockWatchlistMasterAction } from '~/src/Redux/StockWatchlistMaster/Actions'
import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'
import SecuritiesList from './SecuritiesList'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import EmptyWatchListScreen from './EmptyWatchListScreen'
import { DEEP_LINK_KEYS } from '~/src/Constants/APP_CONSTANTS'
import { getDeepLinkAttributes } from '~/src/Redux/AppState/selectors'
import { resetDeepLinkData } from '~/src/Redux/AppState/reducer'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'
import { getServiceSelector } from '~/src/Redux/ServiceTracker/Selectors'
import { TDeepLink } from '~/src/Redux/AppState/TYPES'

export type T_SET_SECURITIES_REQ_DATA = {
  securitiesArray: T_SCRIPTS_OBJ[] | null
  watchlistId: string
}

type ActionTypes = {
  fetchWatchlist: () => Promise<any>
  updateScriptsSequence: (
    reqData: IUpdateScriptsSequencePayload
  ) => Promise<any>
  setSecurityArray: (reqData: T_SET_SECURITIES_REQ_DATA) => Promise<any>
  updateStockWatchlistMaster: (
    requestData: T_StockWatchlistMasterData
  ) => Promise<any>
  setWatchlistToSecuritiesArray: (
    reqData: T_SET_SECURITIES_REQ_DATA
  ) => Promise<any>
  setSelectedWatchlistTab: (reqData: number | string) => Promise<any>
  resetDeepLinkData: () => Promise<any>
}

export interface IWatchlistContainerProps extends IWithRouterProps {
  actions: ActionTypes
  selectedWatchlistTab: number
  setOpen: any
  handleError: (res: any) => void
  currentWatchlistId: string
  currentWatchlistSecurities: T_SCRIPTS_OBJ[] | null
  deepLinkAttributes: TDeepLink
  watchListIndex: {
    [key: string]: string
  }
  subscribeLtpData: (scriptArray: any[]) => void
  unSubscribeLtpData: (scriptArray: any[]) => void
  isWatchlistApiLoading: boolean
  hasWatchlistApiFailed: boolean
  watchlistLength: number
}

interface IWatchlistContainerState {
  hover: number
  selectedScript: string
  disableMounseHover: boolean
}

class WatchlistContainer extends React.PureComponent<IWatchlistContainerProps> {
  state: IWatchlistContainerState = {
    hover: -1,
    selectedScript: '-1',
    disableMounseHover: false
  }

  async componentDidMount(): Promise<void> {
    const { watchlistLength } = this.props

    //SECTION - if watchlist already present, subscribe the stocks
    if (watchlistLength) {
      this.subscribeWatchListStocks()
      return
    }

    //SECTION - if watchlist not present, call watchlist api
    this.getWatchListData()
  }

  async componentDidUpdate(
    prevProps: Readonly<IWatchlistContainerProps>
  ): Promise<void> {
    const {
      actions,
      selectedWatchlistTab,
      watchListIndex,
      deepLinkAttributes
    } = this.props

    const { currentWatchlistSecurities: PREV_SECURITY_ARRAY } = prevProps

    if (prevProps.selectedWatchlistTab !== selectedWatchlistTab) {
      await this.unsubscribeWatchListStocks(PREV_SECURITY_ARRAY)
      this.subscribeWatchListStocks()
    }

    if (deepLinkAttributes && prevProps.watchListIndex !== watchListIndex) {
      const { path, parameterList } = deepLinkAttributes
      if (path === DEEP_LINK_KEYS.WATCHLIST) {
        // get watchlist tab number based on watchlist id
        const watchlistId = parameterList[0]
        const currentWatchlistSequence = watchListIndex[watchlistId]
        // only take to that specific watchlist if id is present,
        // otherwise take it to first available watchlist by default
        if (currentWatchlistSequence) {
          actions.setSelectedWatchlistTab(+currentWatchlistSequence)
        }
        actions.resetDeepLinkData()
      }
    }
  }

  componentWillUnmount(): void {
    this.unsubscribeWatchListStocks()
  }

  getWatchListData = async () => {
    const { actions, handleError } = this.props
    const fetchWatchlistsResponse = await actions.fetchWatchlist()
    if (fetchWatchlistsResponse._isCustomError) {
      handleError(fetchWatchlistsResponse)
      console.log('failed API - fetchWatchlist')
      return
    }

    this.subscribeWatchListStocks()

    const stockWatchlistData: T_StockWatchlistMasterData = {}
    fetchWatchlistsResponse.forEach((watchlist: watchlistObj) => {
      const { watchlistSecurities, watchlistId, watchlistType } = watchlist
      if (watchlistType === 'PRE-DEFINED') {
        return false
      }
      watchlistSecurities?.forEach((stockObj: T_SCRIPTS_OBJ) => {
        const { scriptId } = stockObj
        if (!stockWatchlistData[scriptId]) {
          stockWatchlistData[scriptId] = [watchlistId]
          return
        }
        stockWatchlistData[scriptId] = [
          ...stockWatchlistData[scriptId],
          watchlistId
        ]
      })
    })

    actions.updateStockWatchlistMaster(stockWatchlistData)
  }

  subscribeWatchListStocks = () => {
    const { currentWatchlistSecurities, subscribeLtpData } = this.props
    const securitiesArray = currentWatchlistSecurities
    if (securitiesArray && securitiesArray?.length > 0) {
      subscribeLtpData(securitiesArray)
    }
  }

  unsubscribeWatchListStocks = async (
    PREV_SECURITY_ARRAY: any = this.props.currentWatchlistSecurities
  ) => {
    const { unSubscribeLtpData } = this.props
    if (PREV_SECURITY_ARRAY && PREV_SECURITY_ARRAY.length > 0) {
      unSubscribeLtpData(PREV_SECURITY_ARRAY)
    }
  }

  handleHover = (sequenceNumber: number) => {
    this.setState({ hover: sequenceNumber })
  }

  handleLeaveHover = () => {
    this.setState({ hover: -1, selectedScript: '-1' })
  }

  handleViewMore = (sequenceNumber: any) => {
    this.setState({ selectedScript: sequenceNumber })
  }

  handleDragEnd = async (result: DropResult) => {
    const source = result.source.index
    const destination = result.destination?.index

    this.setState({
      disableMounseHover: false
    })

    if (
      destination === source ||
      destination === null ||
      destination === undefined
    ) {
      return
    }

    const {
      actions,
      currentWatchlistSecurities: securitiesArray,
      currentWatchlistId
    } = this.props

    const newItems = Array.from(securitiesArray as T_SCRIPTS_OBJ[])
    const [removed] = newItems.splice(source, 1)
    newItems.splice(destination, 0, removed)

    let payloadArray: IUpdateScriptsSequenceObject[] = []

    newItems.reverse().forEach((item: T_SCRIPTS_OBJ, index: number) => {
      payloadArray.push({
        ...item,
        sequenceNumber: index
      })
    })

    actions.setSecurityArray({
      securitiesArray: payloadArray,
      watchlistId: currentWatchlistId
    })

    const requestData: IUpdateScriptsSequencePayload = {
      params: {
        watchlistId: currentWatchlistId
      },
      dataPayload: {
        watchlistSecurities: payloadArray
      }
    }

    const updateScriptsSequenceResponse =
      await actions.updateScriptsSequence(requestData)

    if (updateScriptsSequenceResponse?._isCustomError) {
      actions.setSecurityArray({
        securitiesArray: securitiesArray?.length
          ? securitiesArray?.reverse()
          : [],
        watchlistId: currentWatchlistId
      })
      console.log('failed API - updateScriptsSequence')

      enqueueNotistack({
        message: 'Request failed!'
      })
      return
    }

    // update the default securities array in WatchlistToSecuritiesArray redux
    actions.setWatchlistToSecuritiesArray({
      securitiesArray: payloadArray,
      watchlistId: currentWatchlistId
    })

    enqueueNotistack({
      message: 'Script Sequence Updated!'
    })
  }

  handleDragStart = () => {
    this.setState({
      disableMounseHover: true,
      hover: -1
    })
  }

  render() {
    const { hover, selectedScript, disableMounseHover } = this.state
    const {
      currentWatchlistSecurities: securitiesArray,
      isWatchlistApiLoading,
      hasWatchlistApiFailed
    } = this.props
    const securitiesArrayLength = securitiesArray?.length
    const isWatchListEmtpy =
      securitiesArrayLength === 0 || securitiesArray === null
    if (isWatchListEmtpy) {
      return <EmptyWatchListScreen />
    }

    return (
      <DragDropContext
        onDragEnd={this.handleDragEnd}
        onDragStart={this.handleDragStart}
      >
        <DsBox
          sx={{
            borderTop: '1px solid var(--ds-colour-strokeDefault)'
          }}
        >
          <DsList
            sx={{
              paddingBottom: '0px !important',
              paddingTop: '0px !important'
            }}
          >
            <Droppable droppableId='list'>
              {provided => (
                <DsBox {...provided.droppableProps} ref={provided.innerRef}>
                  <SecuritiesList
                    hover={hover}
                    handleHover={this.handleHover}
                    selectedScript={selectedScript}
                    disableMounseHover={disableMounseHover}
                    handleLeaveHover={this.handleLeaveHover}
                    handleViewMore={this.handleViewMore}
                  />
                  {provided.placeholder}
                </DsBox>
              )}
            </Droppable>
          </DsList>
        </DsBox>
      </DragDropContext>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const { currentWatchlistId, currentWatchlistSecurities } =
    getWatchlist(selectedWatchlistTab)(state) || {}
  const deepLinkAttributes = getDeepLinkAttributes(state)
  const watchListIndex = getWatchListIndex(state)
  const watchlistLength = getWatchListLength(state)
  const fetchWatchlistServiceAction = getServiceSelector(
    state,
    fetchWatchlistsServiceName
  )
  const isWatchlistApiLoading = fetchWatchlistServiceAction === 'LOADING'
  const hasWatchlistApiFailed = fetchWatchlistServiceAction === 'ERROR'

  return {
    currentWatchlistId,
    currentWatchlistSecurities,
    selectedWatchlistTab,
    deepLinkAttributes,
    watchListIndex,
    isWatchlistApiLoading,
    hasWatchlistApiFailed,
    watchlistLength
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    fetchWatchlist: () => dispatch(fetchWatchlistsAction()),
    updateScriptsSequence: (requestData: IUpdateScriptsSequencePayload) =>
      dispatch(updateScriptsSequenceAction(requestData)),
    setSecurityArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setSecurityArray(requestData)),
    updateStockWatchlistMaster: (requestData: T_StockWatchlistMasterData) =>
      dispatch(updateStockWatchlistMasterAction(requestData)),
    setWatchlistToSecuritiesArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setWatchlistToSecuritiesArray(requestData)),
    setSelectedWatchlistTab: (selectedWatchlistTab: number | string) =>
      dispatch(
        selectWatchlistTab({
          watchlistTab: selectedWatchlistTab
        })
      ),
    resetDeepLinkData: () => dispatch(resetDeepLinkData(null))
  }
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
    context: appContext
  })(WatchlistContainer)
)
