export const NODE_ENV = process.env.NODE_ENV
export const BABEL_ENV = process.env.BABEL_ENV

export const APP_NAME = process.env.APP_NAME
export const APP_TITLE = process.env.APP_TITLE || ''
export const APP_VERSION = process.env.APP_VERSION
export const APP_DESCRIPTION = process.env.APP_DESCRIPTION

export const SELF_DOMAIN = process.env.SELF_DOMAIN
export const ASSEST_DOMAIN = process.env.ASSEST_DOMAIN
export const APP_PRECONNECT_DOMAINS = process.env.APP_PRECONNECT_DOMAINS

export const ENABLE_CRYPTOGRAPHY = process.env.ENABLE_CRYPTOGRAPHY

export const API_KEY = process.env.API_KEY
export const API_TIMEOUT = process.env.API_TIMEOUT
export const API_DOMAIN = process.env.API_DOMAIN
export const AUTHORIZATION = process.env.AUTHORIZATION
export const X_API_CLIENT_ID = process.env.X_API_CLIENT_ID
export const X_SERVICE_NAME = process.env.X_SERVICE_NAME
export const AS_API_TIMEOUT = parseInt(process.env.AS_API_TIMEOUT || '', 10)

export const INITIATE_REDIRECTION_LINK = new URL(
  `${process.env.SELF_DOMAIN}/sso/authenticate`
).href

export const SEC_MASTER_S3_URL = process.env.SEC_MASTER_S3_URL as string

export const NOTIFICATIONS_URL = process.env.NOTIFICATIONS_URL as string

export const AS_DEFAULT_WSS_URL = process.env.DEFAULT_WSS_URL || ''
export const SEBI_DISCLOSURE = process.env.SEBI_DISCLOSURE
export const AS_REVERSE_FEED_WSS_URL = process.env.REVERSE_FEED_WSS_URL || ''

export const CLEVERTAP_ACCOUNT_ID = process.env.CLEVERTAP_ACCOUNT_ID
export const USE_CLEVERTAP = process.env.USE_CLEVERTAP
export const S3_ASSET_URL = process.env.S3_ASSET_URL
export const AS_TCS_WSS_URL = process.env.TCS_WSS_URL || ''
export const CONNECT_ODIN_SOCKET = process.env.CONNECT_ODIN_SOCKET === 'true'

console.log(NODE_ENV, 'NODE_ENV')
console.log(AS_REVERSE_FEED_WSS_URL, 'AS_REVERSE_FEED_WSS_URL')
