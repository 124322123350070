import { DsStack } from '@am92/react-design-system'
import React, { useEffect } from 'react'
import Header from './Header/Header'
import MobileHeader from './MobileHeader/MobileHeader'
import useResponsiveBreakpoints from '../hooks/useResponsiveBreakpoints'
import { TAppStore } from '../Configurations/AppStore'
import getCustomerProfileDetailsAction from '../Redux/Customer/Services/getCustomerProfileDetails'
import getHoldingsSummaryAction, {
  getHoldingsSummaryPayload
} from '../Redux/Dashboard/Services/getHoldingsSummary.Service'

import { getSSOAuthenticateData } from '../Redux/SSO/Selectors'
import { USE_CLEVERTAP } from '../Configurations/env'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import getNotificationsAction from '../Redux/Notifications/Services/getNotifications.Service'

interface IAppHeaderProps {
  handleShowMobileLayout: () => boolean
}

function AppHeader(props: IAppHeaderProps) {
  const getSSOAuthenticateDataSelector = useAppSelector((state: TAppStore) =>
    getSSOAuthenticateData(state)
  )
  const dispatch = useAppDispatch()
  const { handleShowMobileLayout } = props
  const { isDesktop } = useResponsiveBreakpoints()
  const handleGetCustomerProfileDetails = async () => {
    const response = await dispatch(getCustomerProfileDetailsAction())
    if (response._isCustomError) {
      console.log('failed API - getCustomerProfileDetails')
      return
    } else {
      if (USE_CLEVERTAP === 'true') {
        const { subAccountId, segmentsEnabled } = response
        const { lastLoggedInAt } = getSSOAuthenticateDataSelector?.metadata
        const activeSegments = Object.entries(segmentsEnabled)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key)
        ;(window as any)?.clevertap?.onUserLogin?.push({
          Site: {
            Identity: subAccountId,
            Entity_Id: subAccountId,
            Active_Segments: activeSegments,
            Last_Active: lastLoggedInAt
          }
        })
      }
    }
  }
  const handleGetHoldingsSummaryDetails = async () => {
    const requestData: getHoldingsSummaryPayload = {
      segment: 'EQ'
    }
    const getHoldingsSummaryResponse = await dispatch(
      getHoldingsSummaryAction(requestData)
    )
    if (getHoldingsSummaryResponse._isCustomError) {
      console.log('failed API - getHoldingsSummaryResponse')
      return
    }
  }
  useEffect(() => {
    dispatch(getNotificationsAction({}))
    handleGetCustomerProfileDetails()
    handleGetHoldingsSummaryDetails()
  }, [])
  return (
    <>
      {isDesktop && (
        <DsStack
          sx={{
            position: 'fixed',
            width: '100vw',
            top: 0,
            zIndex: 2,
            height:
              'calc(var(--ds-spacing-superheated) + var(--ds-spacing-quickFreeze))'
          }}
        >
          <Header />
        </DsStack>
      )}
      {handleShowMobileLayout() && (
        <DsStack
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            display: { md: 'none', xs: 'flex' }
          }}
        >
          <MobileHeader />
        </DsStack>
      )}
    </>
  )
}

export default AppHeader
