import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './selectors'
import {
  getDematListActions,
  getMultiplePortfolioActions,
  getMutualFundPortfolioActions,
  getMutualFundSchemesHoldingActions,
  getSecurityLimitActions,
  updateFormattedDematListAction,
  updatePortfolioSelectedTabAction,
  updateSelectedDematScriptAction,
  updateSelectedSecurityLimitScriptAction
} from './actions'
import localforage from 'localforage'
import { DEFAULT_VALUES } from '../Dashboard/Reducer'

import { _groupBy } from '~/src/Utils/lodash'
import {
  I_PORTFOLIO_REDUCER,
  INITIAL_STATE,
  ISingleMutualFundPortfolioData
} from './TYPES'
import { resetDataAction } from '../StoreActions'

export const multiPortfolioMapper: {
  [key: string]: string
} = {
  'Direct Transactions-Equity': 'stocks',
  'SmartEdge-Equity': 'smartedgeplus',
  'Managed Account-Beta-Equity': 'cat3',
  'Direct Transactions-MutualFund': 'MutualFund'
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedPortfolio: (state, action) => {
      state.selectedPortfolio = action?.payload
    },
    toggleMutualFundDisclaimer: (state, action) => {
      state.showMutualFundDisclaimer = !state.showMutualFundDisclaimer
    },
    toggleMutualFundSortAndFilter: (state, action) => {
      state.showMutualFundSortAndFilter = !state.showMutualFundSortAndFilter
    },
    mutualFundSortAndFilter: (state, action) => {
      state.mutualFundSortAndFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    changeSelectedSortAndFilterOption: (state, action) => {
      state.selectedSortAndFilterOption = action?.payload
    },
    resetOriginalMutualFundData: state => {
      if (!state.isDefaultFiltersEnabled) {
        state.mutualFundSortedAndFilteredData =
          state.mutualFundPortfolioData.portfolio.sort(
            (
              ITEM_A: ISingleMutualFundPortfolioData,
              ITEM_B: ISingleMutualFundPortfolioData
            ) => {
              const { investment: investedValueA } = ITEM_A
              const { investment: investedValueB } = ITEM_B
              return investedValueB - investedValueA
            }
          )
        state.mutualFundSortAndFilter = INITIAL_STATE.mutualFundSortAndFilter
        state.isDefaultFiltersEnabled = true
      }
      state.selectedSortAndFilterOption =
        INITIAL_STATE.selectedSortAndFilterOption
    },
    setSortedAndFilteredMutualFundData: (state, action) => {
      state.mutualFundSortedAndFilteredData = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(
      getMultiplePortfolioActions.success,
      (state, { payload }) => {
        const { porfolio: portfolio, portfolioSummary } = payload
        const multiplePortFolioSections = []
        let stockPortFolioCount = 0
        let mutualFundPortFolioCount = 0
        for (let i = 0; i < portfolio?.length; i++) {
          const { holdingType } = portfolio[i]
          if (holdingType === 'Equity') {
            stockPortFolioCount++
            continue
          }
          mutualFundPortFolioCount++
        }

        // create portfolio sections
        stockPortFolioCount &&
          multiplePortFolioSections.push({
            title: 'Stocks Portfolios',
            filter: 'MutualFund',
            filterType: false,
            gridTemplateColumns: '49% 49%'
          })

        mutualFundPortFolioCount &&
          multiplePortFolioSections.push({
            title: 'Mutual Funds',
            filter: 'MutualFund',
            filterType: true,
            gridTemplateColumns: '49%'
          })

        state.multiplePortFolioSections = multiplePortFolioSections
        state.multiplePortfolioData = {
          portfolioSummary,
          portfolio
        }
      }
    )
    builder.addCase(
      getMutualFundPortfolioActions.success,
      (state, { payload }) => {
        const { portfolio } = payload
        state.mutualFundPortfolioData = payload
        const mutualFundSortedAndFilteredData = portfolio.sort(
          (
            ITEM_A: ISingleMutualFundPortfolioData,
            ITEM_B: ISingleMutualFundPortfolioData
          ) => {
            const { investment: investedValueA } = ITEM_A
            const { investment: investedValueB } = ITEM_B
            return investedValueB - investedValueA
          }
        )
        state.mutualFundSortedAndFilteredData = mutualFundSortedAndFilteredData

        // initially setting the original data in this temperory source used for sorting and filtering
      }
    )
    builder.addCase(
      getMutualFundSchemesHoldingActions.success,
      (state, { payload }) => {
        state.mutualFundQuotesDetails = payload
      }
    )

    builder.addCase(getDematListActions.success, (state, { payload }) => {
      state.formattedDematList = payload
      state.filteredDematList = payload
    })

    builder.addCase(updateFormattedDematListAction, (state, { payload }) => {
      state.formattedDematList = payload
      state.filteredDematList = payload
    })
    builder.addCase(updatePortfolioSelectedTabAction, (state, { payload }) => {
      state.portfolioSelectedTab = payload
    })

    builder.addCase(updateSelectedDematScriptAction, (state, { payload }) => {
      state.selectedDematScript = payload
    })
    builder.addCase(getSecurityLimitActions.success, (state, { payload }) => {
      const {
        securityLimitList,
        totalCollateralAmount,
        formattedSecurityUnsettledLimitDetails,
        totalUnsettledAmount
      } = payload
      state.securityLimitList = securityLimitList
      state.totalCollateralAmount = totalCollateralAmount
      state.unsettledList = formattedSecurityUnsettledLimitDetails
      state.totalUnsettledAmount = totalUnsettledAmount
    })

    builder.addCase(
      updateSelectedSecurityLimitScriptAction,
      (state, { payload }) => {
        state.selectedSecurityLimitScript = payload
      }
    )

    builder.addCase(resetDataAction, (state, { payload }) => {
      state.selectedPortfolio = INITIAL_STATE.selectedPortfolio
    })
  }
}

function checkIfDefaultFiltersAreSet(state: I_PORTFOLIO_REDUCER) {
  const { mutualFundSortAndFilter } = state
  const { mutualFundFilter, sortBy } = mutualFundSortAndFilter
  const noFilters = mutualFundFilter.length === 0 ? true : false
  if (sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

const slice = createSlice(sliceOptions)

export const {
  setSelectedPortfolio,
  toggleMutualFundDisclaimer,
  toggleMutualFundSortAndFilter,
  mutualFundSortAndFilter,
  changeSelectedSortAndFilterOption,
  resetOriginalMutualFundData,
  setSortedAndFilteredMutualFundData
} = slice.actions

export const portfolioPersistConfig = {
  key: SLICE_NAME,
  version: 1,
  storage: localforage,
  whitelist: ['selectedPortfolio']
}

export default slice.reducer
