import { DsPopup } from '@am92/react-design-system'
import React, { FunctionComponent, useEffect, useState } from 'react'
import SearchStocks from '../Components/Sidebar/Components/Search2'
import withRouter, { IWithRouterProps } from '../Lib/withRouter'

interface TShortCutSearchProps extends IWithRouterProps {}

const ShortCutSearch: FunctionComponent<TShortCutSearchProps> = props => {
  const [showSearch, setShowSearch] = useState<true | false>(false)
  const [searchString, setSearchString] = useState<string>('')
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const showSearchPopup = (value: boolean) => {
    setShowSearch(value)
  }
  const setString = (searchString: string) => {
    setSearchString(searchString)
  }
  const handleOnClose = () => {
    showSearchPopup(false)
    setSearchString('')
  }

  const handleKeyDown = (event: any) => {
    if (
      ((event.ctrlKey || event.metaKey) && event.key === 'f') ||
      (event.shiftKey && event.key === 'F8')
    ) {
      event.preventDefault()
      showSearchPopup(true)
    }
  }

  const handleStockSelect = (scriptId: string) => {
    const { navigateTo } = props
    handleOnClose()
    navigateTo(`/quotes/${scriptId}`)
  }

  return (
    <>
      <DsPopup
        showClose={true}
        open={showSearch}
        onClose={handleOnClose}
        DsDialogProps={{
          sx: {
            '.MuiDialogActions-root': {
              marginTop: 'var(--ds-spacing-zero)'
            },
            '.MuiDialogContent-root': {
              marginTop: 'var(--ds-spacing-zero)',
              height: '33rem'
            },
            display: { md: 'block', xs: 'none' }
          },
          keepMounted: false
        }}
      >
        {showSearch && (
          <SearchStocks
            openSearch={showSearch}
            searchString={searchString}
            setString={setString}
            handleStockSelect={handleStockSelect}
          />
        )}
      </DsPopup>
    </>
  )
}

export default withRouter(ShortCutSearch)
