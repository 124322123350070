import { servilienceEventsActions } from '../actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { asHttp } from '~/src/Configurations/WebHttp'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'
export interface IServilienceEventPayload {
  message: string
  userConcent: boolean
  scriptId: string
  isPersist: boolean
  metadata: any
}
async function servilienceEvents(payload: IServilienceEventPayload) {
  const options = {
    url: `/code/PLACE_ORDER/event`,
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }
  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}
const servilienceEventsAction = serviceActionCreator(
  servilienceEventsActions,
  servilienceEvents
)
export default servilienceEventsAction
