import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsRadioGroup, DsStack, DsTypography } from '@am92/react-design-system'
import {
  getCommonIsinCodeScriptIdArray,
  getOrderScriptId,
  getSelectedExchange,
  isModifyOrderRequest
} from '~/src/Redux/Orders/selectors'
import ExchangeSelection from './ExchangeSelection'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'
import StockTickerNew from '../../TickerBasedComponents/StockTickerNew'

export interface IOrderHeaderBottomProps extends IWithRouterProps {
  orderScriptId: string
  commonIsinCodeScriptArray: string[]
  selectedDefaultExchange: string
  isModifyOrder: boolean
  stockDetails: T_SCRIPTDATA
  subscribeLtpData: (scriptIdArray: any[]) => void
}

type IOrderHeaderBottomState = {
  selectedExchange: string
}

class OrderHeaderBottom extends Component<
  IOrderHeaderBottomProps,
  IOrderHeaderBottomState
> {
  //TODO - check if below code can be move to orderheader middle
  componentDidUpdate(prevProps: Readonly<IOrderHeaderBottomProps>): void {
    const { commonIsinCodeScriptArray, orderScriptId, subscribeLtpData } =
      this.props
    const { commonIsinCodeScriptArray: PREV_COMMON_ISIN_SCRIPT_ID_ARRAY } =
      prevProps

    if (commonIsinCodeScriptArray !== PREV_COMMON_ISIN_SCRIPT_ID_ARRAY) {
      const scriptArrayToSubscribe = []
      for (let i = 0; i < commonIsinCodeScriptArray.length; i++) {
        const scriptId = commonIsinCodeScriptArray[i]
        if (scriptId.toString() !== orderScriptId.toString()) {
          scriptArrayToSubscribe.push({ scriptId })
        }
      }
      subscribeLtpData(scriptArrayToSubscribe)
    }
  }

  render() {
    const {
      commonIsinCodeScriptArray,
      isModifyOrder,
      orderScriptId,
      stockDetails,
      selectedDefaultExchange
    } = this.props
    const {
      closeprice = 0,
      exchange = '',
      segment = ''
    } = (stockDetails as T_SCRIPTDATA) || {}

    return (
      <>
        <DsRadioGroup value={selectedDefaultExchange}>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            {(!isModifyOrder &&
              commonIsinCodeScriptArray.length > 1 &&
              commonIsinCodeScriptArray?.map(scriptId => {
                return <ExchangeSelection scriptId={scriptId} key={scriptId} />
              })) || (
              <>
                <DsStack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 'var(--ds-spacing-glacial)'
                  }}
                >
                  <DsTypography>{exchange}</DsTypography>
                  <StockTickerNew
                    scriptId={orderScriptId}
                    closeprice={closeprice}
                    segment={segment}
                    showNetChange={false}
                    LtpTypoProps={{
                      variant: 'bodyBoldSmall'
                    }}
                  />
                </DsStack>
              </>
            )}
          </DsStack>
        </DsRadioGroup>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderScriptId = getOrderScriptId(state)
  const commonIsinCodeScriptArray = getCommonIsinCodeScriptIdArray(state)
  const selectedDefaultExchange = getSelectedExchange(state)
  const isModifyOrder = isModifyOrderRequest(state)
  const stockDetails = getStockMasterData(orderScriptId)(state)

  return {
    orderScriptId,
    commonIsinCodeScriptArray,
    selectedDefaultExchange,
    isModifyOrder,
    stockDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(OrderHeaderBottom)
)
