import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { IwithBreakpoints, withBreakpoints } from '@am92/react-design-system'
import ContainerTab from '../../RenderTabs/ContainerTabs'
import {
  getRenderTabsList,
  getSelectedWatchlistTab,
  isNewWatchlistCreated,
  manageWatchlistIndicator
} from '~/src/Redux/WatchList/Selectors'
import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'
import {
  selectWatchlistTab,
  toggleManageWatchlist
} from '~/src/Redux/WatchList/Reducer'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

type ActionTypes = {
  setSelectedWatchlistTab: (reqData: number | string) => Promise<any>
  toggleManageWatchlist: (reqData: {
    isManageWatchlistClicked: boolean
  }) => Promise<any>
}
export interface IRenderTabObj {
  [index: string]: string
  label: string
  id: string
}
export interface ISidebarTabsProps extends IWithRouterProps, IwithBreakpoints {
  actions: ActionTypes
  selectedWatchlistTab: number | string
  watchlistCreatedIndicator: boolean
  renderTabs: IRenderTabObj[]
  isManageWatchlistClicked: boolean
}

class SidebarTabs extends Component<ISidebarTabsProps> {
  handleSelectedWatchlistTab = (selectedTab: string) => {
    const { actions, isManageWatchlistClicked } = this.props
    actions.setSelectedWatchlistTab(+selectedTab)
    if (selectedTab === '-1') {
      actions.toggleManageWatchlist({ isManageWatchlistClicked: true })
      return
    }
    //NOTE - below we have added the casing, to avoid dispatching toggleManageWatchlist action
    // when isManageWatchlistClicked is already false

    isManageWatchlistClicked &&
      actions.toggleManageWatchlist({
        isManageWatchlistClicked: false
      })
  }

  render() {
    const {
      selectedWatchlistTab,
      watchlistCreatedIndicator,
      breakpoints,
      renderTabs
    } = this.props

    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        <ContainerTab
          renderTabs={renderTabs}
          returnValue='id'
          enableArrow={true}
          containedTabs={isDesktop}
          value={+selectedWatchlistTab}
          onChange={e => {
            this.handleSelectedWatchlistTab(e)
          }}
          watchlistCreated={watchlistCreatedIndicator}
          isLowerCasingNeeded={true}
        />
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const renderTabs = getRenderTabsList(state)
  const isManageWatchlistClicked = manageWatchlistIndicator(state)
  const watchlistCreatedIndicator = isNewWatchlistCreated(state)
  return {
    renderTabs,
    selectedWatchlistTab,
    isManageWatchlistClicked,
    watchlistCreatedIndicator
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setSelectedWatchlistTab: (selectedWatchlistTab: number | string) =>
      dispatch(
        selectWatchlistTab({
          watchlistTab: selectedWatchlistTab
        })
      ),
    toggleManageWatchlist: (reqData: { isManageWatchlistClicked: boolean }) =>
      dispatch(toggleManageWatchlist(reqData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
    context: appContext
  })(SidebarTabs)
)
