import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsStack, DsRadio } from '@am92/react-design-system'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { getOrderBuyOrSell } from '~/src/Redux/Orders/selectors'
import StockTickerNew from '../../TickerBasedComponents/StockTickerNew'
import { updateDataOnExchangeChange } from '~/src/Redux/Orders/reducer'

type ActionTypes = {
  updateDataOnExchangeChange: (reqData: any) => Promise<any>
}

export interface IExchangeSelectionProps extends IWithRouterProps {
  actions: ActionTypes
  scriptId: string
  orderBuyOrSell: string
  stockDetails: T_SCRIPTDATA
}

class ExchangeSelection extends Component<IExchangeSelectionProps> {
  handleExchangeSelection = (exchange: string, scriptId: string) => {
    const { actions } = this.props
    actions.updateDataOnExchangeChange({
      orderScriptId: scriptId,
      selectedExchange: exchange
    })
  }

  render() {
    const { stockDetails, orderBuyOrSell, scriptId } = this.props
    const isBUY = orderBuyOrSell === 'BUY'
    const orderColor = isBUY ? 'supportPositive' : 'supportNegative'
    const { exchange, closeprice, segment } = stockDetails || {}
    return (
      <>
        <DsStack sx={{ flexDirection: 'column', flex: 1 }} key={exchange}>
          <DsRadio
            sx={{
              '& .Mui-checked': {
                color: `var(--ds-colour-${orderColor})`
              }
            }}
            label={exchange}
            value={exchange}
            onChange={() => {
              this.handleExchangeSelection(exchange, scriptId)
            }}
          />
          <DsStack
            sx={{
              flexDirection: 'row',
              gap: 'var(--ds-spacing-quickFreeze)',
              alignItems: 'center',
              ml: 'var(--ds-spacing-warm)'
            }}
          >
            <StockTickerNew
              scriptId={scriptId}
              closeprice={closeprice}
              segment={segment}
              showNetChange={false}
              LtpTypoProps={{
                variant: 'bodyBoldSmall'
              }}
            />
          </DsStack>
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const orderBuyOrSell = getOrderBuyOrSell(state)
  const stockDetails = getStockMasterData(scriptId)(state)
  return {
    stockDetails,
    orderBuyOrSell
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateDataOnExchangeChange: (requestData: any) =>
      dispatch(updateDataOnExchangeChange(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeSelection)
