import React from 'react'
import {
  DsBox,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

// constants
import { SEARCH_ZERO_SCREEN } from '~/src/Constants/ASSET_MAP'

// helper/util
const getZeroResultTextBySearchStringLength = (typeOfSearch: string) => {
  if (typeOfSearch === 'recentSearch') {
    return {
      firstLine: 'Not sure where to begin ?',
      secondLine: 'Try searching for AXIS or INFY'
    }
  }
  return {
    firstLine: 'No results found for your search.',
    secondLine: 'Please edit and try again.'
  }
}

export interface IZeroSearchResultProps {
  typeOfSearch: 'recentSearch' | 'stringSearch'
}

const ZeroSearchResult: React.FunctionComponent<IZeroSearchResultProps> = (
  props: IZeroSearchResultProps
) => {
  const { typeOfSearch } = props
  const handleZeroScreen = () => {
    const { firstLine, secondLine } =
      getZeroResultTextBySearchStringLength(typeOfSearch)
    return (
      <>
        <DsStack
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center ',
            width: '100%',
            height: '97%',
            gap: '1rem',
            position: 'absolute',
            zIndex: '1',
            backgroundColor: 'var(--ds-colour-surfacePrimary)'
          }}
        >
          <DsBox>
            <DsImage srcSet={SEARCH_ZERO_SCREEN} />
          </DsBox>
          <DsStack>
            <DsTypography variant='bodyRegularLarge'>{firstLine}</DsTypography>
            <DsTypography variant='bodyRegularLarge'>{secondLine}</DsTypography>
          </DsStack>
        </DsStack>
      </>
    )
  }

  if (typeOfSearch === 'recentSearch') {
    return (
      <DsBox sx={{ width: '100%', zIndex: 1000, height: 'max-content' }}>
        {handleZeroScreen()}
      </DsBox>
    )
  }

  return handleZeroScreen()
}

export default ZeroSearchResult
