import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { DsBox, DsDivider } from '@am92/react-design-system'

import AlertsFormContent from './AlertsFormContent'
import AlertsFormHeader from './AlertsFormHeader'

import { toggleAlertsFormActions } from '~/src/Redux/Alerts/Actions'
import { T_ALERT_FORM_DETAILS } from '~/src/Redux/Alerts/Reducer'
import {
  getAlertsFormDetails,
  getAlertsFormToggleSelector
} from '~/src/Redux/Alerts/Selectors'

import {
  appContext,
  TAppDispatch,
  TAppStore
} from '~/src/Configurations/AppStore'

import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'

interface IAlertsFormProps extends IWithRouterProps, PropsFromRedux {
  handleError: (error: any) => void
}

export class AlertsForm extends Component<IAlertsFormProps> {
  handleClose = async () => {
    const { actions } = this.props
    actions.toggleAlertsForm({
      toggle: false,
      isModify: false,
      data: null,
      scriptId: ''
    })
  }

  render() {
    const { handleError, alertFormToggle } = this.props
    return (
      <DsBox sx={{ width: { md: '24.75rem', sm: '100%' }, height: '100%' }}>
        <AlertsFormHeader handleClose={this.handleClose} />
        <DsDivider />
        {alertFormToggle && (
          <AlertsFormContent
            handleClose={this.handleClose}
            handleError={handleError}
          />
        )}
      </DsBox>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const alertFormDetails = getAlertsFormDetails(state)
  const alertFormToggle = getAlertsFormToggleSelector(state)
  return {
    alertFormDetails,
    alertFormToggle
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    toggleAlertsForm: (data: T_ALERT_FORM_DETAILS) =>
      dispatch(toggleAlertsFormActions(data))
  }
})
const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(AlertsForm)
