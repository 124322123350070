import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsBox, DsDivider } from '@am92/react-design-system'
import { Draggable } from 'react-beautiful-dnd'
import { T_SCRIPTS_OBJ } from '~/src/Redux/WatchList/Reducer'
import SingleSecurity from './SingleSecurity'
import {
  getSelectedWatchlistTab,
  getWatchlist
} from '~/src/Redux/WatchList/Selectors'
import { appContext } from '~/src/Configurations/AppStore'

type ActionTypes = {}

export interface ISecuritiesListProps extends IWithRouterProps {
  actions: ActionTypes
  securitiesArray: T_SCRIPTS_OBJ[] | null
  currentWatchlistType: string
  selectedScript: string
  disableMounseHover: boolean
  hover: number
  handleHover: (sequenceNumber: number) => void
  handleLeaveHover: () => void
  handleViewMore: (sequenceNumber: any) => void
}

class SecuritiesList extends Component<ISecuritiesListProps> {
  render() {
    const {
      securitiesArray,
      currentWatchlistType,
      selectedScript,
      disableMounseHover,
      hover,
      handleHover,
      handleViewMore,
      handleLeaveHover
    } = this.props
    return securitiesArray?.map(
      (singleSecurityData: T_SCRIPTS_OBJ, i: number) => {
        const { scriptId, sequenceNumber } = singleSecurityData
        const isDragDisabled =
          sequenceNumber !== Number(hover) ||
          currentWatchlistType === 'PRE-DEFINED'
        const isLastItemInList = securitiesArray.length - 1 === i
        return (
          <Draggable
            index={i}
            key={scriptId + i}
            draggableId={scriptId + i}
            isDragDisabled={isDragDisabled}
          >
            {provided => (
              <DsBox
                sx={{
                  marginBottom: {
                    xs:
                      (isLastItemInList && 'var(--ds-spacing-blazing)') ||
                      'var(--ds-spacing-zero)',
                    md:
                      (isLastItemInList && 'var(--ds-spacing-plasma)') ||
                      'var(--ds-spacing-zero)'
                  }
                }}
              >
                <SingleSecurity
                  sequenceNumber={sequenceNumber}
                  scriptId={scriptId}
                  selectedScript={selectedScript}
                  disableMounseHover={disableMounseHover}
                  hover={hover}
                  handleHover={handleHover}
                  handleLeaveHover={handleLeaveHover}
                  handleViewMore={handleViewMore}
                  securitiesIndex={i}
                  provided={provided}
                />
                <DsDivider
                  sx={{
                    margin: 'var(--ds-spacing-zero) var(--ds-spacing-mild)'
                  }}
                />
              </DsBox>
            )}
          </Draggable>
        )
      }
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const { currentWatchlistSecurities: securitiesArray, currentWatchlistType } =
    getWatchlist(selectedWatchlistTab)(state) || {}
  return {
    securitiesArray,
    currentWatchlistType
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})(SecuritiesList)
