import { DsBox, DsRemixIcon, DsTypography } from '@am92/react-design-system'
import React, { useEffect } from 'react'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import StockTickerNew from '../TickerBasedComponents/StockTickerNew'
import { TAppStore } from '~/src/Configurations/AppStore'
import { getAlertsFormDetails } from '~/src/Redux/Alerts/Selectors'
import { useAppSelector } from '~/src/hooks/useRedux'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

interface IAlertsFormHeaderProps {
  handleClose: () => void
  subscribeLtpData: (scriptArray: any[]) => void
  unSubscribeLtpData: (scriptArray: any[]) => void
}

const AlertsFormHeader = (props: IAlertsFormHeaderProps) => {
  const { handleClose, subscribeLtpData, unSubscribeLtpData } = props
  const alertFormDetails = useAppSelector((state: TAppStore) =>
    getAlertsFormDetails(state)
  )
  const { scriptId } = alertFormDetails
  const data: T_SCRIPTDATA =
    (window as any).secMaster.getByScriptId(scriptId) || {}
  useEffect(() => {
    if (scriptId) {
      subscribeLtpData([
        {
          scriptId
        }
      ])
    }
  }, [scriptId])
  const { segment, exchangeSymbol, coname, exchange, closeprice } = data || {}
  return (
    <DsBox
      display='flex'
      flexDirection='column'
      p='var(--ds-spacing-bitterCold)'
      gap='var(--ds-spacing-glacial)'
    >
      <DsBox display='flex' flexDirection='row' gap='0.5rem'>
        <DsRemixIcon
          className='ri-close-line'
          sx={{ cursor: 'pointer', ml: '-0.3rem' }}
          onClick={handleClose}
        />
        <DsTypography variant='headingBoldExtraSmall'>Price Alert</DsTypography>
      </DsBox>
      <DsBox
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <DsBox display='flex' flexDirection='column'>
          <DsTypography variant='bodyRegularLarge'>
            {segment === 'EQ' ? exchangeSymbol : coname}
          </DsTypography>
          <DsTypography variant='bodyRegularSmall'>{exchange}</DsTypography>
        </DsBox>
        <DsBox display='flex' flexDirection='column' alignItems='center'>
          <StockTickerNew
            scriptId={scriptId}
            closeprice={closeprice}
            segment={segment}
            withCurrencySymbol={false}
            showNetChange={false}
            LtpTypoProps={{
              variant: 'bodyBoldSmall'
            }}
            percentChangeNumberFormatOptions={{
              signDisplay: 'exceptZero'
            }}
            WrapperProps={{
              sx: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: 'var(--ds-spacing-deepFreeze)'
              }
            }}
          />
        </DsBox>
      </DsBox>
    </DsBox>
  )
}

export default withStockSubUnSub(AlertsFormHeader)
